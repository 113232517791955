import React, { useContext } from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Button, Modal, Spinner, Dropdown } from "react-bootstrap";
import {
  setAddLeadModalOpen,
  newLeadObj,
} from "../../Redux/Slices/newLeadSlice";
import styles from "./AddLeadModalNew.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {constants} from "../../piservices/constants";
import { useEffect } from "react";
import { getReq, postReq } from "../../piservices/apis";
// import { toast } from "react-toastify";
import toast from "react-hot-toast";
import MuiPhoneNumber from "material-ui-phone-number";
import { notification } from "antd";
import desquoContext from "../../picontext/DashBoard/desquoContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import useApiFetchCustomHook from "../../piservices/ApiFetchCustomHook";
import useApiFetchCustomHookForEmailService from "../../piservices/ApiFetchCustomHookForEmailService";
import { namingKeys } from "../../piservices/compCommon";
import { BiChevronDown } from "react-icons/bi";
import PhoneInput from "react-phone-input-2";
function EditLeadModal({ setProgress, show, onHide }) {
  const addLeadModalOpen = useSelector(
    (state) => state.newLeadSlice.addLeadModalOpen
  );
  const profileData = useSelector((state) => state.lmsSlice.profileData);
  console.log("profiledata", profileData);
  const context = useContext(desquoContext);
  const { activeLead } = context;
  const [leadFirstName, setLeadFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const dispatch = useDispatch();
  const [leadLastName, setLeadLastName] = useState("");
  const [phoneNumber, setPhonenumber] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [errorPin, setErrorPin] = useState(false);
  const [leadEmail, setLeadEmail] = useState("");
  const [leadCity, setLeadCity] = useState("");
  const [leadArea, setLeadArea] = useState("");
  const [leadAddress, setLeadAddress] = useState();
  const [workScope, setWorkScope] = useState([]);
  const [leadCityCode, setLeadCityCode] = useState();
  const [userData, setUserData] = useState({});
  const [leadSources, setLeadSources] = useState([]);
  const [leadWorkTypes, setLeadWorkTypes] = useState([]);
  const [filteredSourceName, setFilteredSourceName] = useState([]);
  const [areaUnit, setAreaUnit] = useState("");
  const [assignedToObject, setAssignedToObject] = useState("");
  const [discardOpen, setDiscardOpen] = useState(false);
  const [sourceNames, setSourceNames] = useState([]);
  const [leadSource, setLeadSource] = useState([]);
  const [leadWorkType, setLeadWorkType] = useState([]);
  const [sourceName, setSourceName] = useState([]);
  const [selectedLeadSource, setSelectedLeadSource] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [assignedToName, setAssignedToName] = useState("");
 
  const [tempLeadSource, setTempLeadSource] = useState();
  const [prevStateofAllValue, setPrevStateofAllValue] = useState({});
  const [selectedSourceName, setSelectedSourceName] = useState("");
  const [sourceNameArchitectDetails, setSourceNameArchitectDetails] = useState(
    {}
  );
  const [sourceInputName, setSourceInputName] = useState("");
  const [workScopeInput, setWorkScopeInput] = useState("");
  const [filteredLeadSourceName, setFilteredLeadSourceName] = useState([]);
  const [filteredWorkScopeName, setFilteredWorkScopeName] = useState([]);

  const [addNewLeadSource, setAddLeadNewSource] = useState("");
  const [addNewWorkScope, setAddNewWorkType] = useState("");
  const [addNewSourceName, setAddNewSourceName] = useState("");
  const [buttonClickedOnce, setButtonClickedOnce] = useState(false);
  const [leadSourceInput, setLeadSourceInput] = useState("");
  const [allStoreData, setAllStoreData] = useState([]);
  const [storeDataState, setstoreDataState] = useState("");
  const [formData, setFormData] = useState({});
  const [teamLeadArr, setTeamLeadArr] = useState([]);
  const [budget, setBudget] = useState();
  const [error, setError] = useState(false);

  const [inputFieldActive, setInputFieldActive] = useState(false);

  const [inputValueWorkScope, setInputValueWorkScope] = useState("");
  const [inputValueLeadSource, setInputValueLeadSource] = useState("");
  const [inputValueScopeName, setInputValueScopeName] = useState("");

  const [phoneNumerror,setphoneNumerror]=useState({
    bol:false,
    msg:""
  })

  const projectObj = useSelector((state) => state.newLeadObj);
  const tempObj = {
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    pinCode: "",
    phoneNumber: "",
    budget: "",
    requirements: "",
    leadSource: "",
    sourceName: "",
    assignedTo: "",
    area: "",
  };
  const { apiFetch } = useApiFetchCustomHook();
  let { tab } = useParams();
  const allNotificationsObjects = useSelector(
    (state) => state.notificationSlice.allNotificationsObjects
  );
  const { sendEmailPushBellNotifications } =
    useApiFetchCustomHookForEmailService();
    
console.log( "active lead",activeLead)
  console.log("selectedLeadSourceinedit", selectedLeadSource);
  useEffect(() => {
    


    setPrevStateofAllValue({
      name: activeLead?.name,
      phoneNumber: activeLead?.phoneNumber,
      budget: activeLead?.budget,
      email: activeLead?.email,
      address: activeLead?.address,
      city: activeLead?.city,
      pinCode: activeLead?.pinCode,
      requirements: activeLead?.requirements,
      leadSource: activeLead?.leadSource,
      sourceName: activeLead?.sourceName,
      assignedTo: activeLead?.assignedTo?._id,
      areaUnit: activeLead?.areaUnit,
      area: activeLead?.area,
    });
    setTempLeadSource(
      leadSources.find(
        (leadSource) => leadSource?.leadSource === activeLead?.leadSource
      )
    );

    
    const tempSourceName = sourceNames.find(
      (sourceName) => sourceName.sourceName === activeLead?.sourceName
    );
    setLeadFirstName(
      activeLead
        ? activeLead?.firstName
          ? activeLead?.firstName
          : activeLead?.name
        : ""
    );
    setLeadLastName(activeLead ? activeLead?.lastName : "");
    setPhonenumber(activeLead ? activeLead?.phoneNumber : "");
    setLeadEmail(activeLead ? activeLead?.email : "");
    setLeadCity(activeLead ? activeLead?.city : "");
    setLeadArea(activeLead ? activeLead?.area : "");
    setLeadAddress(activeLead ? activeLead?.address : "");
    setLeadCityCode(activeLead ? activeLead?.pinCode : "");
    setWorkScope(activeLead ? activeLead?.requirements : []);
    setSelectedLeadSource(activeLead?.leadSource );
    setAssignedToName(activeLead?.assignedTo?.fullName)

    
    // setLeadSource(activeLead ? activeLead?.leadSource : "")
    setLeadSource(tempLeadSource > -1 ? tempLeadSource.id : "");
    setAssignedTo(activeLead ? activeLead?.assignedTo?._id : "");
    // setstoreDataState(activeLead ? activeLead?.storeData : "")
    setSelectedSourceName(activeLead ? activeLead?.sourceName : "");
    setSourceName(tempSourceName ? tempSourceName?._id : "");
    setBudget(activeLead ? activeLead?.budget : "");
    setAreaUnit(activeLead?.areaUnit ? activeLead?.areaUnit : "sqft");
    setSourceNameArchitectDetails(
      activeLead ? activeLead?.architectDetails : {}
    );
  }, [activeLead, tempLeadSource]);
  const exceptThisSymbols2 = [
    "~",
    "`",
    "!",
    "#",
    "%",
    "$",
    "^",
    "&",
    "*",
    "(",
    " ",
    ")",
    "+",
    "=",
    "[",
    "_",
    "]",
    "'",
    ";",
    "/",
    "{",
    "}",
    "|",
    ,
    "<",
    ">",
    "?",
    ",",
  ];
  const leadFirstNameHandle = (e) => {
    if (e.target.value?.length <= 24) {
      if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
        setLeadFirstName(e.target.value);
        setFirstNameError(false);
      }
    } else {
      setFirstNameError(true);
    }
  };


  console.log(areaUnit)
  const leadLastNameHandle = (e) => {
    if (e.target.value?.length <= 24) {
      if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
        setLeadLastName(e.target.value);
        setLastNameError(false);
      }
    } else {
      setLastNameError(true);
    }
  };
  const handleWorkScopeInputChange = (event, newInputValue) => {
    setInputValueWorkScope(newInputValue);
    setAddNewWorkType(newInputValue);
  };
  const handleScopeNameInputChange = (event, newInputValue) => {
    setInputValueScopeName(newInputValue);
    setAddNewSourceName(newInputValue);
  };
  const handleInputValueInputChange = (event, newInputValue) => {
    setInputValueLeadSource(newInputValue);
    setAddLeadNewSource(newInputValue);
  };

  // const handlePhoneNumberChange = (event) => {
  //   const inputValue = event.replace(/[^0-9]/g, "");
  //   if (event.value?.length <= 10) {
  //     setPhonenumber(inputValue);
  //     if (/^\d+$/.test(inputValue) && inputValue.length <= 10) {
  //       if (inputValue.length <= 9) {
  //         setPhoneError(true);
  //       } else {
  //         setPhoneError(false);
  //       }
  //       setPhonenumber(inputValue);
  //     }
  //   }
  // };

  //  new handle change 
  const handlePhoneNumberChange = (event,country) => {
    console.log(event);
    let  inputValue = event.replace(/[^0-9]/g, "");
    inputValue=`${inputValue}`;
    console.log(country.dialCode.length,"dialCode")
    let newNumbvalue=inputValue.substring(country.dialCode.length);
    console.log(newNumbvalue,"countrycodevalue")
    

    if(newNumbvalue?.length<5){
      setphoneNumerror({
        bol:true,
        msg:"Number must be greater than 5"
      })
    }

    if(newNumbvalue?.length>=5){
      setphoneNumerror({
        bol:false,
        msg:""
      })
    }

    if(newNumbvalue?.length>15){
      setphoneNumerror({
        bol:true,
        msg:"Number must be less  than 15 Digits"
      })
    }else{
      let PhoneNum=`+${country.dialCode} ${newNumbvalue}`
      console.log(PhoneNum,"FinalNumber")
      setPhonenumber(PhoneNum);
    }
    // if (event?.length <= 10) {
    //   setPhonenumber(inputValue);
    //   if (/^\d+$/.test(inputValue) && inputValue.length <= 10) {
    //     if (inputValue.length <= 9) {
    //       setPhoneError(true);
    //     } else {
    //       setPhoneError(false);
    //     }
    //     setPhonenumber(inputValue);
    //   }
    // }
  };
  const filterSourceChange = (e) => {
    setSourceInputName(e.target.value);
    // var val = e.target.value;
    const filteredNames = sourceNames.filter((name) =>
      name.sourceName.toLowerCase().includes(sourceInputName.toLowerCase())
    );
    setFilteredSourceName(filteredNames);
    // setFilteredSourceName(sourceName.filter)

    // Aman
  };

  const filterLeadSourceChange = (e) => {
    setLeadSourceInput(e.target.value);

    const filteredNames = leadSources.filter((name) =>
      name.leadSource.toLowerCase().includes(leadSourceInput.toLowerCase())
    );
    // aman
    setFilteredLeadSourceName(filteredNames);
  };

  const filterWorkScopeChange = (e) => {
    setWorkScopeInput(e.target.value);

    const filteredNames = leadWorkTypes.filter((name) =>
      name.type.toLowerCase().includes(workScopeInput.toLowerCase())
    );
    // aman
    setFilteredWorkScopeName(filteredNames);
  };

  const saveLeadSource = async () => {
    if (addNewLeadSource.length > 0) {
      const res = await postReq(`${constants.CRM_API}/user/save-lead-source`, {
        userId: localStorage.getItem("userId"),
        leadSource: addNewLeadSource,
      });

      if (res && !res.error) {
        // console.log('getting lead source', res.data.data)
        getLeadSource();
        setAddLeadNewSource("");
        toast.success("New Lead Source Added");
      } else {
        console.log(res.error);
      }
    }
  };
  const saveworkScope = async () => {
    if (addNewWorkScope?.length > 0) {
      const res = await postReq(`${constants.CRM_API}/user/save-work-type`, {
        userId: localStorage.getItem("userId"),
        type: addNewWorkScope,
      });

      if (res && !res.error) {
        // console.log('getting lead source', res.data.data)
        getWorkTypes();
        setAddNewWorkType("");
        toast.success("New Work Scope Added");
      } else {
        console.log(res.error);
      }
    }
  };
  const savesourceName = async () => {
    if (addNewSourceName?.length > 0) {
      const res = await postReq(`${constants.CRM_API}/user/save-source-name`, {
        userId: localStorage.getItem("userId"),
        sourceName: addNewSourceName,
      });

      if (res && !res.error) {
        // console.log('getting lead source', res.data.data)
        getSourceName();
        setAddNewSourceName("");
        toast.success("New Source Name Added");
      } else {
        console.log(res.error);
      }
    }
  };

  
  const handleNewLeadClose = () => {
    setLeadFirstName(
      activeLead
        ? activeLead?.firstName
          ? activeLead?.firstName
          : activeLead?.name
        : ""
    );
    setLeadLastName(activeLead ? activeLead?.lastName : "");
    setPhonenumber(activeLead ? activeLead?.phoneNumber : "");
    setEmailError(false);
    setLeadEmail(activeLead ? activeLead?.email : "");
    setLeadCity(activeLead ? activeLead?.city : "");
    setLeadArea(activeLead ? activeLead?.area : "");
    setLeadAddress(activeLead ? activeLead?.address : "");
    setWorkScope(activeLead ? activeLead?.requirements : []);
    setAssignedTo(activeLead ? activeLead?.assignedTo?._id : "");
    setErrorPin(false);
    setLeadArea(activeLead ? activeLead?.area : "");
    setLeadCityCode(activeLead ? activeLead?.pinCode : "");
    setFirstNameError(false);
    setLastNameError(false);
    setBudget(activeLead ? activeLead?.budget : "");
    dispatch(setAddLeadModalOpen(false));
    setDiscardOpen(false);
    setFilteredLeadSourceName(leadSources);
    setFilteredWorkScopeName(leadWorkTypes);
    setFilteredSourceName(sourceNames);
    onHide();
  };

  const handlePinCodeChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    // setLeadCityCode(inputValue);
    if (event.target.value?.length >= 1) {
      if (event.target.value?.length <= 6) {
        setLeadCityCode(inputValue);

        if (
          /^\d+$/.test(inputValue) &&
          inputValue.length >= 6 &&
          inputValue.length <= 6
        ) {
          setLeadCityCode(inputValue);
          setErrorPin(false);
        } else {
          setErrorPin(true);
          setLeadCityCode(inputValue);
        }
      }
    } else {
      setLeadCityCode(inputValue);
      setErrorPin(false);
    }
  };
  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,100}$/i;
    if (emailPattern.test(inputValue)) {
      setLeadEmail(inputValue);
      setEmailError(false);
    } else {
      setEmailError(true);
      setLeadEmail(inputValue);
    }
  };

  const handleBudget = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 9) {
      setBudget(inputValue);
      if (/^\d+$/.test(inputValue) && inputValue.length <= 9) {
        setBudget(inputValue);
      }
    }
  };

  function handleLeadArea(event) {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 9) {
      setLeadArea(inputValue);
      if (/^\d+$/.test(inputValue) && inputValue.length <= 9) {
        setLeadArea(event.target.value);
      }
    }
  }

  const handleSubmit = () => {
    if (
      leadFirstName == "" ||
      leadFirstName == undefined ||
      leadLastName == "" ||
      leadLastName == undefined ||
      // phoneNumber == "" ||
      // phoneNumber == undefined ||
      leadEmail == "" ||
      leadEmail == undefined ||
      // leadCity == "" ||
      // leadCity == undefined ||
      // leadArea == "" ||
      // leadArea == undefined ||
      // leadAddress == "" ||
      // leadAddress == undefined ||
      // leadCityCode == "" ||
      // leadCityCode == undefined ||
      phoneError ||
      error ||
      errorPin ||
      emailError
    ) {
      alert("Please enter all the details");
    } else {
      handleFormSubmit({
        name: `${leadFirstName} ${leadLastName ? leadLastName : ""}`,
        firstName: leadFirstName,
        lastName: leadLastName ? leadLastName : "",
        email: leadEmail,
        address: leadAddress,
        city: leadCity,
        pinCode: leadCityCode,
        phoneNumber: phoneNumber,
        budget: budget,
        requirements: workScope,
        leadSource: selectedLeadSource,
        sourceName: selectedSourceName,
        assignedTo: assignedTo,
        area: leadArea,
        areaUnit: areaUnit,
        // storeData: storeDataState
      });
    }
  };
  const getUserbyId = async (id) => {
    const res = await getReq(`${constants.CRM_API}/user/getUserById?userId=${id}`);

    if (res && !res.error) {
      setUserData(res?.data?.data);
    } else {
      console.log(res.error);
    }
  };

  const updateProjectInfoOnEditingLead = async () => {
    await axios
      .post(
        `${constants.PMT_API}/api/projects/edit-project?id=${activeLead?.projectId}`,
        {
          // id: projectId,
          // clientName: newProjectData.cliName,
          name: `${leadFirstName} ${leadLastName ? leadLastName : ""}`,
          location: leadAddress,
          phoneNumber: phoneNumber,
          email: leadEmail,
          userId: localStorage.getItem("userId"),
        }
      )
      .then(function (response) {
        localStorage.setItem("projectId", response?.data?._id);
        // localStorage.removeItem("clientId")
        // dispatch(createTeam(false))
        // deleteLead()
        // window.location.reload();
        // setTimeout(() => {
        //     onHide()
        //     window.location.reload();
        // }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  let differenceFields = [];
  const showError = (error, differenceFields) => {
    // console.log(error)
    if (error.response && error.response.data.message) {
      // toast.error(error.response.data.message);
      toast.success(error.response.data.message, {
        autoClose: 3000,
      });
      // clg
      // alert(error.response.data.message)
      return;
    } else {
      toast.success("Lead Edited Successfully", {
        autoClose: 3000,
      });
      if (activeLead?.projectId) {
        updateProjectInfoOnEditingLead();
      } else {
        // setTimeout(() => {
        //     onHide()
        //     window.location.reload();
        // }, 1000);
        console.log("lead edited succesfully", differenceFields);
        onHide();
        apiFetch(tab);
        sendEmailPushBellNotifications(
          "Edit Lead",
          {
            "@leadName": `${leadFirstName} ${leadLastName}`,
            "[Lead Name]": `${leadFirstName} ${leadLastName}`,
            "@username": profileData.fullName,
            '@Sender':profileData.fullName,
            '@sender':profileData.fullName,
            "@senderFirstName": profileData.firstName,
            "@changeObject": differenceFields?.join("<br />"),
          },
          [activeLead.assignedTo]
        );
      }
    }
  };


  const handleFormSubmit = async (data) => {
   
    console.log(data)
    
    for (let key in data) {
      console.log(prevStateofAllValue[key])
      if (data[key] !== prevStateofAllValue[key]) {
        if (key == "assignedTo") {
          differenceFields.push(
            `The Lead has been reassigned from ${activeLead?.assignedTo?.fullName} to ${assignedToName}`
          );
        } else {
          if (!(key == "firstName" || key == "lastName" || key == "areaUnit")) {
            differenceFields.push(
              `The Lead's ${key} has been changed from ${prevStateofAllValue[key]===undefined?"__":prevStateofAllValue[key]} to ${data[key]}`
            );
          }
        }
      }
    }


    // console.log("datafiled",data)

console.log(data)
    console.log("differenceFields", differenceFields);
    setButtonClickedOnce(true);
    try {
      const res = await postReq(
        `${constants.CRM_API}/user/edit-lead?id=${
          activeLead?._id
        }&userId=${localStorage.getItem("userId")}`,
        {
          ...data,
          architectDetails: sourceNameArchitectDetails,
          // architectDetails:{
          //   areaUnit:data?.areaUnit
          // },
          logText: differenceFields,
        }
      );
      console.log("differenceFields22", differenceFields);
      showError(res.error, [...differenceFields]);
      if (res && !res.error) {
        console.log("");
        setButtonClickedOnce(false);
        toast.success(res?.message || "Lead Edited Successfully", {
          autoClose: 3000,
        });
      }
      // await setProgress(100);
    } catch (error) {
      setButtonClickedOnce(false);
      // console.log(error)
      onHide();
      showError(error, [...differenceFields]);
    }
  };
  const getTeamLeads = async () => {
    const res = await getReq(
      `${
        constants.CRM_API
      }/api/enterprise/get-team-leaders?userId=${localStorage.getItem(
        "userId"
      )}&access=1`
    );
    if (res && !res.error) {
      setTeamLeadArr(res.data.data);
    } else {
      console.log(res.error);
    }
  };

  const getLeadSource = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-lead-source?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      setLeadSources(res.data.data);
      setFilteredLeadSourceName(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const getWorkTypes = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-work-types?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      setLeadWorkTypes(res.data.data);
      setFilteredWorkScopeName(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  // const getStoreData = async () => {
  //     const res = await getReq(
  //         `${constants.CRM_API}/user/get-store-data?userId=${localStorage.getItem(
  //             "userId"
  //         )}`
  //     );
  //     if (res && !res.error) {
  //         // console.log('getting lead source', res.data.data)
  //         setAllStoreData(res.data.data);
  //     } else {
  //         console.log(res.error);
  //     }
  // };
  const getSourceName = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-source-name?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      setSourceNames(res.data.data);
      setFilteredSourceName(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const handleLeadSource = (leadSource) => {
    // const tempLeadSource = leadSources.find(
    //   (leadSource) => leadSource.id === e.target.value
    // );
    // setSelectedLeadSource(tempLeadSource.leadSource);
    // setLeadSource(e.target.value);
    setSelectedLeadSource(leadSource);
  };
  const handleSourceName = (sourceName1) => {
    // setSourceName(e.target.value);
    const tempSourceName = sourceNames.find(
      (sourceName) => sourceName.sourceName === sourceName1
    );
    setSelectedSourceName(tempSourceName.sourceName);
    if (tempSourceName?.architectDetails) {
      setSourceNameArchitectDetails(tempSourceName?.architectDetails);
    }
  };

  useEffect(() => {
    getLeadSource();
    getSourceName();
    getTeamLeads();
    getWorkTypes();
    getUserbyId(localStorage.getItem("userId"));
  }, []);
  const handleChangeWorkscope = (event) => {
    const {
      target: { value },
    } = event;
    setWorkScope(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleOnFocus = (fieldName, errorStateName) => {
    if (fieldName?.length === 0) {
      errorStateName(true);
    }
  };



  return (
    <>
      <Modal
        title={false}
        show={discardOpen}
        centered
        width={200}
        bodyStyle={{ height: 60 }}
        closable={false}
      >
        <div
          style={{
            border: "1px solid black",
            borderRadius: "5px",
            margin: "5px",
          }}
        >
          <h6 style={{ padding: "3%" }}>Do you want to discard the lead ?</h6>
          <Modal.Footer>
            <div className={styles.discardCloseBtn}>
              <button
                className={styles.createModalSave}
                onClick={handleNewLeadClose}
              >
                <span
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  Yes{" "}
                </span>
              </button>
              <button
                className={styles.createModalClose}
                onClick={() => setDiscardOpen(false)}
              >
                <span
                  style={{
                    color: "#C9C9C9",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  No{" "}
                </span>
              </button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
      <Modal
        show={show}
        onHide={onHide}
        // backdrop="static"
        keyboard={false}
        centered
        // size="lg"
        // width='1000px'
      >
        <Modal.Header
          closeButton
          style={{
            borderBottom: "none",
            backgroundColor: "#0084ef",
            color: "#ffffff",
          }}
        >
          <Modal.Title>Edit Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.formName}>
            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="First Name*"
              size="small"
              variant="outlined"
              helperText={
                firstNameError && leadFirstName.length == 0
                  ? "Please enter first name"
                  : firstNameError && "Limit of 24 characters is reached"
              }
              isRequired={true}
              onFocus={() => handleOnFocus(leadFirstName, setFirstNameError)}
              value={leadFirstName}
              error={firstNameError}
              InputProps={{
                inputMode: "text",
              }}
              onChange={(e) => {
                leadFirstNameHandle(e);
              }}
            />

            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Last Name*"
              variant="outlined"
              size="small"
              helperText={
                lastNameError && leadLastName.length == 0
                  ? "Please enter last name"
                  : lastNameError && "Limit of 24 characters is reached"
              }
              isRequired={true}
              error={lastNameError}
              onFocus={() => handleOnFocus(leadLastName, setLastNameError)}
              value={leadLastName}
              InputProps={{
                inputMode: "text",
              }}
              onChange={(e) => leadLastNameHandle(e)}
            />
          </div>
          <div className={styles.formName}>
            {/* <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Contact No."
              size="small"
              variant="outlined"
              // isRequired={true}
              helperText={
                phoneNumber?.length > 0
                  ? phoneNumber?.length < 10
                    ? "Please enter valid number"
                    : ""
                  : ""
              }
              error={phoneNumber?.length > 0 && phoneNumber?.length < 10}
              value={phoneNumber}
              onChange={(e) => handlePhoneNumberChange(e)}
              InputProps={{
                inputMode: "numeric",
                startAdornment: (
                  <InputAdornment position="start">
                    {profileData?.CompanySettingDetails?.countryCode}
                  </InputAdornment>
                ),
              }}
            /> */}

              <div >
              <PhoneInput
                placeholder="Enter the Number"
                enableSearch={true}
                inputStyle={{ height: "30px", width: "14rem", }}
                // style={{ width: "14rem" }}
                value={phoneNumber}
                containerClass="phoneNumber"
                // disableCountryGuess={false}
                countryCodeEditable={false}
                enableLongNumbers={19}
                prefix="+"
                // isValid={(inputNumber, country, countries) => {
                //   return countries.some((country) => {
                //     return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                //   });
                // }}
                // onChange={(value, country, e, formattedValue)=>{
                //          console.log(value, country, e, formattedValue,"codedatavalueofcountry")
                // }}

                onChange={(e,country) => handlePhoneNumberChange(e,country)}
                specialLabel="Phone No"
                country="in"
              />
               <div style={{color:"red",marginTop:"3px",paddingLeft:"5px",fontSize:"10px"}}>
                {phoneNumerror && phoneNumerror?.bol===true?phoneNumerror?.msg:""}
               </div>
            </div>



            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Email Address*"
              variant="outlined"
              isRequired={true}
              value={leadEmail}
              size="small"
              onKeyDown={(e) =>
                exceptThisSymbols2.includes(e.key) && e.preventDefault()
              }
              error={emailError}
              helperText={
                emailError
                  ? leadEmail.length == 0
                    ? "Please input email"
                    : "Invalid email address"
                  : ""
              }
              onChange={(e) => handleEmailChange(e)}
            />
          </div>
          <div className={styles.formName}>
            <TextField
              style={{ width: "30rem" }}
              id="outlined-basic"
              label="Address"
              variant="outlined"
              size="small"
              // isRequired={true}
              value={leadAddress}
              onChange={(e) => setLeadAddress(e.target.value)}
            />
          </div>
          <div className={styles.formName}>
            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="City"
              size="small"
              variant="outlined"
              // isRequired={true}
              value={leadCity}
              onChange={(e) => setLeadCity(e.target.value)}
            />
            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Zip/Postal code*"
              variant="outlined"
              size="small"
              // isRequired={true}
              value={leadCityCode}
              error={errorPin}
              helperText={errorPin ? "Invalid Pin Code" : ""}
              onChange={(e) => handlePinCodeChange(e)}
              InputProps={{
                inputMode: "numeric",
              }}
            />
          </div>
          <div className={styles.formName}>
            <FormControl style={{ width: "14rem" }} size="small">
              {/* <Dropdown>
                <Dropdown.Toggle
                  className={styles.dropdownButton}
                  variant="outline-secondary"
                  id="dropdown-basic"
                
                >
                  <span className={styles.buttonText}>
                 
                    {workScope === "" || workScope == undefined ? (
                      <input
                        style={{
                          border: "none",
                          outline: "none",
                          position: "relative",
                        }}
                        type="text"
                        placeholder="Work Scope "
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (filteredWorkScopeName.length === 0) {
                              setAddNewWorkType(workScopeInput);
                              saveworkScope();
                              setWorkScope(workScopeInput);
                            } else {
                            }
                          }
                        }}
                        onChange={(e) => {
                          filterWorkScopeChange(e);
                          setWorkScopeInput(e.target.value);

                          setAddNewWorkType(e.target.value);
                        }}
                        name=""
                        id=""
                      />
                    ) : (
                      <span>
                        {workScope?.length > 20
                          ? `${workScope.substring(0, 20)}...`
                          : workScope}
                        <span
                          style={{ position: "absolute", right: "9px" }}
                          onClick={() => {
                            setFilteredWorkScopeName(leadWorkTypes);

                            setWorkScope("");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="15"
                            fill="#7F7F7F"
                            style={{ fontWeight: "800" }}
                            height="15"
                            viewBox="0 0 50 50"
                          >
                            <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path>
                          </svg>
                        </span>
                      </span>
                    )}
                  </span>{" "}
                  {!Boolean(workScope) ? (
                    <BiChevronDown size={20} color="#74737F" />
                  ) : (
                    ""
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu className={styles.filterDropdownContainer}>
                  <Dropdown.ItemText>
             
                  </Dropdown.ItemText>
           
                  {filteredWorkScopeName?.length > 0 ? (
                    filteredWorkScopeName
                      ?.slice()
                      .sort((a, b) => a.type.localeCompare(b.type))
                      .map((leadWorkType) => {
                        return (
                          <Dropdown.Item
                            onClick={(e) => setWorkScope(leadWorkType.type)}
                            style={{ cursor: "pointer" }}
                          >
                            {leadWorkType.type}
                          </Dropdown.Item>
                        );
                      })
                  ) : (
                    <Dropdown.Item
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      Add :{" "}
                      <span
                        onClick={() => {
                          setAddNewWorkType(workScopeInput);

                          saveworkScope();
                          setWorkScope(workScopeInput);
                        }}
                        style={{
                          marginLeft: "5px",
                          color: "#3498DB",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {workScopeInput}
                      </span>
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown> */}

              <Autocomplete
                disablePortal
                id="combo-box-demo"
                style={{ width: "14rem" }}
                options={filteredWorkScopeName
                  ?.slice()
                  .sort((a, b) => a.type.localeCompare(b.type))
                  .map((workScope) => {
                    return {
                      label: workScope.type,
                    };
                  })}
                onChange={(_object, value) => {
                  setWorkScope(value?.label);
                }}
                value={workScope}
                inputValue={inputValueWorkScope}
                onInputChange={handleWorkScopeInputChange}
                noOptionsText={
                  <span onClick={saveworkScope} style={{ cursor: "pointer" }}>
                    {inputValueWorkScope ? (
                      <span>
                        Create :{" "}
                        <span style={{ color: "blue" }}>
                          "{inputValueWorkScope}"
                        </span>
                      </span>
                    ) : (
                      "No options"
                    )}
                  </span>
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    // label="Work Scope"

                    label={ "Work Scope" }
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        !filteredWorkScopeName.some(
                          (option) => option.type === inputValueWorkScope
                        )
                      ) {
                        saveworkScope();
                      }
                    }}
                  />
                )}
              />
            </FormControl>

            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Budget"
              size="small"
              // startAdornment={<InputAdornment position="start">$</InputAdornment>}
              variant="outlined"
              // isRequired={true}
              value={budget}
              onChange={handleBudget}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {profileData?.CompanySettingDetails?.currencySymbol}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={styles.formName}>
            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Area"
              size="small"
              variant="outlined"
              // isRequired={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Select
                      variant="standard"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={areaUnit}
                      renderValue={() => areaUnit}
                      label="Unit"
                      // onChange={handleChange}
                      InputProps={{
                        disableUnderline: true, // <== added this
                      }}
                      disableUnderline
                    >
                      <MenuItem
                        sx={{ minWidth: 200 }}
                        onClick={() => setAreaUnit("sqft")}
                        value={"sqft"}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className={styles.areaUnit}>Sqft</div>
                        <div className={styles.areaDescription}>
                          Square feet
                        </div>
                      </MenuItem>
                      <MenuItem
                        sx={{ minWidth: 200 }}
                        onClick={() => setAreaUnit("sqm")}
                        value={"sqm"}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className={styles.areaUnit}>Sqm</div>
                        <div className={styles.areaDescription}>
                          Square meter
                        </div>
                      </MenuItem>
                    </Select>
                  </InputAdornment>
                ),
              }}
              value={leadArea}
              onChange={handleLeadArea}
            />

            <FormControl style={{ width: "14rem" }} size="small">
              {/* <InputLabel id="demo-select-large-label">Lead Source</InputLabel>
                            <Select
                                labelId="demo-select-large-label"
                                id="demo-select-large"
                                value={leadSource}
                                label="Lead Source"
                                onChange={(e) => handleLeadSource(e)}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                            >
                                {leadSources &&
                                    leadSources?.map((leadSource) => {
                                        return (
                                            <MenuItem value={leadSource.id}>
                                                {leadSource.leadSource}
                                            </MenuItem>
                                        );
                                    })}
                            </Select> */}
              {/* <Dropdown>
                <Dropdown.Toggle
                  className={styles.dropdownButton}
                  variant="outline-secondary"
                  id="dropdown-basic"
                >
                  <span className={styles.buttonText}>
              
                    {selectedLeadSource === "" ||
                    selectedLeadSource == undefined ? (
                      <input
                
                        style={{
                          border: "none",
                          outline: "none",
                          position: "relative",
                        }}
                        type="text"
                        placeholder="Lead Source"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (filteredLeadSourceName?.length === 0) {
                              setAddLeadNewSource(leadSourceInput);
                              setSelectedLeadSource(leadSourceInput);
                              saveLeadSource();
                            } else {
                            }
                          }
                        }}
                        onChange={(e) => {
                          filterLeadSourceChange(e);
                          setLeadSourceInput(e.target.value);
                          setAddLeadNewSource(e.target.value);
                       
                        }}
                        name=""
                        id=""
                      />
                    ) : (
                      <span>
                        {selectedLeadSource}
                        <span
                          style={{ position: "absolute", right: "9px" }}
                          onClick={() => {
                            setFilteredLeadSourceName(leadSources);
                            setSelectedLeadSource("");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="15"
                            fill="#7F7F7F"
                            style={{ fontWeight: "800" }}
                            height="15"
                            viewBox="0 0 50 50"
                          >
                            <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path>
                          </svg>
                        </span>
                      </span>
                    )}
                  </span>{" "}
                  {!Boolean(selectedLeadSource) ? (
                    <BiChevronDown size={20} color="#74737F" />
                  ) : (
                    ""
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu className={styles.filterDropdownContainer}>
                  <Dropdown.ItemText>
                  
                  </Dropdown.ItemText>
                  {filteredLeadSourceName?.length > 0 ? (
                    filteredLeadSourceName
                      ?.slice()
                      .sort((a, b) => a.leadSource.localeCompare(b.leadSource))
                      .map((leadWorkType) => {
                        return (
                          <Dropdown.Item
                            onClick={(e) =>
                              setSelectedLeadSource(leadWorkType.leadSource)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {leadWorkType.leadSource}
                          </Dropdown.Item>
                        );
                      })
                  ) : (
                    <Dropdown.Item
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      Add :{" "}
                      <span
                        onClick={() => {
                          setAddLeadNewSource(leadSourceInput);
                          saveLeadSource();
                          setSelectedLeadSource(leadSourceInput);
                        }}
                        style={{
                          marginLeft: "5px",
                          color: "#3498DB",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {leadSourceInput}
                      </span>
                    </Dropdown.Item>
                  )}
     
                </Dropdown.Menu>
              </Dropdown> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                style={{ width: "14rem" }}
                value={selectedLeadSource}
                options={filteredLeadSourceName
                  ?.slice()
                  .sort((a, b) => a.leadSource.localeCompare(b.leadSource))
                  .map((leadWorkType) => {
                    return {
                      label: leadWorkType.leadSource,
                    };
                  })}
                onChange={(_object, value) => {
                  setSelectedLeadSource(value?.label);
                }}
                inputValue={inputValueLeadSource}
                onInputChange={handleInputValueInputChange}
                noOptionsText={
                  <span onClick={saveLeadSource} style={{ cursor: "pointer" }}>
                    {inputValueLeadSource ? (
                      <span>
                        Create :{" "}
                        <span style={{ color: "blue" }}>
                          "{inputValueLeadSource}"
                        </span>
                      </span>
                    ) : (
                      "No options"
                    )}
                  </span>
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    // label="Lead Source"
                    label={"Lead Source"}
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        !filteredLeadSourceName.some(
                          (option) => option.leadSource === inputValueLeadSource
                        )
                      ) {
                        saveLeadSource();
                      }
                    }}
                  />
                )}
              />
            </FormControl>
          </div>
          <div className={styles.formName}>
            <FormControl style={{ width: "14rem" }} size="small">
              {/* <Dropdown>
                <Dropdown.Toggle
                  className={styles.dropdownButton}
                  variant="outline-secondary"
                  id="dropdown-basic"
                >
                  <span className={styles.buttonText}>
            
                    {selectedSourceName === "" ||
                    selectedSourceName == undefined ? (
                      <input
                  
                        style={{
                          border: "none",
                          outline: "none",
                          position: "relative",
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (filteredSourceName?.length === 0) {
                              setAddNewSourceName(sourceInputName);
                              savesourceName();
                              setSelectedSourceName(sourceInputName);
                            } else {
                            }
                          }
                        }}
                
                        type="text"
                        placeholder="Source Name"
                        onChange={(e) => {
                          filterSourceChange(e);
                          setAddNewSourceName(e.target.value);
                        }}
                        name=""
                        id=""
                      />
                    ) : (
                      <span>
                        {selectedSourceName}
                        <span
                          style={{ position: "absolute", right: "9px" }}
                          onClick={() => {
                            setFilteredSourceName(sourceNames);
                            setSelectedSourceName("");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="15"
                            fill="#7F7F7F"
                            style={{ fontWeight: "800" }}
                            height="15"
                            viewBox="0 0 50 50"
                          >
                            <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path>
                          </svg>
                        </span>
                      </span>
                    )}
                  </span>{" "}
                  {!Boolean(selectedSourceName) ? (
                    <BiChevronDown size={20} color="#74737F" />
                  ) : (
                    ""
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu className={styles.filterDropdownContainer}>
                  <Dropdown.ItemText>
                 
                  </Dropdown.ItemText>
                  {
                   
                    filteredSourceName?.length > 0 ? (
                      filteredSourceName
                        ?.slice()
                        .sort((a, b) =>
                          a.sourceName.localeCompare(b.sourceName)
                        )
                        .map((leadSource) => (
                          <Dropdown.Item
                            onClick={(e) =>
                              handleSourceName(leadSource.sourceName)
                            }
                            style={{ cursor: "pointer" }}
                            key={leadSource._id} // Assuming each source has a unique ID
                          >
                            {leadSource.sourceName}
                          </Dropdown.Item>
                        ))
                    ) : sourceInputName ? ( // Check if sourceInputName is not empty
                      <Dropdown.Item
                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                      >
                        Add :{" "}
                        <span
                          onClick={() => {
                            setAddNewSourceName(sourceInputName);
                            savesourceName();
                            setSelectedSourceName(sourceInputName);
                          }}
                          style={{
                            marginLeft: "5px",
                            color: "#3498DB",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          {sourceInputName}
                        </span>
                      </Dropdown.Item>
                    ) : null // Render nothing if sourceInputName is empty
                  }
                </Dropdown.Menu>
              </Dropdown> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                style={{ width: "14rem" }}
                value={selectedSourceName}
                options={filteredSourceName
                  ?.slice()
                  .sort((a, b) => a.sourceName.localeCompare(b.sourceName))
                  .map((workScope) => {
                    return {
                      label: workScope.sourceName,
                    };
                  })}
                onChange={(_object, value) => {
                  setSelectedSourceName(value?.label);
                }}
                inputValue={inputValueScopeName}
                onInputChange={handleScopeNameInputChange}
                noOptionsText={
                  <span onClick={savesourceName} style={{ cursor: "pointer" }}>
                    {inputValueScopeName ? (
                      <span>
                        Create :{" "}
                        <span style={{ color: "blue" }}>
                          "{inputValueScopeName}"
                        </span>
                      </span>
                    ) : (
                      "No options"
                    )}
                  </span>
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={
                  "Source Name"
                    }
                  //  value={assignedToName}
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        !filteredSourceName.some(
                          (option) => option.sourceName === inputValueScopeName
                        )
                      ) {
                        savesourceName();
                      }
                    }}
                  />
                )}
              />
            </FormControl>

            {userData?.rolesAssigned?.crm?.canAssign && (
              <FormControl style={{ width: "14rem" }} size="small">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={teamLeadArr?.map((teamLead) => {
                    return {
                      label: teamLead.fullName,
                      id: teamLead._id,
                      email: teamLead.email,
                    };
                  })}

                  value={assignedToName}
                  onChange={(object, value) => (
                    console.log("valuedata",value),
                    setAssignedTo(value?.id),
                    setAssignedToName(value?.label),
                    setAssignedToObject({
                      email: value?.email,
                      fullName: value?.label,
                      _id: value?._id,
                    })
                  )}
                  // sx={{ width: 225 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={
                      
                           "Assign To"
                      
                      }
                    />
                  )}
                  // MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                />
              </FormControl>
            )}
          </div>
          <Modal.Footer>
            <div className={styles.closeBtn}>
              <button
                className={styles.createModalClose}
                onClick={() => setDiscardOpen(true)}
              >
                <span
                  style={{
                    color: "#C9C9C9",
                  }}
                >
                  Cancel{" "}
                </span>
              </button>
              <button
                disabled={buttonClickedOnce}
                className={styles.createModalSave}
                onClick={handleSubmit}
              >
                <span
                  style={{
                    color: "#FFF",
                  }}
                >
                  {!buttonClickedOnce ? (
                    "Submit"
                  ) : (
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ height: "1.25rem", width: "1.25rem" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </span>
              </button>
            </div>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditLeadModal;
