import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useParams, Link } from "react-router-dom";
import desquoContext from "../../picontext/DashBoard/desquoContext";
import { formateDate, valueToLeadStatus } from "../../piservices/compCommon";
import styles from "./ModuleCssFiles/lmsTableItem.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import {
  createProjectModal,
  currentLead,
  selectNextSlide,
  slideIn,
  slideOut,
  setEditLead,
  setLeadsCount,
  setSelectedUnassignedLead,
} from "../../Redux/Slices/lmsSlice";
import Select from "react-select";
import { FaEllipsisV } from "react-icons/fa";
import  { constants} from "../../piservices/constants";
import { postReq, getReq } from "../../piservices/apis";
import {
  Dropdown,
  Space,
  Modal as ModalAntd,
  Form,
  Input,
  Tooltip,
  Button,
  Select as Selectantd,
  notification,
} from "antd";
import toast, { Toaster } from "react-hot-toast";
import AddLeadModal from "./AddLeadModal";
import { getToken } from "../../piservices/authService";
import { set, useForm } from "react-hook-form";
import axios from "axios";
import designquo from "../Lms/images/designquo.svg";
import executionquo from "../Lms/images/executionquo.svg";
import CreateNewProjectNew from "./CreateNewProject/CreateNewProjectNew";
import { selectedLeadData } from "../../Redux/Slices/specificleadSlice";
import { BiChevronDown } from "react-icons/bi";
import ArchDetailsCard from "./ArchDetailsCard";
import {
  projectDataCurrObj,
  setIsChecked,
} from "../../Redux/Slices/convertToProjectSlice";
import { LiaEllipsisVSolid } from "react-icons/lia";
import EditLeadModal from "./EditLeadModal";
import LockBlack from "../../Assets/LockBlack.svg";
import LockWhite from "../../Assets/LockWhite.svg";
import useApiFetchCustomHook from "../../piservices/ApiFetchCustomHook";
import useApiFetchForLeadCountCustomHook from "../../piservices/ApiFetchForLeadCount";
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const LmsTableItemForUnassigned = ({ idx = 0, leadData, showSourceLeads }) => {
  let { tab } = useParams();
  // Object.preventExtensions({idx});
  const dispatch = useDispatch();
  const successful = () => toast.success("Successfully created!");
  const [onEditLead, setEditLead] = useState(false);
  const context = useContext(desquoContext);
  const {
    activeLead,
    setActiveLeadState,
    leads,
    leadStatusItem,
    showAlert,
    setLeads,
  } = context;
  const [data, setData] = useState({});
  // const [teamLeadArr, setTeamLeadArr] = useState([]);
  const [assignId, setAssignId] = useState();

  const [cpName, setcpName] = useState();
  const [cpEmail, setcpEmail] = useState();
  const [quotationModal, setQuotationModal] = useState(false);
  // const [leadSources, setLeadSources] = useState([]);
  // const [sourceNames, setSourceNames] = useState([]);
  const [currentQuot, setCurrentQuot] = useState("");
  const [selectedLeadSourceId, setSelectedLeadSourceId] = useState();
  const [quotationModal2, setQuotationModal2] = useState(false);
  const [quotInput, setQuotInput] = useState("");
  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
  const [showArchitectureDetails, setShowArchitectureDetails] = useState(false);
  const [tempProjectId, setTempProjectId] = useState("");
  const [buttonClickedOnce, setButtonClickedOnce] = useState(false);

  const {
    address,
    budget,
    config,
    leadStatus,
    leadSource,
    name,
    firstName,
    lastName,
    city,
    phoneNumber,
    pinCode,
    email,
    renovationTimeline,
    requirements,
    updatedAt,
    createdAt,
    sourceName,
    userId,
    assignedTo,
    area,
    leadUpdates,
    clouserMonth,
    _id,
  } = data;
  const [assignToValue, setAssignToValue] = useState(assignedTo);
  // const [userData, setUserData] = useState({});
  // const getUserbyId = async (id) => {
  //   const res = await getReq(`${constants.CRM_API}/user/getUserById?userId=${id}`);
  //   if (res && !res.error) {
  //     setUserData(res?.data?.data);
  //   } else {
  //     console.log(res.error);
  //   }
  // };
  let projectDataObj = {
    firstName: "",
    lastName: "",
    projectName: "",
    address: "",
    city: "",
    code: "",
    phnNum: "",
    email: "",
    document: "",
    documentLink: "",
  };
  // useEffect(() => {
  //   getUserbyId(localStorage.getItem("userId"));
  // }, []);
  const handleQuotChange = (e) => {
    setQuotInput(e.target.value);
  };
  const noEmailRedirect = () => {};
  const handleQuotOk = () => {
    setQuotationModal(true);
  };
  const handleCloseModal = () => {
    setQuotationModal(false);
  };
  const handleOk2 = () => {
    setQuotationModal2(true);
  };
  const handleOk22 = async () => {
    if (quotInput != "") {
      const res2 = await postReq(
        `${constants.CRM_API}/user/edit-lead?id=${activeLead?._id}`,
        {
          // leadId: activeLead?._id,
          email: quotInput,
        }
      );
      if (res2 && !res2.error) {
        const res = await postReq(
          `${constants.PMT_API}/api/projects/addClient?userId=${localStorage.getItem(
            "userId"
          )}`,
          {
            email: quotInput,
            location: activeLead.address,
            name: activeLead.name,
            projectName: activeLead.name,
          }
        );
        if (res && !res.error) {
          console.log(res);
        } else {
          console.log(res.error);
        }
        await axios
          .post(`${constants.PMT_API}/api/projects/add-project`, {
            clientId: res.data._id,
            location: activeLead.address,
            projectName: activeLead.name,
            userId: localStorage.getItem("userId"),
            isConfirmed: false,
            isFromLead: true,
            projectAdmin: activeLead?.assignedTo?._id,
            teamMembers: [activeLead?.assignedTo?._id],
          })
          .then(function (response) {
            localStorage.setItem("projectId", response?.data?._id);
            if (currentQuot == "exQuot") {
              localStorage.setItem("projectId", response?.data?._id);
              setQuotInput("");
              setCurrentQuot("");
              window.location.assign(
                `${constants.BASE_URL}/quotation/new-quotation/${response.data._id}`
              );
            } else {
              localStorage.setItem("projectId", response?.data?._id);
              setQuotInput("");
              setCurrentQuot("");
              localStorage.setItem("leadQuo", true);
              window.location.assign(
                `${constants.BASE_URL}/quotation/enterprise-quotation/${response.data._id}`
              );
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        console.log(res2.error);
      }
    }
  };
  const handleCloseModal2 = () => {
    setQuotationModal2(false);
  };
  const [form] = Form.useForm();

  // useEffect(() => {
  //   setData(leads?.data[idx]);
  //   if (idx === 1 && !activeLead?.name) {
  //     let x = leads?.data[0];
  //     x.idx = idx;
  //     setActiveLeadState(x);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [leads]);

  const [openEditLeadModal, setOpenEditLeadModal] = useState(false);

  const nextPanel = useSelector((state) => state.lmsSlice.nextPanel);
  const leadSources = useSelector((state) => state.lmsSlice.leadSources);
  const profileData = useSelector((state) => state.lmsSlice.profileData);
  const sourceNames = useSelector((state) => state.lmsSlice.sourceNames);
  const teamLeadArr = useSelector((state) => state.lmsSlice.teamLeadArr);
  const selectedUnassignedLead = useSelector(
    (state) => state.lmsSlice.selectedUnassignedLead
  );
  const workType = useSelector((state) => state.lmsSlice.workType);
  const userData = useSelector((state) => state.lmsSlice.userData);
  const { apiFetch } = useApiFetchCustomHook();
  const { getLeadsCount } = useApiFetchForLeadCountCustomHook();

  function checkValidations(formData) {
    return (
      formData?.name == "" ||
      formData?.email == "" ||
      formData?.phoneNumber == "" ||
      formData?.address == "" ||
      formData?.budget == ""
    );
  }

  const editLead = async (formData) => {
    if (checkValidations(formData)) {
      alert("Fields can not be empty");
    } else {
      const res = await postReq(
        `${constants.CRM_API}/user/edit-lead?id=${activeLead?._id}`,
        {
          // leadId: activeLead?._id,
          assignedTo: formData?.assignedTo,
          name:
            formData?.firstName || formData?.lastName
              ? formData?.firstName + " " + formData?.lastName
              : activeLead?.firstName + " " + activeLead?.lastName,
          // name:formData?.firstName + activeLead?.lastName,
          firstName: formData?.firstName
            ? formData?.firstName
            : activeLead?.firstName,
          lastName: formData?.lastName
            ? formData?.lastName
            : activeLead?.lastName,
          email: formData?.email ? formData?.email : activeLead?.email,
          phoneNumber: formData?.phoneNumber,
          address: formData?.address ? formData?.address : activeLead?.address,
          city: formData?.city ? formData?.city : activeLead?.city,
          requirements: formData?.requirements
            ? formData?.requirements
            : activeLead?.city,
          budget: formData?.budget,
          sourceName: formData?.sourceName,
          leadSource: selectedOption ? selectedOption : formData?.leadSource,
        }
      );
      if (res && !res.error) {
        console.log("success");
        notification.success({
          message: res?.message || "Edit Lead Successfully",
          duration: 1,
        });
        form.resetFields();
        apiFetch(tab);
        handleCancel();
      } else {
        console.log(res.error);
        notification.error({
          message: res?.message || "Oops Something Went Wrong",
          duration: 1,
        });
      }
      if (activeLead?.projectId) {
        await axios
          .post(
            `${constants.PMT_API}/api/projects/edit-project?id=${activeLead?.projectId}`,
            {
              // id: projectId,
              // clientName: newProjectData.cliName,
              name: formData?.name ? formData?.name : activeLead?.name,
              location: formData?.address
                ? formData?.address
                : activeLead?.address,
              phoneNumber: formData?.phoneNumber,
              email: formData?.email ? formData?.email : activeLead?.email,
              userId: localStorage.getItem("userId"),
            }
          )
          .then(function (response) {
            localStorage.setItem("projectId", response?.data?._id);
            // localStorage.removeItem("clientId")
            // dispatch(createTeam(false))
            // deleteLead()
            window.location.reload();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        window.location.reload();
      }
    }
  };

  const deleteLead = async (formData) => {
    const res = await postReq(
      `${constants.CRM_API}/user/edit-lead?id=${
        activeLead?._id
      }&userId=${localStorage.getItem("userId")}`,
      {
        isDeleted: true,
        deletedBy: localStorage.getItem("userId"),
      }
    );
    if (res && !res.error) {
      notification.success({
        message: res?.message || "Delete Lead Successfully",
        duration: 1,
      });
      form.resetFields();
      handleDeletModal();
      // after delete we again fetch data
      apiFetch(tab);
      getLeadsCount();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      console.log(res.error);
      notification.error({
        message: res?.message || "Oops Something Went Wrong",
        duration: 1,
      });
    }
  };
  const assignLead = async (formData) => {
    setButtonClickedOnce(true);
    const res = await postReq(
      `${constants.CRM_API}/user/edit-lead?id=${
        activeLead?._id
      }&userId=${localStorage.getItem("userId")}`,
      {
        // leadId: activeLead?._id,
        assignedTo: assignId,
      }
    );
    if (res && !res.error) {
      notification.success({
        message: res?.message || "Assign Lead Successfully",
        duration: 1,
      });
      setButtonClickedOnce(false);
      form.resetFields();
      AssignCancelModal();
      setAssignId();
      // dispatch(setEditLead(true));
      apiFetch(tab);
      await setActiveLeadState({ ...activeLead, assignedTo: assignToValue });
    } else {
      console.log(res.error);
      notification.error({
        message: res?.message || "Oops Something Went Wrong",
        duration: 1,
      });
      setButtonClickedOnce(false);
    }
  };
  const createProject = async () => {
    const res = await postReq(`${constants.PMT_API}/api/projects/addEditProject`, {
      name: cpName ? cpName : activeLead?.name,
      email: cpEmail ? cpEmail : activeLead?.email,
      location: activeLead.address,
      userId: localStorage.getItem("userId"),
    });
    if (res && !res.error) {
      notification.success({
        message: res?.message || "Create Project Successfully",
        duration: 1,
      });
      CPCancelModal();
    } else {
      console.log(res.error);
      notification.error({
        message: res?.message || "Oops Something Went Wrong",
        duration: 1,
      });
    }
  };

  useEffect(() => {
    setData(leads?.data[idx]);
    if (idx === 0) {
      let x = leads?.data[0];
      let y = { ...x, idx };
      setActiveLeadState(y);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);
  // const createProjectAndGoToDesignQuotation = async () => {
  //   console.log('quotation1111111')
  //   if (activeLead?.projectId) {
  //   console.log('quotation22222')

  //     localStorage.setItem("projectId", activeLead?.projectId);
  //     localStorage.setItem("leadQuo", true);
  //     window.location.assign(
  //       `${constants.BASE_URL}/quotation/enterprise-quotation/${activeLead?.projectId}`
  //     );
  //   } else {
  //   console.log('quotation3333333')

  //     if (activeLead.email) {
  //   console.log('quotation4444444')

  //       const res = await postReq(`${constants.PMT_API}/api/projects/addClient`, {
  //         email: activeLead.email,
  //         location: activeLead.address,
  //         name: activeLead.name,
  //       });
  //       if (res?.data?.clientId) {
  //   console.log('quotation555555')

  //         await axios
  //         .post(`${constants.PMT_API}/api/projects/add-project`, {
  //           clientId: res.data._id,
  //           location: activeLead.address,
  //           projectName: activeLead.name,
  //           userId: localStorage.getItem("userId"),
  //           isConfirmed: false,
  //           phoneNumber: activeLead.phoneNumber,
  //           clientName: activeLead.name,
  //         })
  //         .then(async function (response) {
  //   console.log('quotation666666')

  //           localStorage.setItem("projectId", response?.data?._id);
  //           await postReq(
  //             `${constants.CRM_API}/api/save-projectId-leads?leadId=${activeLead?._id}&projectId=${response?.data?._id}`
  //           )
  //             .then((res2) => {
  //   console.log('quotation77777')

  //               window.location.assign(
  //                 `${constants.BASE_URL}/quotation/enterprise-quotation/${response?.data?._id}`
  //               );
  //             })
  //             .catch((err) => {
  //               console.log(err.error);
  //               notification.error({
  //                 message: err.error || "client already exist",
  //                 duration: 1,
  //               });
  //             });
  //         })
  //         .catch(function (error) {
  //           console.log(error);
  //         });

  //       } else {
  //         // localStorage.setItem("projectId", res?.data?._id);
  //         // window.location.assign(
  //         //   `${constants.BASE_URL}/quotation/enterprise-quotation/${res.data._id}`
  //         // );
  //         console.log('something error occured')
  //       }
  //     } else {
  //       setCurrentQuot("desQuot");
  //       handleCloseModal();
  //       handleOk2();
  //     }
  //   }

  // };

  const createProjectAndGoToDesignQuotation = async () => {
    if (activeLead?.projectId) {
      localStorage.setItem("projectId", activeLead?.projectId);
      localStorage.setItem("leadQuo", true);
      window.location.assign(
        `${constants.BASE_URL}/quotation/enterprise-quotation/${activeLead?.projectId}`
      );
    } else {
      if (activeLead.email) {
        try {
          await axios
            .post(
              `${
                constants.PMT_API
              }/api/projects/addClient?userId=${localStorage.getItem(
                "userId"
              )}`,
              {
                email: activeLead.email,
                location: activeLead.address,
                name: activeLead.name,
                projectName: activeLead.name,
              }
            )
            .then(async (res) => {
              await axios
                .post(
                  `${
                    constants.PMT_API
                  }/api/projects/add-project?userId=${localStorage.getItem(
                    "userId"
                  )}`,
                  {
                    clientId: res.data._id,
                    location: activeLead.address,
                    isFromLead: true,
                    projectName: activeLead.name,
                    userId: localStorage.getItem("userId"),
                    isConfirmed: false,
                    phoneNumber: activeLead.phoneNumber,
                    clientName: activeLead.name,
                    projectAdmin: activeLead?.assignedTo?._id,
                    teamMembers: [activeLead?.assignedTo?._id],
                  }
                )
                .then(async (response) => {
                  await setActiveLeadState({
                    ...activeLead,
                    projectId: response?.data?._id,
                  });
                  localStorage.setItem("projectId", response?.data?._id);
                  localStorage.setItem("leadQuo", true);
                  await postReq(
                    `${constants.CRM_API}/api/save-projectId-leads?leadId=${activeLead?._id}&projectId=${response?.data?._id}`
                  )
                    .then((res2) => {
                      apiFetch(tab);
                      window.location.assign(
                        `${constants.BASE_URL}/quotation/enterprise-quotation/${response?.data?._id}`
                      );
                    })
                    .catch((err) => {
                      console.log(err.error);
                      notification.error({
                        message: err.error || "client already exist",
                        duration: 1,
                      });
                    });
                })
                .catch((e) => {
                  console.log(e);
                  notification.error({
                    message: e.error || "project already exists",
                    duration: 1,
                  });
                })
                .catch((err) => {
                  notification.error({
                    message: err.error || "client already exists",
                    duration: 1,
                  });
                });
            });
        } catch (e) {
          console.log(e);
          notification.error({
            message: e.error || "client already exists",
            duration: 1,
          });
        }
      } else {
        setCurrentQuot("desQuot");
        handleCloseModal();
        handleOk2();
      }
    }
  };

  const createProjectAndGoToQuotation = async () => {
    localStorage.setItem("leadId", activeLead?._id);
    if (activeLead?.projectId) {
      localStorage.setItem("projectId", activeLead?.projectId);
      window.open(
        `${constants.BASE_URL}/quotation/new-quotation/${activeLead?.projectId}?isLead=true`,
        "_blank"
      );
    } else {
      if (activeLead.email) {
        try {
          await axios
            .post(
              `${
                constants.PMT_API
              }/api/projects/addClient?userId=${localStorage.getItem(
                "userId"
              )}`,
              {
                email: activeLead.email,
                location: activeLead.address,
                name: activeLead.name,
                projectName: activeLead.name,
              }
            )
            .then(async (res) => {
              await axios
                .post(
                  `${
                    constants.PMT_API
                  }/api/projects/add-project?userId=${localStorage.getItem(
                    "userId"
                  )}`,
                  {
                    clientId: res.data._id,
                    location: activeLead.address,
                    projectName: activeLead.name,
                    userId: localStorage.getItem("userId"),
                    isConfirmed: false,
                    phoneNumber: activeLead.phoneNumber,
                    clientName: activeLead.name,
                    isFromLead: true,
                    projectAdmin: activeLead?.assignedTo?._id,
                    teamMembers: [activeLead?.assignedTo?._id],
                  }
                )
                .then(async (response) => {
                  localStorage.setItem("projectId", response?.data?._id);
                  await setActiveLeadState({
                    ...activeLead,
                    projectId: response?.data?._id,
                  });
                  await postReq(
                    `${constants.CRM_API}/api/save-projectId-leads?leadId=${activeLead?._id}&projectId=${response?.data?._id}`
                  )
                    .then((res2) => {
                      apiFetch(tab);
                      window.open(
                        `${constants.BASE_URL}/quotation/new-quotation/${response.data._id}?isLead=true`,
                        "_blank"
                      );
                    })
                    .catch((err) => {
                      console.log(err.error);
                      notification.error({
                        message: err.error || "client already exist",
                        duration: 1,
                      });
                    });
                })
                .catch((e) => {
                  console.log(e);
                  notification.error({
                    message: e.error || "project already exists",
                    duration: 1,
                  });
                })
                .catch((err) => {
                  notification.error({
                    message: err.error || "client already exists",
                    duration: 1,
                  });
                });
            });
        } catch (e) {
          console.log(e);
          notification.error({
            message: e.error || "client already exists",
            duration: 1,
          });
        }
      } else {
        setCurrentQuot("desQuot");
        handleCloseModal();
        handleOk2();
      }
    }
  };

  const updateLeadStatus = async (val) => {
    const res = await postReq(
      `${constants.CRM_API}/user/edit-lead?id=${
        leadData?._id
      }&userId=${localStorage.getItem("userId")}`,
      {
        // leadId: activeLead?._id,
        leadStatus: val,
      }
    );
    if (res && !res.error) {
      notification.success({
        message: res?.message || "Lead Status Updated Successfully",
        duration: 1,
      });
      apiFetch(tab);
      getLeadsCount();
    } else {
      console.log(res.error);
      notification.error({
        message: res?.message || "Oops Something Went Wrong",
        duration: 1,
      });
    }
  };
  const monthChange = async (val) => {
    const res = await postReq(
      `${constants.CRM_API}/user/edit-lead?id=${
        leadData?._id
      }&userId=${localStorage.getItem("userId")}`,
      {
        // leadId: activeLead?._id,
        clouserMonth: val,
      }
    );
    if (res && !res.error) {
      notification.success({
        message: res?.message || "Closure Updated Successfully",
        duration: 1,
      });
      apiFetch(tab);
    } else {
      console.log(res.error);
      notification.error({
        message: res?.message || "Oops Something Went Wrong",
        duration: 1,
      });
    }
  };

  const callStatus = leadData?.leadUpdates
    ? leadData?.leadUpdates[leadData?.leadUpdates?.length - 1]
    : undefined;

  const items = [
    {
      label: (
        <div className={styles.lockBox}>
          <a
            rel="noopener noreferrer"
            onClick={() => (showModal(), setOpenEditLeadModal(true))}
          >
            Edit Lead
          </a>
          {!userData?.rolesAssigned?.crm?.edit && (
            <Tooltip title="Access Disabled by the Admin">
              <img src={LockBlack} alt="LockImg"></img>
            </Tooltip>
          )}
        </div>
      ),
      key: "0",
      disabled: !userData?.rolesAssigned?.crm?.edit,
    },
    {
      label: (
        <div className={styles.lockBox}>
          <a rel="noopener noreferrer" onClick={() => AssignshowModal()}>
            Assign lead to
          </a>
          {!userData?.rolesAssigned?.crm?.canAssign && (
            <Tooltip title="Access Disabled by the Admin">
              <img src={LockBlack} alt="LockImg"></img>
            </Tooltip>
          )}
        </div>
      ),
      key: "1",
      disabled: !userData?.rolesAssigned?.crm?.canAssign,
    },
    {
      label: (
        <div className={styles.lockBox}>
          <a
            rel="noopener noreferrer"
            // onClick={createProjectAndGoToDesignQuotation}
            onClick={createProjectAndGoToQuotation}
          >
            Send a Quotation
          </a>{" "}
          {!userData?.rolesAssigned?.quotation?.edit && (
            <Tooltip title="Access Disabled by the Admin">
              <img src={LockBlack} alt="LockImg"></img>
            </Tooltip>
          )}
        </div>
      ),
      key: "2",
      disabled: !userData?.rolesAssigned?.quotation?.edit,
    },
    {
      label: (
        <div className={styles.lockBox}>
          <a
            rel="noopener noreferrer"
            onClick={() => (
              dispatch(setIsChecked(true)), setCreateProjectModalOpen(true)
            )}
          >
            Convert to Project
          </a>
          {!userData?.rolesAssigned?.pmt?.canCreateProject && (
            <Tooltip title="Access Disabled by the Admin">
              <img src={LockBlack} alt="LockImg"></img>
            </Tooltip>
          )}
        </div>
      ),
      key: "4",
      disabled: !userData?.rolesAssigned?.pmt?.canCreateProject,
    },
    {
      label: (
        <div className={styles.lockBox}>
          <a rel="noopener noreferrer" onClick={() => showDeleteModal()}>
            Delete Lead
          </a>
          {!userData?.rolesAssigned?.crm?.edit && (
            <Tooltip title="Access Disabled by the Admin">
              <img src={LockBlack} alt="LockImg"></img>
            </Tooltip>
          )}
        </div>
      ),
      key: "3",
      disabled: !userData?.rolesAssigned?.crm?.edit,
    },
  ];

  const statusUpdate = async (id) => {
    try {
      let today = new Date();
      let date =
        today.getDate() +
        "-" +
        parseInt(today.getMonth() + 1) +
        "-" +
        today.getFullYear();
      const res = await postReq(constants.CRM_API + "/user/newleadActions", {
        leadId: data._id,
        leadStatus: id,
        leadUpdates: [
          {
            callLeadStatus: id,
            isEmailSend: false,
            updateDate: date,
          },
        ],
      });
      if (res && !res?.error) {
        let x = leads;
        x.data[activeLead?.idx] = res?.data?.data;
        // console.log(x.data[activeLead?.idx] , res )
        // x.data[activeLead?.idx].leadStatus = leadstatus[getActive]?.value;
        // console.log(x ,activeLead?.idx);
        await setLeads({ ...x });
        await setActiveLeadState({ ...leads?.data[idx] });
      }
    } catch (e) {
      console.log("err", e);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAssignModal, setIsAssignModal] = useState(false);
  const [isdeleteModal, setIsDeleteModal] = useState(false);
  const [isCPModal, setIsCPModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [filteredSourceNames, setFilteredSourceNames] = useState([]);
  // const [workType, setWorkType] = useState([])
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setOpenEditLeadModal(false);
  };
  const AssignCancelModal = () => {
    setIsAssignModal(false);
  };
  const AssignshowModal = () => {
    setIsAssignModal(true);
  };
  const handleDeletModal = () => {
    setIsDeleteModal(false);
  };
  const showDeleteModal = () => {
    setIsDeleteModal(true);
  };
  const handleOk = () => {
    deleteLead();
  };

  const handleAssignChange = (value) => {
    // console.log(value,'value')

    setAssignId(value.value);
    setAssignToValue({
      fullName: value?.label,
      _id: value?.value,
    });
  };

  console.log("assignId", assignId);

  const CPCancelModal = () => {
    setIsCPModal(false);
  };
  const CPshowModal = () => {
    setIsCPModal(true);
  };

  const handleLeadSourceChange = (value) => {
    setSelectedLeadSourceId(value);
    const filtered = sourceNames.filter((item) => {
      return item.id === value;
    });
    setFilteredSourceNames(filtered);
    const selectedOptionObject = leadSources.find(
      (option) => option.id === value
    );
    setSelectedOption(
      selectedOptionObject ? selectedOptionObject.leadSource : null
    );
  };

  function numDifferentiation(num, format) {
    let val = convertBudgetToInt(num);
    if (val >= 10000000) val = (val / 10000000).toFixed(2) + "Cr";
    else if (val >= 100000) val = (val / 100000).toFixed(2) + "Lac";
    else if (val >= 1000) val = (val / 1000).toFixed(2) + "K";
    return val;
  }
  function convertBudgetToInt(bud) {
    const newBudget = bud?.replaceAll(",", "");
    return Number(newBudget);
  }
  const normalizeValue = (value) => {
    // Just replace the following regex to what you wnat
    const filteredValue = value.replace(/[a-zA-Z]/g, "");
    return filteredValue;
  };

  const normalizeBudgetValue = (value) => {
    // Just replace the following regex to what you wnat
    const filteredValue = value.replace(
      /[a-zA-Z\!\@\#\$\%\^\&\*\(\_\+\=\)\-]/g,
      ""
    );
    return filteredValue;
  };

  const handleChechBox = (e, leadId) => {
    if (e.target.checked) {
      dispatch(setSelectedUnassignedLead([leadId, ...selectedUnassignedLead]));
    } else {
      dispatch(
        setSelectedUnassignedLead(
          selectedUnassignedLead.filter(
            (unassignedLead) => unassignedLead !== leadId
          )
        )
      );
    }
  };
  console.log("selectedUnassignedLead", selectedUnassignedLead);
  return (
    <>
      <Toaster />
      {openEditLeadModal && (
        <EditLeadModal show={isModalOpen} onHide={handleCancel} />
      )}
      <ModalAntd
        // title={false}
        open={showArchitectureDetails}
        onOk={() => setShowArchitectureDetails(false)}
        onCancel={() => setShowArchitectureDetails(false)}
        centered
        footer={null}
        title="Source Details"
        width={1000}
      >
        <div style={{ height: 200 }}>
          {leadData.architectDetails ? (
            <ArchDetailsCard
              setShowArchitectureDetails={setShowArchitectureDetails}
              showSourceLeads={showSourceLeads}
              data={leadData.architectDetails}
            />
          ) : (
            <></>
          )}
        </div>
      </ModalAntd>
      {/* <Modal
        // title={false}
        open={quotationModal}
        onOk={handleQuotOk}
        onCancel={handleCloseModal}
        centered
        footer={null}
        // closable={false}
        title="Select the quotation"
        // className="ant-modal-centered"
      >
        <div className="d-flex mt-2">
          <div
            onClick={createProjectAndGoToDesignQuotation}
            className="d-flex flex-column align-items-center justify-content-center  py-5"
            style={{
              background: "#FFFFFF",
              border: "1px solid #DFDFDF",
              borderRadius: "8px",
              width: "50%",
              cursor: "pointer",
            }}
          >
            <img
              src={designquo}
              style={{ height: "2.75rem", width: "2.75rem" }}
            />
            <div
              className="mt-4"
              style={{
                color: "#444444",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Design Quotation
            </div>
          </div>
          <div
            onClick={createProjectAndGoToQuotation}
            className="d-flex flex-column align-items-center justify-content-center  py-5 ms-4"
            style={{
              background: "#FFFFFF",
              border: "1px solid #DFDFDF",
              borderRadius: "8px",
              width: "50%",
              cursor: "pointer",
            }}
          >
            <img
              src={executionquo}
              style={{ height: "2.75rem", width: "2.75rem" }}
            />
            <div
              className="mt-4"
              style={{
                color: "#444444",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Execution Quotation
            </div>
          </div>
        </div>
      </Modal> */}
      {/* <Modal
        // title={false}
        open={quotationModal2}
        onOk={handleOk2}
        onCancel={handleCloseModal2}
        centered
        title={
          currentQuot === "exQuot"
            ? `Send Execution Quotation`
            : `Send Design Quotation`
        }
        footer={
          <div>
            <Button onClick={handleCloseModal2}>Cancel</Button>
            <Button
              style={{
                background: "rgb(23,78,134)",
                color: "white",
                borderRadius: "0.25rem",
                fontSize: "13px",
              }}
              onClick={handleOk22}
            >
              Send
            </Button>
          </div>
        }
        closable={false}
        // className="ant-modal-centered"
      >
        <div className="d-flex mt-2">
          <input
            placeholder="Enter Lead Email"
            name="quotInput"
            value={quotInput}
            onChange={handleQuotChange}
            className="quoEmail"
          />
        </div>
      </Modal> */}

      {/* assign modal */}
      <ModalAntd
        title="Assign To"
        l
        open={isAssignModal}
        onCancel={AssignCancelModal}
        footer={false}
      >
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "space-between",
          }}
        >
          <Select
            className="basic-single"
            classNamePrefix="select"
            // defaultValue={colourOptions[0]}
            // isDisabled={isDisabled}
            // isLoading={isLoading}
            // isClearable={true}
            // isRtl={isRtl}
            isSearchable={true}
            onChange={handleAssignChange}
            name="assignTo"
            options={([...teamLeadArr] || []).map((d) => ({
              value: d._id,
              label: d.fullName,
            }))}
          />

          <Button
            type="primary"
            style={{
              width: 80,
              marginTop: "1rem",
            }}
            onClick={assignLead}
            disabled={buttonClickedOnce || !Boolean(assignId)}
          >
            {!buttonClickedOnce ? (
              "Apply"
            ) : (
              <Spinner
                animation="border"
                role="status"
                style={{ height: "1.25rem", width: "1.25rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>
        </div>
      </ModalAntd>
      {/* assign modal end */}

      {/* delete modal start */}
      <Modal
        title={false}
        show={isdeleteModal}
        centered
        width={300}
        bodyStyle={{ height: 60 }}
        closable={false}
      >
        <h6 style={{ padding: "3%" }}>Do you want to delete the lead ?</h6>
        <Modal.Footer>
          <div className={styles.discardCloseBtn}>
            <button className={styles.createModalSave} onClick={handleOk}>
              <span
                style={{
                  color: "#FFF",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
              >
                Yes{" "}
              </span>
            </button>
            <button
              className={styles.createModalClose}
              onClick={handleDeletModal}
            >
              <span
                style={{
                  color: "#C9C9C9",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
              >
                No{" "}
              </span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* <Modal
        title={false}
        open={isdeleteModal}
        onOk={handleOk}
        onCancel={handleDeletModal}
        centered
        closable={false}
        // className="ant-modal-centered"
      >
        <p>Are you sure you want to delete this lead?</p>
      </Modal> */}
      {/* delete modal  end */}

      {/* create Project modal */}
      {/* <Modal
        title="Create Project"
        open={isCPModal}
        onCancel={CPCancelModal}
        footer={false}
      >
        <div style={{ marginBottom: ".7rem" }}>
          <span>Project Name</span>
          <Input
            onChange={(e) => {
              setcpName(e.target.value);
            }}
          />
        </div>

        <h6>Share project link with client</h6>
        <div style={{ marginTop: "1rem", marginBottom: ".7rem" }}>
          <span>Email Id</span>
          <Input
            onChange={(e) => {
              setcpEmail(e.target.value);
            }}
            defaultValue={activeLead ? activeLead?.email : ""}
          />
        </div>

        <Button
          type="primary"
          style={{
            width: 80,
          }}
          onClick={createProject}
        >
          Continue
        </Button>
      </Modal> */}
      {/* create project modal end */}

      {/* <tr>
       
         <td>Shivam</td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td>Shivam</td>
        </tr> */}
      <div
        className={styles.lmsBox}
        style={{
          boxShadow:
            activeLead?.idx === idx
              ? "0px 5px 20px 0px rgba(0, 0, 0, 0.10)"
              : null,
        }}
        onClick={async () => {
          await setActiveLeadState({
            ...leads?.data[idx],
            idx: idx,
            assignedTo: assignToValue ? assignToValue : assignedTo,
          });
          dispatch(selectNextSlide({ ...leadData, index: idx }));
          projectDataObj.firstName = firstName ? firstName : name;
          projectDataObj.lastName = lastName;
          projectDataObj.address = address;
          projectDataObj.phnNum = phoneNumber;
          projectDataObj.email = email;
          projectDataObj.code = pinCode;
          projectDataObj.city = city;
          dispatch(projectDataCurrObj(projectDataObj));
          if (activeLead?.idx !== idx) {
            dispatch(
              activeLead?.idx > idx
                ? slideOut(window.screen.width >= 1341 ? 23 : 19)
                : slideIn(window.screen.width > 1361 ? 23 : 19)
            );
          }
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            background: activeLead?.idx === idx ? "#0084EF" : null,
          }}
          className={styles.topHeader}
        >
          <div
            className={styles.topHeaderText}
            style={{ display: "flex", alignItems: "center", gap: "3px" }}
          >
            {" "}
            <input
              type="checkbox"
              checked={selectedUnassignedLead.includes(_id)}
              onChange={(e) => {
                handleChechBox(e, _id);
                //  setActive(e.target.checked);
              }}
            />
            {tab == 8 ? (
              <span
                style={{ color: activeLead?.idx === idx ? "#ffffff" : "black" }}
              >
                {name}
              </span>
            ) : (
              <Link
                disabled={false}
                to={
                  !userData?.rolesAssigned?.lms?.view
                    ? `/leads/lead-details/${data?._id}`
                    : ""
                }
                onClick={() => (
                  localStorage.setItem("leadId", data?._id),
                  dispatch(selectedLeadData(data)),
                  localStorage.removeItem("projectId")
                )}
                state={{ idx: idx, tab: tab }}
                style={{
                  textDecoration:
                    activeLead?.idx === idx ? "underline" : "none",
                  color: activeLead?.idx === idx ? "#ffffff" : "black",
                  cursor: "pointer",
                }}
              >
                {name}
              </Link>
            )}
          </div>
          <div
            className={styles.addedOnText}
            style={{
              fontSize: "12px",
              color: activeLead?.idx === idx ? "#ffffff" : "#8c8b8b",
            }}
          >
            Added on:{" "}
            <span className="text-nowrap" style={{ marginRight: "10px" }}>
              {createdAt ? formateDate(createdAt) : "-"}
            </span>
            {tab !== "9" && (
              <span>
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items,
                  }}
                  onClick={() => dispatch(currentLead(activeLead))}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <FaEllipsisV
                        style={{
                          cursor: "pointer",
                          color: activeLead?.idx === idx ? "#ffffff" : "black",
                        }}
                      />
                    </Space>
                  </a>
                </Dropdown>
              </span>
            )}
          </div>
        </div>
        <tr className={styles.tableRow}>
          <td className={styles.tableData} style={{ maxWidth: "8vw" }}>
            <span
              style={{
                width: "95%",
                paddingLeft: "15%",
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis ",
              }}
            >
              {address && `${address}  ${city ? city : ""}`}
              {address && <br />}
              {area && `${area} sqft`}
            </span>
          </td>
          <td className={styles.tableData} style={{ width: "7%" }}>
            {budget &&
              `${
                profileData?.CompanySettingDetails?.currencySymbol
                  ? profileData?.CompanySettingDetails?.currencySymbol
                  : "₹"
              }${numDifferentiation(budget)}`}
          </td>
          <td className={styles.tableData} style={{ width: "12.5%" }}>
            <span
              className={styles.requirementsCss}
              style={{
                width: "85%",
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis ",
              }}
            >
              {requirements && `${requirements} `}
            </span>
          </td>
          <td className={styles.tableData} style={{ width: "17.5%" }}>
            {tab == 9 ? (
              <div
                style={{
                  width: "4rem",
                }}
                className=" new-bg leadStatusDropdownbutton bg-interested box-shadow-none py-1 mt-1 px-1 pfs-11"
              >
                {"Won"}
              </div>
            ) : leadData?.leadStatus == 1 ? (
              <button
                type="button"
                className="btn new-bg btn-primary leadStatusDropdownbutton bg-base-blue box-shadow-none py-1 mt-1 px-1 pfs-11"
                style={{
                  minWidth: "60px",
              
           
                }}
                disabled={!userData?.rolesAssigned?.crm?.edit}
              >
                {"New Lead"}
              </button>
            ) : (
              <button
                type="button"
                // onClick={(e) => e.stopPropagation()}
                id="dropdownMenuButton"
                data-mdb-toggle="dropdown"
                // disabled={userData?.rolesAssigned?.crm?.edit}
                style={{
                  cursor: userData?.rolesAssigned?.crm?.edit
                    ? "pointer"
                    : "default",
                }}
                className={`btn leadStatusDropdownbutton btn-primary dropdown-toggle pfs-11 ${
                  leadData?.leadStatus === 6
                    ? "bg-no-response"
                    : leadData?.leadStatus === 2
                    ? "bg-follow-up"
                    : leadData?.leadStatus === 1
                    ? "bg-base-blue"
                    : leadData?.leadStatus === 3
                    ? "bg-interested"
                    : leadData?.leadStatus === 7
                    ? "bg-meeting-scheduled"
                    : leadData?.leadStatus === 5
                    ? "bg-not-interested"
                    : "bg-base-blue"
                } box-shadow-none py-1 mt-1 px-1`}
              >
                {leadStatusItem[valueToLeadStatus[leadData?.leadStatus]]
                  ?.label || "New Lead"}
                <BiChevronDown className="mx-0" />{" "}
                {!userData?.rolesAssigned?.crm?.edit && (
                  <Tooltip title="Access Disabled by the Admin">
                    <img src={LockBlack} alt="LockImg"></img>
                  </Tooltip>
                )}
              </button>
            )}
            {userData?.rolesAssigned?.crm?.edit && (
              <ul
                className={`dropdown-menu ${styles.leadStatushovercontaineer}`}
              >
                {leadStatus !== 2 && (
                  <li
                  // onClick={() => statusUpdate(2)}
                  >
                    <a
                      className={`dropdown-item ${styles.leadStatushover}`}
                      style={{ color: "#000000" }}
                      onClick={() => updateLeadStatus(2)}
                    >
                      Follow Up
                    </a>
                  </li>
                )}
                {leadStatus !== 3 && (
                  <li
                  // onClick={() => statusUpdate(3)}
                  >
                    <a
                      className={`dropdown-item ${styles.leadStatushover}`}
                      style={{ color: "#000000" }}
                      onClick={() => updateLeadStatus(3)}
                    >
                      Interested
                    </a>
                  </li>
                )}
                {leadStatus !== 5 && (
                  <li
                  // onClick={() => statusUpdate(5)}
                  >
                    <a
                      className={`dropdown-item ${styles.leadStatushover}`}
                      style={{ color: "#000000" }}
                      onClick={() => updateLeadStatus(5)}
                    >
                      Not Interested
                    </a>
                  </li>
                )}
                {leadStatus !== 6 && (
                  <li
                  // onClick={() => statusUpdate(6)}
                  >
                    <a
                      className={`dropdown-item ${styles.leadStatushover}`}
                      style={{ color: "#000000" }}
                      onClick={() => updateLeadStatus(6)}
                    >
                      No Response
                    </a>
                  </li>
                )}
              </ul>
            )}
          </td>
          <td
            className={`${styles.tableData} `}
            style={{ width: "10.5%", paddingRight: "10px" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Selectantd
                disabled={
                  leadData?.leadStatus === 5 ||
                  !userData?.rolesAssigned?.crm?.edit
                }
                placeholder="Month"
                value={clouserMonth}
                className={`${styles.expectedClosure} antd-selectt`}
                optionFontSize={10}
                onChange={(value) => {
                  monthChange(value);
                }}
                options={[
                  { value: "Jan", label: "Jan" },
                  { value: "Feb", label: "Feb" },
                  { value: "March", label: "March" },
                  { value: "April", label: "April" },
                  { value: "May", label: "May" },
                  { value: "June", label: "June" },
                  { value: "July", label: "July" },
                  { value: "Aug", label: "Aug" },
                  { value: "Sept", label: "Sept" },
                  { value: "Oct", label: "Oct" },
                  { value: "Nov", label: "Nov" },
                  { value: "Dec", label: "Dec" },
                ]}
              />
              {!userData?.rolesAssigned?.crm?.edit && (
                <Tooltip title="Access Disabled by the Admin">
                  <span>
                    <img src={LockBlack} alt="LockImg"></img>
                  </span>
                </Tooltip>
              )}
            </div>
          </td>

          {leadData?.leadStatus == 1?<>
            <td style={{width:"12.5%",padding:"0px 20px"}}>__</td>
            <td style={{width:"12.5%",padding:"0px 20px"}}>__</td>
          </>:<>
          
    
          <td className={styles.tableData} style={{ width: "12.5%" }}>
            {callStatus &&
              leadStatusItem[valueToLeadStatus[callStatus?.callLeadStatus]]
                ?.label}
            {callStatus && <br />}
            {updatedAt && formateDate(updatedAt)}
          </td>
          <td className={styles.tableData} style={{ width: "12.5%" }}>
            {leadUpdates && leadUpdates[leadUpdates?.length - 1]?.updateDate
              ? leadUpdates[leadUpdates?.length - 1]?.updateDate
              : "-"}
            {leadUpdates && <br />}
            {leadUpdates && leadUpdates[leadUpdates?.length - 1]?.updateDate
              ? leadUpdates[leadUpdates?.length - 1]?.updateTime
              : "-"}
          </td>

          </>}
          <td
            className={styles.tableData}
            style={{ width: "12.5%", maxWidth: "7vw" }}
          >
            {" "}
            <span
              style={{
                width: "95%",
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis ",
              }}
            >
              {leadSource ? (leadSource == 1 ? "Self" : leadSource) : "-"}
              <br />
              <div
                style={{
                  color: "#0084EF",
                  whiteSpace: "nowrap",
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (leadData.architectDetails) {
                    setShowArchitectureDetails(true);
                  }
                }}
              >
                {sourceName && `${sourceName}`}
              </div>
            </span>
          </td>
        </tr>
      </div>

      {createProjectModalOpen && (
        <CreateNewProjectNew currentData={activeLead} />
      )}
    </>
  );
};

export default LmsTableItemForUnassigned;
