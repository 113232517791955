import { TextField,useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import bgmain from "../../Assets/bg-main.svg";
import CountUp from "react-countup";
import LoginHeader from "../loginHeader/LoginHeader";
import { useSelector } from "react-redux";
import { postReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import { useNavigate } from "react-router-dom";
import { constants } from "../../piservices/constants";
import { toasterControl } from "../../piservices/commonService";
import styles from "./verifyOtpForgot.module.css";

const VerifyOtpForgot = () => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const navigateTo = useNavigate();
  const { otpEmail } = useSelector((state) => state.persist);
  const [showResend, setShowResend] = useState(false);
  const [otpText, setOtpText] = useState("");

  const handleInput = (event) => {
    if (event.target.value.length < 5) {
      setOtpText(event.target.value);
    }
  };

  const verifyOtp = async () => {
    const res = await postReq(`${constants.emailApi}${apiEndpoints.verifyOtp}`, {
      email: otpEmail,
      otp: otpText,
    });
    if (res && !res.error) {
      if (res.data.message && res.data.message === "Failed") {
        toasterControl("toasterState", res.data.description, "error");
      } else {
        navigateTo("/create-password");
      }
    } else {
      console.error(res.error);
    }
  };

  const getResendOtp = async () => {
    const res = await postReq(`${constants.emailApi}${apiEndpoints.sendOtp}`, {
      email: otpEmail,
    });
    if (res && !res.error) {
      setOtpText("")
      setShowResend(false);
    } else {
      console.error(res.error);
    }
  };
  return (
    <>
      <LoginHeader />
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 4rem)",
          width: "100%",
          backgroundImage: `url(${bgmain})`,
          backgroundRepeat: " no-repeat",
          backgroundSize: "100%",
          backgroundPositionY: "center",
        }}
      > */}
      <div style={{ backgroundImage: `url(${bgmain})`,
          backgroundRepeat: " no-repeat",
          backgroundSize: "100%",
          backgroundPositionY: "center",}} className={styles.container}>
        {/* <div style={{ width: "40%", border: "1px solid #E0E3E6", borderRadius: "12px", backgroundColor: "#ffffff" }}> */}
        <div className={styles.box}>
          {/* <div style={{ padding: "24px", borderBottom: "1px solid #E0E3E6", color: "#40454A", fontFamily: "Inter", fontSize: "28px", fontStyle: "normal", fontWeight: 700, lineHeight: "28px" }}> */}
          <div className={styles.verificationContainer}>
            Verification
          </div>
          {/* <div style={{ padding: "24px 24px 28px 24px", display: "flex", flexDirection: "column", alignItems: "center", gap: "1.5rem" }}> */}
          <div className={styles.verification}>
            {/* <div style={{ color: "#999", fontFamily: "Inter", fontSize: "16px", fontStyle: "normal", fontWeight: 500, lineHeight: "28px" }}>You will get an OTP via email</div> */}
            <div className={styles.para}>You will get an OTP via email</div>
            <div style={{ borderRadius: "12px", width: "100%" }}>
              <TextField fullWidth id="outlined-basic" label="OTP" variant="outlined" InputProps={{ style: { borderRadius: "12px" } }} value={otpText} onChange={handleInput}  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px"}}} />
            </div>

            <div style={{ width: "100%" }}>
              {/* <button
                style={{
                  borderRadius: "8px",
                  border: "1px solid #0084EF",
                  background: "#0084EF",
                  width: "100%",
                  padding: "10px 14px",
                  color: "#ffffff",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "20px",
                }}
                onClick={() => verifyOtp()}
              > */}
              <button
               className={styles.verifyOtpBtn}
                onClick={() => verifyOtp()}
              >
                Verify
              </button>
            </div>

            
          </div>
          <div style={{ borderTop: "1px solid var(--Neutral-200, #E0E3E6)", width: "100%" }}>
            <div className={styles.footerPara}>
            {/* <div cl style={{ color: "#999", fontFamily: "Inter", fontSize: "14px", fontStyle: "normal", fontWeight: 500, lineHeight: "28px", display: "flex" }}> */}
            <div className={styles.otpFlex}>
              <div style={{ marginRight: "0.5rem" }}>Didn’t receive the verification OTP?</div>
              <div>
              {!showResend && <span>Resend in</span>}
              {showResend ? (
                <div style={{ cursor: "pointer", color: "#0084EF" }} onClick={() => getResendOtp()}>
                  Resend again
                </div>
              ) : (
                <>
                  <CountUp start={30} end={0} duration={30} useEasing={false} onEnd={() => setShowResend(true)} style={{ marginLeft: "0.3rem" }} />
                  <span style={{ marginLeft: "0.2rem" }}>sec</span>
                </>
              )}
              </div>
            </div>
            </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOtpForgot;
