import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import onboardingSlice from "./Slices/onboardingSlice";
import persistSlice from "./Slices/persistSlice";
import lmsSlice from "./Slices/lmsSlice";
import selectedLeadSlice from "./Slices/specificleadSlice";
import convertToProjectSlice from "./Slices/convertToProjectSlice";
import newLeadSlice from "./Slices/newLeadSlice";
import lmsProjectSlice from "./Slices/lmsProjectSlice";
import notificationSlice  from "./Slices/notificationSlice";

const reducers = combineReducers({
  lmsSlice: lmsSlice,
  notificationSlice:notificationSlice,
  lmsProjectSlice: lmsProjectSlice,
  selectedLeadSlice: selectedLeadSlice,
  convertToProjectSlice: convertToProjectSlice,
  newLeadSlice: newLeadSlice,
  onboarding: onboardingSlice,
  persist: persistSlice,
});
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["persist"],
};
const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;
