import React, { useEffect, useRef, useState } from 'react'
import styles from "./PlusIcon.module.css"
import plusIcon from "./Image/Plus.svg"
import userCirclePlus from "./Image/UserCirclePlus.svg"
import calenderPlus from "./Image/CalendarPlus.svg"
import notePencil from "./Image/NotePencil.svg"
import rightArrow from "./Image/CaretRight (1).svg"
import { BsSearch } from "react-icons/bs"
import toast from "react-hot-toast";
import { Dropdown, Modal } from "react-bootstrap";
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { constants  } from '../../../../piservices/constants'
import { setAddLeadModalOpen } from '../../../../Redux/Slices/newLeadSlice'

const PlusIconTopBar = () => {
  const [showDropDownDPR, setShowDropDownDPR] = useState(false)
  const dropdownRef = useRef(null)
  const [showModalLMS, setshowModalLMS] = useState(false);
  const [momOptionsLMS, setmomOptionsLMS] = useState()
  const [projectName, setProjectName] = useState("");
  const [projectId, setProjectId] = useState("");
  const [showError, setShowError] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [allProjects, setAllProjects] = useState([]);
  const [newData, setNewData] = useState([]);
  // const [addLeadModalOpen , setAddLeadModalOpen] = useState(false)
  const dispatch = useDispatch()
  // const addLeadModalOpen = useSelector((state) => state.AdminReducer.addLeadModalOpen)
  // console.log(addLeadModalOpen, "addLeadModalOpen")
  const handlePlusIconClick = () => {
    setShowDropDownDPR((prevShowDropDownDPR) => !prevShowDropDownDPR);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Clicked outside the dropdown, so close it
      setShowDropDownDPR(false);
    }
  };

  useEffect(() => {
    // Add mousedown event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const sortAlphabatically = (data) => {
    const sortedItems = [...data].sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setNewData([...sortedItems]);
  };
  const getAllProjects = async () => {
    const res = await axios.get(
      `${constants.PMT_API
      }/api/projects/get-active-projects?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res) {

      sortAlphabatically(res?.data);
      setAllProjects(res?.data);
    }
  };
  useEffect(() => {
    getAllProjects();
    // getAllProject();
  }, []);

  const handleSearch = (e) => {
    setShowError(false);
    setSearchInput(e.target.value);
  };
  const handleSubmit = () => {
    if (projectId === "") {
      setShowError(true);
      return;
    }
    setshowModalLMS(false)
    if (momOptionsLMS === 1) {
      window.location.assign(`${constants.BASE_URL}/mom/newminute/${localStorage.getItem("projectId")}?type=Minutes`);
    } if (momOptionsLMS === 2) {
      window.location.assign(`${constants.BASE_URL}/mom/newmaterial/${localStorage.getItem("projectId")}?type=Item-Selection`);
    } if (momOptionsLMS === 3) {
      window.location.assign(`${constants.BASE_URL}/mom/newagenda/${localStorage.getItem("projectId")}?type=Agenda`);
    } if (momOptionsLMS === 4) {
      window.location.assign(`${constants.BASE_URL}/mom/newnotes/${localStorage.getItem("projectId")}?type=Notes`);
    }
  };


  return (
    <>
      <Modal
        show={showModalLMS}
        onHide={() => (
          setshowModalLMS(false),
          setProjectId(""),
          setProjectName(""),
          localStorage.removeItem("projectId"),
          localStorage.removeItem("projectName")
        )}
        centered
        size="md"
      >
        <Modal.Body style={{ padding: 36 }}>
          <div style={{ fontWeight: 600, fontSize: 24, color: "#3D3C3C" }}>
            Select Project
          </div>

          <div style={{ marginTop: 32 }}>
            <Dropdown
              drop="bottom"
              className="w-100"
              style={{
                border: showError
                  ? "1px solid red"
                  : "1px solid rgba(0, 0, 0, 0.30)",
                borderRadius: "4px",
              }}
            >
              <Dropdown.Toggle
                className="modal-temp-imp"
                style={{
                  padding: " 0",
                  display: "flex",
                  backgroundColor: "white",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  className="d-flex align-items-center w-100"
                  onClick={() => {
                    document.getElementById("myInput").focus();
                  }}
                >
                  <input
                    id="myInput"
                    type="text"
                    className="search-in h-100 d-flex align-items-center  p-2 w-100"
                    style={{
                      border: "none",
                      background: "transparent",
                      width: "90%",
                      borderRadius: "6.67857px",
                      backgroundColor: "white",
                    }}
                    placeholder="Select a Project"
                    value={searchInput}
                    onChange={handleSearch}
                  />
                  <BsSearch color="#888" className="mx-2" size={14} />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  color: "#333333",
                  fontSize: "14px",
                  fontWeight: "500",
                  width: "85%",
                }}
              >
                <div
                  className="d-flex flex-column"
                  style={{
                    overflowY: "scroll",
                    height: "16vh",
                    width: "100%",
                  }}
                >
                  <div
                    className="d-flex flex-column"
                    style={{
                      overflowY: "scroll",
                      height: "16vh",
                      width: "100%",
                    }}
                  >
                    {allProjects?.filter((data, index) => {
                      if (
                        data?.name
                          ?.toLowerCase()
                          ?.includes(searchInput?.toLowerCase())
                      ) {
                        return true;
                      }
                    }).length > 0
                      ? allProjects
                        ?.filter((data, index) => {
                          if (
                            data?.name
                              ?.toLowerCase()
                              ?.includes(searchInput?.toLowerCase())
                          ) {
                            return true;
                          }
                        })
                        ?.map((item, index) => (
                          // <div className="d-flex flex-column" style={{overflowY: "scroll", height: "25vh"}}>
                          <Dropdown.Item
                            key={index}
                            onClick={() => {
                              setProjectName(item.name);
                              setProjectId(item._id);
                              setSearchInput(item.name);
                              setShowError(false);
                              localStorage.setItem("projectId", item._id);
                              localStorage.setItem(
                                "projectName",
                                item.name
                              );
                              localStorage.setItem(
                                "selectedProject",
                                "true"
                              );
                            }}
                          >
                            {item.name}
                          </Dropdown.Item>
                          // </div>
                        ))
                      : "No Data Available"}
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div
            style={{
              marginTop: 32,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                fontWeight: 600,
                fontSize: 16,
                color: "#1D3557",
                cursor: "pointer",
              }}
            >
              {/* Create Project */}
            </div>

            <div
              style={{
                width: 135,
                height: 43,
                backgroundColor: "#1D3557",
                borderRadius: 8,
                color: "white",
                fontWeight: 600,
                fontSize: 16,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                userSelect: "none",
              }}
              onClick={() => {
                if (searchInput !== "") {
                  handleSubmit();
                  setSearchInput("");
                } else {
                  toast.error("Please Select Project");
                }
              }}
            >
              Submit
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className={styles.plusIconDiv}>
        <img onClick={() => {
          handlePlusIconClick()
        }} className={styles.plusIconNew} src={plusIcon} />
        {showDropDownDPR === true && <div ref={dropdownRef} className={styles.clickDropdown}>

          <div className={`${styles.plusIconFirstChild} ${styles.textCss}`} onClick={() =>
            dispatch(setAddLeadModalOpen(true))
         
          }><img className={styles.userCirclePlus} src={userCirclePlus} /> Add Lead</div>
          <div className={`${styles.plusIconFirstChild} ${styles.textCss}`} onClick={() =>
            window.location.assign(`${constants.BASE_URL}/report/?create-reports=${true}`)
          }>
            <img className={styles.userCirclePlus} src={calenderPlus} /> Create DPR</div>
          <div className={`${styles.plusIconSecondChildHover} ${styles.textCss}`}>
            <div className={`${styles.plusIconSecondChild} ${styles.textCss}`}>
              <div className={styles.plusIconThirdSubChild}><img className={styles.userCirclePlus} src={notePencil} /> Create MOM</div>
              <img src={rightArrow} alt="" />
              <div className={styles.momDropdown}>
                <div className={`${styles.momFirstChild} ${styles.textCss}`}
                  onClick={() => {
                    setShowDropDownDPR(false);
                    setmomOptionsLMS(1); setshowModalLMS(true)
                  }}
                >Minutes of Meeting</div>
                <div className={`${styles.momFirstChild} ${styles.textCss}`}
                  onClick={() => {
                    setShowDropDownDPR(false);
                    setmomOptionsLMS(2); setshowModalLMS(true)
                  }}
                >Material Selection</div>
                <div className={`${styles.momFirstChild} ${styles.textCss}`}
                  onClick={() => {
                    setShowDropDownDPR(false);
                    setmomOptionsLMS(3); setshowModalLMS(true)
                  }}
                >Meeting Agenda</div>
                <div className={`${styles.momFirstChild} ${styles.textCss}`}
                  onClick={() => {
                    setShowDropDownDPR(false);
                    setmomOptionsLMS(4); setshowModalLMS(true)
                  } }
                >Notes</div>
              </div>
            </div>
          </div>
          {/* /report/ */}

        </div>}
      </div>

      
      {/* {addLeadModalOpen &&
        <AddLeadModalNew />
      } */}
    </>
  )
}

export default PlusIconTopBar