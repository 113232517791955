import React from "react";
import { Helmet } from "react-helmet";
import { Link, redirect, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./SideBar/Header";
import { DatePicker, Modal, Select, Space, Form as aForm } from "antd";
import "./index.css";
import { useNavigate } from "react-router-dom";
import vector11 from "./images/Vector-11.png";
import {
  baseUrl,
  convertDateFormat,
  formateDate,
  formateDateNameMonth,
  isMd,
} from "../../piservices/compCommon";
import SideModal from "./SideModal/SideModal";
import LmsMail from "./LmsMail.js/LmsMail";
import LeadUpdate from "./LeadUpdate/LeadUpdate";
import LmsTableTh from "./LmsTableTh";
import LmsTableItemCard from "./LmsTableItemCard";
import { useEffect } from "react";
import { getReq } from "../../piservices/apis";
import {constants} from "../../piservices/constants";
// import { selectedHeaderTab } from "../Common/NewLayout/ProjectHeader";
import desquoContext from "../../picontext/DashBoard/desquoContext";
import { useContext } from "react";
import nolead from "./images/EmptyState.svg";
import boqmv from "./images/project.svg";
import import_csv from "../../Assets/import_csv.svg";
import export_csv from "../../Assets/export_csv.svg";
import AddLeadModal from "./AddLeadModal";
import { getToken } from "../../piservices/authService";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Analytic from "./Analytics/Analytic";
import styles from "./ModuleCssFiles/lms.module.css";
import HeaderSidebar from "../Common/HeaderSidebar/HeaderSidebar";
import {
  isFilterOpen,
  setLeadSources,
  setLeadsCount,
  setPanelUpdate,
  setProfileData,
  setSelectedTabs,
  setSourceNames,
  setTeamLeadArr,
  setTeamMembers,
  setUserData,
  setWorkType,
} from "../../Redux/Slices/lmsSlice";
import LeadManagement from "./LeadManagements/LeadManagement";
import LeadInfoSidePanel from "./LeadManagements/LeadInfoSidePanel";
import { Spinner, Form as bForm } from "react-bootstrap";
import { setAddLeadModalOpen } from "../../Redux/Slices/newLeadSlice";
import { ThreeDots } from "react-loader-spinner";
import { CSVLink } from "react-csv";
import ImportFromCSVModal from "./CSVHandlerFolder/ImportFromCSVModal.jsx";
import axios from "axios";
import useApiFetchForLeadCountCustomHook from "../../piservices/ApiFetchForLeadCount.jsx";
import useApiFetchCustomHookForEmailService from "../../piservices/ApiFetchCustomHookForEmailService.jsx";
const Lms = ({ setProgress, setSelectedtab }) => {
  let { tab } = useParams();

  const [headerTab, setHeaderTab] = useState(
    localStorage.getItem("headerTab") || "Leads Management"
  );
  const navigate = useNavigate();
  const [selectedPeriod, setSelectedPeriod] = useState("this quarter");
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [updatePanel, setUpdatePanel] = useState(false);
  console.log(updatePanel,"leadupdatepa")
  const [updatePanel2, setUpdatePanel2] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [headerSelectedTab, setSelectedHeaderTab] =
    useState("Leads Management");
  const context = useContext(desquoContext);
  const userData = useSelector((state) => state.lmsSlice.userData);

  const [createdByNameArray, setCreatedByNameArray] = useState([]);

  // const panelUpdated = useSelector(state => state.lmsSlice.panelUpdated)
  const searchTerms = useSelector((state) => state.lmsSlice.searchTerms);
  const showEmailModal = useSelector((state) => state.lmsSlice.showEmailModal);

  const { leads, leads2, setLeads, setLeads2, activeLead } = context;
  // console.log('panelupdatedilms', panelUpdated)
  const { getLeadsCount, getLeadFormTemplate } =
    useApiFetchForLeadCountCustomHook();
  const { getNotificationServices, getAllAssignToAccessUser } =
    useApiFetchCustomHookForEmailService();

  const init = async () => {
    setLoading(true);
    await setProgress(30);
    const res = await getReq(
      constants.CRM_API + "/user/newLeadsData?pageNo=0" + (tab > 0 ? "&type=" + tab : "")
    );
    if (res && !res.error) {
      if (tab === "2") {
        let today = new Date();
        console.log("leadsssdata", res?.data?.data?.data);
        console.log("formatedataa", convertDateFormat("23-03-2024"));
        let followUpLeadsBeforeToday = res?.data?.data?.data
          ?.filter(
            (item) =>
              today >
              new Date(
                convertDateFormat(
                  item?.leadUpdates[item?.leadUpdates?.length - 1].updateDate
                )
              )
          )
          ?.sort(
            (a, b) =>
              new Date(
                convertDateFormat(
                  b?.leadUpdates[b?.leadUpdates?.length - 1].updateDate
                )
              ) -
              new Date(
                convertDateFormat(
                  a?.leadUpdates[a?.leadUpdates?.length - 1].updateDate
                )
              )
          );

        console.log("followUpLeadsBeforeToday", followUpLeadsBeforeToday);

        let followUpLeadsTodayAndAfter = res?.data?.data?.data
          ?.filter(
            (item) =>
              today <=
              new Date(
                convertDateFormat(
                  item?.leadUpdates[item?.leadUpdates?.length - 1].updateDate
                )
              )
          )
          ?.sort(
            (b, a) =>
              new Date(
                convertDateFormat(
                  b?.leadUpdates[b?.leadUpdates?.length - 1].updateDate
                )
              ) -
              new Date(
                convertDateFormat(
                  a?.leadUpdates[a?.leadUpdates?.length - 1].updateDate
                )
              )
          );
        console.log("followUpLeadsTodayAndAfter", followUpLeadsTodayAndAfter);
        let x = JSON.parse(JSON.stringify(res?.data?.data));
        delete x["data"];
        x["data"] = [
          ...followUpLeadsTodayAndAfter,
          ...followUpLeadsBeforeToday,
        ];


        console.log(x,"leaddatavalueoffollowup")

     
        setLeads(x);
        setLeads2(x);
      } else {
        const sortedLeads = res?.data?.data?.data?.sort(
          (a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt)
        );
        let x = JSON.parse(JSON.stringify(res?.data?.data));
        delete x["data"];
        x["data"] = sortedLeads;
        setLeads({ ...x });
        setLeads2(x);
      }

      setLoading(false);
    }
    await setProgress(100);
  };


  console.log(leads,"leadatavalue")
  function filterLeads(searchTerms) {
    let filteredLeads = leads2?.data?.filter((item) =>
      item?.name?.toLowerCase()?.includes(searchTerms?.toLowerCase())
    );
    if (leads2?.data) {
      let x = leads2;
      setLeads({ ...x, data: [...filteredLeads] });
    }
  }

  useEffect(() => {
    filterLeads(searchTerms);
  }, [searchTerms]);

  const getLeadSource = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-lead-source?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      dispatch(setLeadSources(res.data.data));
    } else {
      console.log(res.error);
    }
  };

  const getTeamLeads = async () => {
    const res = await getReq(
      `${
        constants.CRM_API
      }/api/enterprise/get-team-leaders?userId=${localStorage.getItem(
        "userId"
      )}&access=1`
    );
    if (res && !res.error) {
      dispatch(setTeamLeadArr(res.data.data));
    } else {
      console.log(res.error);
    }
  };
  const getWorkTypes = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-work-types?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      dispatch(setWorkType(res.data.data));
    } else {
      console.log(res.error);
    }
  };
  const getProfileData = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/profile`,
      {},
      { Authorization: `Bearer ${getToken()}` }
    );
    if (res && !res.error) {
      dispatch(setProfileData(res.data.data));
    } else {
      // console.log(res.error);
      // localStorage.clear();
      // window.location.assign(`${BASE_URL}`);
    }
  };
  const getSourceName = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-source-name?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      // console.log('getting lead source', res.data.data)
      dispatch(setSourceNames(res.data.data));
    } else {
      console.log(res.error);
    }
  };
  const getUserbyId = async (id) => {
    const res = await getReq(
      `${constants.CRM_API}/user/getUserById?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      dispatch(setUserData(res?.data?.data));
    } else {
      console.log(res.error);
    }
  };
  const getTeamMember = async () => {
    await axios
      .get(
        `${
          constants.CRM_API
        }/api/enterprise/get-team-member?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then(function (response) {
        dispatch(setTeamMembers(response.data.data));
        // setteamMember(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const getUserName = async () => {
  //   const res = await getReq(
  //     `${constants.PMT_API}/api/projects/get-project-byId?projectId=662f297bc6bc0524b090c4d7`
  //   );
  //   console.log("YeProjectKaData", res?.data);
  // };
  // getUserName();

  const getUserName = async (projectId) => {
    try {
      const res = await getReq(
        `${constants.PMT_API}/api/projects/get-project-byId?projectId=${projectId}`
      );
      return res.data.createdByName;
    } catch (error) {
      console.error(`Error fetching data for projectId ${projectId}:`, error);
      return null;
    }
  };

  // Function to fetch names for all projects
  const fetchNames = async () => {
    const namesArray = [];
    if(leads !==undefined || leads?.data!==undefined || leads?.data.length>0 )
    for (const lead of  leads?.data) {
      const createdByName = await getUserName(lead.projectId);
      namesArray.push(createdByName);
      lead.createdByName = createdByName;
    }
    setCreatedByNameArray(namesArray);
  };

  console.log("NamesArray", createdByNameArray);

  useEffect(() => {
    fetchNames();
  }, []); // Call fetchNames() only once when the component mounts
  const csvHeader = [
    "First Name",
    "Last Name",
    "Email",
    "Phone Number",
    "Lead status",
    "Expected Closure",
    "Budget",
    "Scope",
    "Address",
    "City",
    "Area",
    "Pin Code",
    "Lead Source",
    "Source Name",
    "Assigned To",
    "Last Call Date",
    "Convert to Project on Date",
    "Follow up Date and Time",
    "Converted By ",
    "Last Call Status",
  ];

  console.log("YeRahaData", leads?.data);
  const csvData = leads?.data.map(
    (
      {
        name,
        firstName,
        lastName,
        email,
        phoneNumber,
        leadStatus,
        clouserMonth,
        budget,
        requirements,
        address,
        city,
        area,
        pinCode,
        leadSource,
        sourceName,
        assignedTo,
        leadUpdates,
        createdByName,
      },
      i
    ) => [
      firstName ? firstName : name,
      lastName ? lastName : "",
      email,
      phoneNumber,
      leadStatus !== 1
        ? leadStatus === 2
          ? "Follow Up"
          : leadStatus === 3
          ? "Interested"
          : leadStatus === 5
          ? "Not Interested"
          : leadStatus === 6
          ? "No Response"
          : ""
        : "New",
      clouserMonth ? clouserMonth : "",
      budget,
      requirements,
      address,
      city,
      area,
      pinCode,
      leadSource,
      sourceName,
      assignedTo?.fullName,

      leadUpdates[leadUpdates?.length - 1]?.updateDate,
      leadUpdates[leadUpdates?.length - 1]?.updateDate,

      leadUpdates[leadUpdates?.length - 1]?.updateDate == undefined
        ? ""
        : leadUpdates[leadUpdates?.length - 1]?.updateDate +
          ", " +
          leadUpdates[leadUpdates?.length - 1]?.updateTime,
      createdByNameArray[i],
      leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 1
        ? "New"
        : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 2
        ? "Follow Up"
        : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 3
        ? "Interested"
        : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 5
        ? "Not Interested"
        : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 6
        ? "No Response"
        : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 7
        ? "Meeting"
        : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 4
        ? "Sign Up"
        : "",
    ]
  );
  useEffect(() => {
    getLeadSource();
    getTeamLeads();
    getSourceName();
    getProfileData();
    getWorkTypes();
    getTeamMember();
    getUserbyId();
  }, []);

  useEffect(() => {
    getNotificationServices();
    getLeadFormTemplate();
    getAllAssignToAccessUser();
    init();
    getLeadsCount();
    if (!getToken()) {
      localStorage.clear();
      window.location.href = "/";
    }
  
  }, [tab, updatePanel]);

  console.log(tab,updatePanel,"leaddatavalueupdate")

  const [showScroll, setShowScroll] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const panelSlide = useSelector((state) => state.lmsSlice.panelSlide);
  const nextPanel = useSelector((state) => state.lmsSlice.nextPanel);
  const leadsCount = useSelector((state) => state.lmsSlice.leadsCount);
  const isFilterOpens = useSelector((state) => state.lmsSlice.isFilterOpen);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [form] = aForm.useForm();
  const [widthOfPanel, setWidthOfPanel] = useState(
    window.screen.width > 1341 ? "22.5rem" : "18.5rem"
  );
  const updateMedia = () => {
    if (window.screen.width >= 1341) {
      setWidthOfPanel("22.5rem");
    } else {
      setWidthOfPanel("18.5rem");
    }
  };
  const handleChange = (value) => {
    if (value === "custom") {
      form.resetFields();
      setShowDateModal(true);
      setShowFilterDropdown(false);
    }

    if (value !== "custom") {
      setSelectedPeriod(value);
    }
  };
  const handleDateSubmit = () => {
    setShowDateModal(false);
    setSelectedPeriod("custom");
  };
  useEffect(() => {
    localStorage.removeItem("visited");
    // setSelectedtab(localStorage.getItem("headerTab"));
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  // const handleBoq = async (data) => {
  //   try {
  //     await setProgress(30);
  //     const res = await postReq(expf.quoapi + "/api/quick_quotation/add_new_project", {
  //       "isLead": false,
  //       "projectName": data.name,
  //       "projectLocation": selectedLead.city,
  //       "name": selectedLead.name,
  //       "emailId": selectedLead.email,
  //       "location": selectedLead.city,
  //       "config": selectedLead.config,
  //       "budget": selectedLead.budget,
  //       "requirements": selectedLead.propertyType,
  //       "renovationTimeline": selectedLead.renovationTimeline,
  //       "designerId": getLoginId()
  //     });
  //     if (res && !res.error) {
  //       navigate("/quick-quotation/req/" + res?.data?._id);
  //     }
  //     await setProgress(100);
  //   } catch (error) {init
  //     if (error.response && error.response.status === 400) {
  //       toast.error(error.response.data, {
  //         position: "bottom-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }
  //   }
  // };

  // document.onscroll= () => {
  //   alert("Hi");
  // }
  const getDateStr2 = (dt) => {
    let date = new Date(dt);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let dte = date.getDate();
    if (dte < 10) {
      dte = "0" + dte;
    }
    let mon = monthNames[date.getMonth()];
    let yr = date.getFullYear() + "";
    let dateStr = dte + " " + mon + " " + yr.slice(2, 4);
    return dateStr;
  };
  setInterval(function () {
    // console.log(window.scrollY)
    // console.log(document.getElementById('td_table').scrollTop)
    // setA(document.getElementById("th")?.offsetTop - 114);
  }, 1 / 100);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  console.log(leads,"leaddatavalue")
  return (
    <div id="body">
      <ToastContainer limit={1} />
      <ImportFromCSVModal show={show} handleClose={handleClose} />
      <Modal
        title=""
        style={{
          left: 400,
          top: 200,
        }}
        open={showDateModal}
        onOk={handleDateSubmit}
        width={200}
        onCancel={() => {
          setShowDateModal(false);
        }}
      >
        <div className="">
          <div className="m-2">
            <div>Start Date</div>
            <DatePicker
              onChange={(_, dateStr) => {
                setStartDate(dateStr);
              }}
            />
          </div>
          <div className="m-2">
            <div>End Date</div>
            <DatePicker
              onChange={(_, dateStr) => {
                setEndDate(dateStr);
              }}
            />
          </div>
        </div>
      </Modal>

      {/* <Header sideClass="d-none d-lg-block" /> */}
      {loading ? (
        <div
          className=""
          style={{
            position: "relative",
            marginTop: "50vh",
            marginLeft: "64vw",
            transform: "translate(-50%, -50%)",
          }}
        >
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#0084EF"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <div
          className="das-main-box"
          onScroll={() => {
            alert("Hi");
          }}
        >
          <div
            className="d-flex flex-wrap bg-white h-100"
            style={
              {
                // paddingTop:'1rem'
              }
            }
          >
            <div
              className="w-100 mv-w-100 "
              style={{ marginTop: isFilterOpens ? "10rem" : null }}
            >
              <div
                className="overflow-auto"
                style={{
                  // height: isMd && window.innerHeight - 100,
                  height: "89vh",
                }}
              >
                <div className="d-flex flex-column">
                  {/* <div className="d-flex justify-content-between align-items-center w-100">
                <h5 className=" d-none d-md-block px-md-4 mb-0 pb-0">
                  Lead Management
                </h5>
                <div className=" align-items-center pt-3 d-none d-md-flex">
                  <button
                    className="btn btn-primary mv-w-100 bg-base-blue box-shadow-none pt-2"
                    data-mdb-toggle="modal"
                    data-mdb-target="#addLeadModal"
                  >
                    Add Lead
                  </button>
                </div>
              </div> */}
                  {/* <div
                className=" px-3 overflow-auto scb-none px-md-4 mt-3 mt-md-0 mv-position-sticky top-0 bg-white"
                style={{
                  zIndex: 1,
                  // minHeight : "100px"
                }}
              > */}
                </div>
                {/* <div
              className="mt-0 w-100 bg-grey1"
              style={{
                height: isMd ? "10px" : "1px",
              }}
            /> */}

                {/*  when leads have no data  */}

                {leads?.data?.length === 0 && (
                  <div
                    className="w-100 text-center my-5 m-auto"
                    style={{
                      maxWidth: "500px",
                      height: "80%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {headerTab === "Leads Management" && (
                      <div
                        className={`cursor-pointer start-0 scb-none  pitabs pfs-14 align-items-center mb-3 ${styles.sideBarPanel}`}
                        style={{
                          marginTop:
                            window.screen.width < 1400 ? "6rem" : "8rem",
                        }}
                      >
                        {/* <Link to={`/leads/`} className={`me-2 px-2 ws-nowrap ${!tab ? "cl-base-blue blue-bottom" : "cl-grey1"} mb-0 pb-2 p-hover`}> */}
                        <Link
                          to={`/leads/`}
                          onClick={() => setUpdatePanel2(!updatePanel2)}
                          className={` ws-nowrap ${
                            !tab || tab == "0"
                              ? styles.activeTab
                              : styles.inactiveTab
                          } mb-2 p-hover`}
                        >
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div>All</div>
                            <div className="countBox">{leadsCount.all}</div>
                          </div>
                        </Link>
                        <Link
                          to={`/leads/3`}
                          onClick={() => setUpdatePanel2(!updatePanel2)}
                          className={` ws-nowrap ${
                            tab === "3" ? styles.activeTab : styles.inactiveTab
                          } mb-2 p-hover`}
                        >
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div>Interested</div>
                            <div className="countBox">
                              {leadsCount?.interested}
                            </div>
                          </div>
                        </Link>
                        <Link
                          to={`/leads/2`}
                          onClick={() => setUpdatePanel2(!updatePanel2)}
                          className={` ws-nowrap ${
                            tab === "2" ? styles.activeTab : styles.inactiveTab
                          } mb-2 p-hover`}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              
                            }}
                          >
                            <div>Follow Up</div>
                            <div className="countBox">
                              {leadsCount?.followUp}
                            </div>
                          </div>
                        </Link>
                        <Link
                          to={`/leads/6`}
                          onClick={() => setUpdatePanel2(!updatePanel2)}
                          className={` ws-nowrap ${
                            tab === "6" ? styles.activeTab : styles.inactiveTab
                          } mb-2 p-hover`}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div>No Response</div>
                            <div className="countBox">
                              {leadsCount?.noResponse}
                            </div>
                          </div>
                        </Link>
                        <Link
                          to={`/leads/5`}
                          onClick={() => setUpdatePanel2(!updatePanel2)}
                          className={` ws-nowrap ${
                            tab === "5" ? styles.activeTab : styles.inactiveTab
                          } mb-2 p-hover`}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div>Not Interested</div>
                            <div className="countBox">
                              {leadsCount?.notInterested}
                            </div>
                          </div>
                        </Link>

                        <Link
                          onClick={() => setUpdatePanel2(!updatePanel2)}
                          to={`/leads/9`}
                          className={` ws-nowrap ${
                            tab === "9" ? styles.activeTab : styles.inactiveTab
                          } mb-2 p-hover`}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div>Won</div>
                            <div className="countBox">{leadsCount.won}</div>
                          </div>
                        </Link>
                        {userData?.rolesAssigned?.crm?.canAssign && (
                          <Link
                            onClick={() => setUpdatePanel2(!updatePanel2)}
                            to={`/leads/8`}
                            className={` ws-nowrap ${
                              tab === "8"
                                ? styles.activeTab
                                : styles.inactiveTab
                            } mb-2 p-hover`}
                            style={{ marginTop: "80%" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <div>Unassigned</div>
                              <div className="countBox">
                                {leadsCount.unassigned}
                              </div>
                            </div>
                          </Link>
                        )}
                      </div>
                    )}

                    <div style={{ position: "fixed", top: "35%", left: "40%" }}>


                      <div
                        className="d-flex w-100 pb-2 pt-2"
                        style={{
                          position: "fixed",
                          left: headerTab == "Analytics" ? "0%" : "14.5%",
                        }}
                      >
                        {leads?.data?.length == 0 && (
                          <div className="pb-0">
                            <div
                              className={` ${styles.tabs__section}`}
                              style={{
                                width:
                                  headerTab == "Analytics" ? "84vw" : "85vw",
                                top: "7rem",
                                marginLeft:
                                  headerTab == "Analytics" ? "7.5%" : "",
                              }}
                            >
                              <div className="d-flex" style={{ gap: "10px" }}>
                                <div
                                  className={`${styles.tabDim2} ${
                                    headerTab === "Leads Management"
                                      ? styles.tab__selected
                                      : styles.tab__not__selcted
                                  }`}
                                  onClick={() => {
                                    setSelectedtab("Leads Management");
                                    localStorage.setItem(
                                      "headerTab",
                                      "Leads Management"
                                    );
                                    dispatch(
                                      setSelectedTabs("Leads Management")
                                    );
                                    setHeaderTab("Leads Management");
                                    setSelectedHeaderTab("Leads Management");
                                  }}
                                >
                                  Lead Management
                                </div>
                                <div
                                  className={`${styles.tabDim1} ${
                                    headerTab === "Analytics"
                                      ? styles.tab__selected
                                      : styles.tab__not__selcted
                                  } ${styles.marginLeft}`}
                                  onClick={() => {
                                    setSelectedtab("Analytics");
                                    dispatch(setSelectedTabs("Analytics"));
                                    localStorage.setItem(
                                      "headerTab",
                                      "Analytics"
                                    );
                                    setHeaderTab("Analytics");
                                    dispatch(isFilterOpen(false));
                                    setSelectedHeaderTab("Analytics");
                                  }}
                                >
                                  Analytics
                                </div>
                              </div>
                              {headerTab === "Leads Management" && (
                                <div className={styles.rightTabContainer}>
                                  <div
                                    className={`${styles.import_csv} ${styles.marginLeft}`}
                                    // onClick={() => {
                                    //   convertToCSV();
                                    // }}
                                    style={{ whiteSpace: "nowrap" }}
                                    onClick={handleShow}
                                  >
                                    <span className={styles.csv_text}>
                                      Import
                                    </span>{" "}
                                    <img
                                      style={{ marginLeft: "5px" }}
                                      src={import_csv}
                                    />
                                  </div>
                                  <div>
                                    <CSVLink
                                      filename="lead_data.csv"
                                      data={[csvHeader, ...csvData]}
                                    >
                                      <div
                                        className={` ${styles.export_csv}  ${styles.marginLeft}`}
                                        // onClick={() => {
                                        //   convertToCSV();
                                        // }}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        <span className={styles.csv_text}>
                                          Convert to CSV{" "}
                                        </span>{" "}
                                        <img
                                          style={{ marginLeft: "5px" }}
                                          src={export_csv}
                                        />
                                      </div>
                                    </CSVLink>
                                  </div>
                                </div>
                              )}

                              {headerTab === "Analytics" && (
                                <div>
                                  <span
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "12.5px",
                                      marginRight: "20px",
                                    }}
                                  >
                                    <span style={{ marginRight: "5px" }}>
                                      From :
                                    </span>
                                    <span>{getDateStr2(startDate)}</span>
                                    <span style={{ margin: "5px" }}>-</span>
                                    <span>{getDateStr2(endDate)}</span>
                                  </span>
                                  <Space wrap>
                                    <Select
                                      bordered={true}
                                      defaultValue="this quarter"
                                      value={
                                        selectedPeriod == ""
                                          ? "this quarter"
                                          : selectedPeriod
                                      }
                                      style={{
                                        width: 120,
                                      }}
                                      onChange={handleChange}
                                      options={[
                                        {
                                          value: "this week",
                                          label: "This Week",
                                        },
                                        {
                                          value: "this month",
                                          label: "This month",
                                        },
                                        {
                                          value: "this quarter",
                                          label: "This Quarter",
                                        },
                                        {
                                          value: "this year",
                                          label: "This Year",
                                        },
                                        {
                                          value: "last week",
                                          label: "Last Week",
                                        },
                                        {
                                          value: "last month",
                                          label: "Last Month",
                                        },
                                        {
                                          value: "last quarter",
                                          label: "Last Quarter",
                                        },
                                        {
                                          value: "last year",
                                          label: "Last Year",
                                        },
                                        // {
                                        //   value: "custom",
                                        //   label: "Custom Date",
                                        // },
                                      ]}
                                    />
                                  </Space>
                                </div>
                              )}
                              {}
                            </div>
                            {/* {headerTab === "Leads Management" && (
                                <LeadManagement
                                  updatePanel={updatePanel}
                                  setUpdatePanel={setUpdatePanel}
                                  rerender={rerender}
                                  setRerender={setRerender}
                                />
                              )} */}
                            {headerTab === "Analytics" && (
                              <Analytic
                                selectedPeriod={selectedPeriod}
                                dates={dates}
                                value={value}
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                              />
                            )}
                          </div>
                        )}
                      </div>

                      
                      {headerTab === "Leads Management" && (
                        <img
                          src={nolead}
                          alt=""
                          srcSet=""
                          width={300}
                          style={{
                            maxWidth: "60vw",
                          }}
                        />
                      )}
                    </div>

                    {headerTab === "Leads Management" && (
                      <div
                        style={{ position: "fixed", top: "66%", left: "43%" }}
                      >
                        <div className="d-flex w-100 mt-3 flex-column align-items-center">
                          <p className="h5 cl-#4A4A4A fw-bold mb-2">
                            No Leads to Show
                          </p>
                          <p className="h6 cl-black fw-normal mb-2">
                            You don't have any leads yet.
                            <br />
                            Start by adding a new lead.
                          </p>
                          <button
                            style={{
                              borderRadius: "0.5rem",
                              color: "#fff",
                              border: "1px solid #0084EF",
                              background: "#0084EF",
                              height: "2.185rem",
                            }}
                            className="mv-w-100 box-shadow-none border-none"
                            onClick={() => dispatch(setAddLeadModalOpen(true))}
                          >
                            Add Lead
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {/*  when leads is more than 1  */}

                <div>
                  {
                    // !isMd ? (
                    !loading && (
                      <div style={{ display: "flex" }}>
                        {/* {headerTab === "Analytics" && (
                          <div
                            style={{ width: "2vw" }}
                            className={`cursor-pointer scb-none pitabs pfs-14 border-0 align-items-center mb-3 ${styles.sideBarPanel}`}
                          ></div>
                        )} */}
                        {leads?.data?.length > 0 &&
                          headerTab === "Leads Management" && (
                            <div
                              className={`cursor-pointer scb-none pitabs pfs-14 align-items-center mb-3 ${styles.sideBarPanel}`}
                            >
                              {/* <Link to={`/leads/`} className={`me-2 px-2 ws-nowrap ${!tab ? "cl-base-blue blue-bottom" : "cl-grey1"} mb-0 pb-2 p-hover`}> */}
                              <Link
                                to={`/leads/`}
                                onClick={() => setUpdatePanel2(!updatePanel2)}
                                className={` ws-nowrap ${
                                  !tab || tab == "0"
                                    ? styles.activeTab
                                    : styles.inactiveTab
                                } mb-2 p-hover`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div>All</div>
                                  <div className="countBox">
                                    {leadsCount.all}
                                  </div>
                                </div>
                              </Link>
                              <Link
                                to={`/leads/3`}
                                onClick={() => setUpdatePanel2(!updatePanel2)}
                                className={` ws-nowrap ${
                                  tab === "3"
                                    ? styles.activeTab
                                    : styles.inactiveTab
                                } mb-2 p-hover`}
                              >
                                {" "}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div>Interested</div>
                                  <div className="countBox">
                                    {leadsCount?.interested}
                                  </div>
                                </div>
                              </Link>
                              <Link
                                to={`/leads/2`}
                                onClick={() => setUpdatePanel2(!updatePanel2)}
                                className={` ws-nowrap ${
                                  tab === "2"
                                    ? styles.activeTab
                                    : styles.inactiveTab
                                } mb-2 p-hover`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                   
                                  }}
                                >
                                  <div>Follow Up</div>
                                  <div className="countBox">
                                    {leadsCount?.followUp}
                                  </div>
                                </div>
                              </Link>
                              <Link
                                to={`/leads/6`}
                                onClick={() => setUpdatePanel2(!updatePanel2)}
                                className={` ws-nowrap ${
                                  tab === "6"
                                    ? styles.activeTab
                                    : styles.inactiveTab
                                } mb-2 p-hover`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div>No Response</div>
                                  <div className="countBox">
                                    {leadsCount.noResponse}
                                  </div>
                                </div>
                              </Link>
                              <Link
                                onClick={() => setUpdatePanel2(!updatePanel2)}
                                to={`/leads/5`}
                                className={` ws-nowrap ${
                                  tab === "5"
                                    ? styles.activeTab
                                    : styles.inactiveTab
                                } mb-2 p-hover`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div>Not Interested</div>
                                  <div className="countBox">
                                    {leadsCount?.notInterested}
                                  </div>
                                </div>
                              </Link>

                              <Link
                                onClick={() => setUpdatePanel2(!updatePanel2)}
                                to={`/leads/9`}
                                className={` ws-nowrap ${
                                  tab === "9"
                                    ? styles.activeTab
                                    : styles.inactiveTab
                                } mb-2 p-hover`}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div>Won</div>
                                  <div className="countBox">
                                    {leadsCount.won}
                                  </div>
                                </div>
                              </Link>
                              {userData?.rolesAssigned?.crm?.canAssign && (
                                <Link
                                  to={`/leads/8`}
                                  onClick={() => setUpdatePanel2(!updatePanel2)}
                                  className={` ws-nowrap ${
                                    tab === "8"
                                      ? styles.activeTab
                                      : styles.inactiveTab
                                  } mb-2 p-hover`}
                                  style={{ marginTop: "80%" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%",
                                    }}
                                  >
                                    <div>Unassigned</div>
                                    <div className="countBox">
                                      {leadsCount?.unassigned}
                                    </div>
                                  </div>
                                </Link>
                              )}
                            </div>
                          )}
                        <div
                          className="d-flex w-100 pb-2 pt-2"
                          style={
                            {
                              //   position: "fixed",
                              // paddingLeft: headerTab == "Analytics" ? "8%" : "14.5%",
                              // , paddingLeft:headerTab == "Analytics" ? "8%" : "14.5%", marginLeft:'3%'
                            }
                          }
                        >
                          {leads?.data?.length > 0 && (
                            <div className="pb-0">
                              <div
                                className={` ${styles.tabs__section}`}
                                style={{
                                  width:
                                    headerTab == "Analytics" ? "84vw" : "85vw",
                                  marginLeft:
                                    headerTab == "Analytics" ? "7.5%" : "14.5%",
                                }}
                              >
                                <div className="d-flex" style={{ gap: "10px" }}>
                                  <div
                                    className={`${styles.tabDim2} ${
                                      headerTab === "Leads Management"
                                        ? styles.tab__selected
                                        : styles.tab__not__selcted
                                    }`}
                                    onClick={() => {
                                      setSelectedtab("Leads Management");
                                      localStorage.setItem(
                                        "headerTab",
                                        "Leads Management"
                                      );
                                      setHeaderTab("Leads Management");
                                      setSelectedHeaderTab("Leads Management");
                                      dispatch(
                                        setSelectedTabs("Leads Management")
                                      );
                                    }}
                                  >
                                    Lead Management
                                  </div>
                                  <div
                                    className={`${styles.tabDim1} ${
                                      headerTab === "Analytics"
                                        ? styles.tab__selected
                                        : styles.tab__not__selcted
                                    } ${styles.marginLeft}`}
                                    onClick={() => {
                                      setSelectedtab("Analytics");
                                      localStorage.setItem(
                                        "headerTab",
                                        "Analytics"
                                      );
                                      setHeaderTab("Analytics");
                                      dispatch(isFilterOpen(false));
                                      setSelectedHeaderTab("Analytics");
                                      dispatch(setSelectedTabs("Analytics"));
                                    }}
                                  >
                                    Analytics
                                  </div>
                                </div>
                                {headerTab === "Leads Management" && (
                                  <div className={styles.rightTabContainer}>
                                    <div
                                      className={`${styles.import_csv} ${styles.marginLeft}`}
                                      // onClick={() => {
                                      //   convertToCSV();
                                      // }}
                                      style={{ whiteSpace: "nowrap" }}
                                      onClick={handleShow}
                                    >
                                      <span className={styles.csv_text}>
                                        Import
                                      </span>{" "}
                                      <img
                                        style={{ marginLeft: "5px" }}
                                        src={import_csv}
                                      />
                                    </div>

                                    <div>
                                      <CSVLink
                                        filename="lead_data.csv"
                                        data={[csvHeader, ...csvData]}
                                      >
                                        <div
                                          className={` ${styles.export_csv}  ${styles.marginLeft}`}
                                          // onClick={() => {
                                          //   convertToCSV();
                                          // }}
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          <span className={styles.csv_text}>
                                            Convert to CSV
                                          </span>{" "}
                                          <img
                                            style={{ marginLeft: "5px" }}
                                            src={export_csv}
                                          />
                                        </div>
                                      </CSVLink>
                                    </div>
                                  </div>
                                )}
                                {headerTab === "Analytics" && (
                                  <div>
                                    <span
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "12.5px",
                                        marginRight: "20px",
                                      }}
                                    >
                                      <span style={{ marginRight: "5px" }}>
                                        From :
                                      </span>
                                      <span>{getDateStr2(startDate)}</span>
                                      <span style={{ margin: "5px" }}>-</span>
                                      <span>{getDateStr2(endDate)}</span>
                                    </span>
                                    <Space wrap>
                                      <Select
                                        value={selectedPeriod}
                                        bordered={true}
                                        defaultValue="this quarter"
                                        style={{
                                          width: 120,
                                        }}
                                        onChange={handleChange}
                                        options={[
                                          {
                                            value: "this week",
                                            label: "This Week",
                                          },
                                          {
                                            value: "this month",
                                            label: "This month",
                                          },
                                          {
                                            value: "this quarter",
                                            label: "This Quarter",
                                          },
                                          {
                                            value: "this year",
                                            label: "This Year",
                                          },
                                          {
                                            value: "last week",
                                            label: "Last Week",
                                          },
                                          {
                                            value: "last month",
                                            label: "Last Month",
                                          },
                                          {
                                            value: "last quarter",
                                            label: "Last Quarter",
                                          },
                                          {
                                            value: "last year",
                                            label: "Last Year",
                                          },
                                        ]}
                                      />
                                    </Space>
                                  </div>
                                )}
                                {}
                              </div>
                              {headerTab === "Leads Management" && (
                                <LeadManagement
                                  updatePanel={updatePanel}
                                  setUpdatePanel={setUpdatePanel}
                                  rerender={rerender}
                                  setRerender={setRerender}
                                />
                              )}
                              {headerTab === "Analytics" && (
                                <Analytic
                                  selectedPeriod={selectedPeriod}
                                  dates={dates}
                                  value={value}
                                  startDate={startDate}
                                  endDate={endDate}
                                  setStartDate={setStartDate}
                                  setEndDate={setEndDate}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
              {showEmailModal && <LmsMail />}
              <AddLeadModal setProgress={setProgress} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Lms;
