import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLinkUploaded:false,
    uplaodLinkArr:"",
    
}
export const lmsProjectSlice = createSlice({
    name: 'lmsProject',
    initialState,
    reducers:{
        setIsLinkUploaded:(state, action)=>{
            state.uplaodLinkArr = action.payload;
        },
        linkUploaded:(state, action)=>{
            state.isLinkUploaded = action.payload;
        },
    }
})

export const {setIsLinkUploaded, linkUploaded} = lmsProjectSlice.actions;
export default lmsProjectSlice.reducer;