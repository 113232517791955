import React, { useState } from "react";
import bgmain from "../../Assets/bg-main.svg";
import { useNavigate } from "react-router-dom";
import { TextField, Tooltip, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import styles from "../Signup/signup.module.css";
import { postReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import { PiEyeLight, PiEyeClosedLight } from "react-icons/pi";
import LoginHeader from "../loginHeader/LoginHeader";
import { multipleValidationFunction } from "../../piservices/commonService";
import expf, {
  constants,
  signUpValidationObject,
  iDesignWebsite,
} from "../../piservices/constants";
import axios from "axios";

const SignUp = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const navigateTo = useNavigate();
  const { otpEmail, userType, companyId } = useSelector(
    (state) => state.persist
  );
  const [inputPassword, setinputPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [confirmErr, setConfirmErr] = useState("");

  const [passedChecks, setPassedChecks] = useState([]);

  const [inputType, setInputType] = useState("password");
  const [confirmInputType, setConfirmInputType] = useState("password");

  const handlePassword = (event) => {
    const { name, value } = event.target;

    if (name === "password") {
      let valArr = multipleValidationFunction(
        signUpValidationObject[name],
        value
      );
      setPassedChecks(valArr);
    }

    if (name === "confirmPassword") {
      if (inputPassword.password === value) {
        setConfirmErr("");
      } else {
        setConfirmErr("Passwords do not match");
      }
    }

    setinputPassword((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // create password
  const Register = async () => {
    if (inputPassword.confirmPassword && inputPassword.password) {
      if (passedChecks.length === 0 && !confirmErr) {
        const res = await postReq(
          `${constants.CRM_API}${apiEndpoints.updatePassword}`,
          {
            email: otpEmail,
            password: inputPassword.password,
          }
        );
        if (res && !res.error) {
          const loginRes = await postReq(
            `${constants.CRM_API}${apiEndpoints.login}`,
            {
              email: otpEmail,
              password: inputPassword.password,
            }
          );
          if (loginRes && !loginRes.error) {
            handleLogin();

            localStorage.setItem("token", loginRes.data.data.accessToken);
            localStorage.setItem("userId", loginRes.data.data._id);
            if (userType === "user") {
              navigateTo("/personal-details");
            } else {
              // navigateTo("/personal-data");
              navigateTo("/company-details")
            }
          } else {
            console.error(loginRes.error);
          }
        } else {
          console.error(res.error);
        }
      }
    }
  };

  const handleLogin = async () => {
    await axios
      .post(
        `${constants.CRM_API}/user/edit-user-details?userId=${localStorage.getItem(
          "userId"
        )}`,
        {
          isFirstTimeLogin: false,
        }
      )
      .then((res) => {
        localStorage.removeItem("emailId");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // toggle password
  const toggleConfirmIcon = () => {
    if (confirmInputType === "password") {
      setConfirmInputType("text");
    } else {
      setConfirmInputType("password");
    }
  };

  const togglePasswordIcon = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  return (
    <>
      <LoginHeader />
      <div
        style={{
          backgroundImage: `url(${bgmain})`,
        }}
        className={styles.signInContainer}
      >
        <div className={styles.boxContainer}>
          <div className={styles.welcomeHead}>Welcome</div>
          <div className={styles.allTextfield}>
            <div
              style={{
                borderRadius: "12px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                label="Email"
                variant="outlined"
                className="saas-form mb-3"
                type="email"
                name="email"
                value={otpEmail}
                onChange={handlePassword}
                style={{ borderRadius: "12px", width: "100%" }}
                InputProps={{ style: { borderRadius: "12px" } }}
                inputProps={{
                  style: { padding: isMobile ? "18px 20px" : "18px 24px" },
                }}
              />
              <div className={styles.passwordInputContainer}>
                <TextField
                  error={passedChecks.length > 0}
                  label="Enter New Password"
                  variant="outlined"
                  className="saas-form mb-3"
                  type={inputType}
                  name="password"
                  value={inputPassword.password}
                  onChange={handlePassword}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputProps={{ style: { borderRadius: "12px" } }}
                  inputProps={{
                    style: { padding: isMobile ? "18px 20px" : "18px 24px" },
                  }}
                />

                <Tooltip
                  title={
                    inputType === "password" ? "Show Password" : "Hide Password"
                  }
                  placement="top"
                >
                  <div
                    className={styles.showPasswordIcon}
                    onClick={togglePasswordIcon}
                  >
                    {inputType === "password" ? (
                      <PiEyeLight fontSize={22} color="#4d4d4d" />
                    ) : (
                      <PiEyeClosedLight fontSize={22} color="#4d4d4d" />
                    )}
                  </div>
                </Tooltip>
              </div>

              {inputPassword.password.length > 0 && (
                <div className="mb-3">
                  <div className={styles.errorHead}>
                    Your password must contain
                  </div>
                  <div
                    className={
                      passedChecks.includes("minlen")
                        ? styles.success
                        : styles.errorSubHead
                    }
                  >
                    <li>At least 8 characters.</li>
                  </div>
                  <div
                    className={
                      passedChecks.includes("nospecial")
                        ? styles.success
                        : styles.errorSubHead
                    }
                  >
                    <li>At least one special character (@).</li>
                  </div>
                  <div
                    className={
                      passedChecks.includes("noupper")
                        ? styles.success
                        : styles.errorSubHead
                    }
                  >
                    <li>At least one capital letter (P)</li>
                  </div>
                  <div
                    className={
                      passedChecks.includes("maxlen")
                        ? styles.success
                        : styles.errorSubHead
                    }
                  >
                    <li>Maximum characters should be 15</li>
                  </div>
                </div>
              )}

              <div className={styles.passwordInputContainer}>
                <TextField
                  error={confirmErr}
                  label="Confirm Password"
                  variant="outlined"
                  className="saas-form"
                  type={confirmInputType}
                  name="confirmPassword"
                  value={inputPassword.confirmPassword}
                  onChange={handlePassword}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputProps={{ style: { borderRadius: "12px" } }}
                  inputProps={{
                    style: { padding: isMobile ? "18px 20px" : "18px 24px" },
                  }}
                />

                <Tooltip
                  title={
                    confirmInputType === "password"
                      ? "Show Password"
                      : "Hide Password"
                  }
                  placement="top"
                >
                  <div
                    className={styles.showPasswordIcon}
                    onClick={toggleConfirmIcon}
                  >
                    {confirmInputType === "password" ? (
                      <PiEyeLight fontSize={22} color="#4d4d4d" />
                    ) : (
                      <PiEyeClosedLight fontSize={22} color="#4d4d4d" />
                    )}
                  </div>
                </Tooltip>
              </div>
              {confirmErr && <div className={styles.error}>{confirmErr}</div>}
            </div>

            <div style={{ width: "100%" }}>
              <button
                className={styles.createBtn}
                onClick={() => {
                  // handleLogin();
                  Register();
                }}
              >
                Create
              </button>
            </div>
          </div>
          <div
            style={{
              borderTop: "1px solid var(--Neutral-200, #E0E3E6)",
              width: "100%",
            }}
          >
            <div className={styles.footerPara}>
              On signing you agree to our{" "}
              <span
                style={{
                  textDecoration: "underline",
                  textUnderlineOffset: "0.2em",
                  cursor: "pointer",
                }}
                className={styles.underlineInteraction}
                onClick={() => window.open(`${iDesignWebsite}/terms`, "_blank")}
              >
                Terms of Use{" "}
              </span>
              and{" "}
              <span
               onClick={() =>
                window.open(
                  `${iDesignWebsite}/privacy-policy`,
                  "_blank"
                )
              }
                style={{
                  cursor:"pointer",
                  textDecoration: "underline",
                  textUnderlineOffset: "0.2em",
                }}
                className={styles.underlineInteraction}
              >
                {" "}
                Privacy Policy
              </span>
              . <br />
              Already having account?{" "}
              <span
                style={{
                  color: "#0084EF",
                  cursor: "pointer",
                  textDecoration: "underline",
                  textUnderlineOffset: "0.2em",
                }}
                onClick={() => navigateTo("/")}
              >
                Sign in
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
