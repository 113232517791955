import React, { useEffect, useLayoutEffect, useState } from "react";
// import "./Notification.css";
import style from "./Notification.module.css";
import SearchIcon from "../asset/SearchIcon.svg";
import axios from "axios";
import Cards from "../Cards/Cards";

import { notInitialized } from "react-redux/es/utils/useSyncExternalStore";
import { constants } from "../../piservices/constants";
const Notification = ({ setShowNotifications ,unread}) => {
  const [activeTab, setActiveTab] = useState("all"); // State to track active tab
  const [searchText, setSearchText] = useState("");
  const [notifications, setNotification] = useState([]);
  const [data, setData] = useState([]);
  const [skip, setSkip] = useState(1);
  const [loadMoreCount, setLoadMoreCount] = useState(40);
  const [dataCount, setDataCount] = useState();
  const [loadstate, setloadstate] = useState(false);
  const [hasMoreNotifications, setHasMoreNotifications] = useState(true);

  async function getNotifications  (skip)  {
  
       await axios.get(
        `${constants.NOTIFY_API}/api/notifications/get-notifications?limit=40&skip=${skip}&userId=${localStorage.getItem(
          "userId"
        )}`
      ).then((res)=>{
        setData(res.data);
        setNotification((prev) => {
          return [...prev, res.data];
        });
      }).catch((e)=>{
        console.log("error while getting notifications :",e);
      })
     
      
    
  };
  useLayoutEffect(() => {
    

      getNotifications(skip);
    
  }, []);

  // const getNotifications = async (skip) =>
  // {
  //   try{
  //     const getNotify = await axios.get(`${constants.NOTIFY_API}/api/notifications/get-notifications?limit=40&skip=${skip}&userId=${localStorage.getItem("userId")}`)
  //     setNotification((prev)=>{
  //       return[...prev,...getNotify.data];
  //     })
  //   }catch(err){
  //     console.log("Error In getNotification Api",err);
  //   }
  // }
  // console.log(notifications.length)
  // useEffect(()=>{
  //   getNotifications();
  // },[])

  //   const getNotifications = async (skip) => {
  //     try {
  //       const getNotify = await axios.get(
  //         `${constants.NOTIFY_API}/api/notifications/get-notifications?limit=40&skip=${skip}&userId=${localStorage.getItem(
  //           "userId"
  //         )}`
  //       );

  //       if (loadstate) {
  //         setNotification((prev) => {
  //           return [...prev, ...getNotify.data];
  //         }

  //         );
  //         console.log("setnotification with skip",skip);
  //         setloadstate(false)
  //       }
  //       else {
  //         setNotification(getNotify.data);
  //       }
  //     } catch (err) {
  //       console.log("Notification APi", err);
  //     }

  //   };

  // useEffect(()=>{
  //   getNotifications();
  // },[])
  //   useEffect(() => {
  //     if (loadstate) {
  //       getNotifications(skip);
  //     } else {
  //       // console.log("inside lad false state");
  //       getNotifications();
  //     }
  //   }, [loadstate]);

  const loadMore = () => {
    setSkip(skip + 1);
    getNotifications(skip + 1);
    setLoadMoreCount(loadMoreCount + 40);
  };
  const readByAll = async () => {
    return await axios
      .post(
        `${constants.NOTIFY_API}/api/notifications/read-all-notifications?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then((res) => {setNotification([]);
        getNotifications(skip)})
      .catch((err) => console.log(err));
  };
  const disload = async () => {
    const Notify = await axios.get(
      `${constants.NOTIFY_API}/api/notifications/get-notifications?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    setDataCount(Notify.data.length);
  };
  useEffect(() => {
    

      disload();
    
  }, []);
  return (
    <div className={style.notification_container}>
      <div className={style.box1}>
        <div className={style.wrapper}>
          <span className={style.wr_box1}>Notifications</span>
          <span className={style.wr_box2} onClick={()=>{
            readByAll()
            unread();
          }}>
            Mark as read
          </span>
        </div>
        <div className={style.button}>
          <div
            className={`${style.button_container} ${
              activeTab === "all" ? `${style.active}` : ""
            }`}
            onClick={() => setActiveTab("all")}
          >
            <div
              className={`${style.button_inner} ${
                activeTab === "all" ? `${style.active}` : ""
              }`}
            >
              All
            </div>
          </div>
          <div
            className={`${style.button_container} ${
              activeTab === "unread" ? `${style.active}` : ""
            }`}
            onClick={() => setActiveTab("unread")}
          >
            <div
              className={`${style.button_inner} ${
                activeTab === "unread" ? `${style.active}` : ""
              }`}
            >
              Unread
            </div>
          </div>
          <div
            className={`${style.button_container} ${
              activeTab === "assigned" ? `${style.active}` : ""
            }`}
            onClick={() => setActiveTab("assigned")}
          >
            <div
              className={`${style.button_inner} ${
                activeTab === "assigned" ? `${style.active}` : ""
              }`}
            >
              Assigned to
            </div>
          </div>
        </div>
        <div className={style.searchBar}>
          <div className={style.inner_search}>
            <input
              placeholder="Search"
              style={{
                border: "none",
                outline: "none",
                fontSize: "16px",
                width: "90%",
              }}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />

            <img src={SearchIcon} style={{ height: "1rem", width: "1rem" }} />
          </div>
        </div>
        <div className={style.notification_cards_container}>
          <div className={style.notification_cards}>
            {notifications
              ?.flat()
              ?.filter((notification) =>
                notification.notification
                  ?.toLowerCase()
                  .includes(searchText.toLowerCase())
              ).length === 0? "No Notification!!!" :
              notifications
              ?.flat()
              ?.filter((notification) =>
                notification.notification
                  ?.toLowerCase()
                  .includes(searchText.toLowerCase())
              )
              .map((notification, i) => {
                if (activeTab === "all") {
                  return (
                    <div key={i}>
                      <Cards
                      unread={unread}
                        notification={notification}
                        key={notification._id}
                        setShowNotifications={setShowNotifications}
                        getNotifications={getNotifications}
                      />
                    </div>
                  );
                } else if (
                  activeTab === "unread" &&
                  !notification.readedBy?.includes(
                    localStorage.getItem("userId")
                  )
                ) {
                  return (
                    <div key={i}>
                      <Cards
                      unread={unread}
                        notification={notification}
                        key={notification._id}
                      />
                    </div>
                  );
                } else if (
                  activeTab === "assigned" &&
                  notification.receiverId === localStorage.getItem("userId")
                ) {
                  return (
                    <div key={i}>
                      <Cards
                        notification={notification}
                        key={notification._id}
                        getNotifications={getNotifications}
                        setShowNotifications={setShowNotifications}
                      />
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
      {loadMoreCount < dataCount &&(
        <div className={style.load_more}>
          <button className={style.load_button} onClick={loadMore}>
            Load more notification
          </button>
          </div>)}


    </div>
  );
};

export default Notification;
