import React, { useContext, useEffect, useRef, useState } from "react";
import "../index.css";
import vector1 from "../images/Vector.png";
import vector0 from "../images/Vector-4.png";
import vector2 from "../images/Vector-1.png";
import vector3 from "../images/Vector-2.png";
import vector4 from "../images/Vector-3.png";
import designquo from "../images/designquo.svg";
import PaperPlaneRight from "../../../Assets/PaperPlaneRight.svg";
import executionquo from "../images/executionquo.svg";
import Remark from "./Remark";
import { Dropdown } from "react-bootstrap";
import DateTime from "./DateTime";
import desquoContext from "../../../picontext/DashBoard/desquoContext";
import SendMail from "./SendMail";
import { getReq, postReq } from "../../../piservices/apis";
import expf, { constants} from "../../../piservices/constants";
import { BiChevronDown } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  baseUrl,
  formateDate,
  valueToLeadStatus,
  valueToCallRemarks,
  valueToLeadStatusFormatted,
} from "../../../piservices/compCommon";
import PiAlert from "../../PiAlert/PiAlert";
import { BsCheck2, BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { HiOutlineClipboardList } from "react-icons/hi";
import { motion } from "framer-motion";
import { getToken } from "../../../piservices/authService";
import { Button, Modal, Tooltip, notification } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  selectedLeadData,
  updateCallStatusUpdatePanel,
} from "../../../Redux/Slices/specificleadSlice";
import LockWhite from "../../../Assets/LockWhite.svg";
import CommentIcon from "../../../Assets/CommentIcon.svg";
import LockBlack from "../../../Assets/LockBlack.svg";
import LmsMail from "../LmsMail.js/LmsMail";
import {
  setLeadsCount,
  setShowEmailModal,
} from "../../../Redux/Slices/lmsSlice";
import useApiFetchCustomHook from "../../../piservices/ApiFetchCustomHook";
import useApiFetchForLeadCountCustomHook from "../../../piservices/ApiFetchForLeadCount";
import { RxCross2 } from "react-icons/rx";
import { notInterestedReasons } from "../../../piservices/compCommon";
import useApiFetchCustomHookForEmailService from "../../../piservices/ApiFetchCustomHookForEmailService";
import moment from "moment";

const LeadUpdate = ({setUpdatePanel}) => {
  const context = useContext(desquoContext);
  let { tab } = useParams();
  let isLead = window.location.href?.includes("lead-details");
  const panelUpdated = useSelector((state) => state.lmsSlice.panelUpdated);
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(tab);
  const [quotationModal, setQuotationModal] = useState(false);
  const [currentQuot, setCurrentQuot] = useState("");
  const [quotationModal2, setQuotationModal2] = useState(false);
  const [panelupdate, setPanelUpdate] = useState();
  console.log(panelupdate,"leadupdatepannel")
  const { apiFetch } = useApiFetchCustomHook();
  const [buttonClickedOnce, setButtonClickedOnce] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [commentTextBoxFocused, setcommentTextBoxFocused] = useState(false);
  const teamMembers = useSelector((state) => state.lmsSlice.teamMembers);
  const [commentError, setCommentError] = useState(false);
  const [quotInput, setQuotInput] = useState("");
  const [creatingMyReason, setCreatingMyReason] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [selectedReasonForNotInterest, setSelectedReasonForNotInterest] =
    useState("");
    //  state for button issue 

    const [buttonshow,setbuttonshow]=useState(false)
  const userData = useSelector((state) => state.lmsSlice.userData);
  const { sendEmailPushBellNotifications } =
    useApiFetchCustomHookForEmailService();
  useEffect(() => {
    setPanelUpdate(panelUpdated);
    
  }, [panelUpdated]);

  useEffect(() => setCurrentTab(tab), [tab]);
  const handleQuotChange = (e) => {
    setQuotInput(e.target.value);
  };
  const noEmailRedirect = () => {};
  const handleOk = () => {
    setQuotationModal(true);
  };
  const handleCloseModal = () => {
    setQuotationModal(false);
  };
  const handleOk2 = () => {
    setQuotationModal2(true);
  };

  
  const getProfileData = async () => {
    const res = await getReq(`${constants.CRM_API}/user/profile`, {}, { Authorization: `Bearer ${getToken()}` });
    if (res && !res.error) {
      setProfileData({ ...res.data.data });
    } else {
      console.log(res.error);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);
  console.log("selectedReasonForNotInterest", selectedReasonForNotInterest);
  const handleOk22 = async () => {
    if (quotInput != "") {
      const res2 = await postReq(
        `${constants.CRM_API}/user/edit-lead?id=${activeLead?._id}`,
        {
          // leadId: activeLead?._id,
          email: quotInput,
        }
      );
      if (res2 && !res2.error) {
        const res = await postReq(
          `${constants.PMT_API}/api/projects/addClient?userId=${localStorage.getItem(
            "userId"
          )}`,
          {
            email: quotInput,
            location: activeLead.address,
            name: activeLead.name,
            projectName: activeLead.name,
          }
        );
        if (res && !res.error) {
          console.log(res);
        } else {
          console.log(res.error);
          alert(res?.response?.data?.message);
        }
        await axios
          .post(`${constants.PMT_API}/api/projects/add-project`, {
            clientId: res.data._id,
            location: activeLead.address,
            projectName: activeLead.name,
            userId: localStorage.getItem("userId"),
            isConfirmed: false,
            isFromLead: true,
            projectAdmin: activeLead?.assignedTo?._id,
            teamMembers: [activeLead?.assignedTo?._id],
          })
          .then(function (response) {
            localStorage.setItem("projectId", response?.data?._id);
            if (currentQuot == "exQuot") {
              localStorage.setItem("projectId", response?.data?._id);
              setQuotInput("");
              setCurrentQuot("");
              window.location.assign(
                `${constants.BASE_URL}/quotation/new-quotation/${response.data._id}?isLead=true`
              );
            } else {
              localStorage.setItem("projectId", response?.data?._id);
              setQuotInput("");
              setCurrentQuot("");
              window.location.assign(
                `${constants.BASE_URL}/quotation/enterprise-quotation/${response.data._id}`
              );
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        console.log(res2.error);
      }
    }
  };
  const handleCloseModal2 = () => {
    setQuotationModal2(false);
  };
  const {
    activeLead,
    leadstatus,
    setLeadstatus,
    getActive,
    mailStatus,
    isnewlead,
    dateTime,
    leads,
    setLeads,
    mailForm,
    myLeadStatus,
    setMyLeadStatus,
    showAlert,
    setMailStatus,
    setActiveLeadState,
  } = context;

  


  const {
    name,
    renovationTimeline,
    phoneNumber,
    email,
    updatedAt,
    leadStatus,
    assignedTo,
  } = activeLead;

  console.log(assignedTo?.fullName, name,"leaddatavalue")

  const [viewremark, setViewremark] = useState(true);

  const [showUpdateBadge, setShowUpdateBadge] = useState(false);
  const [actualLeadStatus, setActualLeadStatus] = useState("");
  console.log(actualLeadStatus,"leadstatusvalue")
  const [actualLeadCallStatus, setActualLeadCallStatus] = useState("");
  

  const [actualLeadStatus2, setActualLeadStatus2] = useState(1);
  const [actualLeadStatus3, setActualLeadStatus3] = useState(1);
  

  const [actualCallType, setActualCallType] = useState();
  const [originalCallStatus, setOriginalCallStatus] = useState();
  const [callStatus2, setCallStatus2] = useState();

const [leadstatustitle,setleadstatustitle]=useState("")
  useEffect(() => {
    if (Object.keys(activeLead)?.length > 0) {
     
      setActualLeadStatus(activeLead.leadStatus);
      setActualLeadStatus2(activeLead.leadStatus);
      setActualLeadStatus3(activeLead.leadStatus);
      setActualCallType(activeLead?.todayRemark || 0);
      setCallStatus2(getActive);
      setOriginalCallStatus(leadstatus[getActive]?.value);
      setActualLeadCallStatus("");
    }
  }, [activeLead]);

  const [teamLeadArr, setTeamLeadArr] = useState([]);
  const getTeamLeads = async () => {
    const res = await getReq(
      `${
        constants.CRM_API
      }/api/enterprise/get-team-leaders?userId=${localStorage.getItem(
        "userId"
      )}&access=1`
    );
    if (res && !res.error) {
      setTeamLeadArr(res.data.data);
    } else {
      console.log(res.error);
    }
  };

  const createProjectAndGoToDesignQuotation = async () => {
    if (activeLead?.projectId) {
      localStorage.setItem("projectId", activeLead?.projectId);
      window.location.assign(
        `${constants.BASE_URL}/quotation/enterprise-quotation/${activeLead?.projectId}`
      );
    } else {
      if (activeLead.email) {
        try {
          await axios
            .post(
              `${
                constants.PMT_API
              }/api/projects/addClient?userId=${localStorage.getItem(
                "userId"
              )}`,
              {
                email: activeLead.email,
                location: activeLead.address,
                name: activeLead.name,
                projectName: activeLead.name,
              }
            )
            .then(async (res) => {
              await axios
                .post(
                  `${
                    constants.PMT_API
                  }/api/projects/add-project?userId=${localStorage.getItem(
                    "userId"
                  )}`,
                  {
                    clientId: res.data._id,
                    location: activeLead.address,
                    projectName: activeLead.name,
                    userId: localStorage.getItem("userId"),
                    isConfirmed: false,
                    phoneNumber: activeLead.phoneNumber,
                    clientName: activeLead.name,
                    isFromLead: true,
                    projectAdmin: activeLead?.assignedTo?._id,
                    teamMembers: [activeLead?.assignedTo?._id],
                  }
                )
                .then(async (response) => {
                  // await setActiveLeadState({...activeLead, projectId: response?.data?._id});

                  localStorage.setItem("projectId", response?.data?._id);
                  await postReq(
                    `${constants.CRM_API}/api/save-projectId-leads?leadId=${activeLead?._id}&projectId=${response?.data?._id}`
                  )
                    .then((res2) => {
                      apiFetch(tab);
                      window.location.assign(
                        `${constants.BASE_URL}/quotation/enterprise-quotation/${response?.data?._id}`
                      );
                    })
                    .catch((err) => {
                      console.log(err.error);
                      notification.error({
                        message: err.error || "client already exist",
                        duration: 1,
                      });
                    });
                })
                .catch((e) => {
                  console.log(e);
                  notification.error({
                    message: e.error || "project already exists",
                    duration: 1,
                  });
                })
                .catch((err) => {
                  notification.error({
                    message: err.error || "client already exists",
                    duration: 1,
                  });
                });
            });
        } catch (e) {
          console.log(e);
          notification.error({
            message: e.error || "client already exists",
            duration: 1,
          });
        }
      } else {
        setCurrentQuot("desQuot");
        handleCloseModal();
        handleOk2();
      }
    }
  };
  const createProjectAndGoToQuotation = async () => {
    localStorage.setItem("leadId", activeLead?._id);
    if (activeLead?.projectId) {
      localStorage.setItem("projectId", activeLead?.projectId);
      if(window.location.href.includes("erp.essentiaenvironments.com")){
        window.open(
          `${constants.BASE_URL}/quotation/${activeLead?.projectId}?isLead=true`,
          "_blank"
        );
      }else{
        window.open(
          `${constants.BASE_URL}/quotation/new-quotation/${activeLead?.projectId}?isLead=true`,
          "_blank"
        );
      }
     
    } else {
      if (activeLead.email) {
        try {
          await axios
            .post(
              `${
                constants.PMT_API
              }/api/projects/addClient?userId=${localStorage.getItem(
                "userId"
              )}`,
              {
                email: activeLead.email,
                location: activeLead.address,
                name: activeLead.name,
                projectName: activeLead.name,
              }
            )
            .then(async (res) => {
              await axios
                .post(
                  `${
                    constants.PMT_API
                  }/api/projects/add-project?userId=${localStorage.getItem(
                    "userId"
                  )}`,
                  {
                    clientId: res.data._id,
                    location: activeLead.address,
                    projectName: activeLead.name,
                    userId: localStorage.getItem("userId"),
                    isConfirmed: false,
                    phoneNumber: activeLead.phoneNumber,
                    clientName: activeLead.name,
                    isFromLead: true,
                    projectAdmin: activeLead?.assignedTo?._id,
                    teamMembers: [activeLead?.assignedTo?._id],
                  }
                )
                .then(async (response) => {
                  localStorage.setItem("projectId", response?.data?._id);
                  await setActiveLeadState({
                    ...activeLead,
                    projectId: response?.data?._id,
                  });
                  await postReq(
                    `${constants.CRM_API}/api/save-projectId-leads?leadId=${activeLead?._id}&projectId=${response?.data?._id}`
                  )
                    .then((res2) => {
                      apiFetch(tab);
                      window.open(
                        `${constants.BASE_URL}/quotation/new-quotation/${response.data._id}?isLead=true`,
                        "_blank"
                      );
                    })
                    .catch((err) => {
                      console.log(err.error);
                      notification.error({
                        message: err.error || "client already exist",
                        duration: 1,
                      });
                    });
                })
                .catch((e) => {
                  console.log(e);
                  notification.error({
                    message: e.error || "project already exists",
                    duration: 1,
                  });
                })
                .catch((err) => {
                  notification.error({
                    message: err.error || "client already exists",
                    duration: 1,
                  });
                });
            });
        } catch (e) {
          console.log(e);
          notification.error({
            message: e?.response?.data?.message || "client already exists",
            duration: 1,
          });
        }
      } else {
        setCurrentQuot("desQuot");
        handleCloseModal();
        handleOk2();
      }
    }
  };


  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
}

  const assignLeadToTL = async (tlId) => {
    const res = await postReq(`${constants.CRM_API}/user/newleadActions`, {
      leadId: activeLead?._id,
      assignedTo: tlId,
    });
    if (res && !res.error) {
    } else {
      console.log(res.error);
    }
  };

  const { getLeadsCount } = useApiFetchForLeadCountCustomHook();

  const isUpdateHappened =
    commentText.length > 0 ||
    dateTime[getActive]?.text?.length > 0 ||
    actualLeadStatus3 !== actualLeadStatus2 ||
    // leadstatus[getActive]?.value !== originalCallStatus ||
    actualLeadCallStatus !== "" ||
    dateTime[getActive]?.updateDate;

  const updateLeadStatus = async (val) => {
    let differenceFields = [];
    if (actualLeadStatus3 !== actualLeadStatus2) {
      differenceFields.push(
        `The Lead status has been updated to ${valueToLeadStatusFormatted[val]}`
      );
    }
    const res = await postReq(
      `${constants.CRM_API}/user/edit-lead?id=${
        activeLead?._id
      }&userId=${localStorage.getItem("userId")}`,
      {
        // leadId: activeLead?._id,
        leadStatus: val,
        logText: differenceFields,
      }
    );
    if (res && !res.error) {
      notification.success({
        message: res?.message || "Lead Status Updated Successfully",
        duration: 1,
      });
      if (val == 3) {
        sendEmailPushBellNotifications("Follow Up -> Interested", {
          "@leadName's": activeLead?.name,
          "@Sender": profileData.fullName
        });
      } else if (val == 2) {
        sendEmailPushBellNotifications("Follow Up -> Follow Up", {
          "@leadName's": activeLead?.name,
          "@Sender": profileData.fullName
        });
      } else if (val == 5) {
        sendEmailPushBellNotifications("Follow Up -> Not Interested", {
          "@leadName's": activeLead?.name,
          "@Sender": profileData.fullName
        });
      }

      console.log("previouslead", res?.data?.data);
      await setActiveLeadState({
        ...res?.data?.data,
        idx: activeLead?.idx,
      });
      apiFetch(tab);
    } else {
      console.log(res.error);
      notification.error({
        message: res?.message || "Oops Something Went Wrong",
        duration: 1,
      });
    }
  };

  const addComment = async () => {
    const res = await postReq(constants.CRM_API + "/user/newleadActions", {
      leadId: activeLead?._id,
      leadStatus: activeLead?.leadStatus,
      // callStatus: actualLeadCallStatus,
      // todayRemark: Number(actualCallType),
      leadUpdates: [
        {
          isEmailSend:
            activeLead?.leadUpdates?.length > 0
              ? activeLead?.leadUpdates[activeLead?.leadUpdates?.length - 1]
                  ?.isEmailSend
              : false,
          callLeadStatus:
            activeLead?.leadUpdates?.length > 0
              ? activeLead?.leadUpdates[activeLead?.leadUpdates?.length - 1]
                  ?.callLeadStatus
              : 1,
          updateDate:
            activeLead?.leadUpdates?.length > 0
              ? activeLead?.leadUpdates[activeLead?.leadUpdates?.length - 1]
                  ?.updateDate
              : "",
          updateTime:
            activeLead?.leadUpdates?.length > 0
              ? activeLead?.leadUpdates[activeLead?.leadUpdates?.length - 1]
                  ?.updateTime
              : false,
          text: commentText,
          isComment: true,
          senderId: localStorage.getItem("userId"),
        },
      ],
    });
    if (res && !res?.error) {
      setCommentText("");
      let x = leads;
      x.data[activeLead?.idx] = res?.data?.data;
      await setActiveLeadState({
        ...res?.data?.data,
        assignedTo: assignedTo,
        idx: activeLead?.idx,
      });
      apiFetch(tab);
      getLeadsCount();
      // notification.success({
      //   message: res?.message || "Lead Updated Successfully",
      //   duration: 2,
      // });

      if (dateTime[getActive]?.text) {
        await axios
          .post(`${constants.CRM_API}/user/save-lead-interaction`, {
            leadId: activeLead?._id,
            meetingMode: valueToCallRemarks[actualCallType],
            outcome: leadstatus[getActive]?.label,
            meetingRemarks: dateTime[getActive]?.text,
          })
          .then(() => console.log("success"))
          .catch(() => console.log("something error"));
      }
      if (isLead) {
        dispatch(
          selectedLeadData({
            ...res?.data?.data,
            assignedTo: assignedTo,
            idx: activeLead?.idx,
          })
        );
      }
      await setLeads({ ...x });
    }
  };



  const handleSubmit = async () => {

    let differenceFields = [];
    // if (actualLeadCallStatus === "") {
    //   if (actualLeadStatus3 !== actualLeadStatus2) {
    //     console.log("activeleadcall",actualLeadStatus3,actualLeadStatus2)
    //     updateLeadStatus(actualLeadStatus3);
    //   } else {
    //     alert("Please select a call status");
    //   }
    // } else {
      try {
        if (getActive === mailStatus?.of && mailStatus?.send) {
          const res0 = await postReq(constants.CRM_API + "/user/lead-email", {
            to: [activeLead?.email],
            // "replyTo": "testing4200@gmmail.com",
            subject: mailForm?.subject,
            emailBody: mailForm?.newText2,
          });

          if (res0) {
            console.log(res0);
          }
        }
        let conditionalLeadStatus = actualLeadStatus3;

        console.log(actualLeadStatus3,conditionalLeadStatus,"leadstatusvallueofdata")


        //condition will only execute if there is a change in call status
        // if (isnewlead) {
        //   if (actualLeadCallStatus !== 6) {
        //     conditionalLeadStatus = actualLeadCallStatus;
        //   } else {
        //   }
        // }

        // if (actualLeadCallStatus !== originalCallStatus) {
        //   if (actualLeadStatus3 === 2) {
        //     if (actualLeadCallStatus === 3) {
        //       conditionalLeadStatus = 3;
        //     } else if (
        //       actualLeadCallStatus === 6 ||
        //       actualLeadCallStatus === 2
        //     ) {
        //       conditionalLeadStatus = actualLeadStatus3;
        //     } else if (actualLeadCallStatus === 5) {
        //       conditionalLeadStatus = 5;
        //     } else if (actualLeadCallStatus === 7) {
        //       conditionalLeadStatus = 3;
        //     } else {
        //       alert(
        //         "Please select valid combination of lead status and call status"
        //       );
        //     }
        //   } else if (actualLeadStatus3 === 3) {
        //     if (
        //       actualLeadCallStatus === 3 ||
        //       actualLeadCallStatus === 2 ||
        //       actualLeadCallStatus === 6 ||
        //       actualLeadCallStatus == 7
        //     ) {
        //       conditionalLeadStatus = actualLeadStatus3;
        //     } else if (actualLeadCallStatus === 5) {
        //       conditionalLeadStatus = 5;
        //     } else {
        //       alert(
        //         "Please select valid combination of lead status and call status"
        //       );
        //     }
        //   } else if (actualLeadStatus3 === 5) {
        //     if (actualLeadCallStatus === 5 || actualLeadCallStatus === 6) {
        //       conditionalLeadStatus = actualLeadStatus3;
        //     } else if (
        //       actualLeadCallStatus === 7 ||
        //       actualLeadCallStatus === 3
        //     ) {
        //       conditionalLeadStatus = 3;
        //     } else if (actualLeadCallStatus === 2) {
        //       conditionalLeadStatus = 2;
        //     } else {
        //       alert(
        //         "Please select valid combination of lead status and call status"
        //       );
        //     }
        //   } else if (actualLeadStatus3 === 6) {
        //     conditionalLeadStatus = actualLeadCallStatus;
        //   }
        // }

        // if (!leadstatus[myLeadStatus]?.value) {
        //   showAlert("Select A Lead Status To Submit !", "Okay");
        //   return;
        // }
        if (actualLeadStatus3 !== actualLeadStatus2) {
          differenceFields.push(
            `The Lead status has been updated to ${valueToLeadStatusFormatted[actualLeadStatus3]}`
          );
        }
        if (actualLeadCallStatus !== "") {
          differenceFields.push(
            `The Lead call status has been updated to ${valueToLeadStatusFormatted[actualLeadCallStatus]}`
          );
        }
        if (dateTime[getActive]?.text || commentText) {
          differenceFields.push(`A new comment has been added.`);
        }

        setButtonClickedOnce(true);
        const res = await postReq(constants.CRM_API + "/user/newleadActions", {
          leadId: activeLead?._id,
          // leadStatus: conditionalLeadStatus?conditionalLeadStatus:actualLeadStatus,
          leadStatus: leadstatustitle == "leadStatus" ? conditionalLeadStatus : actualLeadStatus,
          // leadStatus:conditionalLeadStatus,
          todayRemark: Number(actualCallType),
          logText: differenceFields,
          reasonForNotInterested: selectedReasonForNotInterest,
          leadUpdates: [
            {
              isEmailSend:
                getActive === mailStatus?.of && mailStatus?.send ? true : false,
              callLeadStatus: actualLeadCallStatus,
              text: dateTime[getActive]?.text || commentText,
              updateDate:
                dateTime[getActive]?.updateDate || formateDate(new Date()),
              updateTime: dateTime[getActive]?.updateTime || getCurrentTime(),
              senderId: localStorage.getItem("userId"),
            },
          ],
        });
        if (res && !res?.error) {
          // if (commentText.length > 0 && !commentError) {
          //   addComment();
          // }
          console.log("leadupdateresponse", res);
          setButtonClickedOnce(false);
          dispatch(updateCallStatusUpdatePanel(false));
          let x = leads;
          x.data[activeLead?.idx] = res?.data?.data;
          apiFetch(tab);
          await setActiveLeadState({
            ...res?.data?.data,
            assignedTo: assignedTo,
            idx: activeLead?.idx,
            leadUpdates: res?.data?.data?.leadUpdates,
          });

          getLeadsCount();

    
          if (actualLeadCallStatus == 3) {
            sendEmailPushBellNotifications("Follow Up -> Interested", {
              "@leadName 's": activeLead?.name,
              "@Sender": profileData.fullName,
              "@leadName's": activeLead?.name,
              "@sender": profileData.fullName,
    
            });
          } else if (actualLeadCallStatus == 2) {
            sendEmailPushBellNotifications("Follow Up -> Follow Up", {
              "@leadName 's": activeLead?.name,
              "@Sender": profileData.fullName,
              "@leadName's": activeLead?.name,
              "@sender": profileData.fullName,
            });
          } else if (actualLeadCallStatus == 5) {
            sendEmailPushBellNotifications("Follow Up -> Not Interested", {
              "@leadName 's": activeLead?.name,
              "@Sender": profileData.fullName,
              "@leadName's": activeLead?.name,
              "@sender": profileData.fullName,
            });
          }
          // notification.success({
          //   message: res?.message || "Lead Updated Successfully",
          //   duration: 2,
          // });

          if (dateTime[getActive]?.text || commentText) {
            await axios
              .post(`${constants.CRM_API}/user/save-lead-interaction`, {
                leadId: activeLead?._id,
                meetingMode: valueToCallRemarks[actualCallType],
                outcome: leadstatus[getActive]?.label,
                meetingRemarks: dateTime[getActive]?.text || commentText,
              })
              .then(() => (console.log("success"), setCommentText("")))
              .catch(() => console.log("something error"));
          }
          if (isLead) {
            dispatch(
              selectedLeadData({
                ...res?.data?.data,
                assignedTo: assignedTo,
                idx: activeLead?.idx,
              })
            );
          }
          await setLeads({ ...x });
          if (leadstatus[getActive]?.value !== 3) {
            !leadstatus[getActive]?.addEmail
              ? showAlert(
                  "You have successfully updated your lead status ",
                  "Okay"
                )
              : getActive === mailStatus?.of && mailStatus?.send
              ? showAlert("You have successfully sent the email", "Okay")
              : activeLead?.dontShowAgain === false &&
                showAlert(
                  "You have successfully updated your lead status <br><br> Want to send a reference email? ",
                  "Yes",
                  true,
                  () => {
                    // console.log(isChecked)
                    // updateShowAgain();
                    document.getElementById("opensendMail")?.click();
                    setMailStatus({ of: getActive });
                  }
                );
                
          } else {
            showAlert(
              "You have successfully updated your lead status ",
              "Okay"
            );
          }
        }
      } catch (error) {
        setButtonClickedOnce(false);
        console.log(error);
      }
    // }
  };

  setTimeout(() => {
    setShowUpdateBadge(false);
  }, 1000);

  useEffect(() => {
    getTeamLeads();
  }, []);
  const inputBoxRef = useRef(null);
  function autoResize() {
    if (inputBoxRef.current) {
      const maxHeight = 90;
      inputBoxRef.current.style.height = "1px"; // Reset height to 1px to get scrollHeight accurately
      const newHeight = Math.min(inputBoxRef?.current.scrollHeight, maxHeight); // Limit to max three lines
      inputBoxRef.current.style.height = newHeight + "px";
    }
  }

  console.log(leadStatus,"leadstatusdata")

  return (
    <>
      <Modal
        // title={false}
        open={quotationModal}
        onOk={handleOk}
        onCancel={handleCloseModal}
        centered
        footer={null}
        // closable={false}
        title="Select the quotation"
        // className="ant-modal-centered"
      >
        <div className="d-flex mt-2">
          <div
            onClick={createProjectAndGoToDesignQuotation}
            className="d-flex flex-column align-items-center justify-content-center  py-5"
            style={{
              background: "#FFFFFF",
              // background:"red",
              border: "1px solid #DFDFDF",
              borderRadius: "8px",
              width: "50%",
              cursor: "pointer",
            }}
          >
            <img
              src={designquo}
              style={{ height: "2.75rem", width: "2.75rem" }}
            />
            <div
              className="mt-4"
              style={{
                color: "#444444",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Design Quotation
            </div>
          </div>
          <div
            onClick={createProjectAndGoToQuotation}
            className="d-flex flex-column align-items-center justify-content-center  py-5 ms-4"
            style={{
              background: "#FFFFFF",
              border: "1px solid #DFDFDF",
              borderRadius: "8px",
              width: "50%",
              cursor: "pointer",
            }}
          >
            <img
              src={executionquo}
              style={{ height: "2.75rem", width: "2.75rem" }}
            />
            <div
              className="mt-4"
              style={{
                color: "#444444",
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Execution Quotation
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        // title={false}
        open={quotationModal2}
        onOk={handleOk2}
        onCancel={handleCloseModal2}
        centered
        title={
          currentQuot === "exQuot"
            ? `Send Execution Quotation`
            : `Send Design Quotation`
        }
        footer={
          <div>
            <Button onClick={handleCloseModal2}>Cancel</Button>
            <Button
              style={{
                background: "rgb(23,78,134)",
                color: "white",
                borderRadius: "0.25rem",
                fontSize: "13px",
              }}
              onClick={handleOk22}
            >
              Send
            </Button>
          </div>
        }
        closable={false}
        // className="ant-modal-centered"
      >
        <div className="d-flex mt-2">
          <input
            placeholder="Enter Lead Email"
            name="quotInput"
            value={quotInput}
            onChange={handleQuotChange}
            className="quoEmail"
          />
        </div>
      </Modal>

      <div
        className="br-5 mv-br-0 mv-vh-50 "
        style={{ width: "100%", height: "76vh", overflowY: "scroll" }}
      >
        <div
          // className="pt-1 pt-md-2 px-2 px-md-2 py-2"
          style={{
            background: "linear-gradient(90deg, #0084EF 0%, #0063B2 100%)",
            color: "#ffffff",
            padding: "10px 10px",
          }}
        >
          <div className="d-flex justify-content-between mb-2 align-items-center">
            <div
              className="d-flex align-items-start w-100 justify-content-between mt-2"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <span
                className="fw-bold mb-0"
                style={{
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}
              >
                {name?.charAt(0).toUpperCase() + name?.slice(1)}
              </span>

              <div style={{ height: "30px " }}>
                <div className="d-flex ">
                  <div
                    // class="btn btn-primary"
                    // href="#"
                    // role="button"
                    id="dropdownMenuLink"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      // padding: "0",
                      // backgroundColor: "#00000000",
                      // display: "flex",
                      // alignItems: "center",
                      // zIndex: "1000",
                      // fontSize: "12px",
                      // fontWeight: "500",
                      // color:"#FFF"
                      color: "#FFF",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      fontSize: "12px",
                      width: "85px",
                      fontWeight: "400",
                      // background:"red"
                    }}
                  >
                    Assigned to: &nbsp;
                  </div>
                  <div
                    // class="btn btn-primary"
                    // href="#"
                    // role="button"
                    id="dropdownMenuLink"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      padding: "0",
                      backgroundColor: "#00000000",
                      display: "flex",
                      alignItems: "center",
                      zIndex: "1000",
                      fontSize: "12px",
                      fontWeight: "500",
                      // background:"green",

                      width: "65px",
                    }}
                  >
                    {assignedTo ? assignedTo?.fullName : "Not Assigned yet"}
                  </div>

                  {/* <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  {teamLeadArr &&
                    teamLeadArr.map((curElem) => {
                      if (curElem._id !== localStorage.getItem("userId")) {
                        return (
                          <li>
                            <a class="dropdown-item px-2" href="#" onClick={() => assignLeadToTL(curElem._id)}>
                              {curElem.fullName}
                            </a>
                          </li>
                        );
                      }
                    })}
                </ul> */}
                </div>
              </div>
              {leadStatus !== 1 && (
                <p
                  className="position-md-absolute br-0 mb-0 ms-2 ms-md-0"
                  style={{
                    top: "-3px",
                    right: "-2px",
               
                  }}
                >
                  <motion.span
                    initial={showUpdateBadge ? { opacity: 1 } : { opacity: 0 }}
                    animate={showUpdateBadge ? { opacity: 1 } : { opacity: 0 }}
                    transition={
                      showUpdateBadge ? { duration: 1 } : { duration: 3 }
                    }
                    className="badge badge-primary fw-normal p-2 mv-rounded-pill"
                  >
                    Updated on {formateDate(new Date())?.slice(0, -5)}
                  </motion.span>
                </p>
              )}
              {renovationTimeline && (
                <button
                  type="button"
                  className="btn btn-outline-light btn-rounded border btn-sm py-0 px-1 ms-3"
                  data-mdb-ripple-color="dark"
                >
                  {renovationTimeline}
                </button>
              )}
            </div>
            <div className="d-md-none">
              <button
                type="button"
                className="btn btn-outline-dark btn-floating btn-close"
                data-mdb-dismiss="modal"
                aria-label="Close"
                style={{
                  width: 20,
                  height: 20,
                }}
              ></button>
            </div>
            {/* <div class="dropdown d-flex align-items-center">
            <button style={{ boxShadow: "none", color: "#ffffff" }} class="btn dropdown-toggle p-0" type="button" id="dropdownMenuButton" data-mdb-toggle="dropdown" aria-expanded="false">
              Dropdown button
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <a class="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
            </ul>
          </div> */}
          </div>
          <div
            style={{
              width: "174px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              margin: "12px 0px",
            }}
          >
            {phoneNumber && (
              <div className="d-flex align-items-center" style={{ gap: "8px" }}>
                {/* <img src={vector4} alt="" width={12} height={12} className="" /> */}
                <BsTelephone fontSize={12} />
                <span
                  style={{
                    color: "#ffffff",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                  // className="pfs-12 mv-pfs-14 ms-2"
                >
                  {phoneNumber}
                </span>
              </div>
            )}
            {email && (
              <div className="d-flex align-items-center" style={{ gap: "8px" }}>
                {/* <img src={vector0} alt="" width={12} height={12} className="" /> */}
                <AiOutlineMail />
                <span
                  style={{
                    color: "#ffffff",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                  // className="pfs-12 mv-pfs-14 ms-2"
                >
                  {email}
                </span>
              </div>
            )}
          </div>
          <div>
            <div className="d-flex  mt-2  ">
              {email ? (
                <button
                  className="btn btn-outline-base-secondary border-none px-0 d-flex align-items-center cl-base-blue me-4"
                  data-mdb-toggle="modal"
                  data-mdb-target="#leadmail"
                  id="opensendMail"
                  disabled={!userData?.rolesAssigned?.crm?.edit}
                  onClick={() => {
                    dispatch(setShowEmailModal(true));
                    setMailStatus();
                  }}
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  {/* <img src={vector1} alt="" width={14} height={14} className="" /> */}
                  <AiOutlineMail
                    fontSize={18}
                    color="#ffffff"
                    style={{ height: "20px", width: "20px" }}
                  />
                  <span
                    style={{
                      color: "#ffffff",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }}
                    className="pointer"
                    // className="pfs-12 mv-pfs-14 ms-2"
                  >
                    Send mail
                  </span>
                  {!userData?.rolesAssigned?.crm?.edit && (
                    <Tooltip title="Access Disabled by the Admin">
                      <span>
                        <img src={LockWhite} alt="LockImg"></img>
                      </span>
                    </Tooltip>
                  )}
                </button>
              ) : (
                <button
                  style={{ pointerEvents: "none" }}
                  className="btn btn-outline-base-secondary border-none px-0 d-flex align-items-center cl-base-blue me-4"
                  // data-mdb-toggle="modal"
                  // data-mdb-target="#leadmail"
                  disabled={!userData?.rolesAssigned?.crm?.edit}
                >
                  {/* <img src={vector1} alt="" width={14} height={14} className="" /> */}
                  <AiOutlineMail fontSize={18} color="#ffffff7a" />
                  <span
                    className="pointer"
                    style={{
                      color: "#ffffff",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }}
                    // className="pfs-12 mv-pfs-14 ms-2"
                  >
                    Send mail
                  </span>
                </button>
              )}
              <button
                // data-mdb-toggle="modal"
                // data-mdb-target="#addQuotationModal"

                role="button"
                onClick={createProjectAndGoToQuotation}
                disabled={!userData?.rolesAssigned?.quotation?.edit}
                className="btn btn-outline-base-secondary border-none px-0 d-flex align-items-center cl-base-blue me-4"
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                {/* <img src={vector2} alt="" width={14} height={14} className="" /> */}
                <HiOutlineClipboardList
                  color="#ffffff"
                  fontSize={18}
                  style={{ height: "20px", width: "20px" }}
                />
                <span
                  style={{
                    color: "#ffffff",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                  // className="pfs-12 mv-pfs-14 mx-1"
                  className="pointer"
                >
                  Send a quotation
                </span>
                {!userData?.rolesAssigned?.quotation?.edit && (
                  <Tooltip title="Access Disabled by the Admin">
                    <span>
                      <img src={LockWhite} alt="LockImg"></img>
                    </span>
                  </Tooltip>
                )}
              </button>
              {/* <button className="btn btn-outline-base-secondary border-none px-0 d-flex align-items-center cl-base-blue">
              <img src={vector3} alt="" width={14} height={14} className="" />
              <span style={{ color: "#ffffff" }} className="pfs-12 mv-pfs-14 ms-2">
                Chat
              </span>
            </button> */}
            </div>
          </div>
        </div>
        <div className="px-2 pb-2 bg-white " style={{maxHeight:"54vh",overflowY:"scroll"}}>
          {isnewlead ? (
            <div className="mt-2">
              <p className="fw-bold d-flex mb-0">
                <div>
                  {`How was the ${valueToCallRemarks[actualCallType]}`}?
                </div>
                <div
                  className=" dropdown-toggle pfs-12 mv-pfs-14"
                  type="button"
                  id="dropdownMenuButton"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  <BiChevronDown size={25} className="mx-0" />
                </div>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {valueToCallRemarks &&
                    Object.keys(valueToCallRemarks)?.map((e, i) => {
                      return (
                        <li key={i}>
                          <a
                            className="dropdown-item pfs-14"
                            href="#!"
                            onClick={async () => {
                              setActualCallType(e);
                            }}
                          >
                            {valueToCallRemarks[e]}
                          </a>
                        </li>
                      );
                    })}
                </ul>
                <span className="cl-grey1 fw-normal">(Lead Status)</span>{" "}
              </p>

              
              <div className="d-flex flex-wrap pe-2  ">
                {Object.keys(leadstatus)?.map((e, i) => {
                  return (
                    e !== "meeting_schedule" && (
                      // leadstatus[e].active !== true &&
                      <button
                        key={i}
                                 
                        type="button"
                        className={
                          leadstatus[e].clicked !== true
                            ? "btn  btn-outline-light border cl-black px-2 fw-normal me-2 mt-2 py-1"
                            : "btn  btn-secondary outline-base-blue px-2  fw-normal me-2 mt-2 py-1"
                        }
                        data-mdb-ripple-color="dark"
                        onClick={async () => {
                           console.log("follow====>",leadstatus[e]?.value)
                          setActualLeadStatus(leadstatus[e]?.value);
                          setActualLeadCallStatus(leadstatus[e]?.value);
                          setMyLeadStatus(e);
                          getActive
                            ? await setLeadstatus({
                                ...leadstatus,
                                [getActive]: {
                                  ...leadstatus[getActive],
                                  active: false,
                                  clicked: false,
                                },
                                [e]: {
                                  ...leadstatus[e],
                                  active: true,
                                  clicked: true,
                                },
                              })
                            : await setLeadstatus({
                                ...leadstatus,
                                [e]: {
                                  ...leadstatus[e],
                                  active: true,
                                  clicked: false,
                                },
                              });

                        }}
                      >
                        {leadstatus[e]?.label}
                      </button>
                    )
                  );
                })}

                

                {/* {getActive && (
                  <div className="w-100">
                    <button
                      type="button"
                      className="btn  btn-secondary outline-base-blue px-2  fw-normal me-2 mt-2 py-1"
                      data-mdb-ripple-color="dark"
                      onClick={() => {
                        setLeadstatus({
                          ...leadstatus,
                          [getActive]: {
                            ...leadstatus[getActive],
                            active: false,
                          },
                        });
                        setActualLeadStatus(leadstatus[getActive].value);
                        setMyLeadStatus();
                      }}
                    >
                      {leadstatus[getActive]?.label}
                    </button>
                  </div>
                )} */}
              </div>
              <hr />
              {/* <div>
                <p
                  style={{
                    color: "#0084ef",
                    float: 'right',
                 
                  }}
                  className="mb-0 pfs-12 cl-base-blue cursor-pointer"
                  onClick={() => {
                    setViewremark(!viewremark);
                  }}
                >
                  {!viewremark ? "View last remark" : "Hide remarks"}
                </p>
              </div> */}
              {actualLeadCallStatus == 5 &&
                (creatingMyReason ? (
                  <div
                    className="commentContainer"
                    style={{
                      border: commentTextBoxFocused
                        ? "1px solid #0084EF"
                        : "1px solid #DEDEDE",
                      
                    }}
                  >
                    <input
                      // ref={inputBoxRef}
                      // value={commentText}
                      onChange={(e) => {
                        setSelectedReasonForNotInterest(e.target.value);
                      }}
                      maxLength={1025}
                      type="text"
                      placeholder="Write Reason"
                      name="comment"
                      className="commentInputBox"
                    />
                    <RxCross2
                      size={15}
                      style={{ cursor: "pointer" }}
                      color="#74737F"
                      onClick={() => (
                        setCreatingMyReason(false),
                        setSelectedReasonForNotInterest("")
                      )}
                    />
                  </div>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      className="dropdownButton"
                      variant="outline-secondary"
                      id="dropdown-basic"
                    >
                      <span className="buttonText">
                        {selectedReasonForNotInterest == ""
                          ? "Select"
                          : selectedReasonForNotInterest}
                      </span>{" "}
                      {selectedReasonForNotInterest == "" ? (
                        <BiChevronDown size={20} color="#74737F" />
                      ) : (
                        <RxCross2
                          size={15}
                          style={{ cursor: "pointer" }}
                          color="#74737F"
                          onClick={() => setSelectedReasonForNotInterest("")}
                        />
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="filterDropdownContainer">
                      <Dropdown.Item
                        onClick={() => setCreatingMyReason(true)}
                        className="d-flex"
                      >
                        <div className="filterDropdownText1 filterDropdownText">
                          Create your Own
                        </div>
                      </Dropdown.Item>
                      {notInterestedReasons?.map((t) => {
                        return (
                          <Dropdown.Item
                            onClick={() => setSelectedReasonForNotInterest(t)}
                            className="d-flex"
                          >
                            <div className="filterDropdownText">{t}</div>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                ))}
            </div>
          ) : (
            <div className="pt-2 pb-2" >
              <div className="d-flex align-items-center w-100 pfs-14">
                <div
                  style={{
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                >
                  Lead Status
                </div>
                <div
                  className="dropdown ms-3 border br-5"
                  style={{
                    width: "158px",
                    display: "flex",
                    padding: "8px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexShrink: 0,
                  }}
                >
                  <div
                    className=" dropdown-toggle "
                    type="button"
                    id="dropdownMenuButton"
                    data-mdb-toggle="dropdown"
                    style={{
                      // background:"red",
                      width: "154px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: userData?.rolesAssigned?.crm?.edit
                        ? "pointer"
                        : "default",
                    }}
                    aria-expanded="false"
                    disabled={!userData?.rolesAssigned?.crm?.edit}
                  >
                    {actualLeadStatus3 != 1
                      ? actualLeadStatus3 == 2
                        ? "Follow Up"
                        : actualLeadStatus3 == 3
                        ? "Interested"
                        : actualLeadStatus3 == 5
                        ? "Not Interested"
                        : actualLeadStatus3 == 6
                        ? "No Response"
                        : actualLeadStatus3 == 7
                        ? "Meeting Scheduled"
                        : "Select"
                      : "Select"}
                    <BiChevronDown className="mx-0" />
                    {!userData?.rolesAssigned?.crm?.edit && (
                      <Tooltip title="Access Disabled by the Admin">
                        <span>
                          <img src={LockBlack} alt="LockImg"></img>
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <ul
                    className="dropdown-menu leadStatushovercontaineer"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {leadstatus &&
                      Object.keys(leadstatus)?.map((e, i) => {
                        return (
                          !leadstatus[e].notvisible && (
                            <li key={i}>
                              <a
                                className="dropdown-item pfs-14 leadStatushover"
                                href="#!"
                                onClick={async () => {
                                  setleadstatustitle("leadStatus")
                                  // getActive
                                  //     ? await setLeadstatus({
                                  //         ...leadstatus,
                                  //         [getActive]: {
                                  //             ...leadstatus[getActive],
                                  //             active: false,
                                  //         },
                                  //         [e]: { ...leadstatus[e], active: true },
                                  //     })
                                  //     : await setLeadstatus({
                                  //         ...leadstatus,
                                  //         [e]: { ...leadstatus[e], active: true },
                                  //     });
                                  setActualLeadStatus3(leadstatus[e]?.value);
                                  setMyLeadStatus(e);
                                }}
                              >
                                {leadstatus[e]?.label}
                              </a>
                            </li>
                          )
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          )}

          {/* today call marks */}

          {!isnewlead && (
            <div className="d-flex w-100 justify-content-between align-items-center">
              <div className="pfs-14 d-flex fw-bold align-items-center">
                <div>
                  {`Today’s ${valueToCallRemarks[actualCallType]} Remark`}{" "}
                </div>{" "}
                <div className="dropdown br-5 py-1 px-md-2 e">
                  <div
                    className=" dropdown-toggle pfs-12 mv-pfs-14"
                    type="button"
                    id="dropdownMenuButton"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <BiChevronDown size={25} className="mx-0" />
                  </div>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {valueToCallRemarks &&
                      Object.keys(valueToCallRemarks)?.map((e, i) => {
                        return (
                          <li key={i}>
                            <a
                              className="dropdown-item pfs-14"
                              href="#!"
                              onClick={async () => {
                                // setActualLeadStatus(leadstatus[e]?.value);
                                // setMyLeadStatus(e);
                                setActualCallType(e);
                              }}
                            >
                              {valueToCallRemarks[e]}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>

              {/* <div>
                <p
                  style={{
                    color: "#0084ef",
                    background:"red"
                  }}
                  className="mb-0 pfs-12 cl-base-blue cursor-pointer"
                  onClick={() => {
                    setViewremark(!viewremark);
                  }}
                >
                  {!viewremark ? "View last remark" : "Hide remarks"}
                </p>
              </div> */}
            </div>
          )}
          {/* follow up */}
          {!isnewlead && (
            <div className="" style={{ marginTop: "12px" }}>
              {leadstatus &&
                Object.keys(leadstatus)?.map((e, i) => {
                  return (
                    !leadstatus[e].isNewOnly &&
                  e !== "meeting_schedule" && (
                      <button
                        key={i}
                        type="button"
                        className={`btn 
                                border px-3 px-md-2 fw-normal me-2 py-2 py-md-2 mt-2 mt-md-1 removeShoadow `}
                        style={{
                      
                          fontSize:
                            window.screen.width < 1400 ? "11px" : "12px",
                          // background
                          // :
                          //   leadstatus[e]?.clicked === true
                          //     ? 'blue'
                          //     : "#f2f2f2",
                          color:
                            leadstatus[e]?.value === actualLeadCallStatus
                              ? "#0084EF"
                              : "#000000",
                        }}
                        data-mdb-ripple-color="dark"
                        disabled={!userData?.rolesAssigned?.crm?.edit}
                        onClick={async () => {
                          console.log("mohit=========?",leadstatus[e]?.value)
                          setleadstatustitle("callRemark")

                          setActualLeadStatus(leadstatus[e]?.value)
                          setActualLeadCallStatus(leadstatus[e]?.value);
                          getActive
                            ? await setLeadstatus({
                                ...leadstatus,
                                [getActive]: {
                                  ...leadstatus[getActive],
                                  active: false,
                                },
                                [e]: { ...leadstatus[e], active: true },
                              })
                            : await setLeadstatus({
                                ...leadstatus,
                                [e]: { ...leadstatus[e], active: true },
                              });
                        }}
                      >
                        {/* {leadstatus[e]?.active === true && (
                              <BsCheck2 fontSize={12} className="me-1" />
                            )} */}
                        {leadstatus[e]?.label}
                      </button>
                    )
                  );
                })}
              <hr />
              {actualLeadCallStatus == 5 && (
                <div>
                  <div className="mb-2 fw-normal">
                    Reason for Not-Interested{" "}
                  </div>
                  {creatingMyReason ? (
                    <div
                      className="commentContainer"
                      style={{
                        border: commentTextBoxFocused
                          ? "1px solid #0084EF"
                          : "1px solid #DEDEDE",
                      }}
                    >
                      <input
                        // ref={inputBoxRef}
                        // value={commentText}
                        onChange={(e) => {
                          setSelectedReasonForNotInterest(e.target.value);
                        }}
                        maxLength={1025}
                        type="text"
                        placeholder="Write Reason"
                        name="comment"
                        className="commentInputBox"
                      />
                      <RxCross2
                        size={15}
                        style={{ cursor: "pointer" }}
                        color="#74737F"
                        onClick={() => (
                          setCreatingMyReason(false),
                          setSelectedReasonForNotInterest("")
                        )}
                      />
                    </div>
                  ) : (
                    <Dropdown>
                      <Dropdown.Toggle
                        className="dropdownButton"
                        variant="outline-secondary"
                        id="dropdown-basic"
                      >
                        <span className="buttonText">
                          {selectedReasonForNotInterest == ""
                            ? "Select"
                            : selectedReasonForNotInterest}
                        </span>{" "}
                        {selectedReasonForNotInterest == "" ? (
                          <BiChevronDown size={20} color="#74737F" />
                        ) : (
                          <RxCross2
                            size={15}
                            style={{ cursor: "pointer" }}
                            color="#74737F"
                            onClick={() => setSelectedReasonForNotInterest("")}
                          />
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="filterDropdownContainer">
                        <Dropdown.Item
                          onClick={() => setCreatingMyReason(true)}
                          className="d-flex"
                        >
                          <div className="filterDropdownText1 filterDropdownText">
                            Create your Own
                          </div>
                        </Dropdown.Item>
                        {notInterestedReasons?.map((t) => {
                          return (
                            <Dropdown.Item
                              onClick={() => setSelectedReasonForNotInterest(t)}
                              className="d-flex"
                            >
                              <div className="filterDropdownText">{t}</div>
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
              )}
            </div>
          )}

          {actualLeadCallStatus === 6 && (
            <SendMail
              text={`Send a quick email to ${activeLead?.name} for a callback`}
            />
          )}

          {/*  field of hide and show field */}

          {actualLeadCallStatus === 2 && <DateTime />}
          <div
            style={{
              height: "17px",
              width: "312px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "14px",
              marginBottom: "7px",
            }}
          >
            <span>Comment : {activeLead?.leadUpdates.filter((e)=>e?.text?true:false).length}</span>
            <div>
              <p
                style={{
                  color: "#0084ef",
                }}
                className="mb-0 pfs-12 cl-base-blue  cursor-pointer pointer"
                onClick={() => {
                  setViewremark(!viewremark);
                }}
              >
                {!viewremark ? "View last remark" : "Hide remarks"}
              </p>
            </div>
          </div>

          {console.log("actualLeadCallStatus", actualLeadCallStatus)}
          {actualLeadCallStatus === 7 && (
            <SendMail
              text={`Send a thank you email to ${activeLead?.name} for their time!`}
            />
          )}

          {!viewremark ? (
            <div
              className={`${!isnewlead ? "br-5 px-1 py-1" : ""}`}
              style={{
                background: !isnewlead && "#FFFFFF",
                // backgroundColor:"green",
                marginTop: "8px",
              }}
            >
              {(getActive === "followUp" ||
                getActive === "not_interested" ||
                getActive === "no_response" ||
                getActive === "interested" ||
                getActive === "meeting_schedule") &&
                (mailStatus?.of !== getActive || !mailStatus.send ? (
                  <div>
                    <Remark />
                  </div>
                ) : mailStatus?.of === "followUp" ? (
                  <SendMail
                    text={`Send a quick email to ${activeLead?.name} for a follow up`}
                  />
                ) : mailStatus?.of === "interested" ? (
                  <SendMail
                    text={`Send a quick email to ${activeLead?.name} to show your interest`}
                  />
                ) : (
                  mailStatus?.of === "meeting_schedule" && (
                    <SendMail
                      text={`Send a quick email to ${activeLead?.name} to know that a meet is scheduled`}
                    />
                  )
                ))}
            </div>
          ) : (
            <>
              {viewremark && (
                <div
                  className="commentContainer mb-2"
                  style={{
                    // background:"red",

                    border: commentTextBoxFocused
                      ? "1px solid #0084EF"
                      : "1px solid #DEDEDE",
                    marginTop: "10px",
                  }}
                >
                  <input
                    ref={inputBoxRef}
                    value={commentText}
                    onBlur={() => setcommentTextBoxFocused(false)}
                    onFocus={() =>
                      // autoResize(),
                      setcommentTextBoxFocused(true)
                    }
                    onChange={(e) => {
                      // autoResize();
                      if (commentText?.length < 1024 && e.target.value?.length>0) {
                        setCommentError(false);
                 
                        setCommentText(e.target.value);
                      } else {
                        if (e.target.value.length > 1024) {
                          setCommentError(true);
                        } else {
                          setCommentError(false);
                          setCommentText(e.target.value);
                        }
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" && commentText.length>0) {
                        // console.log("hello world")
                        setShowUpdateBadge(true);
                        handleSubmit();
                      }
                    }}
                    maxLength={1025}
                    type="text"
                    placeholder="Add Comment"
                    name="comment"
                    className="commentInputBox"
                  />
                  {/* {commentText.length > 0 && !commentError && (
                <img
                  className="commentSendIcon"
                  onClick={() => addComment()}
                  src={PaperPlaneRight}
                />
              )} */}
                </div>
              )}
              {viewremark && commentError && (
                <p
                  style={{ color: "red", marginLeft: "3px", fontSize: "12px" }}
                >
                  Comment can't be greater than 1024.
                </p>
              )}
              <div
                className={`border br-5 mb-2 px-2 py-1 overflow-auto scb-none pt-3`}
                style={{
                  background: "white",
                  height: "9rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  width: "100%",
                }}
              >
                {/* {activeLead?.reasonForNotInterested && (
                  <div className="textContainer"> */}
                {/* <div
                      style={{
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      {" "}
                      <div className="statusStyle">
                        {" "}
                        <span>Reason for Not Interested</span>
                      </div>
                    </div>
                    <div style={{fontWeight:'bold'}} className="">
                      Reason: {activeLead?.reasonForNotInterested
                        ? activeLead?.reasonForNotInterested
                        : "-"}
                    </div> */}
                {/* <div className="nameStyle">
                  {
                    teamMembers?.find(
                      (item) => item.memberId == e?.senderId
                    )?.memberName
                  }{" "}
                  <span>
                    {" "}
                    {e?.createdDate && formateDate(e?.createdDate)}
                  </span>
                </div> */}
                {/* </div>
                )} */}

                {console.log("YeRahaActiveLead", activeLead?.leadUpdates)}
                {activeLead?.leadUpdates
                // filter((e)=>e?.text?true:false)
                  ?.map((e, i) => {
                    console.log("commentsdata", e);
                    {
                      console.log("YeRahaActiveLead", e.text);
                    }
                    return (
                      // (activeLead?.leadUpdates[i]?.text?.length > 0 ||
                      //   activeLead?.leadUpdates[i]?.isEmailSend) &&
                      (i < activeLead?.leadUpdates?.length - 1
                        ? formateDate(
                            activeLead?.leadUpdates[i]?.createdDate
                          ) ===
                            formateDate(
                              activeLead?.leadUpdates[i + 1]?.createdDate
                            ) &&
                          activeLead?.leadUpdates[i]?.text ===
                            activeLead?.leadUpdates[i + 1]?.text &&
                          activeLead?.leadUpdates[i]?.callLeadStatus ===
                            activeLead?.leadUpdates[i + 1]?.callLeadStatus
                          ? activeLead?.leadUpdates[i]?.isEmailSend ===
                            activeLead?.leadUpdates[i + 1]?.isEmailSend
                          : true
                        : true) && (
                        <div
                          className="textContainer"
                          key={i}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            alignContent: "",
                            width: "100%",
                            justifyContent: "space-between",
                            padding: "4px",
                            background: "#FAFAFA",
                          }}
                        >
                          {/* message and staus */}
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                width: "60%",
                                fontSize: "12px",
                                background: "",
                              }}
                            >
                              {activeLead?.reasonForNotInterested &&
                              e?.callLeadStatus === 5 &&
                              activeLead?.reasonForNotInterested ? (
                                <div
                                  style={{ fontWeight: "bold" }}
                                  className=""
                                >
                                  Reason:{" "}
                                  {activeLead?.reasonForNotInterested
                                    ? activeLead?.reasonForNotInterested
                                    : "-"}
                                </div>
                              ) : (
                                ""
                              )}
                              <span>{e.text?.length>0?e.text:"-"}</span>
                            </div>
                            <div
                              style={{
                                width: "38%",
                                height: "20px",
                                padding: "4px 8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "4px",
                                background: "#D6EDFF",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "500",
                                  fontSize: "10px",
                                  color: "#0084EF",
                                  // color:"red"
                                }}
                              >
                                
                                {!e?.isComment && e?.callLeadStatus!==null ? (
                                  leadstatus[
                                    valueToLeadStatus[e?.callLeadStatus]
                                  ]?.label
                                ) : (
                                  <>
                               
                                    <span>Comment</span>
                                  </>
                                )}

                               {/* {e?.callLeadStatus===null?<span>comment</span>:e?.callLeadStatus} */}
                              </span>
                            </div>
                          </div>

                          {/* userv details */}

                          <div
                            style={{
                              display: "flex",
                              width: "292px",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "80%",
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              <div
                                style={{ fontSize: "11px", color: "#999999" }}
                              >
                                {
                                  teamMembers?.find(
                                    (item) => item.memberId == e?.senderId
                                  )?.memberName
                                }{" "}
                              </div>

                              {/* //mohit  */}
                              <span
                                style={{
                                  fontSize: "11px",
                                  color: "#999999",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "10px",
                                }}
                              >
                                {" "}
                                {e?.createdDate && formateDate(e?.createdDate)}
                                <span>
                                  {" "}
                                  {e?.createdDate &&
                                    moment(e?.createdDate).format("LT")}
                                </span>
                              </span>
                            </div>
                            <div
                              style={{
                                width: "20%",
                                height: "12px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "4px",
                              }}
                            >
                              {e?.isEmailSend && (
                                // <div className="email_status_style">
                                //   {e?.isEmailSend && " Email sent"}{" "}
                                // </div>
                                <span
                                  style={{
                                    fontSize: "11px",
                                    fontWeight: "400",
                                    color: "#666",
                                  }}
                                >
                                  {e?.isEmailSend && " Email sent"}
                                </span>
                              )}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "none",
                              gap: "10px",
                              alignItems: "center",
                              background: "red",
                            }}
                          >
                            {" "}
                            <div
                              className="statusStyle"
                              style={{ background: "blue" }}
                            >
                              {" "}
                              {!e?.isComment ? (
                                leadstatus[valueToLeadStatus[e?.callLeadStatus]]
                                  ?.label
                              ) : (
                                <>
                                  {/* <img src={CommentIcon} /> */}
                                  <span>Comment</span>
                                </>
                              )}{" "}
                            </div>
                            {e?.isEmailSend && (
                              <div className="email_status_style">
                                {e?.isEmailSend && " Email sent"}{" "}
                              </div>
                            )}
                            <div
                              className="nameStyle"
                              style={{ background: "green" }}
                            >
                              {
                                teamMembers?.find(
                                  (item) => item.memberId == e?.senderId
                                )?.memberName
                              }{" "}
                              <span>
                                {" "}
                                {e?.createdDate && formateDate(e?.createdDate)}
                              </span>
                            </div>
                          </div>
                          {/* <div className="">{e?.text} </div> */}
                        </div>
                      )
                    );
                  })
                  .reverse()}
              </div>
            </>
          )}

          <PiAlert />
        </div>
     
      </div>
      <div style={{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        position:"sticky",
        marginTop:"-13%"
        // background:"red"

      }}
      
      >

        <button  style={{
          padding:"10px",
          // borderRadius:"10px",
          width:"97%",
          boxShadow:"none",
          border:"none",
          borderRadius: "8px",
            background: isUpdateHappened ? "#0084EF" : "#D6D6D6",
            color: isUpdateHappened ? "white" : "#8E8E8E",
        }}


        

        onClick={() => {
          // if (commentText.length > 0 && !commentError) {
          //   addComment();
          // } else {
          setShowUpdateBadge(true);
          handleSubmit();
          // }
        }}
        >    
          {isnewlead ? "Submit" : "Update"}</button>

      </div>

      {/* <div className="d-flex w-100 justify-content-center  bottom-0 end-0 pe-md-0 pb-md-0">
        <button
          className="   btn-primary bg-base-blue box-shadow-none py-3 py-md-2 mv-pfs-14"
          onClick={() => {
            // if (commentText.length > 0 && !commentError) {
            //   addComment();
            // } else {
            setShowUpdateBadge(true);
            handleSubmit();
            // }
          }}
          disabled={!isUpdateHappened || buttonClickedOnce}
          style={{
            borderRadius: "8px",
            background: isUpdateHappened ? "#0084EF" : "#D6D6D6",
            color: isUpdateHappened ? "white" : "#8E8E8E",
            opacity: 1,
            marginTop: !viewremark ? "7rem" : "-2.7rem",
            position: "absolute",
            // marginBottom: "1.2rem",
            width:" 96%",
             margin:" 0px auto",
          }}
        >
          {isnewlead ? "Submit" : "Update"}
        </button>
      </div> */}
    </>
  );
};

export default LeadUpdate;
