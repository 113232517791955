import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextField, useMediaQuery } from "@mui/material";
import styles from "./getOtp.module.css";
import { getReq, postReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import { useDispatch } from "react-redux";
import { saveOtpMail, saveUserType } from "../../Redux/Slices/persistSlice";
import LoginHeader from "../loginHeader/LoginHeader";
import { useState, useEffect } from "react";
import { constants, getOtpVerifyObj } from "../../piservices/constants";
import { validationFunction } from "../../piservices/commonService";

const GetOtp = () => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { userId, type } = useParams();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleInput = (event) => {
    // const valText = validationFunction(getOtpVerifyObj.email, event.target.value);
    // setError(valText);
    setEmail(event.target.value.toLowerCase());
  };

  const getOtp = async () => {
    const res = await postReq(`${constants.emailApi}${apiEndpoints.sendOtp}`, {
      email: email,
    });
    if (res && !res.error) {
      dispatch(saveOtpMail(email));
      if (type) {
        dispatch(saveUserType(type));
      } else {
        dispatch(saveUserType("user"));
      }

      navigateTo("/verify-user");
    } else {
      console.log(res.error.response.data);
      if (email.length === 0) {
        setError("Field is Required");
      } else {
        setError(res.error.response.data);
      }
    }
  };

  useEffect(() => {
    if (userId) {
      const getUserData = async () => {
        const res = await getReq(`${constants.CRM_API}/user/getUserById?userId=${userId}`);
        if (res && !res.error) {
          setEmail(res.data.data.email);
        } else {
          console.error(res.error);
        }
      };
      getUserData();
    }
  }, [userId]);

  return (
    <>
      <LoginHeader />
      <div className={styles.container}>
        <div className={styles.box}>
          {/* <div style={{ padding: "24px", borderBottom: "1px solid #E0E3E6", color: "#40454A", fontFamily: "Inter", fontSize: "28px", fontStyle: "normal", fontWeight: 700, lineHeight: "28px" }}> */}
           <div className={styles.verification} >
            {/* Verification */}
            Register Account
          </div>
          {/* <div style={{ padding: "24px 24px 28px 24px", display: "flex", flexDirection: "column", alignItems: "center", gap: "1.5rem" }}> */}
            <div className={styles.mainContainer}>
            {/* <div style={{ color: "#999", fontFamily: "Inter", fontSize: "16px", fontStyle: "normal", fontWeight: 500, lineHeight: "28px",width:"100%" }}> */}
           <div className={styles.emailContainer}>
            We will send you a One Time Password on your email</div>
            <div style={{ borderRadius: "12px", width: "100%" }}>
              <TextField error={error} fullWidth id="outlined-basic" label="Email" variant="outlined" value={email} InputProps={{ style: { borderRadius: "12px", } }} inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px"}}} onChange={handleInput} />
              <div className="error">{error}</div>
              {/* {checkBtnClick? <div className="error">{apiError}</div> :""} */}
            </div>

            <div style={{ width: "100%" }}>
              <button
                className={styles.getotpBtn}
                style={{ color: "#ffffff", cursor: "pointer" }}
                onClick={() => {
                  getOtp();
                }}
              >
                Get OTP
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetOtp;
