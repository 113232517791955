import { getReq, postReq } from "../piservices/apis";
import { getUserId } from "./authService";
import { apiEndpoints } from "./enums";
import { saveDesignationArr, toasterController } from "../Redux/Slices/onboardingSlice";
import store from "../Redux/store";
import { constants } from "./constants";

export const validationFunction = (field, value) => {
  if (typeof value === "string") {
    if (field?.required && value.trim() === "") {
      return "Field is required";
    }
  } else {
    if (field?.required && !value) {
      return "Field is required";
    }
  }

  if (field?.minLength && value.length < field?.minLength) {
    return `Minimum length is ${field.minLength}`;
  }
  if (field?.maxLength && value?.length > field?.maxLength) {
    return `Maximum length is ${field?.maxLength}`;
  }
  if (field?.pattern && !field.pattern.test(value)) {
    return `You have entered Invalid ${field.label}`;
  }

  return "";
};

export const errorChecker = (objErrState, arrErrState) => {
  if (arrErrState) {
    if (
      arrErrState.some((curElem) => {
        return Object.keys(curElem).length > 0;
      })
    ) {
      let check = arrErrState.some((curElem) => {
        return Object.values(curElem).some((cur) => {
          return cur;
        });
      });
      return check;
    } else {
      return true;
    }
  }
  if (objErrState) {
    if (Object.keys(objErrState).length > 0) {
      let check = Object.values(objErrState).some((cur) => {
        return cur;
      });
      return check;
    } else {
      return true;
    }
  }
};

export const multipleValidationFunction = (field, value) => {
  let multipleVal = ["empty", "minlen", "maxlen", "noupper", "nospecial"];
  if (field.required && value.trim() === "") {
    let x = multipleVal;
    x = [...x, "empty"];
    multipleVal = x;
  } else {
    let x = multipleVal.filter((curElem) => {
      return curElem !== "empty";
    });
    multipleVal = x;
  }

  if (field.minLength && value.length < field.minLength) {
    let x = multipleVal;
    x = [...x, "minlen"];
    multipleVal = x;
  } else {
    let x = multipleVal.filter((curElem) => {
      return curElem !== "minlen";
    });
    multipleVal = x;
  }

  if (field.maxLength && value.length > field.maxLength) {
    let x = multipleVal;
    x = [...x, "maxlen"];
    multipleVal = x;
  } else {
    let x = multipleVal.filter((curElem) => {
      return curElem !== "maxlen";
    });
    multipleVal = x;
  }

  if (field.upperCasePattern && !field.upperCasePattern.test(value)) {
    let x = multipleVal;
    x = [...x, "noupper"];
    multipleVal = x;
  } else {
    let x = multipleVal.filter((curElem) => {
      return curElem !== "noupper";
    });
    multipleVal = x;
  }

  if (field.specialCharacterPattern && !field.specialCharacterPattern.test(value)) {
    let x = multipleVal;
    x = [...x, "nospecial"];
    multipleVal = x;
  } else {
    let x = multipleVal.filter((curElem) => {
      return curElem !== "nospecial";
    });
    multipleVal = x;
  }
  return multipleVal;
};

export const toasterControl = (toasterState, toasterMessage = "", toasterVariant) => {
  store.dispatch(toasterController({ state: toasterState, message: toasterMessage, variant: toasterVariant }));
};

export const getDesignation = async () => {
  const res = await getReq(`${constants.CRM_API}${apiEndpoints.getDesignation}?userId=${getUserId()}`);
  if (res && !res.error) {
    store.dispatch(saveDesignationArr(res.data.data));
  } else {
    console.log(res.error);
  }
};

export const createDesignation = async (desname) => {
  const res = await postReq(`${constants.CRM_API}${apiEndpoints.createDesignation}`, {
    userId: getUserId(),
    designationName: desname,
  });
  if (res && !res.error) {
    getDesignation();
    return res.data.data;
  } else {
    console.log(res.error);
  }
};

export const checkIfHasOnlyNumbers = (value) => {
  if (!isNaN(value) || value === "") {
    return true;
  } else {
    return false;
  }
};
