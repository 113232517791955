import React from "react";
import styles from "./Cards.module.css";
import files from "../asset/files.png";
import accounts from "../asset/accounts.png";
import Dpr from "../asset/Dpr.png";
import mom from "../asset/mom.png";
import po from "../asset/po.png";
import quotation from "../asset/tasks.png";
import timeline from "../asset/timeline.png";
import tasks from "../asset/tasks.png";
import comments from "../asset/comments.png";
import { CgProfile } from "react-icons/cg";
import axios from "axios";
import leads from "../asset/leads.svg";
import attendance from "../asset/attendance.svg";
import { constants } from "../../piservices/constants";

const Cards = ({ notification,setShowNotifications,unread }) => {

  
  function getTimeFormat(date) {
    const currentDate = new Date();
    const timeDifference = date ? new Date(currentDate) - new Date(date) : null;

    let result = "";

    if (timeDifference !== null) {
      const minutesDifference = Math.floor(timeDifference / (60 * 1000));
      const hoursDifference = Math.floor(timeDifference / (60 * 60 * 1000));
      const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
      if (minutesDifference < 60) {
        return (result = `${minutesDifference} min${
          minutesDifference === 1 ? "" : "s"
        }`);
      } else if (hoursDifference < 24) {
        return (result = `${hoursDifference} hr${
          hoursDifference === 1 ? "" : "s"
        }`);
      } else if (hoursDifference > 24 && hoursDifference < 48) {
        return (result = `yesterday`);
      } else {
        if (date === undefined) {
          return "-";
        } else if (typeof date === "string") {
          date = new Date(date);
        }

        let day = date.getDate();
        let month = date.toLocaleDateString("default", { month: "short" });
        let year = date.getFullYear() % 100;
        let ans = "";
        ans += day + "-";
        ans += month + "-";
        ans += year;
        return ans;
      }
    }
  }

  let readAll = {
    ids: "",
    userId: "",
  };
  const getTime = (date) => {
    if (date === undefined) {
      return "-";
    } else if (typeof date === "string") {
      date = new Date(date);
    }

    let day = date.getDate();
    let month = date.toLocaleDateString("default", { month: "short" });
    let year = date.getFullYear() % 100;
    let ans = "";
    ans += day + "-";
    ans += month + "-";
    ans += year;
    return ans;
  };

  const readBy = async () => {
    readAll.ids = notification._id;
    readAll.userId = localStorage.getItem("userId");
    return await axios
      .post(
        `${constants.NOTIFY_API}/api/notifications/add-readedBy`,
        readAll
      )
      .then((res) => {
        // setReadArr([]);
        // window.open(`${notification.detailedLink}`);
        // window.location.reload();

        // notification.featureName === "Quotation"
        //   ? window.open(`${notification.detailedLink}`)
        //   : window.open(`${notification.featureLink}`);

        notification.featureName === "Quotation"
            ? window.open(`${notification.detailedLink}`)
            : notification.featureName === "Mom"
            ? window.open(`${notification.detailedLink}`)
            : notification?.featureLink && window.open(notification?.featureLink) 
      })
      .catch((err) => console.error(err));
  };

  let changebackgroundColor = notification.readedBy.some((elem) => {
    if (elem === localStorage.getItem("userId")) {
      return true;
    } else {
      return false;
    }
  });


  return (
    <div
      className={`${styles.notify_card}  ${
        changebackgroundColor === false ? `${styles.active}` : ""
      }`}
      onClick={() => {
       
        readBy();
        unread()
        localStorage.setItem("projectId",notification?.projectId)
      }}
    >
      <div className={styles.cardwrapper}>
        <div className={styles.img}>
          <div className={styles.img_wrap}>
            {notification.featureName === "file manager" ? (
              <img className={styles.img1} src={files} />
            ) : notification.featureName === "Accounts" ? (
              <img className={styles.img1} src={accounts} alt="" />
            ) : notification.featureName === "MOM" ? (
              <img className={styles.img1} src={mom} alt="" />
            ) : notification.featureName === "Dpr" ? (
              <img className={styles.img1} src={Dpr} alt="" />
            ) : notification.featureName === "projects" ? (
              <img className={styles.img1} src={po} alt="" />
            ) : notification.featureName === "task" ? (
              <img className={styles.img1} src={tasks} alt="" />
            ) : notification.featureName === "timeline" ? (
              <img className={styles.img1} src={timeline} alt="" />
            ) : notification.featureName === "comments" ? (
              <img className={styles.img1} src={comments} alt="" />
            ) : notification.featureName === "Quotation" ? (
              <img className={styles.img1} src={quotation} alt="" />
            ) : notification.featureName === "Leads" ? (
              <img className={styles.img1} src={leads} alt="" />
            ) : (
              <CgProfile
                className={styles.img1}
                style={{
                  color: "white",
                }}
              />
            )}
          </div>
        </div>
        <div className={styles.textfee}>
          <div className={`${styles.notificaition_headingfee}`}>
            {notification.notification}
          </div>
          <div className={`${styles.detailsfee}`}>
            <span className={`${styles.notification_detailfee}`}>
            {notification.projectName && notification.projectName}
            </span>
            <span className={styles.notification_detail2fee}>
              {getTimeFormat(notification.createdAt)}
            </span>
          </div>
        </div>
      </div>
    </div>
  
  );
};

export default Cards;
