import React, { useEffect, useState } from "react";
import styles from "./companyDetails.module.css";
import { TextField, useMediaQuery, FormControl, InputLabel, MenuItem, InputAdornment, Autocomplete } from "@mui/material";
import { Carousel } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { getReq, postReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import idesign from "../../Assets/logo.png";
import expf, { constants, emailValidation, numberArray } from "../../piservices/constants";
import { validationFunction } from "../../piservices/commonService";
import MuiPhoneNumber from "material-ui-phone-number";
import { getToken } from "../../piservices/authService";
import Map from "./Map";
import { MdMyLocation } from "react-icons/md";
import { useSelector } from "react-redux";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import { countryFlags } from "../MockDataCountries";
import Select from "react-select";
import { useLocation } from 'react-router-dom';

const CompanyDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryString=window.location.search;
  const hasQueryParams = queryString !== '';
  const navigateTo = useNavigate();
  if(hasQueryParams){
 localStorage.setItem("token",  queryParams.get('token'));
 localStorage.setItem("userId", queryParams.get('userId'))
}

useEffect(()=>{
  const url= window.location.href;
  const urlParts = url.split('/');
const lastSegment = urlParts[urlParts.length - 1];
localStorage.setItem("currentPageUrl", lastSegment)
},[])

  const [countryDropDownInput, setCountryDropDownInput] = useState('');
  const [phoneNumberErr, setPhoneNumberErr] = useState('');
  const [countryDialCode, setCountryDialCode] = useState("")
  const [valueFromChild, setValueFromChild] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [countries, setCountries] = useState([]);
  const [currency, setCurrency] = useState("");
  const [particularCountryData, setParticularCountryData] = useState({});
  const [cityForMap, setCityForMap] = useState("Delhi")
  const [phoneCode, setPhoneCode] = useState("us");

  const isMobile = useMediaQuery('(max-width:767px)');
  const { mapLocation } = useSelector((state) => state.onboarding);

  const [profileData, setProfileData] = useState({});

  const getProfileData = async () => {
    const res = await getReq(`${constants.CRM_API}/user/profile`, {}, { Authorization: `Bearer ${getToken()}` });
    if (res && !res.error) {
      setProfileData({ ...res.data.data });
    } else {
      localStorage.clear();
      window.location.assign(`${constants.BASE_URL}`);
    }
  };

  const handleOnboarding = async () => {
    const res = await axios
      .post(
        `${constants.CRM_API
        }/user/edit-user-details?userId=${localStorage.getItem("userId")}`,
        {
          isOnboardingDone: true,
        }
      )
      .then((res) => {
        localStorage.removeItem("emailId")
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(()=>{
    localStorage.setItem("currentPageURL", location.pathname)
    },[])

  // console.log("profileData", profileData)
  // console.log(profileData.CompanySettingDetails.companyLocation[0].location);

  // useEffect(() => {
  //   if (profileData && mapLocation) {
  //     let x = profileData;
  //     x.CompanySettingDetails.companyLocation[0].location = mapLocation;
  //     setProfileData(x);
  //   }
  // }, [profileData, mapLocation]);


  const [input, setinput] = useState({
    companyName: "",
    email: "",
    address: "",
    country: "",
    city: "",
    pincode: "",
    gstNo: "",
    number: "",
    countryCode: "",
    currency: "",
    location: "",
  });

  useEffect(() => {
    if(profileData?.CompanySettingDetails?.countryCode){
      if (profileData?.CompanySettingDetails?.countryCode !== "") {
        setCountryDialCode(profileData?.CompanySettingDetails?.countryCode)
      } else if (
        profileData?.CompanySettingDetails?.countryCode == ""
      ) {
        setCountryDialCode("+91")
      }
    }
  else{
      setCountryDialCode("+91")
    }

    if (profileData) {
      if (profileData?.CompanySettingDetails && Object.keys(profileData.CompanySettingDetails).length > 0) {
        if (profileData?.CompanySettingDetails?.companyName) {
          localStorage.setItem("NameCompany", profileData?.CompanySettingDetails?.companyName)
          setinput((prev) => {
            return {
              ...prev,
              companyName: profileData?.CompanySettingDetails?.companyName,
            };
          });
        }
        if (profileData?.CompanySettingDetails?.email) {
          localStorage.setItem("CompanyEmail", profileData?.CompanySettingDetails?.email)
          
          setinput((prev) => {
            return {
              ...prev,
              email: profileData?.CompanySettingDetails?.email,
            };
          });
        }
        if (profileData?.CompanySettingDetails?.country) {
          localStorage.setItem("NameCountry", profileData?.CompanySettingDetails?.country)
          setinput((prev) => {
            return {
              ...prev,
              country: profileData?.CompanySettingDetails?.country,
            };
          });
        }
        // fetchData(countryFromDropdown?.value?.countrycode)

        // if(profileData?.CompanySettingDetails?.zipCode){
        //   setinput((prev) => {
        //     return {
        //       ...prev,
        //       zipCode: profileData?.CompanySettingDetails?.zipCode ,
        //     };
        //   });
        // }
        if (profileData?.CompanySettingDetails?.pincode) {
          localStorage.setItem("CompanyPincode", profileData?.CompanySettingDetails?.pincode)
          
          setinput((prev) => {
            return {
              ...prev,
              pincode: profileData?.CompanySettingDetails?.pincode,
            };
          });
        }
        if (profileData?.CompanySettingDetails?.gstNo) {
          localStorage.setItem("CompanyGST", profileData?.CompanySettingDetails?.gstNo)
          setinput((prev) => {
            return {
              ...prev,
              gstNo: profileData?.CompanySettingDetails?.gstNo,
            };
          });
        }
        if (profileData?.CompanySettingDetails?.city) {   
          localStorage.setItem("CompanyCity", profileData?.CompanySettingDetails?.city)

          setinput((prev) => {
            return {
              ...prev,
              city: profileData?.CompanySettingDetails?.city,
            };
          });
        }
        if (profileData?.CompanySettingDetails?.address) {
          localStorage.setItem("CompanyAddress", profileData?.CompanySettingDetails?.address)

          setinput((prev) => {
            return {
              ...prev,
              address: profileData?.CompanySettingDetails?.address,
            };
          });
        }
        if (profileData?.CompanySettingDetails?.companyLocation[0]?.location) {
          setinput((prev) => {
            return {
              ...prev,
              location: profileData?.CompanySettingDetails?.companyLocation[0]?.location,
            };
          });
        }
        if (profileData?.CompanySettingDetails?.contact) {
          localStorage.setItem("CompanyNumber", profileData?.CompanySettingDetails?.contact)

          setinput((prev) => {
            return {
              ...prev,
              number: profileData?.CompanySettingDetails?.contact,
            };
          });
        }
        if (profileData?.CompanySettingDetails?.longitude) {
          localStorage.setItem("longitude", profileData?.CompanySettingDetails?.longitude)
        }
        if (profileData?.CompanySettingDetails?.latitude) {
          localStorage.setItem("latitude", profileData?.CompanySettingDetails?.latitude)
        }
        if (profileData?.CompanySettingDetails?.location) {
          localStorage.setItem("location", profileData?.CompanySettingDetails?.location)
        }
      }
    }

  }, [profileData])
  const [errormsg, setErrorMsg] = useState("");

  const [error, seterror] = useState({
    companyName: false,
    email: false,
    address: false,
    city: false,
    pincode: false,
    gstNo: false,
    number: false,
    currency: false,
    location: false,
  });



  const [errors, setErrors] = useState({
    email: "",
  });
  const handleInput = (event) => {

    const { name, value } = event.target;
    const alphaValue = value.replace(/[^a-zA-Z]/g, '');
    const alphaValueOne = value.replace(/[^a-zA-Z\s]/g, '');

    const numValue = value.replace(/[^0-9]/g, "");

    if (name === "pincode") {
      if (input.country == "India") {
        if (value.length < 7) {
          setinput((prev) => {
            return {
              ...prev,
              pincode: numValue,
            };
          });
          seterror((prev) => {
            return {
              ...prev,
              pincode: false,
            };
          });
        }
      }
      else {
        if (value.length < 10) {
          setinput((prev) => {
            return {
              ...prev,
              pincode: numValue,
            };
          });
          seterror((prev) => {
            return {
              ...prev,
              pincode: false,
            };
          });
        }
      }
    }

    if (name === "gst") {
      const inputValue = value.toUpperCase();
      const alphaNumericRegex = /^[a-zA-Z0-9]*$/
      if (inputValue.length < 17 && alphaNumericRegex.test(inputValue)) {
        setinput((prev) => {
          return {
            ...prev,
            gstNo: inputValue,
          };
        });
      }
      seterror((prev) => {
        return {
          ...prev,
          gstNo: false,
        };
      });
    }

    if (name === "companyName") {
      if (value.length < 33) {
        setinput((prev) => {
          return {
            ...prev,
            companyName: value.charAt(0).toUpperCase() + value.slice(1),
          };
        });
      }
      seterror((prev) => {
        return {
          ...prev,
          companyName: false,
        };
      });
    }
    if (name === "address") {
      const words = value.trim().split(/\s+/);
      if (words.length <= 24) {
        setinput((prev) => {
          return {
            ...prev,
            address: value.charAt(0).toUpperCase() + value.slice(1),
          };
        });
      }
      // if (value.length < 50) {
      //   setinput((prev) => {
      //     return {
      //       ...prev,
      //       address: value.charAt(0).toUpperCase() + value.slice(1),
      //     };
      //   });
      // }
      seterror((prev) => {
        return {
          ...prev,
          address: false,
        };
      });
    }
    if (name === "country") {
      if (value.length < 50) {
        setinput((prev) => {
          return {
            ...prev,
            country: value.charAt(0).toUpperCase() + value.slice(1),
          };
        });
      }
      seterror((prev) => {
        return {
          ...prev,
          country: false,
        };
      });
    }

    if (name === "currency") {
      if (value.length < 4) {
        setinput((prev) => {
          return {
            ...prev,
            currency: value.toUpperCase(),
          };
        });
      }
      seterror((prev) => {
        return {
          ...prev,
          currency: false,
        };
      });
    }

    if (name === "city") {
      // let check = numberArray.some((elem) => {
      //   return value.includes(elem);
      // });
      // if (!check) {
      //   if (value.length < 33) {
      //     setinput((prev) => {
      //       return {
      //         ...prev,
      //         // city: value,
      //         city: value.charAt(0).toUpperCase() + value.slice(1),
      //       };
      //     });
      //   }
        if (value.length < 33) {
          setinput((prev) => {
            return {
              ...prev,
              // city: value,
              city: alphaValue.charAt(0).toUpperCase() + alphaValue.slice(1),
            };
          });
      }
      seterror((prev) => {
        return {
          ...prev,
          city: false,
        };
      });
    }

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,100}$/;

    if (name === "email") {
      let x = emailRegex.test(value);
      if (!x) {
        setErrorMsg("Invalid Email");
      } else {
        setErrorMsg("");
      }
      setinput((prev) => {
        return {
          ...prev,
          email: value,
        };
      });
      seterror((prev) => {
        return {
          ...prev,
          email: false,
        };
      });
    }
  };
  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    const { country } = input;
    // if (inputValue.length <= (country === "India" ? 10 : 15)) {

    if (inputValue.length <=  15) {
      setinput((prev) => {
        return {
          ...prev,
          number: inputValue,
        };
      });
      if (/^\d+$/.test(inputValue)) {

        // if ((input.country === "India" ? input.number.length !== 10 : countryDialCode?.substring(1) === "91") ||
        //   (input.country !== "India" ? input.number.length !== 5 : countryDialCode?.substring(1) !== "91")) {
          if (input.number.length <5  ) {
          setPhoneNumberErr('yes');
          seterror((prev) => {
            return {
              ...prev,
              number: true,
            };
          });
        } else {
          setPhoneNumberErr('');
          seterror((prev) => {
            return {
              ...prev,
              number: true,
            };
          });
        }
        setinput((prev) => {
          return {
            ...prev,
            number: inputValue,
          };
        });
      }
      if (input.number.length>=4){
        seterror((prev) => {
          return {
            ...prev,
            number: false,
          };
        });
      }
    }
  };

  //  useEffect(() => {
  //     const fetchCountries = async () => {
  //       const countryData = await Country.getAllCountries();
  //       setCountries(countryData);
  //       setSelectedCountry(localStorage.getItem("countryCode"));
  //       getParticularCountryData(localStorage.getItem("countryCode"));
  //       fetchData(localStorage.getItem("countryCode"));
  //       // const initialCountryIsoCode = "IN"; 
  //       // setSelectedCountry(initialCountryIsoCode);
  //     };
  //     fetchCountries();
  //     setinput((prev) => {
  //       return {
  //         ...prev,
  //         city: localStorage.getItem("cityName") == "undefined" ? "" :  localStorage.getItem("cityName") ,
  //         pincode: localStorage.getItem("pincodeValue") ? localStorage.getItem("pincodeValue") : "",
  //       };
  //     });
  //   }, []);

  useEffect(() => {
    localStorage.setItem("city", cityForMap)
  }, [])

// const [CountryDataFromAPI, setCountryDataFromAPI ] = useState([])
// const getCountriesDataFromAPI = async()=>{
//   const url=`${constants.CRM_API}/user/get-countries-data`;
//   try{
//     let res=await axios.get(url);
//     if(res){
//       setCountryDataFromAPI(res?.data)
//     }
//   }
//   catch(err){
//     console.log(err)
//   }
// };
// useEffect(()=>{
//   getCountriesDataFromAPI()
// },[])

  const getParticularCountryData = async (countryCode) => {
    const data = await Country.getCountryByCode(countryCode);
    setinput((prev) => {
      return {
        ...prev,
        country: data?.name,
      };
    });
    setCurrency(data.currency)
    // setPhoneCode(data?.isoCode.toLowerCase())
    const isoCode1 = data?.isoCode ? data.isoCode?.toLowerCase() : "in";
    setPhoneCode(isoCode1);
    setCountryDialCode(data?.phonecode)
    setParticularCountryData(data);
    // fetchData(countryCode);
  };
  // const [countryFromDropdown, setCountryFromDropdown] = useState({
  //   value: {
  //     country: "Country*",
  //     code: "",
  //     countrycode: '',
  //     currency: "",
  //   }
  // });

  const [countryFromDropdown, setCountryFromDropdown] = useState({
    value: {
      country: "",
      code: "",
      countrycode: '',
      currency: "",
      flagUrl:""
    }
  });

  const handleSetCountryFromDropdown = (countryName) => {
    const countryEntry = Object.entries(countryFlags).find(([country, _]) => country?.toLowerCase() === countryName?.toLowerCase());
    if (countryEntry) {
      const [_, { code, countrycode, currency }] = countryEntry;
      setCountryFromDropdown(prevState => ({
        ...prevState,
        value: {
          ...prevState.value,
          country: countryEntry[0],
          code: countryEntry[1]?.code,
          countrycode: countryEntry[1]?.countrycode,
          currency: countryEntry[1]?.currency,
          flagUrl:countryEntry[1]?.flagUrl,
          symbol:countryEntry[1]?.symbol
        }
      }));
      setCountryDialCode(countryEntry[1].code)
            handleCountryDropdown({
              value: {
                country: countryEntry[0],
                code:  countryEntry[1]?.code,
                countrycode:  countryEntry[1]?.countrycode,
                currency: countryEntry[1]?.currency,
                flagUrl:countryEntry[1]?.flagUrl,
                symbol:countryEntry[1]?.symbol
              }
            })

    } else {
      // Handle case where country name is not found
      console.log(`Country '${countryName}' not found`);
    }
  };

  useEffect(() => {
    if(localStorage.getItem("NameCountry")){
      handleSetCountryFromDropdown(localStorage.getItem("NameCountry"))
    }else{
      handleSetCountryFromDropdown("India")
    }
  }, [])

  const handleCountryDropdown = (selectedOption) => {
    localStorage.setItem("NameCountry",selectedOption?.value?.country )
    setShowContent(true)
    setCountryFromDropdown(selectedOption)
    setCountryDialCode(selectedOption?.value?.code)
    setCurrency(selectedOption?.value?.currency)
    setCountryFlag(selectedOption?.value?.flagUrl)
    setCurrencySymbol(selectedOption?.value?.symbol)
    setinput((prev) => {
      return {
        ...prev,
        country: selectedOption?.value?.country,
      };
    });
    // fetchData(selectedOption?.value?.countrycode)
  }

  useEffect(() => {
    setinput((prev) => {
      return {
        ...prev,
        city: localStorage.getItem("cityName") == "undefined" ? "" : localStorage.getItem("cityName"),
        pincode: localStorage.getItem("pincodeValue") ? localStorage.getItem("pincodeValue") : "",
      };
    });
  }, [])

  const handleCountryChange = async (countryCode) => {
    setSelectedCountry(countryCode);
    localStorage.setItem("countryCode", countryCode);
  };
  const [countryDataApi, setCountryDataApi] = useState(null);
  const [countryFlag, setCountryFlag] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");

  const fetchData = async (code) => {
    try {
      const response = await axios.get(
        `https://restcountries.com/v3.1/alpha/${code}`
      );
      setCountryDataApi(response.data);
      setCountryFlag(response.data[0].flags.png)
      setCurrencySymbol(Object.values(response.data[0].currencies)[0].symbol)
    } catch (error) {
      console.error("Error fetching country information:", error.message);
    }
  };

  // useEffect(()=>{
  //   if(localStorage.getItem("countryCode")){
  //     fetchData(localStorage.getItem("countryCode"))
  //     getParticularCountryData(localStorage.getItem("countryCode"))
  //   }
  // },[localStorage.getItem("countryCode")])

  // company details
  const companyDetails = async () => {
    let payload = JSON.parse(JSON.stringify(profileData.CompanySettingDetails));
    // let companyLocationObj = {
    //   ...payload.companyLocation[0],

    //   companyAddress: payload.address,
    //   country: payload.country,
    //   currency: payload.currency,
    //   pincode: payload.pincode,
    //   city: payload.city,
    //   gstNo: payload.gstNo,
    //   location: payload.companyLocation[0]?.location,
    // };

    let Obj = {
      // address: payload.address,
      // email: payload.email,
      // city: payload.city,
      // location: payload.location,
      // pincode: payload.pincode,
      // gstNo: payload.gstNo,
      // contact: payload.contact,
      createdBy: payload.createdBy,
      fileManagerV2: payload.fileManagerV2,
      timeLineV2: payload.timeLineV2,
      companyLogo: payload.companyLogo,
      companyName: input.companyName,
      about: payload.about,
      address: input.address,
      email: input.email,
      city: input.city,
      currency:countryFromDropdown.value.currency,
      currencySymbol: currencySymbol,
      pincode: input.pincode,
      gstNo: input.gstNo,
      contact: input.number,
      latitude: localStorage.getItem("latitude"),
      longitude: localStorage.getItem("longitude"),
      location: input.location,
      country: input.country,
      countryCode: countryDialCode ?(countryDialCode?.startsWith("+") ? countryDialCode : "+" + countryDialCode) : "+91",
      companyAddress: input.address,
      companyLocation:[],
      // companyLocation: [
      //   {
          // latitude: localStorage.getItem("latitude"),
          // longitude: localStorage.getItem("longitude"),
          // country: input.country,
          // currency: currencySymbol,
          // location: input.location,
          // countryCode: countryDialCode?.startsWith("+") ? countryDialCode : "+" + countryDialCode,
          // gstNo: input.gstNo,
          // zipCode: input.pincode,
          // companyAddress: input.address,
          // city: input.city,

          // locationName:"Company Location",
          // countryCode:input.countryCode
          // locationName: payload?.companyLocation[0]?.locationName,
          // location: payload?.companyLocation[0]?.location,
          // companyAddress: payload?.companyLocation[0]?.companyAddress,
          // country: payload?.companyLocation[0]?.country,
          // countryCode: payload?.companyLocation[0]?.countryCode,
          // currency: payload?.companyLocation[0]?.currency,
          // pincode: payload?.companyLocation[0]?.pincode,
          // city: payload?.companyLocation[0]?.city,
          // zipCode: payload?.companyLocation[0]?.zipCode,
          // taxNo: payload?.companyLocation[0]?.taxNo,
          // gstNo: payload?.companyLocation[0]?.gstNo,
          // billingAddress: payload?.companyLocation[0]?.billingAddress,
          // billingCity: payload?.companyLocation[0]?.billingCity,
          // billingPincode: payload?.companyLocation[0]?.billingPincode,
          // createdBy: payload?.companyLocation[0]?.createdBy,
          // createdDate: payload?.companyLocation[0]?.createdDate,
      //   },
      // ],
      attendanceRadius: payload.attendanceRadius,
      workTiming: payload.workTiming,
    };

    // payload["companyLocation"] = [companyLocationObj];
    // delete payload.holidays;

    const res = await postReq(`${constants.CRM_API}/api/enterprise/edit-company-settings?userId=${localStorage.getItem("userId")}`, Obj);
    if (res && !res.error) {
      // setinput({
      //   companyName: "",
      //   email: "",
      //   address: "",
      //   city: "",
      //   pincode: "",
      //   gst: "",
      //   number: "",
      // });
      localStorage.removeItem("city")
      // navigateTo("/team-onboard");
      navigateTo("/personal-data");
    } else {
      console.error(res.error);
    }
  };

  const [openMap, setopenMap] = useState(false);
  const handleValueFromChild = (value) => {
    setValueFromChild(value);
  };

  useEffect(() => {
    if (valueFromChild.length > 0 ) {
      seterror((prev) => {
        return {
          ...prev,
          location: false,
        };
      });
      setinput((prev) => {
        return {
          ...prev,
          location: valueFromChild,
        };
      });
    }
  }, [valueFromChild])

  useEffect(() => {
    if (localStorage.getItem("location")) {
      setValueFromChild(localStorage.getItem("location"))
    }
    if(localStorage.getItem("location")==="null"){
      setValueFromChild("")
      localStorage.setItem("firstTimeMap", false)
    }
    if(localStorage.getItem("NameCompany")){
      setinput((prev) => {
        return {
          ...prev,
          companyName: localStorage.getItem("NameCompany"),
        };
      });
    }
    if(localStorage.getItem("CompanyEmail")){
      setinput((prev) => {
        return {
          ...prev,
          companyName: localStorage.getItem("CompanyEmail"),
        };
      });
    }
    if(localStorage.getItem("CompanyAddress")){
      setinput((prev) => {
        return {
          ...prev,
          companyName: localStorage.getItem("CompanyAddress"),
        };
      });
    }
    if(localStorage.getItem("CompanyCity")){
      setinput((prev) => {
        return {
          ...prev,
          companyName: localStorage.getItem("CompanyCity"),
        };
      });
    }
    if(localStorage.getItem("CompanyPincode")){
      setinput((prev) => {
        return {
          ...prev,
          companyName: localStorage.getItem("CompanyPincode"),
        };
      });
    }
    if(localStorage.getItem("CompanyGST")){
      setinput((prev) => {
        return {
          ...prev,
          companyName: localStorage.getItem("CompanyGST"),
        };
      });
    }
    if(localStorage.getItem("CompanyNumber")){
      setinput((prev) => {
        return {
          ...prev,
          companyName: localStorage.getItem("CompanyNumber"),
        };
      });
    }
  }, [])

  const defaultCountry = {
    label: countryFromDropdown?.value?.country,
    value: {
      country: countryFromDropdown?.value?.country,
      code: countryFromDropdown?.value?.code,
      countrycode: countryFromDropdown?.value?.countrycode,
      currency: countryFromDropdown?.value?.currency
    }
  };

  const [showContent, setShowContent] = useState(false);
  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('hasVisited');

    if (hasVisitedBefore) {
      setShowContent(true);
    } else {
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  const valueToShow = showContent ? `${countryFromDropdown.value.currency} (${currencySymbol})` : '';

  const handleInputChange = (inputValue, { action }) => {
    if (action === 'input-change') {
      setCountryDropDownInput(inputValue)
      console.log("Input value:", inputValue);
    }
  };

  return (
    <>
      <Map open={openMap} close={() => setopenMap(false)}
        //  placeLocation={profileData?.CompanySettingDetails?.location}
        placeLocation={localStorage.getItem("location")}
        sendValueToParent={handleValueFromChild} />
      <div className={styles.container}>
        <div className={styles.first_contain}>
          {/* <img src={idesign} alt="" style={{ height: "2rem", position: "absolute", top: "2rem", left: "4rem" }} /> */}
          <div className={styles.logoImgContainer}><img src={idesign} alt="" className={styles.logoImg} /></div>

          <Carousel className={styles.carouselMainContainer}
            nextIcon=""
            nextLabel=""
            prevIcon=""
            prevLabel=""
          >
            <Carousel.Item className={styles.CarouselItem}>
              <div className={styles.flexForCarousel}>
                <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                  <div className={styles.insideCaraouselItem}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "0.75rem" }}><img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group.svg" alt="" className={styles.miniImg} />
                      <div className={styles.heading}>FILE MANAGER</div></div>
                    <div className={styles.para}>Seamlessly upload files and glide through a structured file hierarchy with ease, ensuring your documents are always organised and accessible.</div>
                  </div>
                </div>
                <div className={styles.ImgDiv}>
                  <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Edit%20location%20%26%20description.png" alt="First slide" className={styles.imgg} />
                </div>
              </div>
              <div className={styles.dotsContainer}>
                <div className={styles.activeDots} ></div>
                <div className={styles.notActiveDots}  ></div>
                <div className={styles.notActiveDots}  ></div>
                <div className={styles.notActiveDots}  ></div>
              </div>
            </Carousel.Item>

            <Carousel.Item className={styles.CarouselItem}>
              <div className={styles.flexForCarousel}>
                <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                  <div className={styles.insideCaraouselItem}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "0.75rem" }}>
                      <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group1.svg" alt="" className={styles.miniImg} />
                      <div className={styles.heading}>TASK MANAGEMENT</div>
                    </div>
                    <div className={styles.para}>
                      Monitor team progress with Task Manager screen and effortlessly create, assign and update tasks. Also generate detailed reports for informed decision-making.
                    </div>
                  </div>
                </div>
                <div className={styles.ImgDiv}>
                  <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Table%20v2-1.png" alt="Second slide" className={styles.imgg} />
                </div>
              </div>
              <div className={styles.dotsContainer}>
                <div className={styles.notActiveDots}  ></div>
                <div className={styles.activeDots} ></div>
                <div className={styles.notActiveDots}  ></div>
                <div className={styles.notActiveDots}  ></div>
              </div>
            </Carousel.Item>
            <Carousel.Item className={styles.CarouselItem}>
              <div className={styles.flexForCarousel}>
                <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem" }}>
                  <div className={styles.insideCaraouselItem}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "0.75rem" }}>
                      <img
                        src="https://idesign-office.s3.ap-south-1.amazonaws.com/CurrencyDollarSimple.svg"
                        alt="" className={styles.miniImg}
                      />
                      <div className={styles.heading}>Quotation</div>
                    </div>
                    <div className={styles.para}>
                      Effortlessly create quotations from an item library. Compare and manage quotations efficiently. Streamline budget creation and purchase orders.
                    </div>
                  </div>
                </div>
                <div className={styles.ImgDiv}>
                  <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Create%20Quotation.png" alt="Third slide" className={styles.imgg} />
                </div>
              </div>
              <div className={styles.dotsContainer}>
                <div className={styles.notActiveDots}  ></div>
                <div className={styles.notActiveDots}  ></div>
                <div className={styles.activeDots} ></div>
                <div className={styles.notActiveDots}  ></div>
              </div>
            </Carousel.Item>

            <Carousel.Item className={styles.CarouselItem}>
              <div className={styles.flexForCarousel}>
                <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                  <div className={styles.insideCaraouselItem}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "0.75rem" }}>
                      <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/LineSegments.svg" alt="" className={styles.miniImg} />
                      <div className={styles.heading}>Timeline</div>
                    </div>
                    <div className={styles.para}>
                      Easily create and allocate hours with Timesheets. Track project progress with multiple views. Create detailed timelines with predecessors, subtasks, and checklists.
                    </div>
                  </div>
                </div>
                <div className={styles.ImgDiv}>
                  <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/For%20onb%201.png" alt="Third slide" className={styles.imgg} />
                </div>
              </div>
              <div className={styles.dotsContainer}>
                <div className={styles.notActiveDots}  ></div>
                <div className={styles.notActiveDots}  ></div>
                <div className={styles.notActiveDots}  ></div>
                <div className={styles.activeDots} ></div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* second div */}
        <div className={styles.second_contain}>
          <div className={styles.PersonalContent_div}>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            > */}
            <div className={styles.companyDetailsDiv} >
              <div className={styles.personal_head}>Company Details</div>
              {/* <button className={styles.backBtn} onClick={() => {
                // navigateTo("/personal-data")
                navigateTo("/company-details")
                }}>
                <AiOutlineArrowLeft className={styles.backArrow} />
                Go back
              </button> */}
              <div className={styles.skipShowHide}>
                <a href={`${constants.BASE_URL}/admin/`} className={styles.skip} style={{ textDecoration: "none" }}
                  onClick={() => handleOnboarding()}>
                  Skip
                </a></div>
            </div>

            {/* all inputs div */}
            <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <div style={{ display: "flex", gap: "1rem" }}>
                <TextField
                  id="outlined-basic"
                  error={error.companyName}
                  label="Company Name*"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="companyName"
                  // value={profileData?.CompanySettingDetails?.companyName}
                  value={input.companyName}
                  onChange={handleInput}
                  fullWidth
                  // disabled
                  InputProps={{ style: { borderRadius: "12px" } }}
                  inputProps={{ style: {  fontSize: isMobile ? "14px" : "18px" } }}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: "20px", }}>
                <TextField
                  id="outlined-basic"
                  error={error.email}
                  label="Company Email*"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="email"
                  // value={profileData?.CompanySettingDetails?.email}
                  value={input.email}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputProps={{ style: { borderRadius: "12px" }, inputMode: "text" }}
                  inputProps={{ style: {  fontSize: isMobile ? "14px" : "18px" } }}
                // inputProps={{  }}
                // disabled
                />
                {(errormsg.length > 0) ? <div style={{ color: 'red', fontSize: "10px", marginTop: "-16px" }}>{errormsg}</div> : ""}
              </div>
              <div style={{ marginTop: (errormsg.length > 0) ? "-10px" : "0px" }}>
                <TextField
                  error={error.address}
                  id="outlined-basic"
                  label="Permanent Address*"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="address"
                  // value={profileData?.CompanySettingDetails?.address}
                  value={input.address}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputProps={{ style: { borderRadius: "12px" } }}
                  inputProps={{ style: {  fontSize: isMobile ? "14px" : "18px" } }}
                // inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />
              </div>
              <div style={{ position: "relative" }}>
                <TextField
                  id="outlined-basic"
                  error={error.location}
                  label="Company location on Google Map*"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="location"
                  value={valueFromChild}
                  // value={profileData?.CompanySettingDetails?.companyLocation[0]?.location}
                  onChange={handleInput}
                  readOnly
                  onClick={() => setopenMap(true)}
                  // style={{ borderRadius: "12px", width: "50%", borderRight: "none" }}
                  sx={{ borderRadius: "12px", width: "100%", borderRight: "none", '@media (max-width: 600px)': { width: '100%', } }}
                  InputProps={{ style: { borderRadius: "12px", paddingRight: "1rem" }, inputMode: "text", readOnly: true, }}
                  inputProps={{ style: { fontSize: isMobile ? "14px" : "18px" } }}
                />

                <div style={{ position: "absolute", right: "0.5rem", top: "0.8rem" }}>
                  <MdMyLocation onClick={() => setopenMap(true)} style={{ color: "#0084EF", cursor: "pointer" }} />
                </div>
              </div>
              <div style={{ display: "flex", gap: "1rem", position: "relative" }}>
                <div style={{ width: "50%" }}>

                  {/* <FormControl sx={{borderRadius: "12px", width:"100%",'@media (max-width: 600px)': { width: '100%'} }} >
                          <InputLabel id="demo-simple-select-helper-label" >Country*</InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={selectedCountry} 
                            label="Country"
                             onChange={(e) => {
                                      handleCountryChange(e.target.value);
                                      getParticularCountryData(e.target.value);
                                    }}
                            sx={{borderRadius: "12px",'@media (max-width: 600px)': { width: '100%'} }}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 300, // Adjust this value as per your requirement
                                  // borderRadius: "12px"
                                },
                              },
                            }}
                          >
                                        {countries.map((country) => (
                                  <MenuItem key={country.isoCode} value={country.isoCode}>
                                    {country.name}
                                  </MenuItem>
                                     ))}
                          </Select>                        
                        </FormControl> */}

                  {/* <FormControl sx={{ borderRadius: "12px", width: "100%", '@media (max-width: 600px)': { width: '100%' } }}>
  <Autocomplete
    id="country-autocomplete"
    options={countries}
    autoHighlight
    getOptionLabel={(option) =>option.name}
    onChange={(e, newValue) => {
      handleCountryChange(newValue ? newValue.isoCode : ""); // Pass isoCode to handleCountryChange
      getParticularCountryData(newValue ? newValue.isoCode : ""); // Pass isoCode to getParticularCountryData
    }}
    renderInput={(params) => {
      console.log("params", params)
      if (params.InputProps.value === "") {
        params.InputProps.value = localStorage.getItem("NameCountry") || "";
      }
      return(
        <TextField
          {...params}
          label="Country*"
          error={error.country}
          InputLabelProps={{ ...params.InputLabelProps,
              shrink: true,}}
              InputProps={{
                ...params.InputProps,
                endAdornment: null, // Remove the endAdornment (clear button)
                sx: {
                  borderRadius: "12px", // Adjust the border radius here
                  '&.MuiOutlinedInput-root': {
                    borderRadius: "12px", // Adjust the border radius for the Autocomplete input
                  },
                },
              }}
        />
      )
    }}
    PaperProps={{
      style: {
        maxHeight: 300,
      },
    }}
  />
</FormControl> */}
                            <span
                              style={{
                                fontWeight: "500",
                                fontSize: "15px",
                                // color: "#252525",
                                marginBottom: "12px",
                                // position: "absolute",
                                zIndex: 2,
                                color:"rgba(0, 0, 0, 0.6)",
                              }}
                              className={
                                // countryFromDropdown?.value?.country !== ""
                                countryDropDownInput !=="" || countryFromDropdown?.value?.country !== ""
                                  ? "name_notforanim_new"
                                  : "name_for_anim"
                              }
                            >
                              Country*
                            </span>
                  <Select
                  onInputChange={handleInputChange} 
                  placeholder="Country*"
                    defaultValue={defaultCountry}
                    value={{
                      label: countryFromDropdown?.value?.country,
                      value: countryFromDropdown?.value?.country,
                    }}
                    options={
                      Object.entries(countryFlags).map(
                        ([country, { code, countrycode, currency, flagUrl, symbol }]) => ({
                          label: ` ${country}`,
                          value: { country, code, countrycode, currency, flagUrl, symbol },
                        })
                      )
                    }
                    onChange={handleCountryDropdown}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "1px solid #999999",
                        borderRadius: "12px",
                        padding: "8px"
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        zIndex: 9999,
                        maxHeight: '225px' ,
                        overflowY: 'auto' 
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state?.isSelected ? '#1976d2' : 'transparent', // Similar to Material-UI TextField selected option color
                        color: state?.isSelected ? '#ffffff' : '#333333', // Similar to Material-UI TextField selected option text color
                      }),
                    }}
                  />
                </div>
                <div style={{ position: "relative", width: "50%" }}>
                  <TextField
                    id="outlined-basic"
                    // error={error.gst}
                    label="Currency*"
                    variant="outlined"
                    className="saas-form"
                    type="text"
                    name="currency"
                    // value={input.currency}
                    // value={currency}
                    value={valueToShow}
                    // value={`${countryFromDropdown.value.currency} (${currencySymbol})`}
                    onChange={handleInput}
                    readOnly
                    style={{ borderRadius: "12px", width: "100%", }}
                    //   InputLabelProps={{ shrink: true }} // Removes mm/dd/yy placeholder
                    InputProps={{ placeholder: "", style: { borderRadius: "12px", position: "relative", paddingLeft: "50px" }, readOnly: true, }}
                  // inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                  />
                {showContent && (
                   <div style={{ position: "absolute", left: "0.5rem", top: "50%",
                 transform: "translateY(-50%)" }}>
                    <img src={countryFlag} alt="Currency Image" 
                    style={{ marginLeft: "10px", width: "1.8rem", height: "1.2rem", borderRadius: "0.1rem" }} />
                  </div>
                )}
                </div>
              </div>

              {/* <div>
                <TextField
                 error={error.address}
                  id="outlined-basic"
                  label="Permanent Address"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="address"
                  // value={profileData?.CompanySettingDetails?.address}
                  value={input.address}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputProps={{ style: { borderRadius: "12px" } }}
                  inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                  // disabled
                />
              </div> */}

              {/* <div style={{ display: "flex", gap: "1rem", position: "relative" }}> */}
              <div className={styles.cityContainer}>
                <div style={{ width: "50%" }}>
                  <TextField
                    error={error.city}
                    id="outlined-basic"
                    label="City*"
                    variant="outlined"
                    className="saas-form"
                    type="text"
                    name="city"
                    // value={profileData?.CompanySettingDetails?.city}
                    value={input.city}
                    onChange={handleInput}
                    // style={{ borderRadius: "12px", width: "50%" }}
                    sx={{ width: "100%", '@media (max-width: 600px)': { width: '100%', } }}
                    InputProps={{ style: { borderRadius: "12px" }, inputMode: "text" }}
                    // disabled
                    inputProps={{ style: { fontSize: isMobile ? "14px" : "18px" } }}
                  /></div>
                <div style={{ width: "50%" }}>
                  <TextField
                    id="outlined-basic"
                    error={error.pincode}
                    // label={input.country == "India" ? "Pin Code*" : "Zip Code*"}
                    label="Zip/Postal Code*"
                    variant="outlined"
                    className="saas-form"
                    type="text"
                    name="pincode"
                    // value={profileData?.CompanySettingDetails?.pincode}
                    value={input.pincode}
                    onChange={handleInput}
                    style={{ borderRadius: "12px", width: "100%" }}
                    InputProps={{ style: { borderRadius: "12px" }, inputMode: "text" }}
                    // style={{ width: "50%" }}
                    sx={{ width: "100%", '@media (max-width: 600px)': { width: '100%', } }}

                    inputProps={{ style: { fontSize: isMobile ? "14px" : "18px" } }}
                  // disabled
                  />
                </div>
              </div>

              <div style={{ display: "flex", gap: "1rem" }}>
                <TextField
                  id="outlined-basic"
                  error={error.gstNo}
                  label="GST/VAT No.*"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="gst"
                  value={input.gstNo}
                  // value={profileData?.CompanySettingDetails?.gstNo}
                  // disabled
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  //   InputLabelProps={{ shrink: true }} // Removes mm/dd/yy placeholder
                  InputProps={{ placeholder: "", style: { borderRadius: "12px" } }}
                  inputProps={{ style: {fontSize: isMobile ? "14px" : "18px" } }}
                />
              </div>
              {/* 
              <div style={{ display: "flex", gap: "1rem" }}>
                <MuiPhoneNumber
                 error={error.number}
                  style={{ width: "100%" }}
                  variant="outlined"
                  label="Contact*"
                  // value={profileData?.CompanySettingDetails?.contact}
                value={input.number}
                  onChange={(newVal, countryCode) => handleInput({ target: { name: "number", value: newVal, countryCode } })}
                  // defaultCountry={"in"}
                  defaultCountry={phoneCode}
                  InputProps={{ placeholder: "", style: { borderRadius: "12px" } }}
                  // disabled
                />
              </div> */}

              <TextField
                style={{ borderRadius: "12px" }}
                id="outlined-basic"
                label="Phone number*"
                variant="outlined"
                value={input.number}
                onChange={(e) => handlePhoneNumberChange(e)}
                // error={input.country === "India" ? input.number.length > 0 && input.number.length < 10 : input.number.length > 0 && input.number.length < 1}
                // error={input.country === "India" ? input.number.length !==10 : countryDialCode?.substring(1) === "91" ?  input.number.length !==10 :  input.number.length <1}
                error={error.number}
                // {input.number.length > 0 && input.number.length < 10}
                InputProps={{
                  style: { borderRadius: "12px" },
                  inputMode: "numeric",
                  startAdornment: (
                    <InputAdornment position="start" >
                      {countryDialCode?.startsWith("+") ? countryDialCode : "+" + countryDialCode}
                    </InputAdornment>
                  ),
                  inputProps: {
                    // maxLength: input?.country === "India" ? 10 : countryDialCode?.substring(1) === "91" ? 10 : 15
                    maxLength:15,
                    minLength:5
                    // input?.country === "India"? 10 : 15 , 
                  },
                }}
                fullWidth
              />

            </div>

            <div>
              <button
                className={styles.nextBtn}
                onClick={() => {
                  // Object.keys(input).forEach((elem) => {
                  //   if (input[elem]) {

                  //   } else {
                  //     seterror((prev) => {
                  //       return {
                  //         ...prev,
                  //         [elem]: true,
                  //       };
                  //     });
                  //   }
                  // });
                  // if (input.companyName !== "" && input.gst !== "") {

                  // }

                  if (valueFromChild.length === 0) {
                    seterror((prev) => {
                      return {
                        ...prev,
                        location: true,
                      };
                    });
                  }

                  Object.keys(input).forEach((curElem) => {
                    if (input[curElem]) {
                      seterror((prev) => {
                        return {
                          ...prev,
                          [curElem]: false,
                        };
                      });
                    } else {
                      seterror((prev) => {
                        return {
                          ...prev,
                          [curElem]: true,
                        };
                      });
                    }
                  });
                  if (input?.companyName !== "" && input?.email !== "" && input?.city !== "" && input?.country !== "" && input?.pincode !== "" && input?.number !== ""
                    && valueFromChild?.length !== 0 && error.address == false && error.gstNo == false  
                    //  && countryFromDropdown?.value?.country.length!==0
                    ) {
                    // postData();
                    if (errormsg !== "") {
                    } else {

                      if (input.number.length <5) {
                        seterror((prev) => {
                          return {
                            ...prev,
                            number: true,
                          };
                        });
                      } else {
                        seterror((prev) => {
                          return {
                            ...prev,
                            number: false,
                          };
                        });
                        if (input.pincode.length !==6 && input.country=="India" ) {
                          seterror((prev) => {
                            return {
                              ...prev,
                              pincode: true,
                            };
                          });
                        }else{
                          seterror((prev) => {
                            return {
                              ...prev,
                              pincode: false,
                            };
                          });
                          handleOnboarding();
                          companyDetails();
                        }
                       
                      }

                    }
                  }
                  // handleOnboarding();
                  // companyDetails();
                }}
              >
                Next
              </button>
              <div style={{ marginBottom: "10px", display: isMobile ? "block" : "none" }}></div>
            </div>
            {/* <div className={styles.skipBtnInLast}>
            <a href={`${constants.BASE_URL}/admin/`} className={styles.skip} style={{ textDecoration: "none" }}
            onClick={()=>handleOnboarding()}>
              Skip Complete Profile
            </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
