export function whatsAppApi(number) {
  return `https://api.whatsapp.com/send?phone=91${number}&text=Hi%2C+Greetings`;
}

var currentURL = window.location.href;
var thirdSlashIndex = currentURL.indexOf('/', currentURL.indexOf('/') +2); // Find the index of the second slash
var slicedURL = currentURL.substring(0, thirdSlashIndex); // Extract substring from beginning till the third slash
console.log(slicedURL);
const url = new URL(slicedURL);
const hostname = url.hostname;
const parts = hostname.split(".");
const substring = parts.slice(1).join(".");


//   const expf = {
//   // api: process.env.REACT_APP_API_URL,
//   // api: "http://localhost:3900/api",
//   // apiurl: process.env.REACT_APP_URL,
//   // proapi: "https://pro-api.idesign.market",
//   // proapi: "http://65.2.27.46:8000",
//   // proapi: "https://pro-api-uat.idesign.market",
//   // quoapi: "https://quotation-api.idesign.market",
//   // pmtapi: "https://PMT_API-api-uat.idesign.market",
//   // dev : "https://pro-api.idesign.market",
//   // dev: "https://pro-api.idesign.market",
//   // dev: "httpd://65.2.27.46:8000",
//   // dev: "https://pro-api-uat.idesign.market",
//   whatsAppApi,
// };
// if ((window?.location?.origin == "http://localhost:3000" || window?.location?.origin == "http://localhost:3001")) {
//   constants.CRM_API= "https://pro-api-uat.idesign.market"
//   constants.QUO_API= "https://quotation-api-uat.idesign.market"
//   constants.PMT_API= "https://PMT_API-api-uat.idesign.market"
//   constants.CRM_API= "https://pro-api-uat.idesign.market"
// } 
// else 
// if(slicedURL === "https://uat.idesign.market"){
//   constants.CRM_API= "https://pro-api-uat.idesign.market"
//   constants.QUO_API= "https://quotation-api-uat.idesign.market"
//   constants.PMT_API= "https://PMT_API-api-uat.idesign.market"
//   constants.CRM_API= "https://pro-api-uat.idesign.market"
// }
// else if(slicedURL === "https://preprod.idesign.market"){
//   constants.CRM_API= "https:// CRM_API-preprod.idesign.market"
//   constants.QUO_API= "https://quotation-api-preprod.idesign.market"
//   constants.PMT_API= "https://PMT_API-api-preprod.idesign.market"
//   constants.CRM_API= "https:// CRM_API-preprod.idesign.market"

// }
// else if(slicedURL === "https://pro.idesign.market"){
// //   constants.B2B_BASE = "https://pro-api.idesign.market"
// //   constants.  S3_API="https://s3-service.idesign.market"
// //   constants.QUO_BASE = "https://quotation-api.idesign.market"
// //   constants.CHAT_BASE= "https://chat-api.idesign.market"
// // constants.PMT_API_BASE = "https://PMT_API.idesign.market"
// // constants. NOTIFY_API = "https:// NOTIFY_API-api.idesign.market"
// // constants.BASE_URL = "https://pro.idesign.market"
// constants.CRM_API= "https://pro-api.idesign.market"
// constants.QUO_API= "https://quotation-api.idesign.market"
// // pmt api 
// // constants.PMT_API= "https://PMT_API-api.idesign.market"
// // crm api 
// // constants.CRM_API= "https://pro-api.idesign.market"
// }
// else{


//   constants.CRM_API = `https:// CRM_API.${substring}`
//   constants.QUO_API = `https://boq-api.${substring}`
//   constants.CRM_API = `https://s3-api.${substring}`
//   constants.PMT_API = `https://PMT_API-api.${substring}`


  
// }


export const constants = {
  CRM_API: "https://pro-api-uat.idesign.market",
  PMT_API: "https://pmt-api-uat.idesign.market",
  QUO_API: "https://boq-api-uat.idesign.market",
  PO_API: "https://po-api-uat.idesign.market",
  S3_API: "https://s3-api-uat.idesign.market",
  BASE_URL:"https://uat.idesign.market",
  CHAT_API:"https://chat-api-uat.idesign.market",
   EMAIL_API: "https://email-api-uat.idesign.market",
  NOTIFY_API:"https://notify-api-uat.idesign.market"
};


if ((window?.location?.origin == "http://localhost:3000" || window?.location?.origin == "http://localhost:3001")) {
  constants.CRM_API= "https://crm-preprod.idesign.market"
  constants.PMT_API= "https://pmt-api-preprod.idesign.market"
  constants.QUO_API= "https://boq-api-preprod.idesign.market"
  constants.PO_API= "https://po-api-preprod.idesign.market"
  constants.S3_API= "https://s3-api-preprod.idesign.market"
  constants.BASE_URL="https://preprod.idesign.market"
  constants.CHAT_API="https://chat-api-preprod.idesign.market"
constants.EMAIL_API= "https://email-api-preprod.idesign.market"
  constants.NOTIFY_API="https://notify-api-preprod.idesign.market"
// constants.CRM_API= "https://pro-api-uat.idesign.market"
// constants.PMT_API= "https://pmt-api-uat.idesign.market"
// constants.QUO_API= "https://boq-api-uat.idesign.market"
// constants.PO_API= "https://po-api-uat.idesign.market"
// constants.S3_API= "https://s3-api-uat.idesign.market"
// constants.BASE_URL="https://uat.idesign.market"
// constants.CHAT_API="https://chat-api-uat.idesign.market"
// constants.EMAIL_API= "https://email-api-uat.idesign.market"
// constants.NOTIFY_API="https://notify-api-uat.idesign.market"

}else if(slicedURL === "https://uat.idesign.market"){
  constants.CRM_API= "https://pro-api-uat.idesign.market"
  constants.PMT_API= "https://pmt-api-uat.idesign.market"
  constants.QUO_API= "https://boq-api-uat.idesign.market"
  constants.PO_API= "https://po-api-uat.idesign.market"
  constants.S3_API= "https://s3-api-uat.idesign.market"
  constants.BASE_URL="https://uat.idesign.market"
  constants.CHAT_API="https://chat-api-uat.idesign.market"
constants.EMAIL_API= "https://email-api-uat.idesign.market"
  constants.NOTIFY_API="https://notify-api-uat.idesign.market"

}
else if(slicedURL === "https://preprod.idesign.market"){
  constants.CRM_API= "https://crm-preprod.idesign.market"
  constants.PMT_API= "https://pmt-api-preprod.idesign.market"
  constants.QUO_API= "https://boq-api-preprod.idesign.market"
  constants.PO_API= "https://po-api-preprod.idesign.market"
  constants.S3_API= "https://s3-api-preprod.idesign.market"
  constants.BASE_URL="https://preprod.idesign.market"
  constants.CHAT_API="https://chat-api-preprod.idesign.market"
constants.EMAIL_API= "https://email-api-preprod.idesign.market"
  constants.NOTIFY_API="https://notify-api-preprod.idesign.market"


}
else if(slicedURL === "https://pro.idesign.market"){
  constants.CRM_API= "https://pro-api.idesign.market"
  constants.PMT_API= "https://pmt.idesign.market"
  constants.QUO_API= "https://quotation-api.idesign.market"
  constants.PO_API= "https://po-api.idesign.market"
  constants.S3_API= "https://s3-service.idesign.market"
  constants.BASE_URL="https://pro.idesign.market"
  constants.CHAT_API="https://chat-api.idesign.market"
constants.EMAIL_API= "https://email-api.idesign.market"
  constants.NOTIFY_API="https://notify-api.idesign.market"

}
else{

  constants.CRM_API= `https://crm.${substring}`
  constants.PMT_API= `https://pmt-api.${substring}`
  constants.QUO_API= `https://boq-api.${substring}`
  constants.PO_API= `https://po-api.${substring}`
  constants.S3_API= `https://s3-api.${substring}`
  constants.BASE_URL=`https://erp.${substring}`
  constants.CHAT_API=`https://chat-api.${substring}`
constants.EMAIL_API= `https://email-api.${substring}`
  constants.NOTIFY_API=`https://notify-api.${substring}`

}



export const iDesignWebsite ="https://www.idesign.market";
// export const constants. BASE_URL = "https://uat.idesign.market";

// export default expf;

export const signUpValidationObject = {
  password: {
    required: true,
    minLength: 8,
    maxLength: 15,
    upperCasePattern: /[A-Z]/,
    specialCharacterPattern: /[!@#$%^&*()_+{}[\]:;<>,.?~|/-]/,
  },
};

export const signInValidationObject = {
  EMAIL_API: {
    label: "EMAIL_API",
    required: true,
    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  },
  password: {
    required: true,
  },
};

export const numberArray = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export const emailValidation = {
  EMAIL_API: {
    label: "EMAIL_API",
    required: true,
    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  },
};

export const adminPersonalDataValObj = {
  fname: {
    required: true,
  },
  lName: {
    required: true,
  },
  address: {
    required: true,
  },
  city: {
    required: true,
  },
  zipCode: {
    required: true,
    minLength: 6,
    maxLength: 6,
  },
  birth: {
    required: true,
  },
  number: {
    required: true,
  },
};

export const verifyOtpValObj = {
  otp: {
    required: true,
    minLength: 4,
    maxLength: 4,
  },
};

export const teamOnboardVerifyObj = {
  fName: {
    required: true,
    maxLength: 15,
  },
  Lname: {
    required: true,
    maxLength: 15,
  },
  EMAIL_API: {
    label: "EMAIL_API",
    required: true,
    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  },

  role: {
    required: true, 
  },

  designation: {
    required: true,
  },
};

export const getOtpVerifyObj = {
  EMAIL_API: {
    label: "EMAIL_API",
    required: true,
    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  },
};
