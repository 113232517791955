import React, { useEffect, useState} from "react";
import idesign from "../../Assets/logo.png";
import { useNavigate } from "react-router-dom";
import styles from "../loginHeader/loginHeader.module.css";
import { useSelector } from 'react-redux';
import { getReq } from "../../piservices/apis";
import {constants} from "../../piservices/constants";
import { getToken } from "../../piservices/authService";

const LoginHeader = () => {
  const [logoImage, setLogoImage] =useState("");
  const logoImgData = useSelector((state) => state.persist.logoImgData);
  const navigate = useNavigate();

   const getLogoImage = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/profile`,
      {},
      { Authorization: `Bearer ${getToken()}` }
    );
    if (res && !res.error) {
       setLogoImage(res.data.data.CompanySettingDetails.companyLogo );
    } else {
      console.log("api not called", res.error);
    }
  };
  useEffect(()=>{
    const faviconElement = document.querySelector("link[rel='icon']");
   if (faviconElement) {
     faviconElement.href = logoImage;
   }
   }, [logoImage]);

   useEffect(() => {
    getLogoImage();
  }, []);
  return (
    <>
      <div className={styles.container}>
        {/* <img
          src={idesign}
          alt=""
          className={styles.headerImage}
          // style={{ width: "11rem", zIndex: "1", cursor: "pointer" }}
          onClick={() => {
            navigate("/");
            window.scroll(0, 0);
          }}
        /> */}
       
        <img
          src={logoImage}
          className={styles.logo}
          // style={{ cursor: "pointer" }}
          onClick={() => {
            window.location.assign(`${constants.BASE_URL}/admin/`);
          }}
          alt=""
        /> 
        {/* <div style={{fontSize:"26px",fontWeight:"bold",color:"#000000"}}>
          UAT
        </div> */}
      </div>
    </>
  );
};

export default LoginHeader;
