import React, { useState, useEffect, useContext } from "react";
import styles from "./NewLayout.module.css";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { BiChevronDown } from "react-icons/bi";
import {
  baseUrl,
  formateDate,
  valueToLeadStatusFormatted,
  valueToCallRemarks,
} from "../../../piservices/compCommon";
import desquoContext from "../../../picontext/DashBoard/desquoContext";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { RxCross2 } from "react-icons/rx";
import { DateRangePicker } from "react-date-range";
import toast from "react-hot-toast";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
function DropdownComponent({
  elementArr,
  selectedElement,
  type,
  clearFilterOptions,
  handleChange,
}) {

  return (
    <Dropdown>
      <Dropdown.Toggle
        className={styles.dropdownButton}
        variant="outline-secondary"
        id="dropdown-basic"
      >
        <span className={styles.buttonText}>
          {selectedElement?.length == 0 ? "Select" : "Selected"}
        </span>{" "}
        {selectedElement?.length == 0 ? (
          <BiChevronDown size={20} color="#74737F" />
        ) : (
          <RxCross2
            size={15}
            style={{ cursor: "pointer" }}
            color="#74737F"
            onClick={() => clearFilterOptions(type)}
          />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.filterDropdownContainer}>
        <Dropdown.ItemText className="d-flex">
          <input
            type="checkbox"
            checked={selectedElement.includes("Select All")}
            onChange={(e) => {
              handleChange(e, "Select All", type);
              //  setActive(e.target.checked);
            }}
          />
          <div className={styles.filterDropdownText}>Select All</div>
        </Dropdown.ItemText>
        {elementArr?.map((t) => {
          return (
            <Dropdown.ItemText className="d-flex">
              <input
                type="checkbox"
                checked={selectedElement.includes(t)}
                onChange={(e) => {
                  handleChange(e, t, type);
                  //  setActive(e.target.checked);
                }}
              />
              <div className={styles.filterDropdownText}>{t}</div>
            </Dropdown.ItemText>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function DropdownComponentModal({elementArr,min, max,selectedElement,setSelectedBudget,type,handleChange}) {
  console.log('selectedElement',selectedElement)
  // const [selectedElement, setSelectedRange] = useState([0, 100])
  const [value, setValue] = useState([0, 100])
  // const [marks, setMarks] = useState({})
  // function createMarks(min, max) {
  //   const interval = Math.ceil((max - min) / 8);
  //   const newMarks = {};
  //   for (let i = 0; i <= 8; i++) {
  //     let value = min + interval * i;
  //     newMarks[value] ="'";
  //   }
  //   setMarks(newMarks);
  // }
  const calculatePointerValue = (pointer) => {
    let temp = (max-min)*pointer*0.01;
    return (min +temp);
  }
  const marks = {
    0:"'",
    12: "'",
    24: "'",
    36: "'",
    48: "'",
    60: "'",
    72: "'",
    84: "'",
    100: "'"
};
function numDifferentiation(num, format) {
  let val = num;
  if (val >= 10000000) val = (val / 10000000).toFixed(2) + "Cr";
  else if (val >= 100000) val = (val / 100000).toFixed(2) + "Lac";
  else if (val >= 1000) val = (val / 1000).toFixed(2) + "K";
  return val;
}
  console.log('marks', marks)
  // useEffect(()=>{createMarks(min, max)},[min,max])

  // console.log('createMarks22',createMarks(min,max))
  return (
    <Dropdown>
      <Dropdown.Toggle
        className={styles.dropdownButton}
        variant="outline-secondary"
        id="dropdown-basic"
      >
        <span className={styles.buttonText}>
          Select Budget
        </span>{" "}
        {/* {selectedElement?.length == 0 ? (
          <BiChevronDown size={20} color="#74737F" />
        ) : (
          <RxCross2
            size={15}
            style={{ cursor: "pointer" }}
            color="#74737F"
            onClick={() => clearFilterOptions(type)}
          />
        )} */}
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.filterDropdownContainerSlider}>
        {numDifferentiation(Math.floor(calculatePointerValue(selectedElement[0])))?<Dropdown.ItemText >

          <div className={styles.filterDropdownText}>
            <Slider
              marks={marks}
              step={null}
              range
              onChange={(value) => setSelectedBudget(value)}
              allowCross={false}
              pushable
              draggableTrack
            />
          </div>

          <div className={styles.dropdownInputs}>
            <Dropdown>
              <Dropdown.Toggle
                className={styles.dropdownButton1}
                variant="outline-secondary"
                id="dropdown-basic"
              >
                {/* <span className={styles.buttonText}>
          {selectedElement?.length == 0 ? "Select" : "Selected"}
        </span>{" "}
        {selectedElement?.length == 0 ? (
          <BiChevronDown size={20} color="#74737F" />
        ) : (
          <RxCross2
            size={15}
            style={{ cursor: "pointer" }}
            color="#74737'
            onClick={() => clearFilterOptions(type)}
          />
        )} */}
                {selectedElement[0]==0?'Min.':numDifferentiation(Math.floor(calculatePointerValue(selectedElement[0])))}
              </Dropdown.Toggle>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle
                className={styles.dropdownButton1}
                variant="outline-secondary"
                id="dropdown-basic"
              >
                {/* <span className={styles.buttonText}>
          {selectedElement?.length == 0 ? "Select" : "Selected"}
        </span>{" "}
        {selectedElement?.length == 0 ? (
          <BiChevronDown size={20} color="#74737F" />
        ) : (
          <RxCross2
            size={15}
            style={{ cursor: "pointer" }}
            color="#74737F"
            onClick={() => clearFilterOptions(type)}
          />
        )} */}
                {selectedElement[1]==100?'Max.':numDifferentiation(Math.ceil(calculatePointerValue(selectedElement[1])))}

              </Dropdown.Toggle>

            </Dropdown>
          </div>

        </Dropdown.ItemText>:
        <Dropdown.ItemText style={{position:'absolute', top:'30%'}} >
          <div className={styles.filterDropdownText}>Leads have no budget to show.</div>
        </Dropdown.ItemText>
        }

      </Dropdown.Menu>
    </Dropdown>
  );
}
function Filter() {
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState([0, 100]);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);
const [selectedQuotationSent, setSelectedQuotationSent] = useState([]);
  const [selectedExpectedClosure, setSelectedExpectedClosure] = useState([]);
  const context = useContext(desquoContext);
  const [searchL, setSearchL] = useState("");
  const { leads, leads2, setLeads, activeLead } = context;


  const [checkboxData, setCheckBoxData] = useState([]);
  const [finalSelectedBudget, setFinalSelectedBudget] = useState([]);
  const [startDateCreatedOn, setStartDateCreatedOn] = useState(new Date());
  const [endDateCreatedOn, setEndDateCreatedOn] = useState(new Date());
  const [startDateFollowUp, setStartDateFollowUp] = useState(new Date());
  const [endDateFollowUp, setEndDateFollowUp] = useState(new Date());
  const [selectedCretedOn, setSelectedCreatedOn] = useState([]);
  const [selectedfollowUp, setSelectedFollowUp] = useState([]);
  const monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = {
    Jan: "Jan",
    Feb: "Feb",
    March: "Mar",
    April: "Apr",
    May: "May",
    June: "Jun",
    July: "Jul",
    Aug: "Aug",
    Sept: "Sep",
    Oct: "Oct",
    Nov: "Nov",
    Dec: "Dec",
  };
  useEffect(() => {
    setCheckBoxData(leads2?.data);
  });



  function clearFilterOptions(type) {
    if (type == "budget") {
      setSelectedBudget([]);
    } else if (type == "area") {
      setSelectedArea([]);
    } else if (type == "status") {
      setSelectedStatus([]);
    } else if (type == "expectedClosure") {
      setSelectedExpectedClosure([]);
    } else if (type == "assignedTo") {
      setSelectedAssignedTo([]);
    } else if (type == "source") {
      setSelectedSource([]);
    } else if (type == "createdOn") {
      setSelectedCreatedOn([]);
    } else if (type == "followUp") {
      setSelectedFollowUp([]);
    }
    else if (type == "qoutationSent") {
      setSelectedQuotationSent([]);
    }
  }



  const statusArr = checkboxData
    ?.map((v) => v.leadStatus)
    ?.filter((item, index, arr) => arr?.indexOf(item) === index)
    ?.filter(
      (element) => element !== null && element !== undefined && element !== "-"
    );

  

  const budgetArr = [
    "Under 1 Lakh",
    "1 Lakh - 5 Lakh",
    "5 Lakh - 10 Lakh",
    "Over 10 Lakh",
  ];

  const budgetArrAll = checkboxData
    ?.map((v) => v.budget)
    ?.filter((item, index, arr) => arr?.indexOf(item) === index)
    ?.filter(
      (element) => element !== null && element !== undefined && element !== "-"
    )?.map((item, index) => convertBudgetToInt(item));

    console.log('budgetArrAll', budgetArrAll)

  const Under1Lakh = budgetArrAll?.filter((item) => item < 100000);
  const Between1LakhTo5Lakh = [budgetArrAll]?.filter(
    (item) => item > 100000 && item < 500000
  );
  const Between5LakhTo10Lakh = budgetArrAll?.filter(
    (item) => item > 500000 && item < 1000000
  );
  const Above10Lakh = budgetArrAll?.filter((item) => item > 1000000);

  const AreaArr = checkboxData
    ?.map((v) => v.city)
    ?.filter((item, index, arr) => arr?.indexOf(item) === index)?.filter((value)=>{
      return value?.length>0;
    })
    ?.sort((a, b) => a?.localeCompare(b))
    ?.filter(
      (element) => element !== null && element !== undefined && element !== "-"
    );


  const AssignedToArr = checkboxData
    ?.map((v) => v.assignedTo?.fullName)
    ?.filter((item, index, arr) => arr?.indexOf(item) === index)
    ?.sort((a, b) => a?.localeCompare(b))
    ?.filter(
      (element) => element !== null && element !== undefined && element !== "-"
    );

  const createdOnArr = checkboxData
    ?.map((v) => v.createdAt)
    ?.filter((item, index, arr) => arr?.indexOf(item) === index);


  // const followUpArr = checkboxData
  //   ?.map((v) => v?.leadUpdates[v?.leadUpdates?.length - 1]?.updateDate)
  //   ?.filter((item, index, arr) => arr?.indexOf(item) === index)
  //   ?.filter(
  //     (element) => element !== null && element !== undefined && element !== "-"
  //   ).filter((value)=>{
  //     return value.length>0;
  //   })

   
     const  followUpArr=[]
    checkboxData?.map((item)=>{
      item?.leadUpdates?.map((updateDates)=>{
        if (!followUpArr?.includes(updateDates?.updateDate)) {
          followUpArr?.push(updateDates?.updateDate)
          console.log("updateDates",updateDates?.updateDate)
        }
        // console.log("updateDates",updateDataArray?.some((item)=>item !== updateDates?.updateDate))

      })
    })






  




  const SourceArr = checkboxData
    ?.map((v) => v.leadSource)
    ?.filter((item, index, arr) => arr?.indexOf(item) === index).filter((value)=>{
      return value?.length>0;
    })
    ?.sort((a, b) => a?.localeCompare(b))
    ?.filter(
      (element) => element !== null && element !== undefined && element !== "-"
    );

  function convertBudgetToInt(bud) {
    const newBudget = bud?.replaceAll(",", "");
    return Number(newBudget);
  }
  function handleChange(e, t, type) {
    if (type == "area") {
      if (e.target.checked) {
        if (t == "Select All") {
          setSelectedArea([...AreaArr, 'Select All']);
        } else {
          setSelectedArea([...selectedArea, t]);
        }
      } else {
        if (t == "Select All") {
          setSelectedArea([]);
        } else {
          setSelectedArea(
            selectedArea.filter((e, i) => selectedArea.indexOf(t) !== i)
          );
        }

      }
    }
    if (type == "status") {
      if (e.target.checked) {
        if (t == "Select All") {
          setSelectedStatus([...statusArr, 'Select All']);
        } else {
          setSelectedStatus([...selectedStatus, t]);
        }
      } else {

        if (t == "Select All") {
          setSelectedStatus([]);
        } else {
          setSelectedStatus(
            selectedStatus.filter((e, i) => selectedStatus.indexOf(t) !== i)
          );
        }
      }
    }
    if (type == "qoutationSent") {
      if (e.target.checked) {
        if (t == "Select All") {
          setSelectedQuotationSent([...['true', 'false'], 'Select All']);
        } else {
          setSelectedQuotationSent([...selectedQuotationSent, t]);
        }
      } else {

        if (t == "Select All") {
          setSelectedQuotationSent([]);
        } else {
          setSelectedQuotationSent(
            selectedQuotationSent.filter((e, i) => selectedQuotationSent.indexOf(t) !== i)
          );
        }
      }
    }
    if (type == "expectedClosure") {
      if (e.target.checked) {
        if (t == "Select All") {
          setSelectedExpectedClosure([...monthNames, 'Select All']);
        } else {
          setSelectedExpectedClosure([...selectedExpectedClosure, t]);
        }
      } else {
        if (t == "Select All") {
          setSelectedExpectedClosure([]);
        } else {
          setSelectedExpectedClosure(
            selectedExpectedClosure.filter((e, i) => selectedExpectedClosure.indexOf(t) !== i)
          );
        }

      }
    }
    if (type == "budget") {
      if (e.target.checked) {
        if (t == "Select All") {
          setSelectedBudget([...budgetArr, 'Select All']);
        } else {
          setSelectedBudget([...selectedBudget, t]);
        }
        // setSelectedBudget([...selectedBudget, t]);
      } else {
        if (t == "Select All") {
          setSelectedBudget([]);
        } else {
          setSelectedBudget(
            selectedBudget.filter((e, i) => selectedBudget.indexOf(t) !== i)
          );
        }
        // setSelectedBudget(
        //   selectedBudget.filter((e, i) => selectedBudget.indexOf(t) !== i)
        // );
      }
    }
    if (type == "assignedTo") {
      if (e.target.checked) {
        if (t == "Select All") {
          setSelectedAssignedTo([...AssignedToArr, 'Select All']);
        } else {
          setSelectedAssignedTo([...selectedAssignedTo, t]);
        }
        // setSelectedAssignedTo([...selectedAssignedTo, t]);
      } else {
        if (t == "Select All") {
          setSelectedAssignedTo([]);
        } else {
          setSelectedAssignedTo(
            selectedAssignedTo.filter((e, i) => selectedAssignedTo.indexOf(t) !== i)
          );
        }
      }
    }
    if (type == "source") {
      if (e.target.checked) {
        if (t == "Select All") {
          setSelectedSource([...SourceArr, 'Select All']);
        } else {
          setSelectedSource([...selectedSource, t]);
        }
        // setSelectedSource([...selectedSource, t]);
      } else {
        if (t == "Select All") {
          setSelectedSource([]);
        } else {
          setSelectedSource(
            selectedSource.filter((e, i) => selectedSource.indexOf(t) !== i)
          );
        }
        // setSelectedSource(
        //   selectedSource.filter((e, i) => selectedSource.indexOf(t) !== i)
        // );
      }
    }
  }
  const calculatePointerValue = (pointer) => {
    let min = Math.min(...budgetArrAll);
    let max = Math.max(...budgetArrAll);
    
    let temp = (max-min)*pointer*0.01;
    return (min +temp);
  }
  function numDifferentiation(num, format) {
    let val = convertBudgetToInt(num);
    if (val >= 10000000) val = (val / 10000000).toFixed(2) + "Cr";
    else if (val >= 100000) val = (val / 100000).toFixed(2) + "Lac";
    else if (val >= 1000) val = (val / 1000).toFixed(2) + "K";
    return val;
  }
  const filterBudgetSlider = () => {
    let temp = [];
    // console.log('selectedBudgetinfunction',selectedBudget)
    const filteredBudget = budgetArrAll.filter((budget)=>budget>=calculatePointerValue(selectedBudget[0]) &&budget<=calculatePointerValue(selectedBudget[1]) )
    console.log('filteredBudget',filteredBudget)
    setFinalSelectedBudget(filteredBudget);

  };
  console.log('finalSelectedBudget', finalSelectedBudget)
  // const filterBudget = () => {
  //   let temp = [];
  //   if (selectedBudget?.length > 0) {
  //     selectedBudget.map((item, index, arr) => {
  //       if (item === "Under 1 Lakh") {
  //         temp = [...temp, ...Under1Lakh];
  //         // setFinalSelectedBudget([...finalSelectedBudget, ...Under1Lakh])
  //       } else if (item === "1 Lakh - 5 Lakh") {
  //         temp = [...temp, ...Between1LakhTo5Lakh];
  //         // setFinalSelectedBudget([...finalSelectedBudget, ...Between1LakhTo5Lakh])
  //       } else if (item === "5 Lakh - 10 Lakh") {
  //         temp = [...temp, ...Between5LakhTo10Lakh];
  //         // setFinalSelectedBudget([...finalSelectedBudget, ...Between5LakhTo10Lakh])
  //       } else if (item === "Over 10 Lakh") {
  //         temp = [...temp, ...Above10Lakh];
  //         // setFinalSelectedBudget([...finalSelectedBudget, ...Above10Lakh])
  //       }
  //     });
  //     setFinalSelectedBudget([...temp]);
  //   }
  // };
  // useEffect(() => {
  //   setFinalSelectedBudget([]);
  //   filterBudget();
  // }, [selectedBudget]);

  useEffect(()=>{
    setFinalSelectedBudget([]);
    filterBudgetSlider();
  },[selectedBudget])

  const isFilterOpen = useSelector((state) => state.lmsSlice.isFilterOpen);
  
// bug fix Date - 19-06-2024
  function applyFilter() {

    if (
      selectedStatus.length > 0 ||
      selectedfollowUp.length > 0 ||
      selectedCretedOn.length > 0 ||
      finalSelectedBudget.length > 0 ||
      selectedSource.length > 0 ||
      selectedArea.length > 0 ||
      selectedQuotationSent.length > 0 ||
      selectedAssignedTo.length > 0 ||
      selectedExpectedClosure.length > 0
    ) {
      const filterData = checkboxData.filter((item) => {
        return (
          selectedArea?.includes(item.address) ||
          selectedQuotationSent?.includes(item.isQuotationSent?.toString()) ||
          selectedStatus?.includes(item.leadStatus) ||
          selectedExpectedClosure?.includes(item.clouserMonth) ||
          finalSelectedBudget?.includes(convertBudgetToInt(item.budget)) ||
          selectedAssignedTo?.includes(item.assignedTo?.fullName) ||
          selectedSource?.includes(item.leadSource) ||
          selectedCretedOn?.includes(item.createdAt) ||
          selectedfollowUp?.includes(
            item.leadUpdates[item?.leadUpdates?.length - 1]?.updateDate
          )
        );
      });
      toast.success("Filter Applied Successfully", {
        autoClose: 3000,
      });
      let x = JSON.parse(JSON.stringify(leads));
      delete x["data"];
      x["data"] = filterData;
      setLeads(x);
    } else {
      setLeads(leads2);
    }
  }

  function clearFilter() {
    setSelectedArea([]);
    setSelectedStatus([]);
    setSelectedExpectedClosure([]);
    setSelectedBudget([]);
    setSelectedAssignedTo([]);
    setSelectedSource([]);
    setSelectedCreatedOn([]);
    setSelectedFollowUp([]);
    setLeads(leads2);
  }

  function handleCancle() { }
  //handling filter for created on date
  function handleSelect(date) {

    setStartDateCreatedOn(date.selection.startDate);
    setEndDateCreatedOn(date.selection.endDate);
    const filteredCreatedOnDate = createdOnArr?.filter((createdOn) => {

      let createdDate = new Date(createdOn).toLocaleDateString();
      console.log(date.selection.startDate.toLocaleDateString(),"Datevalue")
      return (
        createdDate >= date.selection.startDate.toLocaleDateString() &&
        createdDate <= date.selection.endDate.toLocaleDateString()
      );
    });

   
    setSelectedCreatedOn([...filteredCreatedOnDate]);
  }

  // handling filter for follow up

  function handleSelectFollowUp(date) {
    console.log(date.selection.startDate.toLocaleDateString(),date.selection.endDate.toLocaleDateString(),"Datevalue")

    setStartDateFollowUp(date.selection.startDate);
    setEndDateFollowUp(date.selection.endDate);

    const filteredFollowUpDate = followUpArr?.filter((fllUp) => {



      let newdate = fllUp.split("-");

      newdate=[ newdate[2], newdate[1], newdate[0] ].join('-');

      const newDatevalue=new Date(newdate).getTime();
      


      let startDate= new Date(date.selection.startDate).getTime();


      let endDate= new Date(date.selection.endDate).getTime();
      // let endDate= new Date(date.selection.endDate);

      console.log(newDatevalue,startDate,endDate,"Datevaluedatafile");
      console.log(  newDatevalue >= startDate,
        newDatevalue <= endDate,"Datefalsevalue")

      return (

        newDatevalue >= startDate &&
        newDatevalue <= endDate
      
      )
   
    });
    setSelectedFollowUp([...filteredFollowUpDate]);
  }

  console.log(selectedfollowUp,"followupdata")

  const selectionRange = {
    startDate: startDateCreatedOn,
    endDate: endDateCreatedOn,
    key: "selection",
  };
  const selectionRangeFollowUp = {
    startDate: startDateFollowUp,
    endDate: endDateFollowUp,
    key: "selection",
  };


  return (
    <div className={` ${styles.mainContainer4}`}>
      <div className="d-flex justify-content-between w-100">
        <div>
          <div className="d-flex align-items-center">
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >{`Area (${selectedArea?.length})`}</div>
              <DropdownComponent
                clearFilterOptions={clearFilterOptions}
                elementArr={AreaArr}
                selectedElement={selectedArea}
                type="area"
                handleChange={handleChange}
              />
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >{`Status (${selectedStatus?.length})`}</div>
              <Dropdown>
                <Dropdown.Toggle
                  className={styles.dropdownButton}
                  variant="outline-secondary"
                  id="dropdown-basic"
                >
                  <span className={styles.buttonText}>
                    {selectedStatus?.length == 0 ? "Select" : "Selected"}
                  </span>{" "}
                  {selectedStatus?.length == 0 ? (
                    <BiChevronDown size={25} color="#74737F" />
                  ) : (
                    <RxCross2
                      size={15}
                      style={{ cursor: "pointer" }}
                      color="#74737F"
                      onClick={() => clearFilterOptions("status")}
                    />
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu className={styles.filterDropdownContainer}>
                  <Dropdown.ItemText className="d-flex">
                    <input
                      type="checkbox"
                      checked={selectedStatus.includes("Select All")}
                      onChange={(e) => {
                        handleChange(e, "Select All", "status");
                        //  setActive(e.target.checked);
                      }}
                    />
                    <div className={styles.filterDropdownText}>Select All</div>
                  </Dropdown.ItemText>
                  {statusArr?.map((t) => {

                    return (
                      <Dropdown.ItemText className="d-flex">
                        <input
                          type="checkbox"
                          checked={selectedStatus.includes(t)}
                          onChange={(e) => {
                            handleChange(e, t, "status");
                            //  setActive(e.target.checked);
                          }}
                        />
                        <div style={{ marginLeft: "0.6rem" }}>
                          {valueToLeadStatusFormatted[t]}
                        </div>
                      </Dropdown.ItemText>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem"
                }}
              >{`Expected Closure (${selectedExpectedClosure?.length})`}</div>
              <DropdownComponent
                elementArr={monthNames}
                clearFilterOptions={clearFilterOptions}
                selectedElement={selectedExpectedClosure}
                type="expectedClosure"
                handleChange={handleChange}
              />
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >{`Budget (${selectedBudget?.length})`}</div>
              {/* <DropdownComponent
                elementArr={budgetArr}
                clearFilterOptions={clearFilterOptions}
                selectedElement={selectedBudget}
                type="budget"
                handleChange={handleChange}
              /> */}
              <DropdownComponentModal
              elementArr={budgetArr}
              // clearFilterOptions={clearFilterOptions}
              selectedElement={selectedBudget}
              setSelectedBudget={setSelectedBudget}
              type="budget"
              min={Math.min(...budgetArrAll)}
              max={Math.max(...budgetArrAll)}
              handleChange={handleChange}
              />
            </div>

            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >{`Assign To (${selectedAssignedTo?.length})`}</div>
              <DropdownComponent
                elementArr={AssignedToArr}
                clearFilterOptions={clearFilterOptions}
                selectedElement={selectedAssignedTo}
                type="assignedTo"
                handleChange={handleChange}
              />
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >{`Source (${selectedSource?.length})`}</div>
              <DropdownComponent
                elementArr={SourceArr}
                clearFilterOptions={clearFilterOptions}
                selectedElement={selectedSource}
                type="source"
                handleChange={handleChange}
              />
            </div>
          </div>
          <br />
          <div className="d-flex align-items-center">
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >
                Date
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  className={styles.dropdownButton}
                  variant="outline-secondary"
                  id="dropdown-basic"
                >
                  <span className={styles.buttonText}>
                    <span style={{ marginRight: "50px" }}>
                      {selectedCretedOn?.length == 0 ? "Select" : "Selected"}
                    </span>{" "}
                    <span>
                      {selectedCretedOn?.length == 0 ? (
                        <BiChevronDown size={25} color="#74737F" />
                      ) : (
                        <RxCross2
                          size={15}
                          style={{ cursor: "pointer" }}
                          color="#74737F"
                          onClick={() => clearFilterOptions("createdOn")}
                        />
                      )}
                    </span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >
                Follow Up
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  className={styles.dropdownButton}
                  variant="outline-secondary"
                  id="dropdown-basic"
                >
                  <span className={styles.buttonText}>
                    <span style={{ marginRight: "50px" }}>
                      {selectedfollowUp?.length == 0 ? "Select" : "Selected"}{" "}
                    </span>
                    {selectedfollowUp?.length == 0 ? (
                      <BiChevronDown size={25} color="#74737F" />
                    ) : (
                      <RxCross2
                        size={15}
                        style={{ cursor: "pointer" }}
                        color="#74737F"
                        onClick={() => clearFilterOptions("followUp")}
                      />
                    )}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <DateRangePicker
                    ranges={[selectionRangeFollowUp]}
                    onChange={handleSelectFollowUp}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div
              className="d-flex flex-column"
              style={{ marginRight: "0.75rem" }}
            >
              <div
                className={styles.filter_icon}
                style={{
                  color: "#2F2E40",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                }}
              >{`Quotation Sent (${selectedQuotationSent?.length})`}</div>
              <DropdownComponent
                elementArr={['true', 'false']}
                clearFilterOptions={clearFilterOptions}
                selectedElement={selectedQuotationSent}
                type="qoutationSent"
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="d-flex mt-2">
          <div
            className={`${styles.clear} d-flex align-items-center px-1 ms-3`}
            style={{
              height: "2.185rem",
            }}
            onClick={clearFilter}
          >
            <div>Clear</div>
          </div>
          <div
           onClick={applyFilter}
            className="d-flex align-items-center m px-3 ms-3"
            style={{
              borderRadius: "0.5rem",
              border: "1px solid #0084EF",
              background: "#0084EF",
              height: "2.185rem",
              cursor: "pointer",
              marginRight: "1rem",
            }}
          >
            <div
              className="mx-2"
             
              style={{
                fontSize: "0.875rem",
                color: "white",
                
              }}
            >
              Apply
            </div>
          </div>
          <div>
            {/* <RxCross2
            size={25}
            style={{ cursor: "pointer" }}
            color="#74737F"
            onClick={() => handleCancle()}
          /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filter;
