import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { improveInput, InvalidClass } from "../../piservices/compCommon";
import { postReq, getReq } from "../../piservices/apis";
import {constants} from "../../piservices/constants";
import { BsChevronDown } from "react-icons/bs";
const AddLeadModal = ({ setProgress, onEditLead }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
    reset,
  } = useForm();
  const [userData, setUserData] = useState({});
  const [leadSources, setLeadSources] = useState([]);
  const [sourceNames, setSourceNames] = useState([]);
  const [selectedLeadSourceId, setSelectedLeadSourceId] = useState();
  const [sourceNameArchitectDetails, setSourceNameArchitectDetails] = useState({});
  const selectRef = useRef();
  const getUserbyId = async (id) => {
    const res = await getReq(`${constants.CRM_API}/user/getUserById?userId=${id}`);
    if (res && !res.error) {
      setUserData(res?.data?.data);
    } else {
      console.log(res.error);
    }
  };
  useEffect(() => {
    getUserbyId(localStorage.getItem("userId"));
  }, []);
  const onSubmit = (data, e) => {
    handleFormSubmit(data);
  };
  const onError = (errors, e) => console.log(errors, e);
  const [teamLeadArr, setTeamLeadArr] = useState([]);
  const getTeamLeads = async () => {
    const res = await getReq(
      `${constants.CRM_API}/api/enterprise/get-team-leaders?userId=${localStorage.getItem(
        "userId"
      )}&access=1`
    );
    if (res && !res.error) {
      setTeamLeadArr(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  // console.log(teamLeadArr, 'teamLeadArr')
  Object.filter = (obj, predicate) =>
    Object.keys(obj)
      .filter((key) => predicate(obj[key]))
      .reduce((res, key) => {
        return (res[key] = obj[key]), res;
      }, {});

  const handleFormSubmit = async (data) => {
    try {
      await setProgress(30);
      for (const k in data) {
        const e = data[k];
        if (e?.trim() === "") {
          delete data[k];
        }
      }
      if (data?.assignedTo == "UnAssigned") {
        delete data["assignedTo"];
        data["assignedTo"] = null;
      }
      // console.log(data);
      const res = await postReq(constants.CRM_API + "/user/addEditLead", {
        ...data, architectDetails: sourceNameArchitectDetails.architectDetails
      });
      showError(res.error);
      if (res && !res.error) {
        window.location.reload();
      }
      await setProgress(100);
    } catch (error) {
      // console.log(error)
      showError(error);
    }
  };

  const showError = (error) => {
    // console.log(error)
    if (error.response && error.response.data.message) {
      toast.error(error.response.data.message);
      // clg
      // alert(error.response.data.message)
      return;
    } else {
      window.location.reload();
    }
  };
  const getLeadSource = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-lead-source?userId=${localStorage.getItem('userId')}`
    );
    if (res && !res.error) {
      // console.log('getting lead source', res.data.data)
      setLeadSources(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const getSourceName = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-source-name?userId=${localStorage.getItem('userId')}`
    );
    if (res && !res.error) {
      // console.log('getting lead source', res.data.data)
      setSourceNames(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const cleanAllFields = () => {
    // window.location.reload();
  };

  useEffect(() => {
    improveInput();
  }, []);

  const formatNumberForInput = (inputValue) => {
    if (!inputValue) return '';
    const numericValue = parseInt(inputValue.replace(/,/g, ''), 10);
    if (isNaN(numericValue)) return '';
    return numericValue.toLocaleString('en-IN');
  };

  // const optionbudget = [
  //   { value: "", label: "" },
  //   { value: "₹ 1,00,000 - ₹ 3,00,000", label: "₹ 1 lakh - ₹ 3 lakh" },
  //   { value: "₹ 3,00,000 - ₹ 7,00,000", label: "₹ 3 lakh - ₹ 7 lakh" },
  //   { value: "Over ₹ 7,00,000", label: "Over ₹ 7 lakh" },
  // ];

  const reqbudget = [
    { value: "Full Design services", label: "Full Design services" },
    {
      value: "Architecture Design Services",
      label: "Architecture Design Services",
    },
    { value: "Interior Design Services", label: "Interior Design Services" },
    { value: "Landscape Service", label: "Landscape Service" },
    { value: "FFE", label: "FFE" },
    { value: "Renovation of the house", label: "Renovation of the house" },
    { value: "Styling of house", label: "Styling of house" },
  ];
  useEffect(() => {
    getLeadSource();
    getSourceName();
    getTeamLeads();
  }, []);
  const exceptThisSymbols2 = [
    "~",
    "`",
    "!",
    "@",
    "#",
    "%",
    "$",
    "^",
    "&",
    "*",
    "(",
    ")",
    "+",
    "=",
    "[",
    "_",
    "]",
    "'",
    ";",
    "/",
    "{",
    "}",
    "|",
    ,
    "<",
    ">",
    "?",
    ".",
    ",",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];
  return (
    <div>
      <div>
        {/* Button trigger modal */}

        {/* Modal */}
        <div
          className="modal fade droppopmodal"
          id="addLeadModal"
          data-mdb-backdrop="static"
          data-mdb-keyboard="false"
          tabIndex={-1}
          aria-labelledby="addLeadModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable  ">
            <form
              onSubmit={handleSubmit(onSubmit, onError)}
              className="modal-content "
            >
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New Lead
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-mdb-dismiss="modal"
                  aria-label="Close"
                />
              </div>

              <div className="modal-body ">
                <div>
                  <div className="mb-3">
                    <div className="form-outline ">
                      {/* <input type="text" id="form12" className="form-control border br-5 " /> */}
                      <input
                        style={InvalidClass}
                        name="name"
                        type="text"
                        className="form-control form-control-lg border br-5"
                        id="colFormLabelLg1"
                        maxLength={50}
                        {...register("name", {
                          required: "Name is Required",
                          pattern: {
                            value: /^[a-zA-Z ]{2,50}$/,
                            message: "Please enter a valid name",
                          },
                        })}
                        onKeyUp={() => {
                          trigger("name");
                        }}
                      />

                      <label className="form-label" htmlFor="form12">
                        Client Name
                      </label>
                    </div>
                    {errors.name && (
                      <small className="text-danger">
                        {errors.name.message}
                      </small>
                    )}
                  </div>

                  <div className="mb-3">
                    <div className="form-outline ">
                      {/* <input type="text" id="form12" className="form-control border br-5 " /> */}
                      <input
                        style={errors.email && InvalidClass}
                        name="email"
                        type="email"
                        className="form-control form-control-lg border br-5"
                        id="colFormLabelLg1"
                        {...register("email", {
                          required: "Email is Required",
                          pattern: {
                            value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/,
                            message: "Enter valid email address",
                          },
                        })}
                        onKeyUp={() => {
                          trigger("email");
                        }}
                      />

                      <label className="form-label" htmlFor="form12">
                        Email
                      </label>
                    </div>
                    {errors.email && (
                      <small className="text-danger">
                        {errors.email.message}
                      </small>
                    )}
                  </div>
                  <div className="mb-3">
                    <div className="form-outline ">
                      {/* <input type="text" id="form12" className="form-control border br-5 " /> */}
                      <input
                        style={errors.address && InvalidClass}
                        name="address"
                        type="text"
                        className="form-control form-control-lg border br-5"
                        id="colFormLabelLg1"
                        {...register("address", {
                          // required: "Address is Required",
                          // pattern: {
                          //   value: /^(?=.*\S).+$/,
                          //   message: "Not Allowed Empty String",
                          // },
                        })}
                        onKeyUp={() => {
                          trigger("address");
                        }}
                      />

                      <label className="form-label" htmlFor="form12">
                        Location
                      </label>
                    </div>
                    {errors.address && (
                      <small className="text-danger">
                        {errors.address.message}
                      </small>
                    )}
                  </div>
                  <div className="mb-3">
                    <div className="form-outline ">
                      {/* <input type="text" id="form12" className="form-control border br-5 " /> */}
                      <input
                        style={errors.phoneNumber && InvalidClass}
                        name="phoneNumber"
                        type="text"
                        className="form-control form-control-lg border br-5"
                        id="colFormLabelLg1"
                        maxLength={10}
                        {...register("phoneNumber", {
                          pattern: {
                            value:
                              /^(?=.*(\d)(?=\d)(?!\1))\d{10}$/,
                            message: "Phone Number should be Valid",
                          },

                          // required: "Phone number is Required",
                        })}
                        onKeyDown={(e) =>
                          exceptThisSymbols2.includes(e.key) &&
                          e.preventDefault()
                        }
                        onKeyUp={() => {
                          trigger("phoneNumber");
                        }}
                      />

                      <label className="form-label" htmlFor="form12">
                        Contact Number
                      </label>
                    </div>
                    {errors.phoneNumber && (
                      <small className="text-danger">
                        {errors.phoneNumber.message}
                      </small>
                    )}
                  </div>
                  <div className="mb-3">
                    <div className="form-outline  ">
                      <input
                        style={errors.phoneNumber && InvalidClass}
                        name="budget"
                        type="text"
                        className="form-control form-control-lg border br-5"
                        id="colFormLabelLg1"
                        maxLength={12}
                        {...register("budget", {
                          // pattern: {
                          //   value: /^[0-9]*$/,
                          //   message: "Only Numeric Allowed",
                          // },
                          // required: "budget is Required",
                        })}
                        onKeyUp={() => {
                          trigger("budget");
                        }}
                        onChange={(e) => {
                          const inputValue = e.target.value
                          setValue("budget", formatNumberForInput(inputValue));
                        }}
                      />

                      {/* <select
                        style={errors.budget && InvalidClass}
                        name="budget"
                        type="text"
                        id="colFormLabelLg1"
                        {...register("budget", {
                          // required: "budget is Required",
                        })}
                        onKeyUp={() => {
                          trigger("budget");
                        }}
                        className="form-control form-control-lg border capitalize "
                      >
                        {optionbudget.map((option, i) => (
                          <option key={i} className="capitalize" value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      <div className="position-absolute end-0 m-2 top-0">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </div> */}

                      <label className="form-label" htmlFor="form12">
                        Budget
                      </label>
                    </div>
                    {errors.budget && (
                      <small className="text-danger">
                        {errors.budget.message}
                      </small>
                    )}
                  </div>

                  <div className="mb-3">
                    <div className="form-outline ">

                      <select
                        id="mySelect"
                        ref={selectRef}
                        style={errors.budget && InvalidClass}
                        name="requirements"
                        // type="text"
                        {...register("requirements", {
                          // required: "requirements is Required",
                        })}
                        onKeyUp={() => {
                          trigger("requirements");
                        }}
                        className="form-control dropdown form-control-lg border capitalize "
                      // placeholder="city"
                      // {...register("city", {
                      //   // required: "City is Required",
                      // })}
                      >
                        <option
                          className="capitalize"
                          value="-"
                          disabled selected
                        >
                          Work Type (Optional)
                        </option>
                        {reqbudget.map((option, i) => (
                          <option
                            key={i}
                            className="capitalize"
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </select>

                      <div className=" cursor-pointer position-absolute end-0 m-2 top-0" >
                        <BsChevronDown />
                      </div>
                    </div>
                    {errors.requirements && (
                      <small className="text-danger">
                        {errors.requirements.message}
                      </small>
                    )}
                  </div>

                  <div className="mb-3">
                    <div className="form-outline ">
                      {/* <input type="text" id="form12" className="form-control border br-5 " /> */}
                      <select
                        style={errors.leadSource && InvalidClass}
                        name="leadSource"
                        // placeholder="select"
                        type="text"
                        className="form-control form-control-lg border br-5"
                        id="colFormLabelLg1"
                        {...register("leadSource", {
                          //required: "Name is Required",
                          pattern: {
                            value: /^(?=.*\S).+$/,
                            message: "Not Allowed Empty String",
                          },
                        })}
                        onKeyUp={() => {
                          trigger("leadSource");
                        }}
                        onChange={(e) => setSelectedLeadSourceId(leadSources[e.target.selectedIndex - 1]?.id)}
                      >
                        <option
                          className="capitalize"
                          value="-"
                          disabled selected
                        >
                          Lead Source
                        </option>
                        {leadSources.map((option, index) =>
                        (<option
                          key={index}
                          className="capitalize"

                          value={option.leadSource}
                        >
                          {option.leadSource}
                        </option>)
                        )}
                      </select>
                      <div className="position-absolute end-0 m-2 top-0">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </div>
                    </div>
                    {errors.leadSource && (
                      <small className="text-danger">
                        {errors.leadSource.message}
                      </small>
                    )}
                  </div>

                  <div className="mb-3">
                    <div className="form-outline ">
                      {/* <input type="text" id="form12" className="form-control border br-5 " /> */}
                      <select
                        style={errors.sourceName && InvalidClass}
                        name="sourceName"
                        type="text"
                        className="form-control form-control-lg border br-5"
                        id="colFormLabelLg1"
                        {...register("sourceName", {
                          //required: "Name is Required",
                          pattern: {
                            value: /^(?=.*\S).+$/,
                            message: "Not Allowed Empty String",
                          },
                        })}
                        onKeyUp={() => {
                          trigger("sourceName");
                        }}
                        onChange={(e) => setSourceNameArchitectDetails(sourceNames.filter((item) => { if (item.id === selectedLeadSourceId) return true })[e.target.selectedIndex - 1])} // onChange={(e)=>setSelectedLeadSourceId(leadSources[e.target.selectedIndex-1]?.id)}
                      >
                        <option
                          className="capitalize"
                          value=""
                          disabled
                          selected
                        >
                          Source Name
                        </option>
                        {sourceNames.map((option, index) => {
                          if (option.id === selectedLeadSourceId) {
                            return (<option
                              key={index}
                              className="capitalize"
                              value={option.sourceName}
                            >
                              {option.sourceName}
                            </option>)
                          }
                        }
                        )}
                      </select>
                      <div className="position-absolute end-0 m-2 top-0">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </div>
                    </div>
                    {errors.sourceName && (
                      <small className="text-danger">
                        {errors.sourceName.message}
                      </small>
                    )}
                  </div>

                  {/* 
                  <div className="mb-3">
                    <div className="form-outline ">
                      <select
                        style={errors.budget && InvalidClass}
                        name="leadSource"
                        type="text"
                        {...register("leadSource", {
                          // required: "requirements is Required",
                        })}
                        onChange={(e) => setLeadData(e.target.value)}
                        onKeyUp={() => {
                          trigger("leadSource");
                        }}
                        className="form-control form-control-lg border capitalize "
                        placeholder="city"
                      // {...register("city", {
                      //   // required: "City is Required",
                      // })}
                      >
                        {reqbudget.map((option, i) => (
                          <option
                            key={i}
                            className="capitalize"
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </select>
                      <div className="position-absolute end-0 m-2 top-0">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </div>
                      <label className="form-label" htmlFor="form12">
                        Lead Source
                      </label>
                    </div>
                    {errors.leadSource && (
                      <small className="text-danger">
                        {errors.leadSource.message}
                      </small>
                    )}
                  </div> */}
                  {/* 
                  {leadData &&

                    <div className="mb-3">
                      <div className="form-outline ">
                        <select
                          style={errors.budget && InvalidClass}
                          name="sourceName"
                          type="text"
                          {...register("sourceName", {
                            // required: "requirements is Required",
                          })}
                          onKeyUp={() => {
                            trigger("sourceName");
                          }}
                          className="form-control form-control-lg border capitalize "
                          placeholder="city"
                        // {...register("city", {
                        //   // required: "City is Required",
                        // })}
                        >
                          {reqbudget.map((option, i) => (
                            <option
                              key={i}
                              className="capitalize"
                              value={option.value}
                            >
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <div className="position-absolute end-0 m-2 top-0">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth={0}
                            viewBox="0 0 16 16"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </div>
                        <label className="form-label" htmlFor="form12">
                          Source Name
                        </label>
                      </div>
                      {errors.leadSource && (
                        <small className="text-danger">
                          {errors.sourceName.message}
                        </small>
                      )}
                    </div>

                  } */}

                  {/* 
                  <div className="mb-3">
                    <div className="form-outline ">
                      <select
                        style={errors.budget && InvalidClass}
                        name="leadZone"
                        type="text"
                        {...register("leadZone", {
                          // required: "requirements is Required",
                        })}
                        onKeyUp={() => {
                          trigger("leadZone");
                        }}
                        className="form-control form-control-lg border capitalize "
                        placeholder="city"
                      // {...register("city", {
                      //   // required: "City is Required",
                      // })}
                      >
                        {reqbudget.map((option, i) => (
                          <option
                            key={i}
                            className="capitalize"
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </select>
                      <div className="position-absolute end-0 m-2 top-0">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </div>
                      <label className="form-label" htmlFor="form12">
                        Lead Zone
                      </label>
                    </div>
                    {errors.leadZone && (
                      <small className="text-danger">
                        {errors.leadZone.message}
                      </small>
                    )}
                  </div> */}

                  {userData?.rolesAssigned?.crm?.canAssign && (
                    <div className="mb-3">
                      <div className="form-outline ">
                        <select
                          style={errors.budget && InvalidClass}
                          name="assignedTo"
                          type="text"
                          {...register("assignedTo", {
                            // required: "requirements is Required",
                          })}
                          onKeyUp={() => {
                            trigger("assignedTo");
                          }}
                          className="form-control form-control-lg border capitalize "
                          placeholder="AssignTo"
                        // {...register("assignedTo", {
                        //   required: "Assign is Required",
                        // })}
                        >
                          {/* <option value={null} className="capitalize">
                            UnAssigned
                          </option> */}
                          {[
                            { _id: null, fullName: "UnAssigned" },
                            ...teamLeadArr,
                          ]?.map((option, i) => (
                            <option
                              key={i}
                              className="capitalize"
                              value={option._id}

                            >
                              {option.fullName}
                            </option>
                          ))}
                        </select>
                        <div className="position-absolute end-0 m-2 top-0">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth={0}
                            viewBox="0 0 16 16"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                            />
                          </svg>
                        </div>
                        <label htmlFor="form12" id="myid">
                          Assign To
                        </label>
                      </div>
                      {errors.assignedTo && (
                        <small className="text-danger">
                          {errors.assignedTo.message}
                        </small>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer justify-content-end ">
                <button
                  type="button"
                  className="btn btn-light bg-grey1"
                  data-mdb-dismiss="modal"
                  id="leadmodalclose"
                  onClick={() => (reset(
                    {
                      name: '',
                      email: '',
                      address: '',
                      phoneNumber: '',
                      budget: '',
                    }
                  ))}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary box-shadow-none"
                >
                  Add Client
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLeadModal;