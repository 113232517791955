import React, { useContext, useEffect, useState } from "react";
import "../index.css";
import Select from 'react-select';
import desquoContext from "../../../picontext/DashBoard/desquoContext";
import { getReq, postReq } from "../../../piservices/apis";
import expf, { constants, base_url } from "../../../piservices/constants";
import {  Tooltip } from "antd";
import {
    baseUrl,
    formateDate,
    valueToLeadStatus,
} from "../../../piservices/compCommon";
import PiAlert from "../../PiAlert/PiAlert";
import { BsCheck2, BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { HiOutlineClipboardList } from "react-icons/hi";
import { motion } from "framer-motion";
import { getToken } from "../../../piservices/authService";
import { Button, Modal, Form } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setIsChecked, setEditLead, setSelectedUnassignedLead } from "../../../Redux/Slices/lmsSlice";
import { useParams } from "react-router-dom";
import LockBlack from '../../../Assets/LockBlack.svg'
import toast from "react-hot-toast";
import useApiFetchCustomHook from "../../../piservices/ApiFetchCustomHook";
import { updateCallStatusUpdatePanel } from "../../../Redux/Slices/specificleadSlice";
import useApiFetchForLeadCountCustomHook from "../../../piservices/ApiFetchForLeadCount";
const UnAssignedSidebar = () => {
const {apiFetch} =  useApiFetchCustomHook()

    let { tab } = useParams();
    const [onEditLead, setEditLead] = useState(false)
    const context = useContext(desquoContext);
    const [assignId, setAssignId] = useState()
    const {
        activeLead,
        setActiveLeadState,
        setLeads,
    } = context;
    const {
        name,
        phoneNumber,
        assignedTo,
        email,
    } = activeLead;
    const [assignToValue, setAssignToValue] = useState(assignedTo)
    
  const selectedUnassignedLead = useSelector((state) => state.lmsSlice.selectedUnassignedLead);
  const { getLeadsCount } = useApiFetchForLeadCountCustomHook()

    const dispatch = useDispatch()
    const [teamLeadArr, setTeamLeadArr] = useState([]);
    const [form] = Form.useForm()
  const [buttonClickedOnce, setButtonClickedOnce] = useState(false)
    const [userData, setUserData] = useState({});



    const handleAssignChange = (value) => {
        setAssignId(value?.value);
        setAssignToValue({
            fullName: value?.label,
            _id: value?.value,
            email: value?.email
        })
    }
    const getUserbyId = async (id) => {
        const res = await getReq(`${constants.CRM_API}/user/getUserById?userId=${id}`);
        if (res && !res.error) {
            setUserData(res?.data?.data);
        } else {
            console.log(res.error);
        }
    };
    function isObjectBlank(obj) {
        return Object.keys(obj).length === 0;
    }
    useEffect(() => {
        getUserbyId(localStorage.getItem("userId"));
    }, []);
    const getTeamLeads = async () => {
        const res = await getReq(`${constants.CRM_API}/api/enterprise/get-team-leaders?userId=${localStorage.getItem("userId")}&access=1`);
        if (res && !res.error) {
            setTeamLeadArr(res.data.data);
        } else {
            console.log(res.error);
        }
    };
    const assignLead = async () => {
        setButtonClickedOnce(true)
        let differenceFields = []
        if (activeLead?.assignedTo) {
    
          differenceFields.push(`The Lead has been reassigned from ${activeLead?.assignedTo?.fullName} to ${assignToValue?.fullName}`)
        } else {
          differenceFields.push(`The Lead has been assigned to ${assignToValue?.fullName}`)
        }
        const res = await postReq(
            `${constants.CRM_API}/user/edit-lead?id=${activeLead?._id
            }&userId=${localStorage.getItem("userId")}`,
            {
                // leadId: activeLead?._id,
                assignedTo: assignId,
                logText: differenceFields
            }
        );
        if (res && !res.error) {
            setButtonClickedOnce(false)
            getLeadsCount()
            toast.success(res?.message || "Assign Lead Successfully", {
                autoClose: 3000,
              });
            form.resetFields();
            setAssignId()
            // dispatch(setEditLead(true));
            dispatch(updateCallStatusUpdatePanel(false))

            apiFetch(tab)
            await setActiveLeadState({ ...activeLead, assignedTo: assignToValue });
        } else {
            console.log(res.error);
            setButtonClickedOnce(false)
            toast.error(res?.message || "Oops Something Went Wrong", {
                autoClose: 3000,
              });
        }
    };

    const assignMultipleLeads = async ()=>{
        setButtonClickedOnce(true)
        const res = await postReq(
            `${constants.CRM_API}/user/assign-multiple-leads`,
            {
                // leadId: activeLead?._id,
                leadIds:selectedUnassignedLead,
                assignedTo: assignId,
            }
        );
        if (res && !res.error) {
            setButtonClickedOnce(false)
            toast.success(res?.message || "Assign Lead Successfully", {
                autoClose: 3000,
              });
            form.resetFields();
            setAssignId()
            // dispatch(setEditLead(true));
            dispatch(setSelectedUnassignedLead([]));
            dispatch(updateCallStatusUpdatePanel(false))
            apiFetch(tab)
            // await setActiveLeadState({ ...activeLead, assignedTo: assignToValue });
        } else {
            console.log(res.error);
            setButtonClickedOnce(false)
            toast.error(res?.message || "Oops Something Went Wrong", {
                autoClose: 3000,
              });
           
        }
    }
    const handleClick =() => {
        if(selectedUnassignedLead?.length>1){
            assignMultipleLeads();
        }else{
            assignLead()
        }
    }
    useEffect(() => {
        getTeamLeads();
    }, []);
    let isLead = window.location.href?.includes("lead-details");
    return (
        <div className="br-5 mv-br-0 mv-vh-50 " style={{ width: window.screen.width > 1024 ? isLead ? "100%" : "60%" : isLead ? "100%" : "95%", position: "sticky", top: "0px", border: '1px solid #EDEDED' }}>
            <div
                className="pt-3 pt-md-2 px-3 px-md-2 py-2"
                style={{
                    borderRadius: '8px 8px 0px 0px',
                    background: 'linear-gradient(90deg, #0084EF 0%, #0063B2 100%)',
                    color: "#ffffff",
                }}
            >
                {selectedUnassignedLead?.length>1 &&<div className="d-flex justify-content-between mb-2 align-items-center">
                    <div className="d-flex align-items-center w-100 justify-content-between mt-2">
                        <div className="d-flex align-items-center w-100 justify-content-between mt-2">
                            <h6 className="fw-bold mb-0">{`${selectedUnassignedLead?.length} Leads Selected`}</h6>
                        </div>
                    </div>
                </div>}
                
              {  selectedUnassignedLead?.length<2 &&<div className="d-flex justify-content-between mb-2 align-items-center">
                    <div className="d-flex align-items-center w-100 justify-content-between mt-2">
                        <div className="d-flex align-items-center w-100 justify-content-between mt-2">
                            <h6 className="fw-bold mb-0">{name}</h6>
                        </div>
                    </div>
                </div>}
                {selectedUnassignedLead?.length<2 && phoneNumber && (
                    <div className="d-flex align-items-center">
                        {/* <img src={vector4} alt="" width={12} height={12} className="" /> */}
                        <BsTelephone fontSize={12} />
                        <span
                            style={{ color: "#ffffff" }}
                            className="pfs-12 mv-pfs-14 ms-2"
                        >
                            {phoneNumber}
                        </span>
                    </div>
                )}
                {selectedUnassignedLead?.length<2 &&email && (
                    <div className="d-flex align-items-center">
                        {/* <img src={vector0} alt="" width={12} height={12} className="" /> */}
                        <AiOutlineMail />
                        <span
                            style={{ color: "#ffffff" }}
                            className="pfs-12 mv-pfs-14 ms-2"
                        >
                            {email}
                        </span>
                    </div>
                )}
            </div>
            <div className="px-3 pb-2">
                <div className="mt-2">
                    <p className="fw-bold mb-0">
                        Assign To
                    </p>
                </div>
                <div className="mt-3"
                    style={{
                        display: "flex",
                        flexFlow: "column",
                        justifyContent: "space-between",
                    }}
                >

                    {!isObjectBlank(userData) && (!userData?.rolesAssigned?.crm?.canAssign && 
                         <Tooltip title="Access Disabled by the Admin" style={{ display: "flex", alignItems: "center", color: "#00000040" }}><span style={{display:'flex'}}><Select
                         className="basic-single"
                         classNamePrefix="select"
                         // defaultValue={colourOptions[0]}
                         isDisabled={true}
                         // isLoading={isLoading}
                         isClearable={true}
                         // isRtl={isRtl}
                         isSearchable={true}
                         onChange={handleAssignChange}
                         name="assignTo"
                         options={(
                             [...teamLeadArr] || []
                         ).map((d) => ({
                             value: d._id,
                             label: d.fullName,
                             email: d.email
                         }))}
                     /><div><img src={LockBlack} alt="LockImg"></img></div></span></Tooltip>)}

                    {!isObjectBlank(userData) && (userData?.rolesAssigned?.crm?.canAssign && <Select
                        className="basic-single"
                        classNamePrefix="select"
                        // defaultValue={colourOptions[0]}
                        // isDisabled={true}
                        // isLoading={isLoading}
                        isClearable={true}
                        // isRtl={isRtl}
                        isSearchable={true}
                        onChange={handleAssignChange}
                        name="assignTo"
                        options={(
                            [...teamLeadArr] || []
                        ).map((d) => ({
                            value: d._id,
                            label: d.fullName,
                            email: d.email
                        }))}
                    />)}

                  
                    <div className="d-flex w-100 justify-content-end mv-position-fixed bottom-0 end-0 p-3 pe-md-0 pb-md-0">
                        <button className="btn btn-primary mv-w-100 bg-base-blue box-shadow-none py-3 py-md-2 mv-pfs-14"
                            disabled={buttonClickedOnce}
                            onClick={handleClick}
                            style={{
                                borderRadius: '8px',
                                background: assignId ? '#0084EF' : '#DDD'
                            }}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnAssignedSidebar