import React, { useContext, useEffect, useRef, useState } from "react";
import phoneIcon from "../images/Phone.svg";
import EmailIcon from "../images/Email.svg";
import Mappin from "../images/Mappin.svg";
import refresh from "../images/ArrowClockwise.svg";
import addTeam from "../images/UserCirclePlus.svg";
import { TextField } from "@mui/material";
import axios from 'axios'
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  Modal,
  Offcanvas,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { constants } from "../../../piservices/constants";
import toast from "react-hot-toast";
import { linkUploaded, setIsLeadUploaded, setIsLinkUploaded } from "../../../Redux/Slices/lmsProjectSlice";
import "./ProjectDetailSidebar.css";
import desquoContext from "../../../picontext/DashBoard/desquoContext";
function ProjectDetailSidebar(props) {
  const [difference, setDifference] = useState("");
  const [inviteClientModal, setInviteClientModal] = useState(false);
  const [clientEmail, setClientEmail] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [projectAdminName, setProjectAdminName] = useState("");
  const [additionalDetail, showAdditionalDetail] = useState(false);
  const [revokeModal, setRevokeModal] = useState(false);
  const [superAdminCheck, setSuperAdminCheck] = useState(false);
  const [addNotes, setAddNotes] = useState(false);
  const [notesText, setNotesText] = useState("");
  const uploadAttachmentFile = useRef(null);
  const [clientInvite, setClientInvite] = useState(0);
  const [clientReload, setClientReload] = useState(false);
  const [teamMemberArr, setTeamMemberArr] = useState("");
  const [notes, setNotes] = useState(null);
  const [uploadedItem, setUploadedItem] = useState("");
  const context = useContext(desquoContext);
  const { leads, setLeads, activeLead } = context;
  const [currProjectData, setCurrProjectData] = useState("");
  const dispatch = useDispatch()
  const [uploadedLink, setUploadedLink] = useState(null);

  const [submitNotes, setSubmitNotes] = useState(false);
  const profileData = useSelector((state) => state.lmsSlice.profileData);
  let isLead = window.location.href?.includes("lead-details");
  const isLinkUploaded = useSelector(
    (state) => state.lmsProjectSlice.isLinkUploaded
  );
  const uploadLinkArr = useSelector((state) => state.lmsProjectSlice.uplaodLinkArr);
  const sendNotes = async () => {
    if (notesText !== "") {
      const res = await axios.post(
        `${constants.PMT_API
        }/api/projects/save-notes?userId=${localStorage.getItem(
          "userId"
        )}&projectId=${activeLead?.projectId}`,
        {
          text: notesText,
        }
      );
      if (res) {
        setSubmitNotes(true);
        let resData = res?.data?.updatedProject?.notes;
        setNotes(resData?.reverse());
      }
    }
  };
  // Delete attachments
  const formatIndianNumber = (number) => {
    return number.toLocaleString("en-IN");
  };

  function numberWithCommas(x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
    return x;
  }

  console.log('activeleadddinprojectdetails', activeLead?.projectId)
  const projectById = async () => {
    try {
      const res = await axios.get(
        `${constants.PMT_API
        }/api/projects/getProjects?userId=${localStorage.getItem(
          "userId"
        )}&projectId=${activeLead?.projectId}`
      );
      if (res) {
        console.log('projectdertailsinlms', res)
        setCurrProjectData(res?.data?.projects[0]);
        dispatch(setIsLinkUploaded(res?.data?.projects[0]?.links));
      }
    }
    catch (err) {
      console.log('erorr', err)
    }

  };
  const deleteAttachment = async (data) => {
    const res = axios.post(
      `${constants.PMT_API}/api/projects/deleting-link?projectId=${currProjectData?._id}&linkId=${data?._id}`
    );

    if (res) {
      projectById();
    }
    if (!res) {
      alert("Something happened wrong!");
    }
  };
  const createdByName = async () => {
    try {
      const res = await axios.get(
        `${constants.CRM_API}/user/getUserById?userId=${currProjectData?.userId}`
      );
      if (res) {
        setCreatedBy(res?.data?.data?.fullName);
      }
    } catch (error) {
      console.error(error);
    }
    
  };
  const handleRevokeAccess=async()=>{
    const res = await axios.post(`${constants.CRM_API}/user/update-isblocked?email=${clientEmail}`)
    .then((res)=>{
      toast.success("Access Revoked Successfully")
      getClientInvitation()
      setRevokeModal(false)
    })
    }
    
  const getClientInvitation = async(data)=>{
    const res = await axios.get(
      `${constants.CRM_API}/user/getUserById?userId=${data}`
    );
    if(res){
      
      setClientInvite(res?.data?.data?.joinStatus)
    }
  }
  const inviteClientStatus = async ()=>{
    const res = await axios.post(`${constants.PMT_API}/api/projects/invite-client?projectId=${currProjectData._id}&userId=${localStorage.getItem("userId")}`)
    if(res){
      // console.log("Hey")
      toast.success("Client Invited Successfully")
      setInviteClientModal(false);
      setClientReload(!clientReload)
      getClientInvitation()
    }
  }
  const projectAdmin = async () => {
    const res = await axios.get(
      `${constants.CRM_API}/user/getUserById?userId=${currProjectData?.projectAdmin}`
    );
    if (res) {
      setProjectAdminName(res?.data?.data?.fullName);
    }
  };
  useEffect(() => {
    projectAdmin()
    createdByName();
  }, [currProjectData]);
  //Dates difference function
  useEffect(() => {
    for(let i=0;i<currProjectData?.projectMembers?.length;i++){
      if(currProjectData?.projectMembers[i]?.isClient == true){
        getClientInvitation(currProjectData?.projectMembers[i]?._id)
      }
    }
  }, [clientReload])
  useEffect(() => {
    setClientReload(false)
    for(let i=0;i<currProjectData?.projectMembers?.length;i++){
      if(currProjectData?.projectMembers[i]?.isClient == true){
        getClientInvitation(currProjectData?.projectMembers[i]?._id)
      }
    }
  }, [currProjectData]);
  const calculateDifference = () => {
    const start = new Date(currProjectData?.createdAt);
    const end = new Date();

    if (!isNaN(start) && !isNaN(end)) {
      const timeDifference = Math.abs(end - start);
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      setDifference(`${daysDifference}`);
    } else {
      setDifference("Invalid dates");
    }
  };
  useEffect(() => { projectById() }, [activeLead?.projectId])
  useEffect(() => {
    calculateDifference();
    for(let i =0 ;i<currProjectData?.projectMembers?.length;i++){
      
      if(currProjectData?.projectMembers[i]?.isClient){
        setClientEmail(currProjectData?.projectMembers[i]?.email)
      }
    }

  }, [currProjectData]);
  const handleFileUpload = async (e) => {
    // console.log("Called");

    let uploadIt = e.target.files;

    let file = new FormData();

    file.append("bucketName", "idesign-quotation");
    file.append("files", uploadIt[0]);
    // console.log({ file });
    const res = await axios
      .put(`${constants.S3_API}/api/upload/`, file)

      .then(async (res) => {
        const resLink = await axios.post(
          `${constants.PMT_API}/api/projects/save-links?projectId=${currProjectData?._id
          }&userId=${localStorage.getItem("userId")}`,
          {
            link: res.data.locations[0],
          }
        );
        setUploadedLink(resLink?.data?.updatedProject?.links);

        dispatch(setIsLinkUploaded(resLink?.data?.updatedProject?.links));

        dispatch(linkUploaded(true));
        file = new FormData();
        uploadIt = "";
      })

    // .catch((err) => console.log(err));
    // console.log({ res });

    let x = e.target.value.split("\\");
    setUploadedItem(x[x.length - 1]);
  };
  const sortAlphabatically = (data) => {
    const sortedItems = [...data].sort((a, b) => {
      const nameA = a.fullName.toUpperCase();
      const nameB = b.fullName.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    setTeamMemberArr(sortedItems);
  };

  const getTime = (date) => {
    if (date === undefined || date === null) {
      return "-";
    } else if (typeof date === "string") {
      date = new Date(date);
    }

    let day = date.getDate();
    let month = date.toLocaleDateString("default", { month: "numeric" });
    let year = date.getFullYear() % 100;
    let ans = "";
    ans += day + "/";
    ans += month + "/";
    ans += year;
    return ans;
  };
  const redirectToGoogleMaps = (data) => {
    const encodedAddress = encodeURIComponent(data.location + " " + data.city);
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    window.open(googleMapsUrl);
  };
  const redirectToGoogleMapsLocation = (data) => {
    const encodedAddress = encodeURIComponent(data);
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    window.open(googleMapsUrl);
  };
  const copyPhoneNumber = (data) => {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        toast.success("Phone number copied!");
      })
      .catch((error) => {
        console.error("Failed to copy phone number: ", error);
      });
  };

  return (
    <div
      className="br-5 mv-br-0 mv-vh-50 "
      style={{
        width:
          window.screen.width > 1024
            ? isLead
              ? "100%"
              : "60%"
            : isLead
              ? "100%"
              : "95%",
        position: "sticky",
        top: "0px",
        padding:'1rem',
        border: "1px solid #EDEDED",
      }}
    >
      <>
        <div
          className="projectNameContainer"
          onClick={(event) => event.stopPropagation()}
        >
          <span className="projectName">
            {currProjectData?.name
              ? currProjectData?.name.charAt(0).toUpperCase() +
              currProjectData?.name.slice(1)
              : "NA"}
          </span>
          {/* <img src={threeDot} alt="" /> */}
          <div className="onGoingData">
            {" "}
            <span style={{ color: "#212529", fontSize: "0.624rem" }}>
              On going for
            </span>
            <span className="goingDateText">{difference} </span>
            <span className="goingDateTextDays">Days </span>
          </div>
        </div>
        <div className="projectCreationContainerOver">
          <div className="projectCreationDetails">
            <div className="createdByContainer">
              <span className="createdByText">Created By</span>
              <span className="createdByName">
                {" "}
                {createdBy
                  ? createdBy.charAt(0).toUpperCase() + createdBy.slice(1)
                  : "NA"}
              </span>
            </div>

            <div className="createdByContainer">
              <span className="createdByText">Start Date</span>
              <span className="createdByName">
                {currProjectData?.startDate == ""
                  ? "Not Started"
                  : getTime(currProjectData?.startDate)}
              </span>
            </div>
            <div className="createdByContainer">
              <span className="createdByText">Share with Client</span>
              <div className="clientInviteCont">
                <span className="createdByName">
                  {clientInvite == 0
                    ? "Not Invited"
                    : clientInvite == 1
                      ? "Not Joined"
                      : "Joined"}
                </span>

                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip">Invite Client</Tooltip>}
                >
                  <img
                    onClick={(event) => (
                      event.stopPropagation(), setInviteClientModal(true)
                    )}
                    src={refresh}
                    alt=""
                    style={{
                      cursor: "pointer",
                      marginLeft: "10%",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </OverlayTrigger>
                {/* {clientInvite == 0 &&(
                  <div className="clientInvitebtn" onClick={()=>inviteClientStatus()}>
                   <span className="clientInviteSt">Invite Client</span> 
                  </div>
                  )} */}
              </div>
              {clientInvite !== 0 && (
                <span
                  className="revokeAccess"
                  onClick={() => setRevokeModal(true)}
                >
                  Revoke Access
                </span>
              )}
            </div>
          </div>
          <div className="projectCreationDetails">
            <div className="createdByContainer">
              <span className="createdByText">Created on</span>
              <span className="createdByName">
                {getTime(currProjectData?.createdAt)}
              </span>
            </div>

            <div className="createdByContainer">
              <span className="createdByText">Due Date</span>
              <span className="createdByName">
                {currProjectData?.endDate == ""
                  ? "-"
                  : getTime(currProjectData?.endDate)}
              </span>
            </div>
            <div className="createdByContainer">
              <span className="createdByText">Client Name</span>
              <span className="createdByName">
                {currProjectData?.projectMembers?.some(
                  (item) => item.isClient
                )
                  ? currProjectData?.projectMembers.map((cur, idp) => {
                    return (
                      <div key={idp}>
                        {cur.isClient === true && (
                          <span>
                            {cur?.fullName
                              ? cur?.fullName.charAt(0).toUpperCase() +
                              cur?.fullName.slice(1)
                              : "-"}
                          </span>
                        )}
                      </div>
                    );
                  })
                  : "-"}
              </span>
            </div>
          </div>
          <div className="projectCreationDetails">
            <div className="createdByContainer">
              <span className="createdByText">Project Admin</span>
              <span className="createdByName">
                {projectAdminName
                  ? projectAdminName.charAt(0).toUpperCase() +
                  projectAdminName.slice(1)
                  : "NA"}
              </span>
            </div>

            <div className="createdByContainer">
              <span className="createdByText">Project Member</span>
              <span className="createdByName">
                {currProjectData?.projectMembers?.length < 1 ? (
                  <div className="addTeamCont">
                    <img src={addTeam} alt="" />
                    <span style={{ color: "#0084EF" }}>Add Member</span>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    {currProjectData?.projectMembers?.length > 0
                      ? currProjectData?.projectMembers
                        ?.sort()
                        ?.map((cu, count) => (
                          <>
                            {count < 2 && cu.isClient === false && (
                              <div
                                style={{
                                  zIndex: count,
                                }}
                              >
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip id="button-tooltip">
                                      <span>
                                        {cu?.fullName
                                          ? cu?.fullName
                                            .charAt(0)
                                            .toUpperCase() +
                                          cu?.fullName.slice(1)
                                          : "-"}
                                      </span>
                                    </Tooltip>
                                  }
                                >
                                  <div
                                    className={`teams_${count}`}
                                  // style={{
                                  //   width: "1.5rem",
                                  //   height: "1.5rem",
                                  //   borderRadius: "50%",
                                  //   backgroundColor: "#9747FF",
                                  //   color: "white",
                                  //   display: "flex",
                                  //   alignItems: "center",
                                  //   justifyContent: "center"
                                  // }}
                                  >
                                    {" "}
                                    <span>
                                      {cu?.fullName?.charAt(0)
                                        ? cu?.fullName
                                          ?.charAt(0)
                                          .charAt(0)
                                          .toUpperCase() +
                                        cu?.fullName?.charAt(0).slice(1)
                                        : "-"}
                                    </span>
                                  </div>
                                </OverlayTrigger>
                              </div>
                            )}
                          </>
                        ))
                      : "-"}
                    {currProjectData?.projectMembers?.length > 3 && (
                      <Dropdown style={{ width: "35%" }}>
                        <Dropdown.Toggle
                          style={{
                            border: "none",
                            background: "none",
                            marginTop: "-5%",
                            marginLeft: "-18%",
                            width: "5vw",
                          }}
                          id="dropdown-basic"
                        >
                          <div
                            style={{
                              color: "#0084EF",
                              fontSize: "0.75rem",
                              fontWeight: "600",
                              display: "flex",
                              marginLeft: "-12%",
                              alignItems: "center",
                              width: "4vw",
                            }}
                            onClick={() =>
                              sortAlphabatically(
                                currProjectData?.projectMembers
                              )
                            }
                          >
                            {" "}
                            +{currProjectData?.projectMembers?.length -
                              3}{" "}
                            more
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          style={{
                            padding: "0.5rem 0rem",
                            borderRadius: "0.5rem",
                            maxHeight: "23vh",
                            overflow: "scroll",
                          }}
                          className="teamMemDropDown"
                        >
                          {teamMemberArr.length > 0 &&
                            teamMemberArr?.map((cur, count) => {
                              return (
                                <>
                                  {cur.isClient === false && (
                                    <Dropdown.Item>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItem: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "1.2rem",
                                            height: "1.2rem",
                                            borderRadius: "50%",
                                            backgroundColor: "#9747FF",
                                            color: "white",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontSize: "0.7rem",
                                          }}
                                        >
                                          {" "}
                                          {cur?.fullName?.charAt(0)
                                            ? cur?.fullName
                                              ?.charAt(0)
                                              .charAt(0)
                                              .toUpperCase() +
                                            cur?.fullName?.charAt(0).slice(1)
                                            : "-"}
                                        </div>
                                        <span
                                          style={{
                                            marginLeft: "5%",
                                            fontSize: "0.7rem",
                                            width: "68%",
                                          }}
                                        >
                                          {cur?.fullName
                                            ? cur?.fullName
                                              .charAt(0)
                                              .toUpperCase() +
                                            cur?.fullName.slice(1)
                                            : "NA"}
                                        </span>
                                      </div>
                                    </Dropdown.Item>
                                  )}
                                </>
                              );
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                      // <OverlayTrigger
                      //   placement="top"
                      //   delay={{ show: 250, hide: 400 }}
                      //   overlay={
                      //     <Tooltip id="button-tooltip">
                      //       {currProjectData?.projectMembers
                      //         ?.sort()
                      //         ?.map((cur, count) => {
                      //           return (
                      //             <>
                      //               {cur.isClient === false && (
                      //                 <span>
                      //                   {cur?.fullName}
                      //                   {", "}
                      //                 </span>
                      //               )}
                      //             </>
                      //           );
                      //         })}
                      //     </Tooltip>
                      //   }
                      // >

                      // </OverlayTrigger>
                    )}
                  </div>
                )}
              </span>
            </div>
            <div className="createdByContainer1">
              <span className="createdByText">Company Name</span>
              <span className="createdByName">
                {currProjectData?.companyName
                  ? currProjectData?.companyName.charAt(0).toUpperCase() +
                  currProjectData?.companyName.slice(1)
                  : "NA"}
              </span>
            </div>
          </div>
        </div>

        <div className="contactDetailsContainer">
          <span className="contactHeader">Contact Details</span>

          {currProjectData?.projectMembers?.map((curr, i) => {
            return (
              <>
                {curr?.isClient === true && (
                  <>
                    <div className="contactDetailsPhone">
                      <img
                        src={phoneIcon}
                        alt=""
                        style={{ height: "1.5625rem", cursor: "pointer" }}
                        onClick={() => copyPhoneNumber(curr?.phoneNumber)}
                      />
                      <div className="phoneNumber" style={{ width: "22vw" }}>
                        <span className="detailText">Phone Number</span>
                        <span className="detailData">{curr?.phoneNumber}</span>
                      </div>
                    </div>
                    <div className="contactDetailsEmail">
                      <img
                        src={EmailIcon}
                        alt=""
                        style={{ height: "1.5625rem", cursor: "pointer" }}
                        onClick={() =>
                          (window.location = `mailto:${curr?.email}`)
                        }
                      />
                      <div className="phoneNumber" style={{ width: "22vw" }}>
                        <span className="detailText">Email</span>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Tooltip id="button-tooltip">{curr?.email}</Tooltip>
                          }
                        >
                          <span
                            className="detailData"
                            style={{ cursor: "pointer" }}
                          >
                            {curr?.email}
                          </span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </>
                )}
              </>
            );
          })}

          <div className="contactDetailsLocation">
            <img
              src={Mappin}
              alt=""
              style={{ height: "1.5625rem", cursor: "pointer" }}
              onClick={() => redirectToGoogleMaps(currProjectData)}
            />
            <div className="phoneNumber" style={{ width: "22vw" }}>
              <span className="detailText">Address</span>
              <span
                className="detailData"
                style={{ cursor: "pointer" }}
                onClick={() => redirectToGoogleMaps(currProjectData)}
              >
                {currProjectData?.location}
              </span>
            </div>
          </div>
          <div className="contactDetailsLocation">
            <div alt="" style={{ height: "1.5625rem", width: "1.5625rem" }}>
              {" "}
            </div>
            <div className="phoneNumber" style={{ width: "22vw" }}>
              <span className="detailText">Country</span>

              <span className="detailData">{currProjectData?.country}</span>
            </div>
          </div>
          <div className="contactDetailsLocation">
            <div alt="" style={{ height: "1.5625rem", width: "1.5625rem" }}>
              {" "}
            </div>
            <div className="phoneNumber" style={{ width: "22vw" }}>
              <span className="detailText">City</span>

              <span className="detailData">{currProjectData?.city}</span>
            </div>
          </div>

          <div className="contactDetailsLocation">
            <div alt="" style={{ height: "1.5625rem", width: "1.5625rem" }}>
              {" "}
            </div>
            <div className="phoneNumber" style={{ width: "22vw" }}>
              <span className="detailText">Zip/Postal code</span>
              {currProjectData?.projectMembers?.map((curr, i) => {
                return (
                  <>
                    {curr?.isClient === true && (
                      <span className="detailData">{curr?.pinCode}</span>
                    )}
                  </>
                );
              })}
            </div>
          </div>
          <div className="contactDetailsLocation">
            <div alt="" style={{ height: "1.5625rem", width: "1.5625rem" }}>
              {" "}
            </div>
            <div className="phoneNumber" style={{ width: "22vw" }}>
              <span className="detailText">Currency</span>

              <span className="detailData">
                {profileData?.CompanySettingDetails?.currencySymbol}
              </span>
            </div>
          </div>
          <div className="contactDetailsLocation">
            <div alt="" style={{ height: "1.5625rem", width: "1.5625rem" }}>
              {" "}
            </div>
            <div className="phoneNumber" style={{ width: "22vw" }}>
              <span className="detailText">Location</span>
              <span
                className="detailData"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  redirectToGoogleMapsLocation(currProjectData?.mapAddress)
                }
              >
                {currProjectData?.mapAddress
                  ? currProjectData?.mapAddress
                  : "-"}
              </span>
            </div>
          </div>

          <div
            className="addAdditionalInfo"
            onClick={() => showAdditionalDetail(!additionalDetail)}
          >
            <span className="addAdditionalInfoText">Additional Details</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M15.2219 7.34787L9.59692 12.9729C9.51854 13.0515 9.42539 13.1139 9.32283 13.1565C9.22027 13.1991 9.11032 13.221 8.99927 13.221C8.88822 13.221 8.77826 13.1991 8.6757 13.1565C8.57314 13.1139 8.48 13.0515 8.40161 12.9729L2.77661 7.34787C2.6181 7.18936 2.52905 6.97438 2.52905 6.75021C2.52905 6.52605 2.6181 6.31107 2.77661 6.15256C2.93512 5.99405 3.1501 5.905 3.37427 5.905C3.59843 5.905 3.81341 5.99405 3.97192 6.15256L8.99997 11.1806L14.028 6.15185C14.1865 5.99335 14.4015 5.9043 14.6257 5.9043C14.8498 5.9043 15.0648 5.99335 15.2233 6.15185C15.3818 6.31036 15.4709 6.52535 15.4709 6.74951C15.4709 6.97368 15.3818 7.18866 15.2233 7.34717L15.2219 7.34787Z"
                fill="#0084EF"
              />
            </svg>
          </div>

          {additionalDetail && (
            <>
              <div className="projectCreationContainerOver">
                <div className="projectCreationDetails">
                  <div className="createdByContainer">
                    <span className="createdByText">Expected Budget</span>
                    <span className="createdByName">
                      {" "}
                      {/* {currProjectData?.expectedBudget
                    ? formatIndianNumber(currProjectData?.expectedBudget)
                    : "-"} */}
                      {currProjectData?.expectedBudget
                        ? numberWithCommas(currProjectData?.expectedBudget)
                        : "-"}
                    </span>
                  </div>

                  <div className="createdByContainer">
                    <span className="createdByText">Area</span>
                    <span className="createdByName">
                      {currProjectData?.area
                        ? formatIndianNumber(currProjectData?.area)
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className="projectCreationDetails">
                  <div className="createdByContainer">
                    <span className="createdByText">Expected Start Date</span>
                    <span className="createdByName">
                      {currProjectData?.expectedStartDate == ""
                        ? "Not Started"
                        : getTime(currProjectData?.expectedStartDate)}
                    </span>
                  </div>

                  <div className="createdByContainer">
                    <span className="createdByText">Tax Bill To</span>
                    <span className="createdByName">
                      {currProjectData?.billTo
                        ? currProjectData?.billTo
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className="projectCreationDetails">
                  <div className="createdByContainer">
                    <span className="createdByText">
                      Expected Delivery Date
                    </span>
                    <span className="createdByName">
                      {currProjectData?.expectedEndDate == ""
                        ? "-"
                        : currProjectData?.expectedEndDate}
                    </span>
                  </div>

                  <div className="createdByContainer">
                    <span className="createdByText">Tax Reg No.</span>
                    <span className="createdByName">
                      {currProjectData?.gstRegNo
                        ? currProjectData?.gstRegNo
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="projectAdditionalDetail">
                <div className="createdByContainer">
                  <span className="createdByText">Billing City</span>
                  <span className="createdByName">
                    {" "}
                    {currProjectData?.billingCity
                      ? currProjectData?.billingCity
                      : "-"}
                  </span>
                </div>

                <div className="createdByContainer">
                  <span className="createdByText">Billing Address</span>
                  <span className="createdByName">
                    {currProjectData?.billingAddress
                      ? currProjectData?.billingAddress
                      : "-"}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="attachmentContainer">
          <div className="attachmentHeader">
            <span className="attachmentText">Attachments</span>
            {(currProjectData?.projectAdmin ==
              localStorage.getItem("userId") ||
              superAdminCheck) &&
              (
                <>
                  <div className="uploadText">
                    {" "}
                    <input
                      style={{ cursor: "pointer" }}
                      type="file"
                      ref={uploadAttachmentFile}
                      accept=".doc,.docx,.png,.jpg,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onChange={(e) => handleFileUpload(e)}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                      multiple
                    />
                    <div
                      className="addBtn"
                      onClick={() => uploadAttachmentFile.current.click()}
                    >
                      <span style={{ color: "#0084EF" }}>+</span>
                      <span className="addText">Add</span>
                    </div>
                  </div>
                </>
              )}
          </div>
          <div className="groupAttachment">
            {isLinkUploaded ? (
              <>
                {uploadLinkArr?.length == 0 && (
                  <span
                    style={{
                      fontSize: "0.75rem",
                      color: "#888888",
                      paddingLeft: "10%",
                    }}
                  >
                    No Attachments!
                  </span>
                )}
                {uploadLinkArr?.map((elem, i) => {
                  return (
                    <div className="attachmentItems">
                      <div className="image_cross">
                        <img
                          src={`${elem?.link}`}
                          style={{ height: "5.3125rem", width: "5.375rem" }}
                          alt=""
                          onClick={() => window.open(elem?.link)}
                        />
                        <button
                          className="cross_img"
                          onClick={() => (
                            deleteAttachment(elem), dispatch(linkUploaded(true))
                          )}
                        >
                          X
                        </button>
                      </div>

                      <span className="attachmentName">
                        {elem?.link?.substring(
                          elem?.link?.lastIndexOf("/") + 1
                        )}
                      </span>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {uploadLinkArr?.length == 0 && (
                  <span
                    style={{
                      fontSize: "0.75rem",
                      color: "#888888",
                      paddingLeft: "10%",
                    }}
                  >
                    No Attachments!
                  </span>
                )}
                {currProjectData?.links?.map((elem, i) => {
                  return (
                    <div className="attachmentItems">
                      <div className="image_cross">
                        <img
                          src={`${elem?.link}`}
                          style={{ height: "5.3125rem", width: "5.375rem" }}
                          alt=""
                          onClick={() => window.open(elem?.link)}
                        />

                        <button
                          className="cross_img"
                          onClick={() => (
                            deleteAttachment(elem), dispatch(linkUploaded(true))
                          )}
                        >
                          X
                        </button>
                      </div>
                      <span className="attachmentName">
                        {elem?.link?.substring(
                          elem?.link?.lastIndexOf("/") + 1
                        )}
                      </span>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>

        <div className="notesContainer">
          <div
            className="attachmentHeader"
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <span className="attachmentText">Notes</span>
            {addNotes === false &&
              (currProjectData?.projectAdmin ==
                localStorage.getItem("userId") ||
                superAdminCheck) &&
              (
                <div
                  className="addBtn"
                  onClick={() => {
                    setAddNotes(true);
                  }}
                >
                  <span style={{ color: "#0084EF" }}>+</span>
                  <span className="addText">Add</span>
                </div>
              )}
          </div>
          {addNotes && (
            <>
              <div className="notesInputContainer">
                <textarea
                  type="text"
                  className="notesInput"
                  placeholder="Add Notes"
                  onChange={(e) => setNotesText(e.target.value)}
                />
              </div>
              <div className="submitBtn">
                <button
                  className="notesbtnCancel"
                  onClick={() => (setAddNotes(false), setNotesText(""))}
                >
                  {" "}
                  <span className="notesBtnCancelText">Cancel</span>
                </button>
                {notesText != "" ? (
                  <button
                    className="notesbtn"
                    onClick={() => (
                      setAddNotes(false),
                      setNotesText(""),
                      sendNotes(),
                      projectById()
                    )}
                  >
                    {" "}
                    <span className="notesBtnText">Submit</span>
                  </button>
                ) : (
                  <button
                    className="notesbtn"
                    onClick={() => (
                      setAddNotes(false),
                      setNotesText(""),
                      sendNotes(),
                      projectById()
                    )}
                    disabled
                  >
                    {" "}
                    <span className="notesBtnText">Submit</span>
                  </button>
                )}
              </div>
            </>
          )}
          {submitNotes ? (
            <>
              {notes?.length == 0 && (
                <span
                  style={{
                    fontSize: "0.75rem",
                    color: "#888888",
                    paddingLeft: "10%",
                  }}
                >
                  No Notes!
                </span>
              )}
              {notes?.map((elem, i) => {
                return (
                  <div className="notesBody">
                    <span className="notesText">{elem?.text}</span>
                    <span className="notesTime">
                      {getTime(elem?.createdate)}
                    </span>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {currProjectData?.notes?.length == 0 && (
                <span
                  style={{
                    fontSize: "0.75rem",
                    color: "#888888",
                    paddingLeft: "10%",
                  }}
                >
                  No Notes!
                </span>
              )}
              {currProjectData?.notes?.map((elem, i) => {
                return (
                  <div className="notesBody">
                    <span className="notesText">{elem?.text}</span>
                    <span className="notesTime">
                      {getTime(elem?.createdate)}
                    </span>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <Modal
        show={inviteClientModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header style={{ borderBottom: "none" }}>
          <Modal.Title>
            <div className="createProjectModalHeader">
              <span>Invite Client</span>
              <div className="closeBtn" style={{ left: "35%" }}>
                <button
                  className="createModalClose"
                  onClick={() => setInviteClientModal(false)}
                >
                  <span
                    style={{
                      color: "#C9C9C9",
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Cancel{" "}
                  </span>
                </button>
                <button
                  className="createModalSave"
                  onClick={() => inviteClientStatus()}
                >
                  <span
                    style={{
                      color: "#FFF",
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Invite{" "}
                  </span>
                </button>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            style={{ width: "100%" }}
            id="outlined-basic"
            label="Email*"
            variant="outlined"
            disabled
            isRequired={true}
            value={clientEmail}
          />
        </Modal.Body>
      </Modal>
     
      {revokeModal &&
      <Modal
          title={false}
          show={revokeModal}
          centered
          // width={200}
          bodyStyle={{ height: 70 }}
          closable={false}
        >
          <Modal.Body>
            <div style={{ display: "flex" }}>
             
              <span
                style={{
                  marginLeft: "0.6rem",
                  color: "#333",
                  fontFamily: "Inter",
                  fontSize: "1rem",
                  fontWeight: "700",
                }}
              >
               Revoke Access
              </span>
            </div>
            <h6 style={{ marginTop: "2%", paddingLeft:"2%" }}>
              <span
                style={{
                  color: "#333",
                  fontFamily: "Inter",
                  fontSize: "0.9375rem",
                  fontWeight: "400",
                }}
              >
                {" "}
                Are you sure, you want to revoke access?
              </span>
              
              
            </h6>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none", marginTop: "-2%" }}>
            <div className="discardCloseBtn">
              <button
                className="createModalClose"
                onClick={()=>setRevokeModal(false)}
              >
                <span
                  style={{
                    color: "#C9C9C9",
                    fontWeight: "600",
                    fontSize: "0.875rem",
                  }}
                >
                  Cancel{" "}
                </span>
              </button>
              <button className="createModalSave" onClick={()=>handleRevokeAccess()}>
                <span
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                    fontSize: "0.875rem",
                  }}
                >
                  Submit{" "}
                </span>
              </button>
            </div>
          </Modal.Footer>
        </Modal>}
      </>
    </div>
  );
}

export default ProjectDetailSidebar;
