import React, { useContext } from 'react'
import desquoContext from '../../../picontext/DashBoard/desquoContext';
import { setShowEmailModal } from '../../../Redux/Slices/lmsSlice';
import {useDispatch} from 'react-redux';
import "./Leadupdate.css"
const SendMail = ({ text }) => {
    
    const context = useContext(desquoContext);
    console.log('mail statusnnn44: ')
    const { mailStatus, setMailStatus, getActive , isnewlead } = context;
    console.log('mail statusnnn: ', mailStatus)
    const dispatch = useDispatch();
    return (
        <div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckChecked" checked={(getActive === mailStatus?.of) ? mailStatus?.send : false} onChange={(e) => {
                    setMailStatus({ ...mailStatus, "send": e.target.checked, "of": getActive })
                }} />
                <div className=" pfs-12 mv-pfs-14 fw-bold" htmlFor="flexCheckChecked">
                    <label className="form-check-label mb-0">{text}</label>
                    <p className="mb-0 cl-base-blue pfs-12 mv-pfs-14 ms-1  d-inline-block cursor-pointer preview"  onClick={(e) => {
                         dispatch(setShowEmailModal(true));
                        setMailStatus({ ...mailStatus, "of": getActive })
                    }}
                     
                 
                    >
                        Preview & edit
                    </p>
                </div>
            </div>
            <div className={` ${!isnewlead ? "mt-3" : "mt-5"}  mb-2 p-3 br-5`} style={{
                background: "#F5F7FA"
            }}>
                <p className="fw-bold pfs-14 cl-base-blue mb-2">
                    Pro tip
                </p>
                <p className="cl-grey1 fw-normal">
                    Increase your conversion chances by 33% by sending a callback email
                </p>
            </div>

        </div>
    )
}

export default SendMail