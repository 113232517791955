export const isMd = window.innerWidth <= 767;
// export const leadStatusItem = {
//     new: {
//         label: "New",
//         value: 1,
//         visible: false
//     },
//     interested: {
//         label: "Interested",
//         value: 3,
//         templates: {
//             0: {
//                 active: true
//             }
//         }
//     },
//     followUp: {
//         label: "Follow Up",
//         value: 2,
//         templates: {
//             0: {
//                 active: true
//             },
//             1: {},
//             2: {}
//         },

//     },
//     not_interested: {
//         label: "Not Interested",
//         value: 5,
//         // active: true,
//         templates: {
//             0: {
//                 active: true
//             }
//         }
//     },
//     no_response: {
//         label: "No Response",
//         value: 6,
//         templates: {
//             0: {
//                 active: true
//             }
//         }
//     }
// }

export const valueToLeadStatus = {
    1: "new",
    2: "followUp",
    3: "interested",
    4: "signup",
    5: "not_interested",
    6: "no_response",
    7: "meeting_schedule"
}

export const namingKeys = {
    name: 'Name',
    email: 'Email',
    address: 'Address',
    city: 'City',
    pinCode: 'Pin/Zip Code',
    phoneNumber: 'Phone Number',
    budget: 'Budget',
    requirements: 'Work Scope',
    leadSource: 'Lead Source',
    sourceName: 'Source Name',
    area: 'Area',
    areaUnit: 'Area Unit'
}

export const notInterestedReasons=[]

export const valueToLeadStatusFormatted = {
    1: "New",
    2: "Follow Up",
    3: "Interested",
    4: "Sign Up",
    5: "Not Interested",
    6: "No Response",
    7: "Meeting Schedule"
}



export const countryCurrencies = {
    '$': { locale: 'en-US' }, // United States
    '£': { locale: 'en-GB' }, // United Kingdom
    '€': { locale: 'fr-FR' }, // France
    '₹': { locale: 'en-IN' }, // India
    'د.إ': { locale: 'ar-AE' }, // United Arab Emirates (Dubai)
    'A$': { locale: 'en-AU' }, // Australia
    // Add more currencies as needed
  };

export const valueToCallRemarks={
    0:"Call",
    1:"Vr. Meeting",
    2:"Visit"
}



export const improveInput = (val=false , inId) => {
    let allInps = [...document.getElementsByTagName("input"), ...document.getElementsByTagName("select"), ...document.getElementsByTagName("textarea")];
    if(inId){
        allInps = [...document.querySelectorAll("#" + inId + " input"), ...document.querySelectorAll("#" + inId + " select"), ...document.querySelectorAll("#" + inId + " textarea")]
    }
    for (let i = 0; i < allInps.length; i++) {
        const e = allInps[i];
        if (val === true) {
            // alert("here")
            e.parentElement.classList.add("inpactive");
        }else
        e.addEventListener("change", () => {
            if (e.value?.trim() !== "") {
                e.parentElement.classList.add("inpactive")
            } else {
                e.parentElement.classList.remove("inpactive")
            }
        });
    }
}

export const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export function timeFormat12hr(value){
    var input = value.split(':');
    var hours = parseInt(input[0], 10);
    var minutes = input[1];
    var amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // "0" should be "12" in 12-hour format
    hours = (hours < 10) ? '0' + hours : hours;
    value = hours + ':' + minutes + ' ' + amPm;
    return value
}
export function convertTo24Hour(time12h) {
    // Split the time string into hours, minutes, and AM/PM
    var time = time12h.split(':');
    var hours = parseInt(time[0], 10);
    var minutes = parseInt(time[1], 10);
    var period = time[1].split(' ')[1];
    
    // Convert hours to 24-hour format
    if (period === 'PM' && hours < 12) {
        hours = hours + 12;
    }
    if (period === 'AM' && hours === 12) {
        hours = 0;
    }
    
    // Format the hours and minutes to ensure they have two digits
    var hoursStr = hours < 10 ? '0' + hours : hours.toString();
    var minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
    
    // Concatenate the hours and minutes to form the 24-hour time string
    var time24h = hoursStr + ':' + minutesStr;
    
    return time24h;
}
export function convertDateFormat(dateStr) {
    // Split the date string into day, month, and year
    console.log('dateStr',dateStr)
    var parts = dateStr.split('-');
    var day = parseInt(parts[0]);
    var monthIndex = parseInt(parts[1]) - 1; // Months are zero-based in JavaScript
    var year = parseInt(parts[2]);

    // Array of month names
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Create a Date object
    var date = new Date(year, monthIndex, day);

    // Format the date as "DD-MMM-YYYY"
    var formattedDate = day + '-' + monthNames[monthIndex] + '-' + year;

    return formattedDate;
}
export function formateDate(date, type) {
    
    let today = "";
    if(date){
        today = new Date(date)
    }else{
        today = new Date();
    }
    if (!today) {
        return undefined;
    }
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    if (type === "number") {
        return dd + '-' + mm + '-' + yyyy;
    }
    return dd + '-' + mm + '-' + yyyy;
    // return dd + '-' + monthNames[mm - 1]?.slice(0, 3) + '-' + yyyy;
}
export function formateDateNameMonth(date, type) {
    
    let today = "";
    if(date){
        today = new Date(date)
    }else{
        today = new Date();
    }
    if (!today) {
        return undefined;
    }
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    if (type === "number") {
        return dd + '-' + mm + '-' + yyyy;
    }
    // return dd + '-' + mm + '-' + yyyy;
    return dd + '-' + monthNames[mm - 1]?.slice(0, 3) + '-' + yyyy;
}
// export const isnewlead = false;

export function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}

export const InvalidClass = {
    borderColor: "#dc3545",
    paddingRight: "calc(1.5em + 0.75rem)",
    backgroundImage:
        "url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right calc(0.375em + 0.1875rem) center",
    backgroundAize: "calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)",
    position: "relative"
};

export const baseUrl = "/quo-beta";