import React, { useContext} from "react";
import "./index.css";
import { useParams } from "react-router-dom";
import desquoContext from "../../picontext/DashBoard/desquoContext";
import { setSelectedUnassignedLead } from "../../Redux/Slices/lmsSlice";
import { useDispatch, useSelector } from "react-redux";

const LmsTableTh = () => {

  const context = useContext(desquoContext);
  const { leads } = context;
  const selectedUnassignedLead = useSelector(
    (state) => state.lmsSlice.selectedUnassignedLead
  );
  const dispatch = useDispatch();

  let { tab } = useParams();

  const allLeadIds = leads?.data?.map((lead) => lead._id);

  const handleChechBox = (e) => {
    if (e.target.checked) {
      dispatch(setSelectedUnassignedLead([...allLeadIds]));
    } else {
      dispatch(setSelectedUnassignedLead([]));
    }
  };
  return (
    <tr
      className={`bg-grey1 tableHeader`}
      style={{
        outline: "thick solid white",
        borderSpacing: "0",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <th
        scope="col"
        className="  cl-grey1 ws-nowrap "
        style={{
          width: "15%",
          display: "flex",
          alignItems: "center",
          gap: "3px",
        }}
      >
        {tab == 8 && (
          <input
            type="checkbox"
            checked={selectedUnassignedLead.length === allLeadIds.length}
            onChange={(e) => {
              handleChechBox(e);
              //  setActive(e.target.checked);
            }}
          />
        )}
        Client Details
      </th>
      <th
        scope="col"
        className="  cl-grey1 ws-nowrap align-items-center ps-0 d-flex"
        style={{ width: "7%" }}
      >
        Budget
      </th>
      <th
        scope="col"
        className="  cl-grey1 ws-nowrap ps-0 align-items-center d-flex"
        style={{ width: "12.5%" }}
      >
        Scope
      </th>
      <th
        scope="col"
        className="  cl-grey1 ws-nowrap ps-0"
        style={{ width: "15.5%" }}
      >
        Lead Status
      </th>
      <th
        scope="col"
        className="  cl-grey1 ws-nowrap ps-0"
        style={{ width: "12.5%" }}
      >
        Expected Closure
      </th>
      <th
        scope="col"
        className="  cl-grey1 ws-nowrap ps-0"
        style={{ width: "12.5%" }}
      >
        Last Call
      </th>
      <th
        scope="col"
        className="  cl-grey1 ws-nowrap ps-0"
        style={{ width: "12.5%" }}
      >
        Follow Up
      </th>
      <th
        scope="col"
        className="  cl-grey1 ws-nowrap ps-0 align-items-center d-flex"
        style={{ zIndex: "10", width: "12.5%" }}
      >
        Source
      </th>
    </tr>
  );
};

export default LmsTableTh;
