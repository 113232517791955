import React, { useMemo, useRef } from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Autocomplete,
  FormHelperText,
  Select,
  TextField,

} from "@mui/material";


import { Button, Modal, Spinner, Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  setAddLeadModalOpen,
  newLeadObj,
} from "../../Redux/Slices/newLeadSlice";
import { BiChevronDown } from "react-icons/bi";
import "react-phone-input-2/lib/material.css";
import styles from "./AddLeadModalNew.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import expf, { BASE_URL, constants } from "../../piservices/constants";
import { useEffect } from "react";
import { getReq, postReq } from "../../piservices/apis";
import toast from "react-hot-toast";
import MuiPhoneNumber from "material-ui-phone-number";
import { notification } from "antd";
import PhoneInput from "react-phone-input-2";

import useApiFetchCustomHook from "../../piservices/ApiFetchCustomHook";
import useApiFetchForLeadCountCustomHook from "../../piservices/ApiFetchForLeadCount";
import useApiFetchCustomHookForEmailService from "../../piservices/ApiFetchCustomHookForEmailService";
import { countryFlags } from "../../Pages/MockDataCountries";
import axios from "axios";
import { useCountries } from 'use-react-countries'
import countrydata from "country-codes-list"



function AddLeadModalNew({ setProgress }) {
  //  get  code data for state 
  const { countries } = useCountries()

 const countrycode=countries.map((item)=>{
  
  return item.countryCallingCode
 })


 console.log(countrycode,"countrycode")

 




  const profileData = useSelector((state) => state.lmsSlice.profileData);
  const haveAssigneToAccess = useSelector(
    (state) => state.notificationSlice.allAssignToAccessUser
  );
  const addLeadModalOpen = useSelector(
    (state) => state.newLeadSlice.addLeadModalOpen
  );
  const importedDataFromFile = useSelector(
    (state) => state.newLeadSlice.fileImportedData
  );

  let { tab } = useParams();

  const uploadImportedData = () => {
    importedDataFromFile?.map((item) => { });
  };

  const { apiFetch } = useApiFetchCustomHook();
  const { getLeadsCount } = useApiFetchForLeadCountCustomHook();

  useEffect(() => {
    if (importedDataFromFile?.length > 0) {
    }
  }, [importedDataFromFile]);
  const dispatch = useDispatch();
  const [leadFirstName, setLeadFirstName] = useState("");
  const [leadLastName, setLeadLastName] = useState("");
  const [phoneNumber, setPhonenumber] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [errorPin, setErrorPin] = useState(false);
  const [leadEmail, setLeadEmail] = useState("");
  const [leadCity, setLeadCity] = useState("");
  const [leadArea, setLeadArea] = useState("");
  const [leadAddress, setLeadAddress] = useState("");
  const [workScope, setWorkScope] = useState("");
  const [leadCityCode, setLeadCityCode] = useState("");
  const [userData, setUserData] = useState({});
  const [leadSources, setLeadSources] = useState([]);
  const [leadWorkTypes, setLeadWorkTypes] = useState([]);
  const [sourceNames, setSourceNames] = useState([]);
  const [leadSource, setLeadSource] = useState([]);
  const [leadWorkType, setLeadWorkType] = useState([]);
  const [sourceName, setSourceName] = useState([]);
  const [selectedLeadSource, setSelectedLeadSource] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [assignedToName, setAssignedToName] = useState("UNASSIGNED");
  const [areaUnit, setAreaUnit] = useState("sqft");
  const [assignedToObject, setAssignedToObject] = useState("");
  const [selectedSourceName, setSelectedSourceName] = useState("");
  const [addNewWorkScope, setAddNewWorkType] = useState("");
  const [addNewLeadSource, setAddLeadNewSource] = useState("");
  const [addNewSourceName, setAddNewSourceName] = useState("");
  // const [addNewWorkScopeName, setAddNewWorkScopeName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [leadSourceClicked, setLeadSourceClicked] = useState(false);
  const [sourceInputName, setSourceInputName] = useState("");
  const [workScopeInput, setWorkScopeInput] = useState("");
  const [leadSourceInput, setLeadSourceInput] = useState("");
  const [filteredSourceName, setFilteredSourceName] = useState([]);
  const [filteredWorkScopeName, setFilteredWorkScopeName] = useState([]);
  const [filteredLeadSourceName, setFilteredLeadSourceName] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpen3, setDropdownOpen3] = useState(false);
  // State for checking whether user has access to edit lead or not :
  const [editLeadBoolean, setEditLeadBoolean] = useState(false);
  const [loggedInUserName, setLoggedInUserName] = useState(true);

  // console.log("True/False", editLeadBoolean);
  const [sourceNameArchitectDetails, setSourceNameArchitectDetails] = useState(
    {}
  );
  const [inputValueWorkScope, setInputValueWorkScope] = useState("");
  const [inputValueLeadSource, setInputValueLeadSource] = useState("");
  const [inputValueScopeName, setInputValueScopeName] = useState("");

  // const classes = useStyles();
  const [selectedCountryCode, setSelectedCountryCode] = useState("1");
  const [countryCodeInput, setCountryCodeInput] = useState("+1");
  const [phoneNumberInput, setPhoneNumberInput] = useState("");

  //   getting coundtry code 

  

  const handleCountryCodeChange = (e) => {
    const countryCode = e.target.value;
    setSelectedCountryCode(countryCode);
    setCountryCodeInput("+" + countryCode);
  };

  const handleWorkScopeInputChange = (event, newInputValue) => {
    setInputValueWorkScope(newInputValue);
    setAddNewWorkType(newInputValue);
  };
  const handleScopeNameInputChange = (event, newInputValue) => {
    setInputValueScopeName(newInputValue);
    setAddNewSourceName(newInputValue);
  };
  const handleInputValueInputChange = (event, newInputValue) => {
    setInputValueLeadSource(newInputValue);
    setAddLeadNewSource(newInputValue);
  };

  const { sendEmailPushBellNotifications } =
    useApiFetchCustomHookForEmailService();
  const [buttonClickedOnce, setButtonClickedOnce] = useState(false);
  const saveLeadSource = async () => {
    if (addNewLeadSource != "" && editLeadBoolean) {
      const res = await postReq(`${constants.CRM_API}/user/save-lead-source`, {
        userId: localStorage.getItem("userId"),
        leadSource: addNewLeadSource,
      });

      if (res && !res.error) {
        // console.log('getting lead source', res.data.data)
        getLeadSource();
        setAddLeadNewSource("");
        toast.success("New Lead Source Added");
      } else {
        console.log(res.error);
      }
    }
  };
  const handleDropdownFocus = () => {
    setTimeout(() => {
      setDropdownOpen(true);
    }, 100);
  };

  const handleDropdownBlur = () => {
    setTimeout(() => {
      setDropdownOpen(false);
    }, 100);
  };
  const handleDropdownFocus2 = () => {
    setTimeout(() => {
      setDropdownOpen2(true);
    }, 100);
  };

  const handleDropdownBlur2 = () => {
    setTimeout(() => {
      setDropdownOpen2(false);
    }, 100);
  };
  const handleDropdownFocus3 = () => {
    setTimeout(() => {
      setDropdownOpen3(true);
    }, 100);
  };

  const handleDropdownBlur3 = () => {
    setTimeout(() => {
      setDropdownOpen3(false);
    }, 100);
  };

  // console.log("leadWorkTypes", leadWorkType);
  const saveworkScope = async () => {
    if (addNewWorkScope != "") {
      const res = await postReq(`${constants.CRM_API}/user/save-work-type`, {
        userId: localStorage.getItem("userId"),
        type: inputValueWorkScope,
        // type: addNewWorkScope,
      });

      if (res && !res.error) {
        // console.log('getting lead source', res.data.data)
        getWorkTypes();
        setAddNewWorkType("");
        toast.success("New Work Scope Added");
      } else {
        console.log(res.error);
      }
    }
  };



  // const renderOptionWorkScope = (option) => {
  //   return (
  //     <li key={option.label} onClick={() => saveworkScope()}>
  //       Create : "{option.label}"
  //     </li>
  //   );
  // };
  const savesourceName = async () => {
    // alert("async call");
    console.log("NewSourceName", addNewSourceName);
    if (addNewSourceName != "" && editLeadBoolean) {
      const res = await postReq(`${constants.CRM_API}/user/save-source-name`, {
        userId: localStorage.getItem("userId"),
        sourceName: addNewSourceName,
      });

      if (res && !res.error) {
        // console.log('getting lead source', res.data.data)
        getSourceName();
        setAddNewSourceName("");
        toast.success("New Source Name Added");
      } else {
        console.log(res.error);
      }
    }
  };

  // console.log("SourceNames : ", sourceNames);

  const filterSourceChange = (e) => {
    setSourceInputName(e.target.value);
    // var val = e.target.value;
    const filteredNames = sourceNames.filter((name) =>
      name.sourceName.toLowerCase().includes(sourceInputName.toLowerCase())
    );
    setFilteredSourceName(filteredNames);
    // setFilteredSourceName(sourceName.filter)

    // Aman
  };
  const filterWorkScopeChange = (e) => {
    setWorkScopeInput(e.target.value);

    const filteredNames = leadWorkTypes.filter((name) =>
      name.type.toLowerCase().includes(workScopeInput.toLowerCase())
    );
    // aman
    setFilteredWorkScopeName(filteredNames);
  };
  const filterLeadSourceChange = (e) => {
    setLeadSourceInput(e.target.value);

    const filteredNames = leadSources.filter((name) =>
      name.leadSource.toLowerCase().includes(leadSourceInput.toLowerCase())
    );
    // aman
    setFilteredLeadSourceName(filteredNames);
  };

  // useEffect(() => {
  //   if (sourceInputName == "") {
  //     setFilteredSourceName(sourceNames);
  //   }
  // }, [sourceInputName]);

  const defaultValue = {
    label: "UNASSIGNED",
    _id: "",
    email: "",
  };


  const [formData, setFormData] = useState({});
  const [teamLeadArr, setTeamLeadArr] = useState([]);
  const [budget, setBudget] = useState();
  const [error, setError] = useState(false);
  const [phoneNumerror,setphoneNumerror]=useState({
    bol:false,
    msg:""
  })
  const projectObj = useSelector((state) => state.newLeadObj);
  const tempObj = {
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    pinCode: "",
    phoneNumber: "",
    budget: "",
    requirements: "",
    leadSource: "",
    sourceName: "",
    assignedTo: "",
    area: "",
  };
  const exceptThisSymbols2 = [
    "~",
    "`",
    "!",
    "#",
    "%",
    "$",
    "^",
    "&",
    "*",
    "(",
    " ",
    ")",
    "+",
    "=",
    "[",
    "_",
    "]",
    "'",
    ";",
    "/",
    "{",
    "}",
    "|",
    ,
    "<",
    ">",
    "?",
    ",",
  ];

  const leadFirstNameHandle = (e) => {
    if (e.target.value?.length <= 24) {
      if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
        setLeadFirstName(e.target.value);
        setFirstNameError(false);
      }
    } else {
      setFirstNameError(true);
    }
  };

  const leadCityHandle = (e) => {
    if (e.target.value?.length <= 23 && /^[a-zA-Z\s]*$/.test(e.target.value)) {
      setLeadCity(e.target.value);
    }
  };

  console.log("YeRahaTeamLead", teamLeadArr);
  console.log("YeRahaFilteredArray", filteredWorkScopeName);
  const handleDiscardModalOpen = () => {
    console.log("handleDiscardModalOpen caling");
    if (
      !(
        leadFirstName == "" &&
        leadLastName == "" &&
        phoneNumber == "" &&
        leadEmail == "" &&
        leadCity == "" &&
        leadArea == "" &&
        leadAddress == "" &&
        leadCityCode == "" &&
        budget == "" &&
        workScope == "" &&
        selectedLeadSource == "" &&
        selectedSourceName == "" &&
        leadArea == ""
      )
    ) {
      setDiscardOpen(true);
    } else {
      handleNewLeadClose();
    }
  };

  const leadLastNameHandle = (e) => {
    if (e.target.value?.length <= 24) {
      if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
        setLeadLastName(e.target.value);
        setLastNameError(false);
      }
    } else {
      setLastNameError(true);
    }
  };
  
  const handlePhoneNumberChange = (event,country) => {
    console.log(event);
    let  inputValue = event.replace(/[^0-9]/g, "");
    inputValue=`${inputValue}`;
    console.log(country.dialCode.length,"dialCode")
    let newNumbvalue=inputValue.substring(country.dialCode.length);
    console.log(newNumbvalue,"countrycodevalue")
    

    if(newNumbvalue?.length<5){
      setphoneNumerror({
        bol:true,
        msg:"Number must be greater than 5"
      })
    }

    if(newNumbvalue?.length>=5){
      setphoneNumerror({
        bol:false,
        msg:""
      })
    }

    if(newNumbvalue?.length>15){
      setphoneNumerror({
        bol:true,
        msg:"Number must be less  than 15 Digits"
      })
    }else{
      let PhoneNum=`+${country.dialCode} ${newNumbvalue}`
      console.log(PhoneNum,"FinalNumber")
      setPhonenumber(PhoneNum);
    }
    // if (event?.length <= 10) {
    //   setPhonenumber(inputValue);
    //   if (/^\d+$/.test(inputValue) && inputValue.length <= 10) {
    //     if (inputValue.length <= 9) {
    //       setPhoneError(true);
    //     } else {
    //       setPhoneError(false);
    //     }
    //     setPhonenumber(inputValue);
    //   }
    // }
  };




// const handlePhoneNumberChange = (event,country) => {
//     console.log(event, country.dialCode,"eventttt");
//     let countryCode = "";
//     let inputValue = event.replace(/[^0-9]/g, "");
    
//     inputValue=`+${inputValue}`


    

 
//     // console.log(countrycode.length,"countrycodevalue")

//     // Extract country code

  
  
//   // countrycode

//     console.log(countryCode, "phoneNumber");
//     console.log(phoneNumber, "phoneNumber");

//     if (phoneNumber.length < 5) {
//         setphoneNumerror({
//             bol: true,
//             msg: "Number must be greater than 5"
//         });
//     } else if (phoneNumber.length > 15) {
//         setphoneNumerror({
//             bol: true,
//             msg: "Number must be less than 15 Digits"
//         });
//     } else {
//         setphoneNumerror({
//             bol: false,
//             msg: ""
//         });
//         setPhonenumber(inputValue); // or setPhonenumber(phoneNumber) based on what you need to store
//     }
// };

  const handleNewLeadClose = () => {
    setphoneNumerror({
      bol:false,
      msg:""
    })
    setLeadFirstName("");
    setLeadLastName("");
    setPhonenumber("");
    setEmailError(false);
    setLeadEmail("");
    setLeadCity("");
    setLeadArea("");
    setLeadAddress("");
    setWorkScope("");
    setLeadSource("");
    setSourceName("");
    setAssignedTo("");
    setErrorPin(false);
    setLeadArea("");
    setLeadCityCode("");
    setBudget("");
    setFirstNameError(false);
    setLastNameError(false);
    setDiscardOpen(false);
    setFilteredSourceName(sourceNames);
    setFilteredWorkScopeName(leadWorkTypes);
    setFilteredLeadSourceName(leadSources);
    dispatch(setAddLeadModalOpen(false));
    setAssignedTo("");
    setAssignedToName("UNASSIGNED");
  };

  const handlePinCodeChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 6) {
      setLeadCityCode(inputValue);

      if (
        /^\d+$/.test(inputValue) &&
        inputValue.length >= 6 &&
        inputValue.length <= 6
      ) {
        setLeadCityCode(inputValue);
        setErrorPin(false);
      } else {
        setErrorPin(true);
        setLeadCityCode(inputValue);
      }
    }
  };
  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i;

    if (inputValue.length < 64 && emailPattern.test(inputValue)) {
      setLeadEmail(inputValue);
      setEmailError(false);
    } else {
      if (inputValue?.length == 0) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
      if (inputValue.length < 64) {
        setLeadEmail(inputValue);
      }
    }
  };

  const handleBudget = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 10) {
      setBudget(inputValue);
      if (/^\d+$/.test(inputValue) && inputValue.length <= 9) {
        setBudget(inputValue);
      }
    }
  };

  function handleLeadArea(event) {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 6) {
      setLeadArea(inputValue);
      if (/^\d+$/.test(inputValue) && inputValue.length <= 6) {
        setLeadArea(inputValue);
      }
    }
  }

  const handleSubmit = () => {
    if (
      leadFirstName == "" ||
      leadFirstName == undefined ||
      leadLastName == "" ||
      leadLastName == undefined ||
      // phoneNumber == "" ||
      // phoneNumber == undefined ||
      leadEmail == "" ||
      leadCityCode == "" ||
      leadEmail == undefined ||
      // leadCity == "" ||
      // leadCity == undefined ||
      // leadArea == "" ||
      // leadArea == undefined ||
      // leadAddress == "" ||
      // leadAddress == undefined ||
      // leadCityCode == "" ||
      // leadCityCode == undefined ||
      phoneNumerror.bol||
      phoneError ||
      error ||
      errorPin ||
      emailError
    ) {
      alert("Please enter all the details");
    }else if(phoneNumber>15 && phoneNumerror?.bol===true){
      alert("Number must be less  or equal to 15")

    } 
    else {
      handleFormSubmit({
        firstName: leadFirstName,
        lastName: leadLastName ? leadLastName : "",
        email: leadEmail,
        address: leadAddress,
        city: leadCity,
        pinCode: leadCityCode,
        phoneNumber: phoneNumber,
        budget: budget,
        requirements: workScope,
        leadSource: selectedLeadSource,

        sourceName: editLeadBoolean ? selectedSourceName : loggedInUserName,

        // assignedTo: assignedTo,
        area: leadArea,
        areaUnit: areaUnit,
        ...(editLeadBoolean ? { assignedTo: assignedTo } : {}),
      });
    }
  };
  const getUserbyId = async (id) => {
    const res = await getReq(`${constants.CRM_API}/user/getUserById?userId=${id}`);
    if (res && !res.error) {
      setUserData(res?.data?.data);
      setLoggedInUserName(res?.data?.data.fullName);
      // console.log("YeRahaUser", res?.data?.data.rolesAssigned.crm.edit);
      setEditLeadBoolean(res?.data?.data?.rolesAssigned?.crm?.edit);
    } else {
      console.log(res.error);
    }
  };

  const showError = (error) => {
    // console.log(error)
    if (error.response && error.response.data.message) {
      // toast.error(error.response.data.message);
      toast.error(error.response.data.message, {
        // position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      // clg
      // alert(error.response.data.message)
      return;
    } else {
      window.location.reload();
    }
  };
  // const addLeadNotificationType = allNotificationsObjects?.filter(
  //   (notificationObject) => notificationObject.CTA === "Add Lead"
  // );
  // console.log("addLeadNotificationType", addLeadNotificationType);

  const handleFormSubmit = async (data) => {

    console.log("data======>", data)
    setButtonClickedOnce(true);
    // console.log({...data});
    try {
      // await setProgress(30);
      for (const k in data) {
        const e = data[k];
        if (e?.trim() === "") {
          delete data[k];
        }
      }
      // if (data?.assignedTo == "UnAssigned") {
      //   delete data["assignedTo"];
      //   data["assignedTo"] = null;
      // }
      // console.log(data);

      const res = await postReq(constants.CRM_API + "/user/addEditLead", {
        ...data,
        architectDetails: sourceNameArchitectDetails,
      });
      if (res.error) {
        showError(res.error);
      }

      if (res && !res.error) {
        setButtonClickedOnce(false);
        dispatch(setAddLeadModalOpen(false));
        apiFetch(tab);
        getLeadsCount(tab);
        notification.success({
          message: res?.message || "Lead Created Successfully",
          duration: 2,
        });

        console.log("responseof creatinglead", res);
        const assignedToObjectReceivers =
          assignedToObject == "" ? haveAssigneToAccess : [assignedToObject];
        console.log("afterpushinarray", assignedToObjectReceivers);
        handleNewLeadClose();
        sendEmailPushBellNotifications(
          "Add Lead",
          {
            "@leadName": `${leadFirstName} ${leadLastName}`,
            "@lead Name": `${leadFirstName} ${leadLastName}`,
            "@leadName": `${leadFirstName} ${leadLastName}`,
            "@leadCompany": profileData.CompanySettingDetails.companyName,
            "@lead Company": profileData.CompanySettingDetails.companyName,
            "@Lead Email": leadEmail,
            "@lead Phone": phoneNumber,
            "@leadPhone": phoneNumber,
            "@leadSource": leadSource,
            "@lead Source": leadSource,
            "@SalesRep": assignedToName,
            // '@Receiver': assignedToName,
            // '@receiver': assignedToName,
            "@Sender": profileData.fullName,
            "@sender": profileData.fullName,
            "@senderFirstName": profileData.firstName,
          },
          [...assignedToObjectReceivers]
        );
      }
      await setProgress(100);
    } catch (error) {
      setButtonClickedOnce(false);
      // console.log(error)
      // dispatch(setAddLeadModalOpen(false))
      // showError(error);
    }
  };
  const getTeamLeads = async () => {
    const res = await getReq(
      `${constants.CRM_API
      }/api/enterprise/get-team-leaders?userId=${localStorage.getItem(
        "userId"
      )}&access=1`
    );
    if (res && !res.error) {
      setTeamLeadArr(res.data.data);
    } else {
      console.log(res.error);
    }
  };

  const getLeadSource = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-lead-source?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      // console.log('getting lead source', res.data.data)
      setLeadSources(res.data.data);
      setFilteredLeadSourceName(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const getWorkTypes = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-work-types?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      setLeadWorkTypes(res.data.data);

      setFilteredWorkScopeName(res.data.data);

      console.log("thisIsData : ", res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const getSourceName = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-source-name?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      // console.log('getting lead source', res.data.data)
      setSourceNames(res.data.data);
      setFilteredSourceName(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const handleLeadSource = (leadSource) => {
    // const tempLeadSource = leadSources.find(
    //   (leadSource) => leadSource.id === e.target.value
    // );
    // setSelectedLeadSource(tempLeadSource.leadSource);
    // setLeadSource(e.target.value);
    setSelectedLeadSource(leadSource);
  };
  const handleSourceName = (sourceName1) => {
    // setSourceName(e.target.value);
    const tempSourceName = sourceNames.find(
      (sourceName) => sourceName.sourceName === sourceName1
    );
    setSelectedSourceName(tempSourceName.sourceName);
    if (tempSourceName?.architectDetails) {
      setSourceNameArchitectDetails(tempSourceName?.architectDetails);
    }
  };
  useEffect(() => {
    getLeadSource();
    getSourceName();
    getTeamLeads();
    getWorkTypes();
    getUserbyId(localStorage.getItem("userId"));
  }, []);
  const [discardOpen, setDiscardOpen] = useState(false);

  const handleOnFocus = (fieldName, errorStateName) => {
    if (fieldName?.length === 0) {
      errorStateName(true);
    }
  };

  <script type="text/javascript"></script>;

  //  custom phone input

  return (
    <>
      <Modal
        title={false}
        show={discardOpen}
        centered
        width={200}
        bodyStyle={{ height: 60 }}
        closable={false}
      >
        <div
          style={{
            border: "1px solid black",
            borderRadius: "5px",
            margin: "5px",
          }}
        >
          <h6 style={{ padding: "3%" }}>Do you want to discard the lead ?</h6>
          <Modal.Footer>
            <div className={styles.discardCloseBtn}>
              <button
                className={styles.createModalSave}
                onClick={handleNewLeadClose}
              >
                <span
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  Yes{" "}
                </span>
              </button>
              <button
                className={styles.createModalClose}
                onClick={() => setDiscardOpen(false)}
              >
                <span
                  style={{
                    color: "#C9C9C9",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  No{" "}
                </span>
              </button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
      <Modal
        show={addLeadModalOpen}
        onHide={() => handleNewLeadClose()}
        backdrop="static"
        keyboard={false}
        centered
        width={300}
      // size="lg"
      // width='1000px'
      >
        <Modal.Header
          // closeButton
          style={{
            borderBottom: "none",
            backgroundColor: "#0084ef",
            color: "#ffffff",
          }}
        >
          <Modal.Title>Add New Lead </Modal.Title>
          <button
            type="button"
            onClick={handleDiscardModalOpen}
            class="btn-close"
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.formName} >
            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="First Name*"
              size="small"
              helperText={
                firstNameError && leadFirstName.length == 0
                  ? "Please enter first name"
                  : firstNameError && "Limit of 24 characters is reached"
              }
              variant="outlined"
              // onFocus={() => handleOnFocus(leadFirstName, setFirstNameError)}
              isRequired={true}
              error={firstNameError}
              value={leadFirstName}
              InputProps={{
                inputMode: "text",
              }}
              onChange={(e) => {
                leadFirstNameHandle(e);
              }}
            />

            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Last Name*"
              variant="outlined"
              size="small"
              helperText={
                lastNameError && leadLastName.length == 0
                  ? "Please enter last name"
                  : lastNameError && "Limit of 24 characters is reached"
              }
              error={lastNameError}
              // onFocus={() => handleOnFocus(leadLastName, setLastNameError)}
              isRequired={true}
              value={leadLastName}
              InputProps={{
                inputMode: "text",
              }}
              onChange={(e) => leadLastNameHandle(e)}
            />
          </div>
          <div className={styles.formName} style={{marginLeft:"-3px"}}>

            {/*  adding list  */}




            {/* <TextField
                style={{ width: "14rem",display:"flex",
              alignItems:"center",justifyContent:"start" }}
                id="outlined-basic"
                label="Contact No."
                size="small"
                variant="outlined"
                // isRequired={true}
                helperText={
                  phoneNumber.length > 0
                    ? phoneNumber.length < 10
                      ? "Please enter valid number"
                      : ""
                    : ""
                }
                value={phoneNumber}
                onChange={(e) => handlePhoneNumberChange(e)}
                error={phoneNumber.length > 0 && phoneNumber.length < 10}
                InputProps={{
                  inputMode: "numeric",
                  style:{padding:"0px"},
                  startAdornment: (
                    <InputAdornment position="start">

                       <PhoneInput
                       
                placeholder="Enter the Number"
                enableSearch={true}
                inputClass="phoneinput"
                countryCodeEditable={false}
                inputStyle={{ height: "30px", width: "223px"  ,background:"yellow"}}
                value={phoneNumber}
                disableCountryGuess={false}
                onChange={(e) => handlePhoneNumberChange(e)}
                specialLabel="Country No"
                country="in"
              />
                  
                    </InputAdornment>
                  ),
                }}
              /> */}


            <div >
              <PhoneInput
                placeholder="Enter the Number"
                enableSearch={true}
                inputStyle={{ height: "30px", width: "14rem", }}
                // style={{ width: "14rem" }}
                value={phoneNumber}
                containerClass="phoneNumber"
                // disableCountryGuess={false}
                countryCodeEditable={false}
                enableLongNumbers={17}
                prefix="+"
                // isValid={(inputNumber, country, countries) => {
                //   return countries.some((country) => {
                //     return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                //   });
                // }}
                // onChange={(value, country, e, formattedValue)=>{
                //          console.log(value, country, e, formattedValue,"codedatavalueofcountry")
                // }}

                onChange={(e,country) => handlePhoneNumberChange(e,country)}
                specialLabel="Phone No"
                country="in"
              />
               <div style={{color:"red",marginTop:"3px",paddingLeft:"5px",fontSize:"10px"}}>
                {phoneNumerror && phoneNumerror?.bol===true?phoneNumerror?.msg:""}
               </div>
            </div>

            {/* <MuiPhoneNumber
              variant="outlined"
              style={{ width: "14rem" }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
              size="small"
              label="Contact No.*"
              isRequired={true}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              id="outlined-basic"
              defaultCountry={"in"}
              disableAreaCodes={true}
              countryCodeEditable={false}
            />  */}
            {/* <PhoneInput
            // style={{ width: "10rem" }}
            isRequired={true}
            value={phoneNumber}
            inputProps={{
                name: 'phone',
                required: true,
                autoFocus: true
              }}
            /> */}
            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Email Address*"
              variant="outlined"
              isRequired={true}
              value={leadEmail}
              size="small"
              onKeyDown={(e) =>
                exceptThisSymbols2.includes(e.key) && e.preventDefault()
              }
              error={emailError}
              helperText={
                emailError
                  ? leadEmail.length == 0
                    ? "Please input email"
                    : "Invalid email address"
                  : ""
              }
              onChange={(e) => handleEmailChange(e)}
            />
          </div>

          <div className={styles.formName}>
            <TextField
              style={{ width: "30rem" }}
              id="outlined-basic"
              label="Address"
              variant="outlined"
              size="small"
              value={leadAddress}
              onChange={(e) => {
                if (e.target.value.length < 1024) {
                  setLeadAddress(e.target.value);
                }
              }}
            />
          </div>
          <div className={styles.formName}>
            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="City"
              size="small"
              variant="outlined"
              // isRequired={true}
              value={leadCity}
              onChange={(e) => leadCityHandle(e)}
            />

            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Zip/Postal code*"
              variant="outlined"
              size="small"
              // isRequired={true}
              value={leadCityCode}
              error={errorPin}
              helperText={
                errorPin
                  ? leadCityCode.length == 0
                    ? "Please enter city code"
                    : "Invalid Pin Code"
                  : ""
              }
              onChange={(e) => handlePinCodeChange(e)}
              InputProps={{
                inputMode: "numeric",
              }}
            />
          </div>
          {/* aman */}
          <div className={styles.formName}>
            <FormControl style={{ width: "14rem" }} size="small">
              {/* <Dropdown show={dropdownOpen}>
                <Dropdown.Toggle
                  className={styles.dropdownButton}
                  variant="outline-secondary"
                  id="dropdown-basic"
                  onFocus={() => {
                    if (workScope == "") {
                      handleDropdownFocus();
                    }
                  }}
                  onBlur={handleDropdownBlur}
                >
                  <span className={styles.buttonText}>
                   

                    {workScope === "" ? (
                      <input
                      
                        style={{
                          border: "none",
                          outline: "none",
                          position: "relative",
                        }}
                        type="text"
                        placeholder="Work Scope"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (filteredWorkScopeName.length === 0) {
                              setAddNewWorkType(workScopeInput);
                              saveworkScope();
                              setWorkScope(workScopeInput);
                              handleDropdownBlur();
                            } else {
                            }
                          }
                        }}
                        onChange={(e) => {
                          
                          filterWorkScopeChange(e);
                          setWorkScopeInput(e.target.value);
                          setAddNewWorkType(e.target.value);
                        }}
                        name=""
                        id=""
                      />
                    ) : (
                      <span>
                        {workScope.length > 20
                          ? `${workScope.substring(0, 20)}...`
                          : workScope}
                        <span
                          style={{ position: "absolute", right: "9px" }}
                          onClick={() => {
                            setFilteredWorkScopeName(leadWorkTypes);

                            setWorkScope("");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="15"
                            fill="#7F7F7F"
                            style={{ fontWeight: "800" }}
                            height="15"
                            viewBox="0 0 50 50"
                          >
                            <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path>
                          </svg>
                        </span>
                      </span>
                    )}
                  </span>{" "}
                  {workScope == "" ? (
                    <BiChevronDown size={20} color="#74737F" />
                  ) : (
                    ""
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu className={styles.filterDropdownContainer}>
                  <Dropdown.ItemText>
                  
                  </Dropdown.ItemText>
                  

                  {filteredWorkScopeName.length > 0 ? (
                    filteredWorkScopeName
                      ?.slice()
                      .sort((a, b) => a.type.localeCompare(b.type))
                      .map((leadWorkType) => {
                        return (
                          <Dropdown.Item
                            onClick={(e) => {
                              setWorkScope(leadWorkType.type);
                              handleDropdownBlur();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {leadWorkType.type}
                          </Dropdown.Item>
                        );
                      })
                  ) : (
                    <Dropdown.Item
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      Create :{" "}
                      <span
                        onClick={() => {
                          setAddNewWorkType(workScopeInput);
                          saveworkScope();
                          setWorkScope(workScopeInput);
                          handleDropdownBlur();
                        }}
                        style={{
                          marginLeft: "5px",
                          color: "#3498DB",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {workScopeInput}
                      </span>
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                style={{ width: "14rem" }}
                options={filteredWorkScopeName
                  ?.slice()
                  .sort((a, b) => a.type.localeCompare(b.type))
                  .map((workScope) => {
                    return {
                      label: workScope.type,
                    };
                  })}
                onChange={(_object, value) => {
                  setWorkScope(value?.label);
                }}
                inputValue={inputValueWorkScope}
                onInputChange={handleWorkScopeInputChange}
                noOptionsText={
                  <span onClick={saveworkScope} style={{ cursor: "pointer" }}>
                    {inputValueWorkScope ? (
                      <span>
                        Create :{" "}
                        <span style={{ color: "blue" }}>
                          "{inputValueWorkScope}"
                        </span>
                      </span>
                    ) : (
                      "No options"
                    )}
                  </span>
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Work Scope"
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        !filteredWorkScopeName.some(
                          (option) => option.type === inputValueWorkScope
                        )
                      ) {
                        saveworkScope();
                      }
                    }}
                  />
                )}
              />
            </FormControl>
            {/* AmanHere */}
            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Budget"
              size="small"
              variant="outlined"
              value={budget}
              onChange={(e) => handleBudget(e)}
              InputProps={{
                inputMode: "numeric",
                startAdornment: (
                  <InputAdornment position="start">
                    {profileData?.CompanySettingDetails?.currencySymbol}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div
            className={styles.formName}
            style={{
              // background: "yellow",
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",
            }}
          >
            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Area"
              size="small"
              variant="outlined"
              // isRequired={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Select
                      variant="standard"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={areaUnit}
                      renderValue={() => areaUnit}
                      label="Unit"
                      // onChange={handleChange}
                      InputProps={{
                        disableUnderline: true, // <== added this
                      }}
                      disableUnderline
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 150,
                            "&.MuiPaper-root": {
                              left:
                                window.screen.width < 1400
                                  ? "48.5vw !important"
                                  : "52.5vw !important",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem
                        sx={{ minWidth: 200 }}
                        onClick={() => setAreaUnit("sqft")}
                        value={"sqft"}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className={styles.areaUnit}>Sqft</div>
                        <div className={styles.areaDescription}>
                          Square feet
                        </div>
                      </MenuItem>
                      <MenuItem
                        sx={{ minWidth: 200 }}
                        onClick={() => setAreaUnit("sqm")}
                        value={"sqm"}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className={styles.areaUnit}>Sqm</div>
                        <div className={styles.areaDescription}>
                          Square meter
                        </div>
                      </MenuItem>
                    </Select>
                  </InputAdornment>
                ),
              }}
              value={leadArea}
              onChange={handleLeadArea}
            />
            {editLeadBoolean && (
              <FormControl style={{ width: "14rem" }} size="small">
                {/* <Dropdown show={dropdownOpen2}>
                <Dropdown.Toggle
                  className={styles.dropdownButton}
                  variant="outline-secondary"
                  id="dropdown-basic"
                  onFocus={() => {
                    if (selectedLeadSource == "") {
                      handleDropdownFocus2();
                    }
                  }}
                  onBlur={handleDropdownBlur2}
                >
                  <span className={styles.buttonText}>
                    {selectedLeadSource === "" ? (
                      <input
                     
                        style={{
                          border: "none",
                          outline: "none",
                          position: "relative",
                        }}
                        type="text"
                        placeholder="Lead Source"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (filteredLeadSourceName.length === 0) {
                              setAddLeadNewSource(leadSourceInput);
                              saveLeadSource();
                              setSelectedLeadSource(leadSourceInput);
                              handleDropdownBlur2();
                            } else {
                            }
                          }
                        }}
                        onChange={(e) => {
                          filterLeadSourceChange(e);
                          setLeadSourceInput(e.target.value);
                          setAddLeadNewSource(e.target.value);

                        }}
                        name=""
                        id=""
                      />
                    ) : (
                      <span>
                        {selectedLeadSource}
                        <span
                          style={{ position: "absolute", right: "9px" }}
                          onClick={() => {
                            setFilteredLeadSourceName(leadSources);
                            setSelectedLeadSource("");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="15"
                            fill="#7F7F7F"
                            style={{ fontWeight: "800" }}
                            height="15"
                            viewBox="0 0 50 50"
                          >
                            <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path>
                          </svg>
                        </span>
                      </span>
                    )}
                  </span>{" "}
                  {selectedLeadSource == "" ? (
                    <BiChevronDown size={20} color="#74737F" />
                  ) : (
                    ""
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu className={styles.filterDropdownContainer}>
                  <Dropdown.ItemText>
          
                  </Dropdown.ItemText>
                  {filteredLeadSourceName.length > 0 ? (
                    filteredLeadSourceName
                      ?.slice()
                      .sort((a, b) => a.leadSource.localeCompare(b.leadSource))
                      .map((leadWorkType) => {
                        return (
                          <Dropdown.Item
                            onClick={(e) => {
                              setSelectedLeadSource(leadWorkType.leadSource);
                              handleDropdownBlur2();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {leadWorkType.leadSource}
                          </Dropdown.Item>
                        );
                      })
                  ) : (
                    <Dropdown.Item
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      Create :{" "}
                      <span
                        onClick={() => {
                          setAddLeadNewSource(leadSourceInput);
                          saveLeadSource();
                          setSelectedLeadSource(leadSourceInput);
                          handleDropdownBlur2();
                        }}
                        style={{
                          marginLeft: "5px",
                          color: "#3498DB",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {leadSourceInput}
                      </span>
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown> */}

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  style={{ width: "14rem" }}
                  options={filteredLeadSourceName
                    ?.slice()
                    .sort((a, b) => a.leadSource.localeCompare(b.leadSource))
                    .map((leadWorkType) => {
                      return {
                        label: leadWorkType.leadSource,
                      };
                    })}
                  onChange={(_object, value) => {
                    setSelectedLeadSource(value?.label);
                  }}
                  inputValue={inputValueLeadSource}
                  onInputChange={handleInputValueInputChange}
                  noOptionsText={
                    <span
                      onClick={saveLeadSource}
                      style={{ cursor: "pointer" }}
                    >
                      {inputValueLeadSource && editLeadBoolean ? (
                        <span>
                          Create :{" "}
                          <span style={{ color: "blue" }}>
                            "{inputValueLeadSource}"
                          </span>
                        </span>
                      ) : (
                        "No options"
                      )}
                    </span>
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Lead Source"
                      onKeyDown={(event) => {
                        if (
                          event.key === "Enter" &&
                          editLeadBoolean &&
                          !filteredLeadSourceName.some(
                            (option) =>
                              option.leadSource === inputValueLeadSource
                          )
                        ) {
                          saveLeadSource();
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
            )}

            {/* adding other field */}

            {editLeadBoolean && (
              <FormControl style={{ width: "14rem" }} size="small">
                {/* <Dropdown show={dropdownOpen3}>
                <Dropdown.Toggle
                  className={styles.dropdownButton}
                  variant="outline-secondary"
                  id="dropdown-basic"
                  onFocus={() => {
                    if (selectedSourceName == "") {
                      handleDropdownFocus3();
                    }
                  }}
                  onBlur={handleDropdownBlur3}
                >
                  <span className={styles.buttonText}>
               
                    {selectedSourceName === "" ? (
                      <input
                     
                        style={{
                          border: "none",
                          outline: "none",
                          position: "relative",
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (filteredSourceName.length === 0) {
                              setAddNewSourceName(sourceInputName);
                              savesourceName();
                              setSelectedSourceName(sourceInputName);
                              handleDropdownBlur3();
                            } else {
                            }
                          }
                        }}
                        type="text"
                        placeholder="Source Name"
                        onChange={(e) => {
                          filterSourceChange(e);
                          setAddNewSourceName(e.target.value);
                        }}
                        name=""
                        id=""
                      />
                    ) : (
                      <span>
                        {selectedSourceName}
                        <span
                          style={{ position: "absolute", right: "9px" }}
                          onClick={() => {
                            setFilteredSourceName(sourceNames);
                            setSelectedSourceName("");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="15"
                            fill="#7F7F7F"
                            style={{ fontWeight: "800" }}
                            height="15"
                            viewBox="0 0 50 50"
                          >
                            <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path>
                          </svg>
                        </span>
                      </span>
                    )}
                  </span>{" "}
                  {selectedSourceName == "" ? (
                    <BiChevronDown size={20} color="#74737F" />
                  ) : (
                    ""
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu className={styles.filterDropdownContainer}>
                  <Dropdown.ItemText>
          
                  </Dropdown.ItemText>
            
                  {
                
                    filteredSourceName.length > 0 ? (
                      filteredSourceName
                        ?.slice()
                        .sort((a, b) =>
                          a.sourceName.localeCompare(b.sourceName)
                        )
                        .map((leadSource) => (
                          <Dropdown.Item
                            onClick={(e) => {
                              handleSourceName(leadSource.sourceName);
                              handleDropdownBlur3();
                            }}
                            style={{ cursor: "pointer" }}
                            key={leadSource._id} // Assuming each source has a unique ID
                          >
                            {leadSource.sourceName}
                          </Dropdown.Item>
                        ))
                    ) : sourceInputName ? ( // Check if sourceInputName is not empty
                      <Dropdown.Item
                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                      >
                        Create :{" "}
                        <span
                          onClick={() => {
                            setAddNewSourceName(sourceInputName);
                            savesourceName();
                            setSelectedSourceName(sourceInputName);
                            handleDropdownBlur3();
                          }}
                          style={{
                            marginLeft: "5px",
                            color: "#3498DB",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          {sourceInputName}
                        </span>
                      </Dropdown.Item>
                    ) : null 
                  }
                </Dropdown.Menu>
              </Dropdown> */}
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  style={{ width: "14rem" }}
                  options={filteredSourceName
                    ?.slice()
                    .sort((a, b) => a.sourceName.localeCompare(b.sourceName))
                    .map((workScope) => {
                      return {
                        label: workScope.sourceName,
                      };
                    })}
                  onChange={(_object, value) => {
                    setSelectedSourceName(value?.label);
                  }}
                  inputValue={inputValueScopeName}
                  onInputChange={handleScopeNameInputChange}
                  noOptionsText={
                    <span
                      onClick={savesourceName}
                      style={{ cursor: "pointer" }}
                    >
                      {inputValueScopeName && editLeadBoolean ? (
                        <span>
                          Create :{" "}
                          <span style={{ color: "blue" }}>
                            "{inputValueScopeName}"
                          </span>
                        </span>
                      ) : (
                        "No options"
                      )}
                    </span>
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Source Name"
                      onKeyDown={(event) => {
                        if (
                          event.key === "Enter" &&
                          editLeadBoolean &&
                          !filteredSourceName.some(
                            (option) =>
                              option.sourceName === inputValueScopeName
                          )
                        ) {
                          savesourceName();
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
            )}

            {userData?.rolesAssigned?.crm?.canAssign && editLeadBoolean && (
              <FormControl style={{ width: "14rem" }} size="small">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  // options={teamLeadArr?.map((teamLead) => {
                  //   return {
                  //     label: teamLead.fullName,
                  //     _id: teamLead._id,
                  //     email: teamLead.email,
                  //   };
                  // })}
                  options={[
                    defaultValue,
                    ...teamLeadArr?.map((teamLead) => ({
                      label: teamLead.fullName,
                      _id: teamLead._id,
                      email: teamLead.email,
                    })),
                  ]}
                  // onChange={(object, value) => (
                  //   setAssignedTo(value?._id),
                  //   setAssignedToName(value?.label),
                  //   setAssignedToObject({
                  //     email: value?.email,
                  //     fullName: value?.label,
                  //     _id: value?._id,
                  //   })
                  // )}
                  onChange={(object, value) => {
                    if (value) {
                      setAssignedTo(value._id);
                      setAssignedToName(value.label);
                      setAssignedToObject({
                        email: value.email,
                        fullName: value.label,
                        _id: value._id,
                      });
                    } else {
                      // Handle the case when "UNASSIGNED" is selected
                      // setAssignedTo("");
                      // setAssignedToName("UNASSIGNED");
                      // setAssignedToObject("");
                    }
                  }}
                  value={assignedToName}
                  // sx={{ width: 225 }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Assign To" />
                  )}
                // MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                />
              </FormControl>
            )}
          </div>

          <Modal.Footer>
            <div className={styles.closeBtn}>
              <button
                className={styles.createModalClose}
                onClick={handleDiscardModalOpen}
              >
                <span
                  style={{
                    color: "#C9C9C9",
                  }}
                >
                  Cancel{" "}
                </span>
              </button>
              <button
                disabled={buttonClickedOnce}
                className={styles.createModalSave}
                onClick={handleSubmit}
              >
                <span
                  style={{
                    color: "#FFF",
                  }}
                >
                  {!buttonClickedOnce ? (
                    "Add Lead"
                  ) : (
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ height: "1.25rem", width: "1.25rem" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </span>
              </button>
            </div>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddLeadModalNew;
