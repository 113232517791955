import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./FAQModal.module.css";
import VideoModal from "./VideoModal";

const arrayData=[
    {
        question:"How to add a lead?",
        answer:[
            {
                answerHeading:"",
                description:['Click on add lead, fill in details of the lead like name, contact, source, etc. in the lead form and click on add lead.']
            },
        ],
        url:""
    },
    {
        question:"How to import lead?",
        answer:[
           {
             answerHeading:"",
            description:[
                'Click on Import and a modal will open.',
                'Click on the download sample and then fill in the details in that sheet.',
                'Click on upload excel file and choose the file.',
                'Click on import.'
            ]
        }
        ]
    },
    {
        question:"How to assign lead?",
        answer:[
           {
             answerHeading:"",
            description:[
                'Click on three dots and choose to assign the lead.',
                'Choose from the dropdown of project members and click on apply.'
            ]
        }
        ]
    },
    {
        question:"How to send quotation to lead?",
        answer:[
           {
             answerHeading:"",
            description:[
                ' Click on Send a quotation on the side card.',
                'Create a quotation and send it for client approval.'
            ]
        }
        ]
    },
    {
        question:"How do I update a lead?",
        answer:[
           {
             answerHeading:"",
            description:[
                'Click on the dot menu.',
                'Click on edit lead and edit the required changes.',
                'Click on submit.'
            ]
        }
        ]
    },
    {
        question:"How do I add comments against a lead?",
        answer:[
           {
             answerHeading:"",
            description:[
                'You can comment by inputting the remark in "Add Comment" input field and then clicking on submit.'
            ]
        }
        ]
    },
    {
        question:"How do I create a task against the lead?",
        answer:[
           {
             answerHeading:"",
            description:[
                'Click on the lead. It will take you to the lead dashboard.',
                'Go to Tasks from the left panel.',
                'Click on create task.'
            ]
        }
        ]
    },
    {
        question:"Can I convert a lead into a project?",
        answer:[
           {
             answerHeading:"Yes, a lead can be converted into a project. ",
            description:[
                'Click on three-dots',
                'Click on convert to project.',
                'The lead will now be shown in won stage on the left panel'
            ]
        }
        ]
    },
    {
        question:"What is unassigned leads?",
        answer:[
           {
             answerHeading:"",
            description:[
                " The leads who have shown interest but haven't been given to a specific person for a follow-up yet."
            ]
        }
        ]
    }
    
]

const FAQModal = ({ show, onHide}) => {
    const heading="Need Help with Leads? Check Here!"
  const [activeIndex, setActiveIndex] = useState(null);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const openVideoModal = () => {
    setIsVideoModalOpen(true);
  };
  const closeVideoModal = () => {
    setIsVideoModalOpen(false);
  };
  const toggleAnswer = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  useEffect(() => {
    const modalTitle = document?.querySelector(".modal-title");
    const modalHeader = document?.querySelector(".modal-header");

    if (modalTitle) {
      modalTitle.style.fontSize = "0.875rem";
      modalTitle.style.color = "#363535";
      modalTitle.style.fontStyle = "normal";
      modalTitle.style.fontFamily = "Inter";
      modalTitle.style.fontWeight = 600;
      modalTitle.style.lineHeight = "normal";
      modalTitle.style.letterSpacing = " 0.0105rem";
      modalTitle.style.width = "100%";
    }
    if (modalHeader) {
      modalHeader.style.borderBottom = "unset";
      modalHeader.style.paddingBottom = "unset";
      modalHeader.style.display="flex";
      modalHeader.style.padding="1rem 2rem 10px 1rem"
    }
  
  }, [show]);

  return (
    <>
      <Modal show={show} onHide={onHide} dialogClassName={styles.dialogClass} contentClassName={styles.contentClass}>
        <Modal.Header className={styles.modalHeader}>
          <Modal.Title id="ModalHeader"  className={styles.modalTitle}>
            <div style={{display:"flex", justifyContent:"space-between"}}>
            <div  style={{color:"#363535", fontSize: "0.875rem", fontWeight:600}}>{heading}</div>
            <img onClick={onHide}
             src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/crossImg.svg" alt="" 
             style={{transform:"scale(1.3)", cursor:"pointer"}} />
            </div>
          
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <div className={styles.allQuestionsContainer}>
            {arrayData.map((item, index) => (
              <div
                key={index}
                className={`${styles.accordionItem} ${
                  activeIndex == index ? styles.clickedAccItem : ""
                }`}
              >
                <div
                  style={{ cursor: "pointer" }}
                  className={styles.questionContainer}
                  onClick={() => toggleAnswer(index)}
                >
                  <div className={styles.question}>
                    <ul className={styles.ulList}>
                      <li className={styles.ulListItem}>{item.question}</li>
                    </ul>
                  </div>
                  <div className={styles.plusMinusToggle}>
                    {activeIndex === index ? (
                      <img
                        src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/CaretUp.png"
                        alt=""
                        className={styles.caretDown}
                      />
                    ) : (
                      <img
                      src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/CaretDownone.svg"
                        // src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/arrowdown.svg"
                        alt=""
                        className={styles.caretDown}
                      />
                    )}
                  </div>
                </div>
                {activeIndex === index && (
                  <>
                      <div className={styles.answer} style={{marginLeft:"1rem", marginRight:"2rem"}} key={index}>
                           {item?.answer?.map((step, stepIndex) => (
                                    <>
                                    <div className={styles.answerHeading}>{step.answerHeading}</div>
                                    <ul>
                                {step?.description?.map((a, stepIndex) => (
                                  <>
                                        <li key={stepIndex}>
                                            {a}
                                        </li>
                                         <ul style={{listStyleType:"lower-alpha"}}>
                                         {step?.innerDescription?.map((a, stepIndex) => (
                                            <li key={stepIndex}>
                                                {a}
                                            </li>
                                        ))}
                                        </ul>
                                        </>
                                    ))}
                                   
                                </ul>
                                </>
                                ))}
                            </div>
                    {/* <div
                      style={{ marginTop: "1rem", marginBottom: "0.62rem", marginLeft:"3rem" }}
                      className={styles.answer}
                    >
                      For better clarification watch Video
                    </div>
                    <div
                      className={styles.videoConatiner}
                      onClick={openVideoModal}
                    >
                         <img alt="" height="inherit" width="inherit"
                      src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Videoimg.svg" />
                    </div>
                    <VideoModal
                      videoTitle={item.question}
                      url={item.url}
                      showVideoModal={isVideoModalOpen}
                      onHideVideoModal={closeVideoModal}
                    /> */}
                  </>
                )}
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FAQModal;