import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./createnewproject2.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
// import { postReq } from "../../Services/api";
// import { constants } from "../../constants";
import { useDispatch, useSelector } from "react-redux";

import { RiArrowDownSFill } from "react-icons/ri";
import { Dropdown } from "react-bootstrap";
import { MdClose } from "react-icons/md";

import axios from "axios";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

import { notification } from "antd";


import { createTeam } from "../../../Redux/Slices/lmsSlice";
import { constants } from "../../../piservices/constants";
import { postReq } from "../../../piservices/apis";
import {
  mapState,
  projectDataCurrObj,
  setIsChecked2,
} from "../../../Redux/Slices/convertToProjectSlice";
import { TextField } from "@mui/material";
import { lmsSlice } from "./../../../Redux/Slices/lmsSlice";
import desquoContext from "../../../picontext/DashBoard/desquoContext";
import { formateDate } from "../../../piservices/compCommon";
import useApiFetchCustomHookForEmailService from "../../../piservices/ApiFetchCustomHookForEmailService";



const CreateNewProject2 = ({
  leadInfo,
  setMapModalopen,
  setCreateTeamModal,
}) => {

  console.log(leadInfo,"infodatavalue")
  const dispatch = useDispatch();
  
  const { sendEmailPushBellNotifications } =
    useApiFetchCustomHookForEmailService();

  const context = useContext(desquoContext);
  const { activeLead } = context;
  const currProjectDataValue = useSelector(
    (state) => state.convertToProjectSlice.currProjectDataObj
  );
  const lat = useSelector((state) => state.lmsSlice.lat);
  const lag = useSelector((state) => state.lmsSlice.lag);

  const navigate = useNavigate();
  const [inputText, setinputText] = useState("");
  const [members, setmembers] = useState([]);
  const [showtags, setshowtags] = useState(false);
  const [show0, setShow0] = useState(false);
  const [selectAdmins, setselectAdmins] = useState("");
  const [selectAdminsName, setSelectAdminsName] = useState("");
  const [suggestion, setsuggestion] = useState(false);
  const [closeDropdown, setclosedropdown] = useState(false);
  // const [mapModalopen, setMapModalopen] = useState(false)
  const [adminDropdown, setAdminDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [memberRemove, setMemberRemove] = useState([]);

  const checked2 = useSelector(
    (state) => state.convertToProjectSlice.isChecked2
  );
  let isLead = window.location.href?.includes("lead-details");

  const hideMember = (elem) => {
    setshowtags(true);
    setinputText("");
  };



  const removeTags = (Id) => {
    const val = members.filter((elem, index) => {
      return elem.memberId !== Id;
    });
    setmembers(val);
    const val1 = memberRemove.filter((ele, i) => {
      return ele !== Id;
    });
    setMemberRemove(val1);
  };
  const [proadmin, setProAdmin] = useState("");
  const adminGet = async () => {
    await axios
      .get(
        `${
          constants.CRM_API
        }/api/enterprise/get-all-admins?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then(function (response) {
        setProAdmin(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  document.getElementById("body").onclick = (e) => {
    if (e.target.id == "projectAdminAtulya") {
      setShow0(true);
    } else {
      setShow0(false);
    }
  };

  useEffect(() => {
    adminGet();
  }, []);

  // team members api
  const [teamMember, setteamMember] = useState([]);
  const [filterTeam, setFilterTeam] = useState([]);
  const [data, setData] = useState(teamMember);
  const inputField = (event) => {
    const { value } = event.target;
    if (value.length > 0) {
      setsuggestion(true);
    } else {
      setsuggestion(false);
    }
    setinputText(value);
    let x = teamMember.filter((curElem) => {
      return curElem.memberName.toLowerCase().includes(value.toLowerCase());
    });
    setFilterTeam([...x]);
  };

  // get team member
  const getTeamMember = async () => {
    await axios
      .get(
        `${
          constants.CRM_API
        }/api/enterprise/get-team-member?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then(function (response) {
        setteamMember(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getTeamMember();
  }, []);

  // {
  //   "firstName": "baggit",
  //   "lastName": "sdijlk",
  //   "projectName": "podklf",
  //   "address": "Faridabad, Haryana, India",
  //   "city": "Delhi",
  //   "code": "122201",
  //   "phnNum": "3387489327",
  //   "email": "baggit@gmail.com",
  //   "document": "",
  //   "documentLink": ""
  // }
  const deleteLead = async () => {
    const res = await postReq(
      `${constants.CRM_API}/user/edit-lead?id=${
        leadInfo?._id
      }&userId=${localStorage.getItem("userId")}`,
      {
        isDeleted: true,
        deletedBy: localStorage.getItem("userId"),
      }
    );
    if (res) {
      if (isLead) {
        notification.success({
          message: res?.message || "Project Created Successfully",
          duration: 2,
        });
        navigate("/leads");
      } else {
        notification.success({
          message: res?.message || "Project Created Successfully",
          duration: 2,
        });
      }
      window.location.reload();
    }
  };
  const projectCreatedWithoutDeletingLead = async () => {
    const res = await postReq(
      `${constants.CRM_API}/user/edit-lead?id=${
        leadInfo?._id
      }&userId=${localStorage.getItem("userId")}`,
      {
        // isDeleted: true,
        isProjectCreated: true,
        logText: [
          `${
            currProjectDataValue?.projectName
          } project is created on ${formateDate(new Date())}`,
        ],
        // deletedBy: localStorage.getItem("userId"),
      }
    );
    if (res) {
      if (isLead) {
        notification.success({
          message: res?.message || "Project Created Successfully",
          duration: 2,
        });
        alert("Project Created Successfully");
        // navigate("/leads");
      } else {
        notification.success({
          message: res?.message || "Project Created Successfully",
          duration: 2,
        });
      }
      // window.location.reload();
    }
  };
  const CreateNewProjectwithLead = async (projectId) => {
    let arr = members.map((curElem) => {
      return curElem.memberId;
    });
    const res = await axios
      .post(`${constants.PMT_API}/api/projects/edit-project?id=${projectId}`, {
        clientName: `${currProjectDataValue?.firstName}  ${currProjectDataValue?.lastName}`,
        name: currProjectDataValue?.projectName,
        location: currProjectDataValue?.address,
        city: currProjectDataValue?.city,
        phoneNumber: currProjectDataValue?.phnNum,
        email: currProjectDataValue?.email,
        projectAdmin: selectAdmins,
        projectAdminName: selectAdminsName,
        teamMembers: arr,
        isConfirmed: true,
        lat: lat,
        lng: lag,
        country: currProjectDataValue?.country,
        kycType: currProjectDataValue?.kycType,
        projectUom: currProjectDataValue?.projectUom,
        companyName: currProjectDataValue?.companyName,
        expectedBudget: currProjectDataValue?.expectedBudget,
        area: currProjectDataValue?.area,
        expectedStartDate: currProjectDataValue?.expectedStartDate,
        expectedEndDate: currProjectDataValue?.expectedEndDate,
        workScope: currProjectDataValue?.workScope,
        billingAddress: currProjectDataValue?.billingAddress,
        billingLandmark: currProjectDataValue?.billingLandmark,
        billingCity: currProjectDataValue?.billingCity,
        billTo: currProjectDataValue?.billTo,
        gstRegNo: currProjectDataValue?.gstRegNo,
        note: currProjectDataValue?.note,
        phoneCode: currProjectDataValue?.phoneCode,
        userId: localStorage.getItem("userId"),
      })
      .then(function (response) {
        localStorage.setItem("projectId", response?.data?._id);
        // localStorage.removeItem("clientId")
        dispatch(createTeam(false));
        if (currProjectDataValue?.emailCheck) {
          axios.post(
            `${constants.CRM_API}/user/edit-client-password?projectId=${projectId}`
          );
        }
        // deleteLead()
        window.location.assign(`${constants.BASE_URL}/pmt/`);
   
       


        projectCreatedWithoutDeletingLead();
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log(res);
  };

  const CreateNewProject = async () => {
    let arr = members.map((curElem) => {
      return curElem.memberId;
    });
    localStorage.removeItem("clientId");
    const res = await postReq(
      `${constants.PMT_API}/api/projects/addClient?userId=${localStorage.getItem(
        "userId"
      )}&flag=${currProjectDataValue?.emailCheck ? 1 : 0}`,
      {
        name: `${currProjectDataValue?.firstName}  ${currProjectDataValue?.lastName}`,
        email: currProjectDataValue?.email,
        phoneNumber: currProjectDataValue?.phnNum,
        city: currProjectDataValue?.city,
        location: currProjectDataValue?.address,
        pinCode: currProjectDataValue?.code,
        projectName: currProjectDataValue.projectName,
      }
    );
    console.log(res);
    if (res && !res.error) {
      localStorage.setItem("clientId", res.data._id);
      // setClientId(res.data._id)
    } else {
      console.log(res.error);
    }
    if (res?.data?.isClientAlreadyAdded) {
      alert("Client already exist");
    } else if (
      res?.error?.response?.data?.message === "project is already made "
    ) {
      // console.log(res.error);
      alert("Project Name already exists");
    } else if (
      res?.error?.response?.data?.message === "user can't be client.."
    ) {
      // console.log(res.error);
      alert("User can't be client");
    } else {
      await axios
        .post(
          `${
            constants.PMT_API
          }/api/projects/save-all-data?userId=${localStorage.getItem(
            "userId"
          )}`,
          {
            isWeb: true,
            clientName: `${currProjectDataValue?.firstName}  ${currProjectDataValue?.lastName}`,
            name: currProjectDataValue?.projectName,
            location: currProjectDataValue?.address,
            city: currProjectDataValue?.city,
            phoneNumber: currProjectDataValue?.phnNum,
            email: currProjectDataValue?.email,
            projectAdmin: selectAdmins,
            projectAdminName: selectAdminsName,
            teamMembers: arr,
            lat: lat,
            lng: lag,
            country: currProjectDataValue?.country,
            kycType: currProjectDataValue?.kycType,
            projectUom: currProjectDataValue?.projectUom,
            companyName: currProjectDataValue?.companyName,
            expectedBudget: currProjectDataValue?.expectedBudget,
            area: currProjectDataValue?.area,
            expectedStartDate: currProjectDataValue?.expectedStartDate,
            expectedEndDate: currProjectDataValue?.expectedEndDate,
            workScope: currProjectDataValue?.workScope,
            billingAddress: currProjectDataValue?.billingAddress,
            billingLandmark: currProjectDataValue?.billingLandmark,
            billingCity: currProjectDataValue?.billingCity,
            billTo: currProjectDataValue?.billTo,
            gstRegNo: currProjectDataValue?.gstRegNo,
            note: currProjectDataValue?.note,
            phoneCode: currProjectDataValue?.phoneCode,
            clientId: localStorage.getItem("clientId"),
            userId: localStorage.getItem("userId"),
            isFromLead: true,
          }
        )
        .then(async function (response) {
          localStorage.setItem("projectId", response?.data?._id);
          localStorage.removeItem("clientId");
          await postReq(
            `${constants.CRM_API}/api/save-projectId-leads?leadId=${activeLead?._id}&projectId=${response?.data?._id}`
          );
          dispatch(createTeam(false));
          dispatch(setIsChecked2(false));
          console.log("2222222222222");
          window.location.assign(`${constants.BASE_URL}/pmt/`);
          // window.location.href("/pmt");
          dispatch(projectDataCurrObj(""));
          // deleteLead()
          projectCreatedWithoutDeletingLead();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    setFilterTeam(teamMember);
  }, [teamMember]);

  useEffect(() => {
    setData(teamMember);
  }, [teamMember]);

  const filtermem = (item) => {
    let x = members.map((curElem) => {
      return curElem.memberName;
    });
    if (!x.includes(item.memberName)) {
      setmembers((prev) => {
        return [...prev, item];
      });
      setMemberRemove((prev) => {
        return [...prev, item.memberId];
      });
      setFilterTeam(teamMember);
    }
  };
  const checkProjectAdmin = () => {
    if (!selectAdmins) {
      alert("Please select Project admin");
    } else if (members.length == 0) {
      alert("Please select Team members ");
    } else {
      // PostTeamMember();
      if (leadInfo?.projectId) {
        CreateNewProjectwithLead(leadInfo?.projectId);
  
      } else {
   
        CreateNewProject();
      }
    }
  };
  // post team member
  const deleteCreatedProject = async () => {
    const res = await postReq(
      `${
        constants.PMT_API
      }/api/projects/edit-location?projectId=${localStorage.getItem(
        "projectId"
      )}`,
      {
        isDeleted: true,
      }
    );
    if (res) {
      console.log(res);
    }
  };

  const handleSearch = (event) => {
    const searchText = event.target.value;
    setSearchTerm(searchText);

    const filteredData = teamMember.filter((item) =>
      item.memberName.toLowerCase().includes(searchText.toLowerCase())
    );

    setData(filteredData);
  };

  const [closeModal, setcloseModal] = useState(false);

  return (
    <React.Fragment>
      <Modal
        title={false}
        show={closeModal}
        size="sm"
        onHide={() => setcloseModal(false)}
        centered
      >
        <h6 style={{ padding: "5% 5%" }}>
          Do you want to discard the project ?
        </h6>
        <Modal.Footer className={styles.footer}>
          <button
            className={styles.cancel}
            onClick={() => setcloseModal(false)}
          >
            Cancel
          </button>
          <button
            className={styles.nextBtn}
            onClick={() => {
              setcloseModal(false);
              dispatch(setIsChecked2(false));
              deleteCreatedProject();
              // dispatch(setProjectReload(true));
            }}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        className={styles.modalHeight}
        show={checked2}
        onHide={() => setcloseModal(true)}
        centered
        size="lg"
      >
        <Modal.Header style={{ borderBottom: "none" }}>
          <Modal.Title>
            <div className={styles.createProjectModalHeader}>
              <span>Add Team</span>
              <div className={styles.closeBtn}>
                <button
                  className={styles.createModalClose}
                  onClick={() => {
                    dispatch(mapState(true));
                    dispatch(setIsChecked2(false));
                  }}
                >
                  <span
                    style={{
                      color: "#C9C9C9",
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Previous{" "}
                  </span>
                </button>
                <button
                  className={styles.createModalSave}
                  onClick={() => {
                    checkProjectAdmin();
                    // dispatch(setProjectReload(true))
                    // window.location.reload()
                    // setSelectAdminsName("")
                    // setselectAdmins("")
                    // setProAdmin("");
                    // setmembers("");
                  }}
                >
                  <span
                    style={{
                      color: "#FFF",
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Finish{" "}
                  </span>
                </button>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className={styles.modalBody} style={{ height: "65vh" }}>
          <div className={styles.formName}>
            <TextField
              style={{ width: "46.5rem" }}
              id="projectAdminAtulya"
              label="Select an Admin"
              variant="outlined"
              value={searchTerm}
              isRequired={true}
              onChange={handleSearch}
              onFocus={() => (setAdminDropdown(true), setShow0(true))}
              // onBlur={() => (setAdminDropdown(false))}
            />
          </div>
          {adminDropdown && show0 && (
            <div className={styles.adminDropDown}>
              {data?.map((elem, i) => {
                return (
                  <div
                    className={styles.adminElem}
                    onClick={() => {
                      setselectAdmins(elem?.memberId);
                      setSelectAdminsName(elem?.memberName);
                      setSearchTerm(elem?.memberName);
                    }}
                  >
                    <div
                      style={{
                        width: "2.25rem",
                        height: "2.25rem",
                        borderRadius: "50%",
                        backgroundColor: "#9747FF",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {elem?.memberName?.charAt(0)
                        ? elem?.memberName?.charAt(0).charAt(0).toUpperCase() +
                          elem?.memberName?.charAt(0).slice(1)
                        : "-"}
                    </div>
                    <span className={styles.adminName}>{elem?.memberName}</span>
                  </div>
                );
              })}
            </div>
          )}

          <div className={styles.inputSection2} onClick={() => hideMember()}>
            <div className={styles.addMembers}>
              {" "}
              <span className={styles.addTeamMember}>Select Team Member </span>
              <div> Total added team members : {memberRemove.length}</div>
            </div>
          </div>

          <>
            {/* <input
              className={styles.input2}
              value={inputText}
              // style={{border:'none'}}
              onChange={inputField}
              placeholder="Search Team Member"
              onBlur={() =>
                setTimeout(() => {
                  setsuggestion(false);
                }, 300)
              }
            /> */}
            <div className={styles.teamMemText}>
              <TextField
                style={{ width: "46.5rem", background: "#FAFAFA" }}
                label="Search Team Member"
                variant="outlined"
                value={inputText}
                isRequired={true}
                onChange={inputField}
              />
            </div>

            <div className={styles.teamMemberCont}>
              {filterTeam?.map((elem, index) => {
                return (
                  <>
                    {elem?.memberName != selectAdminsName && (
                      <div className={styles.teamMemberElem}>
                        <div className={styles.teamMemberElemLeft}>
                          <div
                            style={{
                              width: "2.25rem",
                              height: "2.25rem",
                              borderRadius: "50%",
                              backgroundColor: "#9747FF",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: "1%",
                            }}
                          >
                            {" "}
                            {elem?.memberName?.charAt(0)
                              ? elem?.memberName
                                  ?.charAt(0)
                                  .charAt(0)
                                  .toUpperCase() +
                                elem?.memberName?.charAt(0).slice(1)
                              : "-"}
                          </div>
                          <span className={styles.adminName}>
                            {elem?.memberName
                              ? elem?.memberName.charAt(0).toUpperCase() +
                                elem?.memberName.slice(1)
                              : "-"}
                          </span>
                        </div>
                        {memberRemove.includes(elem?.memberId) ? (
                          <div
                            className={styles.remBtn}
                            onClick={() => removeTags(elem?.memberId)}
                          >
                            <span
                              style={{ color: "#B40000", fontWeight: "600" }}
                            >
                              Remove
                            </span>
                          </div>
                        ) : (
                          <div
                            className={styles.addBtn}
                            onClick={() => {
                              hideMember(elem, index);
                              filtermem(elem);
                            }}
                          >
                            <span
                              style={{ color: "#0084EF", fontWeight: "600" }}
                            >
                              + Add
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default CreateNewProject2;
