import React, { useEffect, useState, useRef } from "react";
import styles from "./teamOnboard.module.css";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  createFilterOptions,
  useMediaQuery,
} from "@mui/material";
import { Carousel } from "react-bootstrap";
import { AiOutlineArrowLeft, AiOutlineConsoleSql, AiOutlinePlusCircle } from "react-icons/ai";
import { postReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import { useNavigate } from "react-router-dom";
import {
  createDesignation,
  errorChecker,
  getDesignation,
  toasterControl,
  validationFunction,
} from "../../piservices/commonService";
import { useSelector } from "react-redux";
import { getUserId } from "../../piservices/authService";
import {
  constants,
  teamOnboardVerifyObj,
} from "../../piservices/constants";
import idesign from "../../Assets/logo.png";
import { FiMinusCircle } from "react-icons/fi";
import expf from "../../piservices/constants";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import toast from "react-hot-toast";

const filter = createFilterOptions();

const TeamOnboard = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryString=window.location.search;
  const hasQueryParams = queryString !== '';
  
  if(hasQueryParams){
 localStorage.setItem("token",  queryParams.get('token'));
 localStorage.setItem("userId", queryParams.get('userId'))
  }

  // useEffect(()=>{
  //   window.location.href="https://uat.idesign.market/team-onboard/"
  //   },[window.location.href])

const [rolesAssigned,setRolesAssigned] = useState()
  const isMobile = useMediaQuery("(max-width:767px)");
  const navigateTo = useNavigate();
  const { designationArr } = useSelector((state) => state.onboarding);
  const [inputData, setinputData] = useState([
    {
      fName: "",
      Lname: "",
      email: "",
      role: "",
      designation: "",
    },
    {
      fName: "",
      Lname: "",
      email: "",
      role: "",
      designation: "",
    },
    {
      fName: "",
      Lname: "",
      email: "",
      role: "",
      designation: "",
    },
  ]);

  useEffect(()=>{
  const url= window.location.href;
  const urlParts = url.split('/');
const lastSegment = urlParts[urlParts.length - 1];
localStorage.setItem("currentPageUrl", lastSegment)
},[])

const [predefinedRoles, setpreDefinedRoles] = useState([]);
  const [error, seterror] = useState([{}, {}, {}]);

  const handleinput = (name, event, newValue, index) => {
    const { value } = event.target;
    // const alphaValue = value.replace(/[^a-zA-Z]/g, '');
    const alphaValue = value.replace(/[^a-zA-Z\s]/g, '');
    if (newValue) {
      if (name === "fName" || name === "Lname") {
        if (value.length < 16) {
          let x = JSON.parse(JSON.stringify(inputData));
          x[index][name] = newValue;
          setinputData(x);
          let valtext = validationFunction(
            teamOnboardVerifyObj[name],
            newValue
          );
          let y = error;
          if (valtext) {
            y[index][name] = true;
          } else {
            y[index][name] = false;
          }
          seterror(y);
        }
      } else {
        let x = JSON.parse(JSON.stringify(inputData));
        x[index][name] = newValue;
        setinputData(x);
        let valtext = validationFunction(teamOnboardVerifyObj[name], newValue);
        let y = error;
        if (valtext) {
          y[index][name] = true;
        } else {
          y[index][name] = false;
        }
        seterror(y);
      }
    } else {
      if (name === "fName" || name === "Lname") {
        if (value.length < 31) {
          let x = JSON.parse(JSON.stringify(inputData));
          x[index][name] =alphaValue.charAt(0).toUpperCase() + alphaValue.slice(1);
          setinputData(x);
          let valtext = validationFunction(teamOnboardVerifyObj[name], value);
          let y = error;
          if (valtext) {
            y[index][name] = true;
          } else {
            y[index][name] = false;
          }
          seterror(y);
        }
      } else {
        let x = JSON.parse(JSON.stringify(inputData));
        x[index][name] = value;
        setinputData(x);
        let valtext = validationFunction(teamOnboardVerifyObj[name], value);
        let y = error;
        if (valtext) {
          y[index][name] = true;
        } else {
          y[index][name] = false;
        }
        seterror(y);
      }
    }
  };

  const createAndSelectDesignation = async (desName, index) => {
    const newDes = await createDesignation(desName);
    let x = JSON.parse(JSON.stringify(inputData));
    x[index]["designation"] = newDes;
    setinputData(x);
  };

  useEffect(() => {
    getDesignation();
  }, []);

  const handleOnboarding = async () => {
    const res = await axios
      .post(
        `${
          constants.CRM_API
        }/user/edit-user-details?userId=${localStorage.getItem("userId")}`,
        {
          isOnboardingDone: true,
        }
      )
      .then((res) => {
        localStorage.removeItem("currentPageUrl")
        localStorage.removeItem("CompanyEmail")
        localStorage.removeItem("CompanyAddress")
        localStorage.removeItem("CompanyCity")
        localStorage.removeItem("CompanyPincode")
        localStorage.removeItem("CompanyGST")
        localStorage.removeItem("CompanyNumber")
        localStorage.removeItem("NameCompany")
        localStorage.removeItem("NameCountry")
        localStorage.removeItem("hasVisited")
        localStorage.removeItem("firstTimeMap")
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // invite member
  const teamOnboard = async () => {
    let check = errorChecker(false, error);
    if (check) {
      let x = JSON.parse(JSON.stringify(error));
      inputData.forEach((curElem, index) => {
        Object.keys(curElem).forEach((cur) => {
          let valtext = validationFunction(
            teamOnboardVerifyObj[cur],
            curElem[cur]
          );
          if (valtext) {
            x[index][cur] = true;
          } else {
            x[index][cur] = false;
          }
        });
      });
      seterror(x);
    } else {
      let payload = JSON.parse(JSON.stringify(inputData));
      let x = payload.map((elem) => {
        return {
          firstName: elem.fName,
          lastName: elem.Lname,
          email: elem.email,
          roleName: elem.role,
          designation: elem.designation._id,
          reportTo:localStorage.getItem("userId"),
          addedBy:localStorage.getItem("userId"),
          isAdmin: elem.role==="Admin" ? true : false,
          rolesAssigned:rolesAssigned,
          // designation: elem.designation.designationName,
        };
      });
      let data = x.filter((elem) => {
        return (
          elem.fName !== "" &&
          elem.Lname !== "" &&
          elem.email !== "" &&
          elem.role !== "" &&
          elem.designation !== ""
        );
      });
      const res = await postReq(
        `${constants.CRM_API}${apiEndpoints.teamOnboard}?userId=${getUserId()}`,
        {
          teamMembers: data,
        }
      );
      if (res && !res.error) {
        localStorage.removeItem("currentPageUrl")
        localStorage.removeItem("adminEmail")
        localStorage.removeItem("CompanyEmail")
        localStorage.removeItem("CompanyAddress")
        localStorage.removeItem("CompanyCity")
        localStorage.removeItem("CompanyPincode")
        localStorage.removeItem("CompanyGST")
        localStorage.removeItem("CompanyNumber")
        localStorage.removeItem("NameCompany")
        localStorage.removeItem("NameCountry")
        localStorage.removeItem("hasVisited")
        localStorage.removeItem("location")
        localStorage.removeItem("firstTimeMap")
        localStorage.removeItem("emailId")
        window.location.assign(`${constants.BASE_URL}/admin/`);
      } else {
        console.error(res.error);
      }
    }
  };
  const getPredefinedRoles = async () => {
    try {
      const res = await axios.get(`${constants.CRM_API}/api/enterprise/get-default-roles?userId=${localStorage.getItem("userId")}`);
      if (res?.status === 200) {
        const filteredroles = res?.data?.data?.filter(role=>role?.roleName != "Super Admin")
        console.log('res.data.data :',res?.data?.data)
        setpreDefinedRoles(filteredroles)
      }
    } catch (error) {
      console.error(error);
    }
  };

  function getFeaturesByRoleName(event) {
    const role = predefinedRoles.find(role => role.roleName === event.target.value);
    return role ? setRolesAssigned(role.features) : null;
}

useEffect(()=>{
  getPredefinedRoles()
},[])

const [emailError, setEmailError] = useState({});
// const checkIfEmailExists= async(email, index)=>{
//   const payload={
//     emails:[email]
//   }
//   try {
//     const res = await axios.post(`${constants.CRM_API}/user/get-user-details-by-emailId`, payload);
//     if (res?.status === 200) {
  
//     if (res?.data?.data.length === 0) {
//       setEmailError((prevState) => ({
//         ...prevState,
//         [index]: false,
//       }));
//     } else {
//       setEmailError((prevState) => ({
//         ...prevState,
//         [index]: true,
//       }));
//       toast.error("Email id you have entered is already registered with us")
//     }
  
//     }
//   } catch (error) {
//     console.error(error);
//   }
// }
const [emailExistInForm, setEmialExistInForm] = useState(false)
const [emailExistInFormIndex, setEmialExistInFormIndex] = useState(0)
const [emailSameAsAdmin, setEmailSameAsAdmin] = useState(false)
const [emailSameAsAdminIndex, setEmailSameAsAdminIndex] = useState(0)

const ifEmailExistsInInputFields =(email, index, array)=>{
    for (let i = 0; i < array.length; i++) {
        if (i !== index && array[i].email === email) {
             setEmialExistInForm(true)
             setEmialExistInFormIndex(index)
             return true;
        }else{
          setEmialExistInForm(false)
          return false;
        }
    }
}

console.log("adminEmail", localStorage.getItem("adminEmail"))

const checkIfEmailSameAsAdmin=(email, index)=>{
  console.log("adminEmail", localStorage.getItem("adminEmail"))
if(email==localStorage.getItem("adminEmail")){
  setEmailSameAsAdmin(true)
  setEmailSameAsAdminIndex(index)
  return true;
}else{
  setEmailSameAsAdmin(false)
  return false
}
}

useEffect(()=>{
if(emailSameAsAdmin){
  setEmailError((prevState) => ({
    ...prevState,
    [emailSameAsAdminIndex]: true,
  }));
  toast.error("Team Members cannot have same Email Id as of Admin")
}else{
  setEmailError((prevState) => ({
    ...prevState,
    [emailSameAsAdminIndex]: false,
  }));
}
},[emailSameAsAdmin])

useEffect(()=>{
if(emailExistInForm){
  setEmailError((prevState) => ({
    ...prevState,
    [emailExistInFormIndex]: true,
  }));
  toast.error("Team Members cannot have same Email Id")
}else{
  setEmailError((prevState) => ({
    ...prevState,
    [emailExistInFormIndex]: false,
  }));
}
},[emailExistInForm])

  const checkIfAllFilled = () => {
    const check = inputData.some((curElem) => {
      return (
        curElem.Lname !== "" &&
        curElem.designation !== "" &&
        curElem.email !== "" &&
        curElem.fName !== "" &&
        curElem.role !== ""
      );
    });
    return check;
  };
  const removeArrayItem = (indexToRemove) => {
    setinputData((prevInputData) => {
      return prevInputData.filter((_, index) => index !== indexToRemove);
    });
  };
  const inputContainerRef = useRef(null);

  const isEmailValid = () => {
    return !Object.values(emailError).some((value) => value === true);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.first_contain}>
          {/* <img src={idesign} alt="" style={{ height: "2rem", position: "absolute", top: "2rem", left: "4rem"  }} /> */}
          <div className={styles.logoImgContainer}>
            <img src={idesign} alt="" className={styles.logoImg} />
          </div>

          <Carousel className={styles.carouselMainContainer}
            nextIcon=""
            nextLabel=""
            prevIcon=""
            prevLabel=""
          >
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                <div className={styles.insideCaraouselItem}>
                  <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}><img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group.svg" alt="" className={styles.miniImg} />
                  <div className={styles.heading}>FILE MANAGER</div></div>
                  <div className={styles.para}>Seamlessly upload files and glide through a structured file hierarchy with ease, ensuring your documents are always organised and accessible.</div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Edit%20location%20%26%20description.png" alt="First slide" className={styles.imgg} />
              </div>
              </div>
              <div className={styles.dotsContainer}>
                   <div className={styles.activeDots} ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                </div>
            </Carousel.Item>

            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                <div className={styles.insideCaraouselItem}>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group1.svg" alt="" className={styles.miniImg} />
                  <div className={styles.heading}>TASK MANAGEMENT</div>
                  </div>
                  <div className={styles.para}>
                  Monitor team progress with Task Manager screen and effortlessly create, assign and update tasks. Also generate detailed reports for informed decision-making.
                  </div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Table%20v2-1.png" alt="Second slide" className={styles.imgg} />
              </div>
              </div>
              <div className={styles.dotsContainer}>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.activeDots} ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                </div>
            </Carousel.Item>
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem" }}>
                <div className={styles.insideCaraouselItem}>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/CurrencyDollarSimple.svg"
                  alt="" className={styles.miniImg}
                />
                  <div className={styles.heading}>Quotation</div>
                  </div>
                  <div className={styles.para}>
                  Effortlessly create quotations from an item library. Compare and manage quotations efficiently. Streamline budget creation and purchase orders.
                  </div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Create%20Quotation.png" alt="Third slide" className={styles.imgg} />
              </div>
              </div>
              <div className={styles.dotsContainer}>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.activeDots} ></div>
                  <div className={styles.notActiveDots}  ></div>
                </div>
            </Carousel.Item>

            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                <div className={styles.insideCaraouselItem}>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/LineSegments.svg" alt="" className={styles.miniImg} />
                  <div className={styles.heading}>Timeline</div>
                  </div>
                  <div className={styles.para}>
                  Easily create and allocate hours with Timesheets. Track project progress with multiple views. Create detailed timelines with predecessors, subtasks, and checklists.
                  </div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/For%20onb%201.png" alt="Third slide" className={styles.imgg} />
              </div>
              </div>
              <div className={styles.dotsContainer}>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.activeDots} ></div>
                </div>
            </Carousel.Item>
          </Carousel>  
        </div>

        {/* second div */}
        <div className={styles.second_contain}>
          <div className={styles.PersonalContent_div}>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            > */}
            <div className={styles.getItemOnboard}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className={styles.personal_head}>
                  Get your team on board
                </div>
                <div className={styles.head_para}>
                  Invite your team mates and collaborate
                </div>
              </div>

              <button
                className={styles.backBtn}
                onClick={() => navigateTo("/personal-data")}
              >
                <AiOutlineArrowLeft className={styles.backArrow} />
                Go back
              </button>
              <div className={styles.addLater}>
                <a
                   href={`${constants.BASE_URL}/admin/`}
                  className={styles.skip}
                  style={{ textDecoration: "none" }}
                  onClick={()=>handleOnboarding()}
                >
                  Add Later
                </a>
              </div>
            </div>

            {/* <div>Invite your team mates and collaborate</div> */}

            <div ref={inputContainerRef} 
              style={{
                overflowY: "scroll",
                height: "32rem",
                marginTop: isMobile ? "0rem" : "0.5rem",
              }}
              className="example"
            >
              {inputData.map((curElem, index) => {
                return (
                  <div style={{ marginTop: "1rem", marginBottom: "0.5rem" }}>
                    {/* all inputs div */}
                    {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.75rem",
                      }}
                    >
                      <div
                        className={styles.inputWrapper}
                        style={{
                          borderRadius: "0.875rem",
                          border: "0.75px solid #C8C8C8",
                          padding: "0.75rem",
                        }}
                      >
                        {/* <div style={{ display: "flex" }}> */}
                        <div className={styles.wrapper}>
                          <div className={styles.textAreaWrapper}>
                            <TextField
                              id="outlined-basic"
                              error={error[index].fName}
                              label="First Name"
                              variant="outlined"
                              // className="saas-form "
                              type="text"
                              value={curElem.fName}
                              onChange={(event) =>
                                handleinput("fName", event, false, index)
                              }
                              sx={{
                                width: "50%",
                                marginRight: "0.5rem",
                                marginBottom: "0.5rem",
                                "@media (max-width: 600px)": {
                                  marginRight: "0rem",
                                  marginBottom: "0rem",
                                },
                              }}
                              InputProps={{ style: { borderRadius: "8px" } }}
                              inputProps={{
                                style: {
                                  padding: isMobile ? "18px 20px" : "16.5px 14px",
                                  fontSize: isMobile ? "14px" : "18px",
                                },
                              }}
                            />

                            <TextField
                              id="outlined-basic"
                              error={error[index].Lname}
                              label="Last Name"
                              variant="outlined"
                              className="saas-form"
                              type="text"
                              value={curElem.Lname}
                              onChange={(event) =>
                                handleinput("Lname", event, false, index)
                              }
                              sx={{
                                width: "50%",
                                marginRight: "0.5rem",
                                marginBottom: "0.5rem",
                                "@media (max-width: 600px)": {
                                  marginRight: "0rem",
                                  marginBottom: "0rem",
                                },
                              }}
                              InputProps={{ style: { borderRadius: "8px" } }}
                              inputProps={{
                                style: {
                                  padding: isMobile ? "18px 20px" : "16.5px 14px",
                                  fontSize: isMobile ? "14px" : "18px",
                                },
                              }}
                            />
                          </div>
                          <div className={styles.emailWrapper}>
                            <TextField
                              id="outlined-basic"
                              error={error[index].email || emailError[index] }
                              label="Email"
                              variant="outlined"
                              className="saas-form"
                              type="email"
                              value={curElem.email}
                              onBlur={() =>{
                                checkIfEmailSameAsAdmin(curElem.email, index)
                                ifEmailExistsInInputFields(curElem.email, index, inputData)
                                // checkIfEmailExists(curElem.email, index)
                              } }
                              onChange={(event) =>
                                handleinput("email", event, false, index)
                              }
                              sx={{
                                width: "100%",
                                "@media (max-width: 600px)": { width: "100%" },
                              }}
                              InputProps={{ style: { borderRadius: "8px" } }}
                              inputProps={{
                                style: {
                                  padding: isMobile ? "18px 20px" : "16.5px 14px",
                                  fontSize: isMobile ? "14px" : "18px",
                                },
                              }}
                            />
                          </div>
                        </div>
                        {/* <div style={{ display: "flex" }}> */}
                        <div
                          className={styles.roleWrapper}
                          style={{ width: "100%" }}
                        >
                          {/* <FormControl
                            sx={{
                              width: "50%",
                              marginRight: "0.5rem",
                              "@media (max-width: 600px)": {
                                width: "50%",
                                marginBottom: "0rem",
                                marginRight: "0rem",
                              },
                            }}
                            error={error[index].role}
                          >
                            <InputLabel id="demo-simple-select-helper-label">
                              Role
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={curElem.role}
                                label="Role"
                                onChange={(event) =>
                                  handleinput("role", event, false, index)
                                }
                              >

                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value="Admin">Admin</MenuItem>
                                <MenuItem value="Site Manager">
                                  Site Manager
                                </MenuItem>
                                <MenuItem value="Project Head">
                                  Project Head
                                </MenuItem>
                                <MenuItem value="Team Leader">
                                  Team Leader
                                </MenuItem>
                                <MenuItem value="Accountant">
                                  Accountant
                                </MenuItem>
                                <MenuItem value="Purchase Order">
                                  Purchase Order
                                </MenuItem>
                                <MenuItem value="BD/Sales">BD/Sales</MenuItem>
                              </Select>
                                
                          </FormControl> */}

                          {
                              localStorage.getItem("hasQueryParams")==="yes" ?
                              <FormControl
                            sx={{
                              width: "50%",
                              marginRight: "0.5rem",
                              "@media (max-width: 600px)": {
                                width: "50%",
                                marginBottom: "0rem",
                                marginRight: "0rem",
                              },
                            }}
                            error={error[index].role}
                          >
                            <InputLabel id="demo-simple-select-helper-label">
                              Role
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={curElem.role}
                              label="Role"
                              onChange={(event) =>{
                                handleinput("role", event, false, index)
                              }
                              }
                            >
                              {/* <MenuItem value="">
                                <em>None</em>
                              </MenuItem> */}
                              <MenuItem value="Admin">Admin</MenuItem>
                              <MenuItem value="Site Manager">
                                Site Manager
                              </MenuItem>
                              <MenuItem value="Accountant">
                                Accountant
                              </MenuItem>
                              <MenuItem value="Purchase Manager">
                              Purchase Manager
                              </MenuItem>
                              <MenuItem value="Accountant">
                                Accountant
                              </MenuItem>
                            </Select>
                          </FormControl>
                              :
                              <FormControl
                              sx={{
                                width: "50%",
                                marginRight: "0.5rem",
                                "@media (max-width: 600px)": {
                                  width: "50%",
                                  marginBottom: "0rem",
                                  marginRight: "0rem",
                                },
                              }}
                              error={error[index].role}
                            >
                              <InputLabel id="demo-simple-select-helper-label">
                                Role
                              </InputLabel>
                              <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  value={curElem.role}
                                  label="Role"
                                  onChange={(event) =>{
                                    getFeaturesByRoleName(event)
                                    handleinput("role", event, false, index)
                                  }}
                                >
  
                                  {/* <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem> */}
                                {
                                predefinedRoles.map((item, index) => (
                                  <MenuItem value={item?.roleName} key={index}>
                                    {item?.roleName}
                                  </MenuItem>
                                ))
                              }
                                  {/* <MenuItem value="Admin">Admin</MenuItem>
                                  <MenuItem value="Site Manager">
                                    Site Manager
                                  </MenuItem>
                                  <MenuItem value="Project Head">
                                    Project Head
                                  </MenuItem>
                                  <MenuItem value="Team Leader">
                                    Team Leader
                                  </MenuItem>
                                  <MenuItem value="Accountant">
                                    Accountant
                                  </MenuItem>
                                  <MenuItem value="Purchase Order">
                                    Purchase Order
                                  </MenuItem>
                                  <MenuItem value="BD/Sales">BD/Sales</MenuItem> */}
                                </Select>
                                  
                            </FormControl>
                            }

                          <Autocomplete
                            sx={{
                              width: "50%",
                              marginRight: "0.5rem",
                              "@media (max-width: 600px)": {
                                width: "50%",
                                marginBottom: "0rem",
                                marginRight: "0rem",
                              },
                            }}
                            value={curElem.designation}
                            onChange={(event, newValue) => {
                              if (typeof newValue === "string") {
                                if (newValue.length <= 30) {
                                let x = JSON.parse(JSON.stringify(inputData));
                                x[index]["designation"] = {
                                  designationName: newValue,
                                };
                                setinputData(x);
                              }
                              } else if (newValue && newValue.inputValue) {
                                if (
                                  newValue.designationName &&
                                  newValue.designationName.includes("Create")
                                ) {
                                  if (newValue.inputValue.length <= 30) {
                                  createAndSelectDesignation(
                                    newValue.inputValue,
                                    index
                                  );
                                }
                                } else {
                                  if (newValue.inputValue.length <= 30) {
                                  let x = JSON.parse(JSON.stringify(inputData));
                                  x[index]["designation"] = {
                                    designationName: newValue.inputValue,
                                  };
                                  setinputData(x);
                                }
                                }
                              } else {
                                let x = JSON.parse(JSON.stringify(inputData));
                                x[index]["designation"] = newValue;
                                setinputData(x);
                              }
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              const { inputValue } = params;
                              let truncatedInputValue = inputValue.slice(0, 30);
                              // Suggest the creation of a new value
                              const isExisting = options.some(
                                (option) => truncatedInputValue === option.designationName
                              );
                              // const isExisting = options.some(
                              //   (option) =>
                              //     inputValue === option.designationName
                              // );
                              if (truncatedInputValue !== "" && !isExisting) {
                                filtered.push({
                                  inputValue: truncatedInputValue,
                                  designationName: `Create ${truncatedInputValue}`,
                            //       designationName: (
                            //         <>
                            // <span style={{ color: '#1976d2'}}>Create</span>&nbsp;
                            // {truncatedInputValue}
                            //      </>
                            //       ),
                                });
                              }
                              // if (inputValue !== "" && !isExisting) {
                              //   filtered.push({
                              //     inputValue,
                              //     designationName: `Create ${inputValue}`,
                              //   });
                              // }

                              return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="free-solo-with-text-demo"
                            options={designationArr}
                            getOptionLabel={(option) => {
                              // Value selected with enter, right from the input
                              if (typeof option === "string") {
                                return option;
                              }
                              // Add "xxx" option created dynamically
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              // Regular option
                              return option.designationName;
                            }}
                            // renderOption={(props, option) => (
                            //   <li {...props}>{option.designationName}</li>
                            // )}
                            renderOption={(props, option) => (
                              <li {...props}>
                                {option.designationName.startsWith("Create") ? (
                                  <span>
                                    <span style={{ color: '#1976d2' }}>Create</span>
                                    {option.designationName.substring(6)}
                                  </span>
                                ) : (
                                  option.designationName
                                )}
                              </li>
                            )}
                            freeSolo
                            renderInput={(params) => {
                              return(
                                <TextField {...params} label="Designation"
                                InputProps={{
                                  ...params.InputProps,
                                  inputProps: {
                                    ...params.inputProps,
                                    maxLength: 30,      
                                    onKeyPress:(e) => {
                                      if (e.key === 'Enter') {
                                        createAndSelectDesignation(
                                          params.inputProps.value,
                                          index
                                        );
                                      }
                                    },     
                                  },
                                }}
                                />
                              )
                            }}
                          />
                        </div>
                        {/* <div style={{ width: "40%" }}></div> */}
                        <div className={styles.forDiv}></div>
                      </div>
                      {inputData.length > 1 && (
                        <div
                          style={{ cursor: "pointer", color: "red" }}
                          onClick={() => removeArrayItem(index)}
                        >
                          {/* <FiMinusCircle
                            style={{ cursor: "pointer", height: "1.5rem", width: "1.5rem" }}
                          /> */}
                          <span className={styles.binImage}>
                          <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/greyBin.svg"
                          />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              className={styles.addMoreBtn}
              // onClick={() => {
              //   setinputData((prev) => {
              //     return [
              //       ...prev,
              //       {
              //         fName: "",
              //         Lname: "",
              //         email: "",
              //         role: "",
              //         designation: "",
              //       },
              //     ];
              //   });
              //   seterror((prev) => {
              //     return [...prev, {}];
              //   });
              // }}
              onClick={() => {
                if (inputData.length < 16) {
                  setinputData(prev => [
                    ...prev,
                    {
                      fName: "",
                      Lname: "",
                      email: "",
                      role: "",
                      designation: "",
                    },
                  ]);
                  seterror(prev => [
                    ...prev,
                    {},
                  ]);
                } else {
                  toast.error("Maximum limit has been reached")
                }
              }}
              
            >
                <span 
                className={styles.addAnotherPlusInteraction}
                onClick={()=>{
                  if(inputData.length<16){
                    toast.success("A new field has been added")
                  }
                  }}>
              {/* <AiOutlinePlusCircle className="me-1 "/> */}
              <span   className={styles.addAnotherPlusInteractionSpan} >
              <img style={{marginRight:"2px", height:"20px"}}
                   src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/PlusCircleblue.svg"/>
              </span>               
              <span className={styles.addAnotherInteraction}>Add New User</span>
            </span>
            </div>

            <div>
              <button
                className={styles.nextBtn}
                onClick={() => {
                  localStorage.removeItem("hasQueryParams")
                  handleOnboarding();
                  if (checkIfAllFilled() && isEmailValid()) {
                    teamOnboard();
                    // window.location.assign(`${constants.BASE_URL}/admin/`);
                  } else {
                    if(emailExistInForm){
                      toast.error("Team Members cannot have same Email Id")
                    }else{ 
                    toasterControl(
                      true,
                      "Please fill at least one row",
                      "error"
                    );
                    }
                  }
                }}
              >
                Invite
              </button>
              <div
                style={{
                  marginBottom: "10px",
                  display: isMobile ? "block" : "none",
                }}
              ></div>
            </div>
            <div className={styles.addBtnInLast}>
              <a
                href={`${constants.BASE_URL}/admin/`}
                className={styles.skip}
                style={{ textDecoration: "none" }}
                onClick={()=>
                 {
                   localStorage.removeItem("hasQueryParams")
                   handleOnboarding()
                }
                }
              >
                <span className={styles.addLaterInteraction}>
                Add Later
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamOnboard;
