import dpr from "./NewLayoutAssets/dpr.svg";
import timeline from "./NewLayoutAssets/timeline.svg";
import tasks from "./NewLayoutAssets/tasks.svg";
import po from "./NewLayoutAssets/po.svg";
import files from "./NewLayoutAssets/files.svg";
import mom from "./NewLayoutAssets/mom.svg";
import form from "./NewLayoutAssets/form.png";
import quotation from "./NewLayoutAssets/quotation.svg";
import Summary from "./NewLayoutAssets/Summary.svg"
export const sidebar = [
  {
    label: "Summary",
    image: Summary,
    active: false,
    href: `leads/lead-details`,
    accessName: "default",
    active: window.location.pathname.includes("project-dashboard") ? true : false,
  },
  {
    label: "Quotation",
    image: quotation,
    href: `quotation`,
    accessName: "quotation",
    active: window.location.pathname.includes("quotation") ? true : false,
  },
  {
    label: "Files",
    image: files,
    href: `file-manager`,
    accessName: "fileManager",
    active: window.location.pathname.includes("file-manager") ? true : false,
  },
  {
    label: "Tasks",
    image: tasks,
    href: `timeline/task-manager/all-task/task`,
    accessName: "taskManager",
    active: window.location.pathname.includes("taskManager") ? true : false,
  },

  {
    label: "MOM",
    image: mom,
    href: `mom`,
    accessName: "mom",
    active: window.location.pathname.includes("mom") ? true : false,
  },

  {
    label: "Forms",
    image: form,
    active: false,
    href: `library/templates/landing-form`,
    accessName: "default",
    active: window.location.pathname.includes("dpr") ? true : false,
  }
];

// bug fix Date - 19-06-2024