import React, { useContext } from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Button, Modal, Spinner, Dropdown } from "react-bootstrap";
import {
  setAddLeadModalOpen,
  newLeadObj,
} from "../../Redux/Slices/newLeadSlice";
import styles from "./AddLeadModalNew.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {constants} from "../../piservices/constants";
import { useEffect } from "react";
import { getReq, postReq } from "../../piservices/apis";
// import { toast } from "react-toastify";
import toast from "react-hot-toast";
import MuiPhoneNumber from "material-ui-phone-number";
import { notification } from "antd";
import desquoContext from "../../picontext/DashBoard/desquoContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import useApiFetchCustomHook from "../../piservices/ApiFetchCustomHook";
import useApiFetchCustomHookForEmailService from "../../piservices/ApiFetchCustomHookForEmailService";
import { namingKeys } from "../../piservices/compCommon";
import { BiChevronDown } from "react-icons/bi";
function EditLeadModalForFormTemplate({ setProgress, show, onHide }) {
  const addLeadModalOpen = useSelector(
    (state) => state.newLeadSlice.addLeadModalOpen
  );
  const profileData = useSelector((state) => state.lmsSlice.profileData);
  console.log("profiledata", profileData);
  const context = useContext(desquoContext);
  const { activeLead } = context;
  const [leadFirstName, setLeadFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const dispatch = useDispatch();
  const [leadLastName, setLeadLastName] = useState("");
  const [phoneNumber, setPhonenumber] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [errorPin, setErrorPin] = useState(false);
  const [leadEmail, setLeadEmail] = useState("");
  const [leadCity, setLeadCity] = useState("");
  const [leadArea, setLeadArea] = useState("");
  const [leadAddress, setLeadAddress] = useState();
  const [workScope, setWorkScope] = useState([]);
  const [leadCityCode, setLeadCityCode] = useState();
  const [userData, setUserData] = useState({});
  const [leadSources, setLeadSources] = useState([]);
  const [leadWorkTypes, setLeadWorkTypes] = useState([]);
  const [areaUnit, setAreaUnit] = useState("");
  const [assignedToObject, setAssignedToObject] = useState("");
  const [discardOpen, setDiscardOpen] = useState(false);
  const [sourceNames, setSourceNames] = useState([]);
  const [leadSource, setLeadSource] = useState([]);
  const [leadWorkType, setLeadWorkType] = useState([]);
  const [sourceName, setSourceName] = useState([]);
  const [selectedLeadSource, setSelectedLeadSource] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [assignedToName, setAssignedToName] = useState("");
  const [tempLeadSource, setTempLeadSource] = useState();
  const [prevStateofAllValue, setPrevStateofAllValue] = useState({});
  const [selectedSourceName, setSelectedSourceName] = useState("");
  const [sourceNameArchitectDetails, setSourceNameArchitectDetails] = useState(
    {}
  );

  const addLeadFormTemplate = useSelector(
    (state) => state.lmsSlice.addLeadFormTemplate
  );
  const [addNewLeadSource, setAddLeadNewSource] = useState("");
  const [addNewWorkScope, setAddNewWorkType] = useState("");
  const [addNewSourceName, setAddNewSourceName] = useState("");
  const [buttonClickedOnce, setButtonClickedOnce] = useState(false);

  const [allStoreData, setAllStoreData] = useState([]);
  const [storeDataState, setstoreDataState] = useState("");
  const [formData, setFormData] = useState({});
  const [teamLeadArr, setTeamLeadArr] = useState([]);
  const [budget, setBudget] = useState();
  const [error, setError] = useState(false);
  const projectObj = useSelector((state) => state.newLeadObj);
  const tempObj = {
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    pinCode: "",
    phoneNumber: "",
    budget: "",
    requirements: "",
    leadSource: "",
    sourceName: "",
    assignedTo: "",
    area: "",
  };
  const { apiFetch } = useApiFetchCustomHook();
  let { tab } = useParams();
  const allNotificationsObjects = useSelector(
    (state) => state.notificationSlice.allNotificationsObjects
  );
  const { sendEmailPushBellNotifications } =
    useApiFetchCustomHookForEmailService();

  console.log("selectedLeadSourceinedit", selectedLeadSource);
  useEffect(() => {
    setPrevStateofAllValue({
      name: activeLead?.name,
      phoneNumber: activeLead?.phoneNumber,
      budget: activeLead?.budget,
      email: activeLead?.email,
      address: activeLead?.address,
      city: activeLead?.city,
      pinCode: activeLead?.pinCode,
      requirements: activeLead?.requirements,
      leadSource: activeLead?.leadSource,
      sourceName: activeLead?.sourceName,
      assignedTo: activeLead?.assignedTo?._id,
      areaUnit: activeLead?.areaUnit,
      area: activeLead?.area,
    });
    setTempLeadSource(
      leadSources.find(
        (leadSource) => leadSource?.leadSource === activeLead?.leadSource
      )
    );

    const tempSourceName = sourceNames.find(
      (sourceName) => sourceName.sourceName === activeLead?.sourceName
    );
    setLeadFirstName(
      activeLead
        ? activeLead?.firstName
          ? activeLead?.firstName
          : activeLead?.name
        : ""
    );
    setLeadLastName(activeLead ? activeLead?.lastName : "");
    setPhonenumber(activeLead ? activeLead?.phoneNumber : "");
    setLeadEmail(activeLead ? activeLead?.email : "");
    setLeadCity(activeLead ? activeLead?.city : "");
    setLeadArea(activeLead ? activeLead?.area : "");
    setLeadAddress(activeLead ? activeLead?.address : "");
    setLeadCityCode(activeLead ? activeLead?.pinCode : "");
    setWorkScope(activeLead ? activeLead?.requirements : []);
    setSelectedLeadSource(activeLead ? activeLead?.leadSource : "");
    // setLeadSource(activeLead ? activeLead?.leadSource : "")
    setLeadSource(tempLeadSource > -1 ? tempLeadSource.id : "");
    setAssignedTo(activeLead ? activeLead?.assignedTo?._id : "");
    // setstoreDataState(activeLead ? activeLead?.storeData : "")
    setSelectedSourceName(activeLead ? activeLead?.sourceName : "");
    setSourceName(tempSourceName ? tempSourceName?._id : "");
    setBudget(activeLead ? activeLead?.budget : "");
    setAreaUnit(activeLead?.areaUnit ? activeLead?.areaUnit : "sqft");
    setSourceNameArchitectDetails(
      activeLead ? activeLead?.architectDetails : {}
    );
  }, [activeLead, leadSources, tempLeadSource]);
  const exceptThisSymbols2 = [
    "~",
    "`",
    "!",
    "#",
    "%",
    "$",
    "^",
    "&",
    "*",
    "(",
    " ",
    ")",
    "+",
    "=",
    "[",
    "_",
    "]",
    "'",
    ";",
    "/",
    "{",
    "}",
    "|",
    ,
    "<",
    ">",
    "?",
    ",",
  ];


  const leadFirstNameHandle = (e) => {
    if (e.target.value?.length <= 24) {
      if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
        setLeadFirstName(e.target.value);
        setFirstNameError(false);
      }
    } else {
      setFirstNameError(true);
    }
  };

  const leadLastNameHandle = (e) => {
    if (e.target.value?.length <= 24) {
      if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
        setLeadLastName(e.target.value);
        setLastNameError(false);
      }
    } else {
      setLastNameError(true);
    }
  };


  console.log("addLeadFormTemplateineditleadd", addLeadFormTemplate);
  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 10) {
      setPhonenumber(inputValue);
      if (/^\d+$/.test(inputValue) && inputValue.length <= 10) {
        if (inputValue.length <= 9) {
          setPhoneError(true);
        } else {
          setPhoneError(false);
        }
        setPhonenumber(inputValue);
      }
    }
  };
  const saveLeadSource = async () => {
    if (addNewLeadSource.length > 0) {
      const res = await postReq(`${constants.CRM_API}/user/save-lead-source`, {
        userId: localStorage.getItem("userId"),
        leadSource: addNewLeadSource,
      });

      if (res && !res.error) {
        // console.log('getting lead source', res.data.data)
        getLeadSource();
        setAddLeadNewSource("");
        toast.success("New Lead Source Added");
      } else {
        console.log(res.error);
      }
    }
  };
  const leadCityHandle = (e) => {
    if (e.target.value?.length <= 23 && /^[a-zA-Z\s]*$/.test(e.target.value)) {
      setLeadCity(e.target.value);
    }
  };
  const saveworkScope = async () => {
    if (addNewWorkScope.length > 0) {
      const res = await postReq(`${constants.CRM_API}/user/save-work-type`, {
        userId: localStorage.getItem("userId"),
        type: addNewWorkScope,
      });

      if (res && !res.error) {
        // console.log('getting lead source', res.data.data)
        getWorkTypes();
        setAddNewWorkType("");
        toast.success("New Work Scope Added");
      } else {
        console.log(res.error);
      }
    }
  };
  const savesourceName = async () => {
    if (addNewSourceName.length > 0) {
      const res = await postReq(`${constants.CRM_API}/user/save-source-name`, {
        userId: localStorage.getItem("userId"),
        sourceName: addNewSourceName,
      });

      if (res && !res.error) {
        // console.log('getting lead source', res.data.data)
        getSourceName();
        setAddNewSourceName("");
        toast.success("New Source Name Added");
      } else {
        console.log(res.error);
      }
    }
  };
  const handleNewLeadClose = () => {
    setLeadFirstName(
      activeLead
        ? activeLead?.firstName
          ? activeLead?.firstName
          : activeLead?.name
        : ""
    );
    setLeadLastName(activeLead ? activeLead?.lastName : "");
    setPhonenumber(activeLead ? activeLead?.phoneNumber : "");
    setEmailError(false);
    setLeadEmail(activeLead ? activeLead?.email : "");
    setLeadCity(activeLead ? activeLead?.city : "");
    setLeadArea(activeLead ? activeLead?.area : "");
    setLeadAddress(activeLead ? activeLead?.address : "");
    setWorkScope(activeLead ? activeLead?.requirements : []);
    setAssignedTo(activeLead ? activeLead?.assignedTo?._id : "");
    setErrorPin(false);
    setLeadArea(activeLead ? activeLead?.area : "");
    setLeadCityCode(activeLead ? activeLead?.pinCode : "");
    setFirstNameError(false);
    setLastNameError(false);
    setBudget(activeLead ? activeLead?.budget : "");
    dispatch(setAddLeadModalOpen(false));
    setDiscardOpen(false);
    onHide();
  };

  const handlePinCodeChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    // setLeadCityCode(inputValue);
    if (event.target.value?.length >= 1) {
      if (event.target.value?.length <= 6) {
        setLeadCityCode(inputValue);

        if (
          /^\d+$/.test(inputValue) &&
          inputValue.length >= 6 &&
          inputValue.length <= 6
        ) {
          setLeadCityCode(inputValue);
          setErrorPin(false);
        } else {
          setErrorPin(true);
          setLeadCityCode(inputValue);
        }
      }
    } else {
      setLeadCityCode(inputValue);
      setErrorPin(false);
    }
  };
  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,100}$/i;
    if (emailPattern.test(inputValue)) {
      setLeadEmail(inputValue);
      setEmailError(false);
    } else {
      setEmailError(true);
      setLeadEmail(inputValue);
    }
  };

  const handleBudget = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 9) {
      setBudget(inputValue);
      if (/^\d+$/.test(inputValue) && inputValue.length <= 9) {
        setBudget(inputValue);
      }
    }
  };

  function handleLeadArea(event) {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 9) {
      setLeadArea(inputValue);
      if (/^\d+$/.test(inputValue) && inputValue.length <= 9) {
        setLeadArea(event.target.value);
      }
    }
  }

  const handleSubmit = () => {
    if (
      addLeadFormTemplate?.fields[0]?.isFirstNameMandatory &&
      leadFirstName == ""
    ) {
      alert("Please enter First Name.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isLastNameMandatory &&
      leadLastName == ""
    ) {
      alert("Please enter Last Name.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isContactNumberMandatory &&
      (phoneError || phoneNumber == "")
    ) {
      alert("Please enter Contact Number.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isEmailMandatory &&
      (emailError || leadEmail == "")
    ) {
      alert("Please enter email.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isAddressMandatory &&
      leadAddress == ""
    ) {
      alert("Please enter address.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isCityMandatory &&
      leadCity == ""
    ) {
      alert("Please enter city.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isPostalCodeMandatory &&
      (errorPin || leadCityCode == "")
    ) {
      alert("Please enter postal code.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isWorkScopeMandatory &&
      workScope == ""
    ) {
      alert("Please enter work scope.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isBudgetMandatory &&
      budget == ""
    ) {
      alert("Please enter Budget.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isAreaMandatory &&
      leadArea == ""
    ) {
      alert("Please enter Area.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isLeadSourceMandatory &&
      selectedLeadSource == ""
    ) {
      alert("Please enter Lead source.");
    } else if (
      addLeadFormTemplate?.fields[0]?.isSouceNameMandatory &&
      selectedSourceName == ""
    ) {
      alert("Please enter Source name");
    } else if (
      addLeadFormTemplate?.fields[0]?.isAssignToMandatory &&
      assignedTo == ""
    ) {
      alert("Please enter Assign to");
    } else {
      handleFormSubmit({
        name: `${leadFirstName} ${leadLastName ? leadLastName : ""}`,
        firstName: leadFirstName,
        lastName: leadLastName ? leadLastName : "",
        email: leadEmail,
        address: leadAddress,
        city: leadCity,
        pinCode: leadCityCode,
        phoneNumber: phoneNumber,
        budget: budget,
        requirements: workScope,
        leadSource: selectedLeadSource,
        sourceName: selectedSourceName,
        assignedTo: assignedTo,
        area: leadArea,
        areaUnit: areaUnit,
        // storeData: storeDataState
      });
    }
  };
  const getUserbyId = async (id) => {
    const res = await getReq(`${constants.CRM_API}/user/getUserById?userId=${id}`);

    if (res && !res.error) {
      setUserData(res?.data?.data);
    } else {
      console.log(res.error);
    }
  };

  const updateProjectInfoOnEditingLead = async () => {
    await axios
      .post(
        `${constants.PMT_API}/api/projects/edit-project?id=${activeLead?.projectId}`,
        {
          // id: projectId,
          // clientName: newProjectData.cliName,
          name: `${leadFirstName} ${leadLastName ? leadLastName : ""}`,
          location: leadAddress,
          phoneNumber: phoneNumber,
          email: leadEmail,
          userId: localStorage.getItem("userId"),
        }
      )
      .then(function (response) {
        localStorage.setItem("projectId", response?.data?._id);
        // localStorage.removeItem("clientId")
        // dispatch(createTeam(false))
        // deleteLead()
        // window.location.reload();
        // setTimeout(() => {
        //     onHide()
        //     window.location.reload();
        // }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  let differenceFields = [];
  const showError = (error, differenceFields) => {
    // console.log(error)
    if (error.response && error.response.data.message) {
      // toast.error(error.response.data.message);
      toast.success(error.response.data.message, {
        autoClose: 3000,
      });
      // clg
      // alert(error.response.data.message)
      return;
    } else {
      toast.success("Lead Edited Successfully", {
        autoClose: 3000,
      });
      if (activeLead?.projectId) {
        updateProjectInfoOnEditingLead();
      } else {
        // setTimeout(() => {
        //     onHide()
        //     window.location.reload();
        // }, 1000);
        console.log("lead edited succesfully", differenceFields);
        onHide();
        apiFetch(tab);
        sendEmailPushBellNotifications(
          "Edit Lead",
          {
            "@leadName": `${leadFirstName} ${leadLastName}`,
            "[Lead Name]": `${leadFirstName} ${leadLastName}`,
            "@username": profileData.fullName,
            "@changeObject": differenceFields?.join("<br />"),
          },
          [activeLead.assignedTo]
        );
      }
    }
  };
  const handleFormSubmit = async (data) => {
    for (let key in data) {
      if (data[key] !== prevStateofAllValue[key]) {
        if (key == "assignedTo") {
          differenceFields.push(
            `The Lead has been reassigned from ${activeLead?.assignedTo?.fullName} to ${assignedToName}`
          );
        } else {
          if (!(key == "firstName" || key == "lastName" || key == "areaUnit")) {
            differenceFields.push(
              `The Lead's ${key} has been changed from ${prevStateofAllValue[key]} to ${data[key]}`
            );
          }
        }
      }
    }
    console.log("differenceFields", differenceFields);
    setButtonClickedOnce(true);
    try {
      const res = await postReq(
        `${constants.CRM_API}/user/edit-lead?id=${
          activeLead?._id
        }&userId=${localStorage.getItem("userId")}`,
        {
          ...data,
          architectDetails: sourceNameArchitectDetails,
          logText: differenceFields,
        }
      );
      console.log("differenceFields22", differenceFields);
      showError(res.error, [...differenceFields]);
      if (res && !res.error) {
        console.log("");
        setButtonClickedOnce(false);
        toast.success(res?.message || "Lead Edited Successfully", {
          autoClose: 3000,
        });
      }
      // await setProgress(100);
    } catch (error) {
      setButtonClickedOnce(false);
      // console.log(error)
      onHide();
      showError(error, [...differenceFields]);
    }
  };
  const getTeamLeads = async () => {
    const res = await getReq(
      `${
        constants.CRM_API
      }/api/enterprise/get-team-leaders?userId=${localStorage.getItem(
        "userId"
      )}&access=1`
    );
    if (res && !res.error) {
      setTeamLeadArr(res.data.data);
    } else {
      console.log(res.error);
    }
  };

  const getLeadSource = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-lead-source?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      setLeadSources(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const getWorkTypes = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-work-types?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      setLeadWorkTypes(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  // const getStoreData = async () => {
  //     const res = await getReq(
  //         `${constants.CRM_API}/user/get-store-data?userId=${localStorage.getItem(
  //             "userId"
  //         )}`
  //     );
  //     if (res && !res.error) {
  //         // console.log('getting lead source', res.data.data)
  //         setAllStoreData(res.data.data);
  //     } else {
  //         console.log(res.error);
  //     }
  // };
  const getSourceName = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-source-name?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      setSourceNames(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const handleLeadSource = (e) => {
    // const tempLeadSource = leadSources.find(
    //   (leadSource) => leadSource.id === e.target.value
    // );
    // setSelectedLeadSource(tempLeadSource.leadSource);
    // setLeadSource(e.target.value);
    setSelectedLeadSource(e.target.value);
  };
  const handleSourceName = (sourceName1) => {
    // setSourceName(e.target.value);
    const tempSourceName = sourceNames.find(
      (sourceName) => sourceName.sourceName === sourceName1
    );
    setSelectedSourceName(tempSourceName.sourceName);
    if (tempSourceName?.architectDetails) {
      setSourceNameArchitectDetails(tempSourceName?.architectDetails);
    }
  };

  useEffect(() => {
    getLeadSource();
    getSourceName();
    getTeamLeads();
    getWorkTypes();
    getUserbyId(localStorage.getItem("userId"));
  }, []);
  const handleChangeWorkscope = (event) => {
    const {
      target: { value },
    } = event;
    setWorkScope(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleOnFocus = (fieldName, errorStateName) => {
    if (fieldName?.length === 0) {
      errorStateName(true);
    }
  };

  return (
    <>
      <Modal
        title={false}
        show={discardOpen}
        centered
        width={200}
        bodyStyle={{ height: 60 }}
        closable={false}
      >
        <div
          style={{
            border: "1px solid black",
            borderRadius: "5px",
            margin: "5px",
          }}
        >
          <h6 style={{ padding: "3%" }}>Do you want to discard the lead ?</h6>
          <Modal.Footer>
            <div className={styles.discardCloseBtn}>
              <button
                className={styles.createModalSave}
                onClick={handleNewLeadClose}
              >
                <span
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  Yes{" "}
                </span>
              </button>
              <button
                className={styles.createModalClose}
                onClick={() => setDiscardOpen(false)}
              >
                <span
                  style={{
                    color: "#C9C9C9",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  No{" "}
                </span>
              </button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
      <Modal
        show={show}
        onHide={onHide}
        // backdrop="static"
        keyboard={false}
        centered
        // size="lg"
        // width='1000px'
      >
        <Modal.Header
          closeButton
          style={{
            borderBottom: "none",
            backgroundColor: "#0084ef",
            color: "#ffffff",
          }}
        >
          <Modal.Title>Edit Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "65vh", overflow: "scroll" }}>
          <div className={styles.formName}>
            {addLeadFormTemplate?.fields[0]?.firstName && (
              <TextField
                style={{ width: "14rem" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isFirstNameMandatory
                    ? "First Name*"
                    : "First Name"
                }
                size="small"
                helperText={
                  firstNameError && leadFirstName.length == 0
                    ? "Please enter first name"
                    : firstNameError && "Limit of 24 characters is reached"
                }
                variant="outlined"
                onFocus={() => handleOnFocus(leadFirstName, setFirstNameError)}
                isRequired={true}
                error={firstNameError}
                value={leadFirstName}
                InputProps={{
                  inputMode: "text",
                }}
                onChange={(e) => {
                  leadFirstNameHandle(e);
                }}
              />
            )}

            {addLeadFormTemplate?.fields[0]?.lastName && (
              <TextField
                style={{ width: "14rem" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isLastNameMandatory
                    ? "Last Name*"
                    : "Last Name"
                }
                variant="outlined"
                size="small"
                helperText={
                  lastNameError && leadLastName.length == 0
                    ? "Please enter last name"
                    : lastNameError && "Limit of 24 characters is reached"
                }
                error={lastNameError}
                onFocus={() => handleOnFocus(leadLastName, setLastNameError)}
                isRequired={true}
                value={leadLastName}
                InputProps={{
                  inputMode: "text",
                }}
                onChange={(e) => leadLastNameHandle(e)}
              />
            )}
          </div>
          <div className={styles.formName}>
            {addLeadFormTemplate?.fields[0]?.contactNumber && (
              <TextField
                style={{ width: "14rem" }}
                id="outlined-basic"
                label="Contact No*"
                size="small"
                variant="outlined"
                // isRequired={true}
                helperText={
                  phoneNumber.length > 0
                    ? phoneNumber.length < 10
                      ? "Please enter valid number"
                      : ""
                    : ""
                }
                value={phoneNumber}
                onChange={(e) => handlePhoneNumberChange(e)}
                error={phoneNumber.length > 0 && phoneNumber.length < 10}
                InputProps={{
                  inputMode: "numeric",
                  startAdornment: (
                    <InputAdornment position="start">
                      {profileData?.CompanySettingDetails?.countryCode}
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {addLeadFormTemplate?.fields[0]?.email && (
              <TextField
                style={{ width: "14rem" }}
                id="outlined-basic"
                label="Email Address*"
                variant="outlined"
                isRequired={true}
                value={leadEmail}
                size="small"
                onKeyDown={(e) =>
                  exceptThisSymbols2.includes(e.key) && e.preventDefault()
                }
                error={emailError}
                helperText={
                  emailError
                    ? leadEmail.length == 0
                      ? "Please input email"
                      : "Invalid email address"
                    : ""
                }
                onChange={(e) => handleEmailChange(e)}
              />
            )}
          </div>
          <div className={styles.formName}>
            {userData?.rolesAssigned?.crm?.canAssign &&
              addLeadFormTemplate?.fields[0]?.assignTo && (
                <FormControl style={{ width: "100%" }} size="small">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={teamLeadArr?.map((teamLead) => {
                      return {
                        label: teamLead.fullName,
                        id: teamLead._id,
                        email: teamLead.email,
                      };
                    })}
                    onChange={(object, value) => (
                      setAssignedTo(value?.id),
                      setAssignedToName(value?.fullName),
                      setAssignedToObject({
                        email: value?.email,
                        fullName: value?.label,
                        _id: value?._id,
                      })
                    )}
                    // sx={{ width: 225 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label={
                          assignedTo == ""
                            ? "Assign To"
                            : activeLead?.assignedTo?.fullName
                        }
                      />
                    )}
                    // MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                  />
                </FormControl>
              )}
          </div>
          {addLeadFormTemplate?.fields[0]?.address && (
            <div className={styles.formName}>
              <TextField
                style={{ width: "30rem" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isAddressMandatory
                    ? "Address*"
                    : "Address"
                }
                variant="outlined"
                size="small"
                isRequired={addLeadFormTemplate?.fields[0]?.isAddressMandatory}
                value={leadAddress}
                onChange={(e) => setLeadAddress(e.target.value)}
              />
            </div>
          )}
          {addLeadFormTemplate?.fields[0]?.city && (
            <div className={styles.formName}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isCityMandatory
                    ? "City*"
                    : "City"
                }
                size="small"
                variant="outlined"
                isRequired={addLeadFormTemplate?.fields[0]?.isCityMandatory}
                value={leadCity}
                onChange={(e) => leadCityHandle(e)}
              />
            </div>
          )}
          {addLeadFormTemplate?.fields[0]?.postalCode && (
            <div className={styles.formName}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isPostalCodeMandatory
                    ? "Zip/Postal code*"
                    : "Zip/Postal code"
                }
                variant="outlined"
                size="small"
                isRequired={
                  addLeadFormTemplate?.fields[0]?.isPostalCodeMandatory
                }
                value={leadCityCode}
                error={errorPin}
                helperText={
                  errorPin
                    ? leadCityCode.length == 0
                      ? "Please enter city code"
                      : "Invalid Pin Code"
                    : ""
                }
                onChange={(e) => handlePinCodeChange(e)}
                InputProps={{
                  inputMode: "numeric",
                }}
              />
            </div>
          )}
          
            {!addLeadFormTemplate?.fields[0]?.workScopeShortAnswer ? (
              addLeadFormTemplate?.fields[0]?.workScope.length > 0 && (
                <div className={styles.formName}>
                <FormControl style={{ width: "14rem" }} size="small">
                  <InputLabel id="demo-select-large-label">
                    {addLeadFormTemplate?.fields[0]?.isWorkScopeMandatory
                      ? "Work Scope*"
                      : "Work Scope"}
                  </InputLabel>
                  <Select
                    labelId="demo-select-large-label"
                    id="demo-select-large"
                    value={workScope}
                    isRequired={
                      addLeadFormTemplate?.fields[0]?.isWorkScopeMandatory
                    }
                    label={
                      addLeadFormTemplate?.fields[0]?.isWorkScopeMandatory
                        ? "Work Scope*"
                        : "Work Scope"
                    }
                    onChange={(e) => setWorkScope(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 150,
                          "&.MuiPaper-root": {
                            left:
                              window.screen.width < 1400
                                ? "32.8vw !important"
                                : "35vw !important",
                          },
                        },
                      },
                    }}
                  >
                    {addLeadFormTemplate?.fields[0]?.workScope?.map(
                      (leadWorkType) => {
                        return (
                          <MenuItem value={leadWorkType}>
                            {leadWorkType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl></div>
              )
            ) : (
                <div className={styles.formName}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isWorkScopeMandatory
                    ? "Work Scope*"
                    : "Work Scope"
                }
                size="small"
                variant="outlined"
                isRequired={
                  addLeadFormTemplate?.fields[0]?.isWorkScopeMandatory
                }
                value={workScope}
                onChange={(e) => setWorkScope(e.target.value)}
              />  </div>
            )}
        {" "}
       
            {addLeadFormTemplate?.fields[0]?.budgetShortAmswer ? (
                   <div className={styles.formName}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isBudgetMandatory
                    ? "Budget*"
                    : "Budget"
                }
                size="small"
                variant="outlined"
                isRequired={addLeadFormTemplate?.fields[0]?.isBudgetMandatory}
                value={budget}
                onChange={(e) => handleBudget(e)}
                InputProps={{
                  inputMode: "numeric",
                  startAdornment: (
                    <InputAdornment position="start">
                      {profileData?.CompanySettingDetails?.currencySymbol}
                    </InputAdornment>
                  ),
                }}
              /></div>
            ) : (
              addLeadFormTemplate?.fields[0]?.budget.length > 0 && (
                <div className={styles.formName}>
                <FormControl style={{ width: "100%" }} size="small">
                  <InputLabel id="demo-select-large-label">
                    {addLeadFormTemplate?.fields[0]?.isBudgetMandatory
                      ? "Budget*"
                      : "Budget"}
                  </InputLabel>
                  <Select
                    labelId="demo-select-large-label"
                    id="demo-select-large"
                    value={budget}
                    label="Budget"
                    required={addLeadFormTemplate?.fields[0]?.isBudgetMandatory}
                    onChange={(e) => setBudget(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 150,
                          "&.MuiPaper-root": {
                            left:
                              window.screen.width < 1400
                                ? "32.8vw !important"
                                : "35vw !important",
                          },
                        },
                      },
                    }}
                  >
                    {addLeadFormTemplate?.fields[0]?.budget?.map((bud) => {
                      return <MenuItem value={bud}>{bud}</MenuItem>;
                    })}
                  </Select>
                </FormControl></div>
              )
            )}
         
      
            {addLeadFormTemplate?.fields[0]?.areaShortAnswer ? (
                    <div className={styles.formName}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isAreaMandatory
                    ? "Area*"
                    : "Area"
                }
                size="small"
                variant="outlined"
                isRequired={addLeadFormTemplate?.fields[0]?.isAreaMandatory}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Select
                        variant="standard"
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={areaUnit}
                        renderValue={() => areaUnit}
                        label="Unit"
                        // onChange={handleChange}
                        InputProps={{
                          disableUnderline: true, // <== added this
                        }}
                        disableUnderline
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 150,
                              "&.MuiPaper-root": {
                                left:
                                  window.screen.width < 1400
                                  ? "48.5vw !important"
                                  : "52.5vw !important",
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem
                          sx={{ minWidth: 200 }}
                          onClick={() => setAreaUnit("sqft")}
                          value={"sqft"}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className={styles.areaUnit}>Sqft</div>
                          <div className={styles.areaDescription}>
                            Square feet
                          </div>
                        </MenuItem>
                        <MenuItem
                          sx={{ minWidth: 200 }}
                          onClick={() => setAreaUnit("sqm")}
                          value={"sqm"}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className={styles.areaUnit}>Sqm</div>
                          <div className={styles.areaDescription}>
                            Square meter
                          </div>
                        </MenuItem>
                      </Select>
                    </InputAdornment>
                  ),
                }}
                value={leadArea}
                onChange={handleLeadArea}
              /></div>
            ) : (
              addLeadFormTemplate?.fields[0]?.area?.length > 0 && (
                <div className={styles.formName}>
                <FormControl style={{ width: "100%" }} size="small">
                  <InputLabel id="demo-select-large-label">
                    {addLeadFormTemplate?.fields[0]?.isAreaMandatory
                      ? "Area*"
                      : "Area"}
                  </InputLabel>
                  <Select
                    labelId="demo-select-large-label"
                    id="demo-select-large"
                    value={leadArea}
                    label="Area"
                    onChange={(e) => setLeadArea(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 150,
                          "&.MuiPaper-root": {
                            left:
                              window.screen.width < 1400
                                ? "32.8vw !important"
                                : "35vw !important",
                          },
                        },
                      },
                    }}
                  >
                    {addLeadFormTemplate?.fields[0]?.area?.map((ar) => {
                      return <MenuItem value={ar}>{ar}</MenuItem>;
                    })}
                  </Select>
                </FormControl></div>
              )
            )}
        
          
            {!addLeadFormTemplate?.fields[0]?.leadSourceShortAnswer ? (
              addLeadFormTemplate?.fields[0]?.leadSource.length > 0 && (
                <div className={styles.formName}>
                <FormControl style={{ width: "100%" }} size="small">
                  <InputLabel id="demo-select-large-label">
                    {addLeadFormTemplate?.fields[0]?.isLeadSourceMandatory
                      ? "Lead Source*"
                      : "Lead Source"}
                  </InputLabel>
                  <Select
                    labelId="demo-select-large-label"
                    id="demo-select-large"
                    value={selectedLeadSource}
                    isRequired={
                      addLeadFormTemplate?.fields[0]?.isLeadSourceMandatory
                    }
                    label="Lead Source"
                    onChange={(e) => handleLeadSource(e)}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                  >
                    {addLeadFormTemplate?.fields[0]?.leadSource?.map(
                      (ldSource) => {
                        return <MenuItem value={ldSource}>{ldSource}</MenuItem>;
                      }
                    )}
                  </Select>
                </FormControl></div>
              )
            ) : (
                <div className={styles.formName}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label={
                  addLeadFormTemplate?.fields[0]?.isLeadSourceMandatory
                    ? "Lead Source*"
                    : "Lead Source"
                }
                size="small"
                variant="outlined"
                isRequired={
                  addLeadFormTemplate?.fields[0]?.isLeadSourceMandatory
                }
                value={selectedLeadSource}
                onChange={(e) => setSelectedLeadSource(e.target.value)}
                // InputProps={{
                //   inputMode: "numeric",
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       {profileData?.CompanySettingDetails?.currencySymbol}
                //     </InputAdornment>
                //   ),
                // }}
              /></div>
            )}
         
            {!addLeadFormTemplate?.fields[0]?.sourceNameShortAnswer ? (
              addLeadFormTemplate?.fields[0]?.sourceName.length > 0 && (
                <div className={styles.formName}>
                <FormControl style={{ width: "100%" }} size="small">
                  <InputLabel id="demo-select-large-label">
                    {addLeadFormTemplate?.fields[0]?.isSouceNameMandatory
                      ? "Source name*"
                      : "Source name"}
                  </InputLabel>
                  <Select
                    labelId="demo-select-large-label"
                    id="demo-select-large"
                    value={selectedSourceName}
                    label="Source name"
                    required={
                      addLeadFormTemplate?.fields[0]?.isSouceNameMandatory
                    }
                    // error={leadSourceClicked && !selectedLeadSource}
                    // helperText="Please select a Lead source"
                    // onClick={() => setLeadSourceClicked(true)}
                    // onBlur={() => setLeadSourceClicked(false)}
                    onChange={(e) => setSelectedSourceName(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: { maxHeight: 150 },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      },
                    }}
                  >
                    {addLeadFormTemplate?.fields[0]?.sourceName?.map(
                      (srceName) => {
                        return <MenuItem value={srceName}>{srceName}</MenuItem>;
                      }
                    )}
                  </Select>
                  {/* {!selectedLeadSource && leadSourceClicked && (
                    <FormHelperText>
                      <span style={{ color: "#d32f2f" }}>
                        Please select lead source
                      </span>
                    </FormHelperText>
                  )} */}
                </FormControl></div>
              )
            ) : (
                <div className={styles.formName}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label="Source Name"
                size="small"
                variant="outlined"
                isRequired={
                  addLeadFormTemplate?.fields[0]?.isSouceNameMandatory
                }
                value={selectedSourceName}
                onChange={(e) => setSelectedSourceName(e.target.value)}
                // InputProps={{
                //   inputMode: "numeric",
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       {profileData?.CompanySettingDetails?.currencySymbol}
                //     </InputAdornment>
                //   ),
                // }}
              /></div>
            )}
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.closeBtn}>
            <button
              className={styles.createModalClose}
              onClick={() => setDiscardOpen(true)}
            >
              <span
                style={{
                  color: "#C9C9C9",
                }}
              >
                Cancel{" "}
              </span>
            </button>
            <button
              disabled={buttonClickedOnce}
              className={styles.createModalSave}
              onClick={handleSubmit}
            >
              <span
                style={{
                  color: "#FFF",
                }}
              >
                {!buttonClickedOnce ? (
                  "Submit"
                ) : (
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ height: "1.25rem", width: "1.25rem" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                )}
              </span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditLeadModalForFormTemplate;
